package com.appcreator.creatorapp.project

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material.icons.filled.OpenInBrowser
import androidx.compose.material3.DrawerValue
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalNavigationDrawer
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.PermanentNavigationDrawer
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material3.VerticalDivider
import androidx.compose.material3.rememberDrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import coil3.compose.rememberAsyncImagePainter
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.creatorapp.Navigation
import com.appcreator.creatorapp.iconcreator.IconCreatorScreen
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch


@Composable
fun AdaptiveDrawerScaffold(
    appbar: @Composable (@Composable () -> Unit) -> Unit,
    drawerContent: @Composable (() -> Unit) -> Unit,
    content: @Composable () -> Unit
) {
    BoxWithConstraints {
        if(maxWidth < 640.dp) {

            Column {
                val drawerState = rememberDrawerState(DrawerValue.Closed)
                val scope = rememberCoroutineScope()
                appbar {
                    IconButton(onClick = {
                        scope.launch {
                            if (drawerState.isOpen) {
                                drawerState.close()
                            } else {
                                drawerState.open()
                            }
                        }
                    }) {
                        Icon(Icons.Default.Menu, contentDescription = null)
                    }
                }
                HorizontalDivider()
                ModalNavigationDrawer(
                    drawerState = drawerState,
                    drawerContent = {
                        drawerContent {
                            scope.launch {
                                drawerState.close()
                            }
                        }
                    },
                    content = content
                )
            }

        } else {
            Column {
                appbar {}
                HorizontalDivider()
                PermanentNavigationDrawer(
                    drawerContent = { drawerContent { } },
                    content = {
                        VerticalDivider()
                        content()
                    }
                )
            }
        }
    }
}

@Composable
fun ProjectScreen(
    organisation: OrganisationResponse,
    project: Project,
    navigate: (String) -> Unit,
    refresh: () -> Unit,
    openDownload: (String) -> Unit
) {
    var selected by remember { mutableStateOf(SettingsOptions.Releases) }
    AdaptiveDrawerScaffold(
        appbar = {
            TopAppBar(
                navigationIcon = it,
                title = {
                    Text(project.projectConfig.name)
                },
                actions = {
                    IconButton(onClick = {
                        navigate("/${Navigation.Organisations.route}/${organisation.organisation.id}")
                    }) {
                        Box(
                            Modifier.size(30.dp)
                                .background(
                                    MaterialTheme.colorScheme.primary,
                                    shape = CircleShape
                                ),
                            contentAlignment = Alignment.Center
                        ) {
                            Text(
                                organisation.organisation.name.first().toString(),
                                color = MaterialTheme.colorScheme.onPrimary
                            )
                        }
                    }
                }
            )
        },
        drawerContent = { close ->
            Column(Modifier
                .width(320.dp)
                .fillMaxHeight()
                .background(MaterialTheme.colorScheme.surface)
                .verticalScroll(rememberScrollState())
                .padding(ThemeMargins.SmallMargin)
            ) {

                Spacer(Modifier.height(ThemeMargins.LargeMargins))
                Box(Modifier.align(Alignment.CenterHorizontally)) {
                    AppIcon(project, organisation, refresh)
                }
                Spacer(Modifier.height(ThemeMargins.LargeMargins))

                NavigationDrawerItem(
                    selected = false,
                    colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified),
                    onClick = {
                        navigate("/${Navigation.Organisations.route}/${organisation.organisation.id}/${Navigation.Projects.route}/${project.id}/${Navigation.Blueprints.route}/${project.blueprintId}")
                    },
                    label = {
                        Text("Open Editor")
                    },
                    icon = {
                        Icon(Icons.Default.OpenInBrowser, contentDescription = null)
                    }
                )


                SettingsOptions.entries.groupBy { it.category }
                    .forEach {
                        Spacer(Modifier.height(ThemeMargins.LargeMargins))

                        it.key.title?.let { title ->
                            Text(
                                text = title,
                                style = MaterialTheme.typography.headlineMedium
                            )
                            Spacer(Modifier.height(ThemeMargins.SmallMargin))
                        }

                        it.value.forEach { item ->
                            NavigationDrawerItem(
                                selected = selected == item,
                                colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified),
                                onClick = {
                                    selected = item
                                    close()
                                },
                                label = {
                                    Row(
                                        modifier = Modifier.heightIn(max = 30.dp),
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin)) {
                                        item.label(this)
                                    }
                                }
                            )
                        }
                    }
            }
        },
        content = {
            Column(Modifier.sizeIn(maxWidth = 600.dp)) {
                Row(
                    modifier = Modifier.heightIn(max = 120.dp).padding(ThemeMargins.LargeMargins),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin)) {
                    CompositionLocalProvider(LocalTextStyle provides MaterialTheme.typography.headlineLarge) {
                        selected.label(this)
                    }
                }
                Spacer(Modifier.height(ThemeMargins.SmallMargin))
                selected.content(organisation, project, openDownload)
            }
        }
    )

}

@Composable
private fun AppIcon(project: Project, organisation: OrganisationResponse, refresh: () -> Unit) {
    ModalScaffold(
        anchor = {
            Box(
                Modifier.size(220.dp)
                    .clickable { it() }
                    .clip(RoundedCornerShape(8.dp)),
                contentAlignment = Alignment.BottomEnd
            ) {
                project.appIcon?.let {
                    Image(
                        modifier = Modifier.fillMaxSize(),
                        painter = rememberAsyncImagePainter(it.full),
                        contentDescription = null
                    )
                } ?: run {
                    Box(
                        Modifier
                            .fillMaxSize()
                            .border(
                                2.dp,
                                color = MaterialTheme.colorScheme.onBackground,
                                shape = RoundedCornerShape(8.dp)
                            ),
                        contentAlignment = Alignment.Center
                    ) {
                        Text("Create app icon")
                    }
                }

                Icon(
                    Icons.Default.Edit,
                    modifier = Modifier.padding(ThemeMargins.SmallMargin),
                    contentDescription = null
                )
            }
        },
        header = {
            Text("Icon Creator")
        },
        body = {
            var hasSaved by remember { mutableStateOf(false) }
            IconCreatorScreen(orgId = organisation.organisation.id, projectId = project.id) {
                hasSaved = true
            }
            DisposableEffect(Unit) {
                onDispose {
                    if(hasSaved) {
                        refresh()
                    }
                }
            }
        }
    )
}

