package com.appcreator

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import coil3.ImageLoader
import coil3.annotation.ExperimentalCoilApi
import coil3.compose.setSingletonImageLoaderFactory
import coil3.svg.SvgDecoder
import com.appcreator.blueprint.blueprintSerializer
import com.appcreator.compose.di.platformGifFactory
import com.appcreator.creatorapp.local.ProvideApiClient
import com.appcreator.local.ProvideAnalytics
import com.appcreator.local.SnackbarContainer
import com.appcreator.local.UserState
import com.appcreator.theme.AppTheme
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule

@OptIn(ExperimentalCoilApi::class)
@Composable
fun MyApp(
    userState: State<UserState>,
    content: @Composable () -> Unit
) {
    val json = remember {
        Json {
            serializersModule = SerializersModule {
                blueprintSerializer()()
            }
            ignoreUnknownKeys = true
            encodeDefaults = true
            explicitNulls = false
            isLenient = true
        }
    }

    setSingletonImageLoaderFactory {
        ImageLoader.Builder(it)
            .components {
                add(platformGifFactory())
                add(SvgDecoder.Factory())
            }
            .build()
    }

    ProvideAnalytics {
        ProvideApiClient(json, userState) {
            Column(Modifier.fillMaxSize()) {
                AppTheme {
                    SnackbarContainer(content = content)
                }
            }
        }
    }
}

