package com.appcreator.blueprint.core

import kotlinx.serialization.Serializable

interface LoaderSpec {
    val id: String
    val parameters: Set<String>
    val dataShape: DataShape?
    val parameterSettings: Map<String, ParameterSetting>?
    @Serializable
    data class ParameterSetting(
        val default: String,
        val perComponentConfigurable: Boolean
    )
    companion object {
        fun defaultParameterSetting() = ParameterSetting(
            default = "",
            perComponentConfigurable = true
        )
    }
}

fun String.findParameters(): List<String> {
    return envRegex.findAll(this)
        .map {
            it.value.trimStart('{').trimEnd('}')
        }.toList()
}

@Serializable
data class LoaderSpecReference(
    val id: String
)

@Serializable
data class DataShape(
    val options: List<DataSpec>
)

