package com.appcreator.creatorapp.editor

import io.ktor.http.URLBuilder
import kotlinx.browser.window

actual fun restoreSelectedScreen(): String? {
    val urlBuilder = URLBuilder(window.location.href)
    return urlBuilder.parameters["screen"]
}

actual fun setSelectedScreen(screen: String) {
    val urlBuilder = URLBuilder(window.location.href)
    urlBuilder.parameters["screen"] = screen
    window.history.pushState("Page", window.name, urlBuilder.buildString())
}
