package com.appcreator.blueprint.components.data

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("LocalState")
@ComponentClass(
    group = "Data",
    title = "Local State",
    availableFrom = "0.0.1"
)
data class LocalStateComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "Data Items",
        availableFrom = "0.0.1",
    )
    val items: List<DataSpec>?,

    @InputProperty(
        title = "Component",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

    ): Component
