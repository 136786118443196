package com.appcreator.creatorapp.editor.centercolor

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.DragHandle
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.ColorValue
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.compose.extensions.composeColor
import com.appcreator.creatorapp.editor.centerdata.rest.randomStringByKotlinRandom
import com.appcreator.creatorapp.editor.inputs.ColorConfig
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.styles.ThemeMargins
import sh.calvin.reorderable.ReorderableItem
import sh.calvin.reorderable.rememberReorderableLazyListState

@Composable
fun ColorCenterModal(
    anchor: @Composable (() -> Unit) -> Unit,
) {
    ModalScaffold(
        anchor = anchor,
        header = {
            Text("Colors")
        },
        body = {
            ColorCenterScreen()
        }
    )
}

@Composable
fun ColorCenterScreen() {

    val blueprintManager = LocalBlueprintManager.current
    val colors = blueprintManager.theme.colors
    var selected by remember { mutableStateOf(colors.firstOrNull()) }
    Row(Modifier.fillMaxWidth()) {

        Box(Modifier.width(240.dp).fillMaxHeight()) {

            val lazyListState = rememberLazyListState()
            val reorderableLazyListState = rememberReorderableLazyListState(lazyListState) { from, to ->
                colors.add(to.index, colors.removeAt(from.index))
                blueprintManager.notifyUpdates(true)
            }

            LazyColumn(
                state = lazyListState,
                modifier = Modifier.fillMaxSize(),
                contentPadding = PaddingValues(bottom = 64.dp)
            ) {
                items(colors, key = { it }) { color ->
                    ReorderableItem(reorderableLazyListState, key = color) {
                        NavigationDrawerItem(
                            colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified),
                            selected = selected == color,
                            label = {
                                Text(color.name)
                            },
                            icon = {
                                IconButton(modifier = Modifier.draggableHandle(), onClick = {}) {
                                    Icon(Icons.Default.DragHandle, contentDescription = null)
                                }
                                Box(
                                    modifier = Modifier.size(24.dp)
                                        .background(color.composeColor())
                                )
                            },
                            onClick = {
                                selected = color
                            }
                        )
                    }
                }
            }

            ExtendedFloatingActionButton(
                modifier = Modifier.align(Alignment.BottomCenter),
                text = {
                    Text("Add")
                },
                icon = {
                    Icon(Icons.Default.Add, contentDescription = null)
                },
                onClick = {
                    val newColor = ColorValue(
                        id = randomStringByKotlinRandom(),
                        name = "New Color",
                        baseHex = "#000000",
                        themeValues = emptyMap(),
                        userCreated = true
                    )
                    colors.add(newColor)
                    selected = newColor
                    blueprintManager.notifyUpdates(true)
                }
            )
        }

        Spacer(Modifier.width(ThemeMargins.SmallMargin))
        VerticalDivider()
        Spacer(Modifier.width(ThemeMargins.SmallMargin))

        selected?.let {
            ColorDetails(
                color = it,
                update = { newColor ->
                    newColor?.let { new ->
                        colors[colors.indexOf(it)] = new
                    }?: colors.remove(it)

                    selected = newColor
                    blueprintManager.notifyUpdates(true)
                },
                delete = {
                    colors.remove(it)
                    selected = null
                    blueprintManager.notifyUpdates(true)
                }
            )
        }
    }
}

@Composable
private fun ColorDetails(
    color: ColorValue,
    update: (ColorValue?) -> Unit,
    delete: () -> Unit
) {
    Column(modifier = Modifier
        .padding(horizontal = ThemeMargins.SmallMargin)
    ) {

        CreatorTextInput(
            label = "Name",
            value = color.name,
            onValueChange = {
                update(color.copy(name = it))
            }
        )

        Box(Modifier.height(ThemeMargins.SmallMargin))

        ColorConfig(color = color) { newColor ->
            update(newColor)
        }

        Box(Modifier.height(ThemeMargins.SmallMargin))


        Box(Modifier.height(ThemeMargins.SmallMargin))

        if (color.userCreated == true) {
            TextButton(
                modifier = Modifier.align(Alignment.End),
                onClick = delete
            ) {
                Icon(Icons.Default.Delete, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Delete")
            }
            Box(Modifier.height(ThemeMargins.SmallMargin))
        }
    }
}
