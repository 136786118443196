package com.appcreator.creatorapp.editor.panelclipboard

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.app.utils.onDrag
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.compose.AbsoluteSize
import com.appcreator.compose.LocalAbsoluteSize
import com.appcreator.compose.LocalVerticalScrollingParent
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.creatorapp.editor.DragHolder
import com.appcreator.creatorapp.editor.DragItem
import com.appcreator.creatorapp.editor.LocalDrag
import com.appcreator.creatorapp.editor.local.LocalComponentClipboard
import com.appcreator.styles.ThemeMargins

@Composable
fun PanelClipBoard(add: (ComponentBuilder, Component) -> Unit) {
    val clipboard = LocalComponentClipboard.current
    val dragHolder = LocalDrag.current
    Column(Modifier.padding(ThemeMargins.MedMargins).fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
        Text(
            text = "Clipboard",
            style = MaterialTheme.typography.headlineLarge
        )

        Spacer(Modifier.height(ThemeMargins.MedMargins))

        if(clipboard.components.isEmpty()) {
            Text("No items on your clipboard, select a component then press \"Copy\" to reuse components")
        }

        LazyColumn {
            items(clipboard.components) {
                ClipboardItem(it, dragHolder, add) {
                    clipboard.components.remove(it)
                }
            }
        }
    }
}

@Composable
private fun ClipboardItem(
    component: Component,
    dragHolder: DragHolder,
    add: (ComponentBuilder, Component) -> Unit,
    remove: () -> Unit
) {

    Row(Modifier.fillMaxWidth(), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.SpaceBetween) {
        Text(
            text = component._nodeLabel ?: "",
            style = MaterialTheme.typography.titleMedium
        )
        IconButton(onClick = remove) {
            Icon(Icons.Default.Delete, contentDescription = null)
        }
    }


    BoxWithConstraints(modifier = Modifier
        .fillMaxWidth()
        .onDrag {
            if(it) {
                dragHolder.startDrag(
                    DragItem(component.toBuilder()) {
                        BoxWithConstraints(Modifier.sizeIn(maxWidth = 340.dp, maxHeight = 400.dp)) {
                            CompositionLocalProvider(
                                LocalAbsoluteSize provides AbsoluteSize(this),
                                LocalVerticalScrollingParent provides true
                            ) {
                                ComponentComposable(Modifier, component)
                            }
                        }
                    }
                )
            } else {
                dragHolder.completeDrag()?.let { targetComponent ->
                    add(component.toBuilder(true), targetComponent)
                }
            }
        }
    ) {
        CompositionLocalProvider(
            LocalAbsoluteSize provides AbsoluteSize(this),
            LocalVerticalScrollingParent provides true
        ) {
            ComponentComposable(Modifier, component)
        }
    }

    Spacer(Modifier.height(ThemeMargins.SmallMargin))
    HorizontalDivider()
}