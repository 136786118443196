package com.appcreator.components.layouts

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextFieldColors
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusEvent

object InputGroupScope

@Composable
fun CreatorTextInput(
    modifier: Modifier = Modifier,
    readOnly: Boolean = false,
    singleLine: Boolean = false,
    label: String,
    value: String,
    colors: TextFieldColors = OutlinedTextFieldDefaults.colors(),
    onValueChange: (String) -> Unit,
    leadingIcon: @Composable (() -> Unit)? = null,
    trailingIcon: @Composable (() -> Unit)? = null,
)  {
    OutlinedTextField(
        value = value,
        onValueChange = onValueChange,
        label = {
            Text(label)
        },
        modifier = modifier,
        readOnly = readOnly,
        singleLine = singleLine,
        leadingIcon = leadingIcon,
        trailingIcon = trailingIcon,
        colors = colors
    )
}


@Composable
fun InputGroup(modifier: Modifier = Modifier, content: @Composable InputGroupScope.() -> Unit) {
    content(InputGroupScope)
}

@Composable
fun InputGroupScope.LeftAddon(modifier: Modifier = Modifier, content: @Composable () -> Unit) {

}

@Composable
fun InputGroupScope.TextInput(modifier: Modifier = Modifier, text: String, onTextChanged: (String) -> Unit) {
    OutlinedTextField(
        value = text,
        onValueChange = onTextChanged,
        label = {

        },
        modifier = Modifier
    )
}

@Composable
fun ClickableInput(
    modifier: Modifier = Modifier.fillMaxWidth(),
    onClick: () -> Unit,
    title: String,
    value: String,
    trailing: @Composable (() -> Unit)? = null,
    leadingIcon: @Composable (() -> Unit)? = null,
) {
    CreatorTextInput(
        modifier = modifier
            .onFocusEvent {
                if (it.hasFocus) {
                    onClick()
                }
            },
        readOnly = true,
        label = title,
        value = value,
        onValueChange = {},
        leadingIcon = leadingIcon,
        trailingIcon = {
            trailing?.invoke()
        }
    )
}

@Composable
fun DropDownInputGroup(
    modifier: Modifier = Modifier.fillMaxWidth(),
    title: String,
    dropDownTitle: String,
    leadingIcon: @Composable (() -> Unit)? = null,
    dropDownContent: @Composable (() -> Unit) -> Unit,
) {

    var show by remember { mutableStateOf<(() -> Unit)?>(null) }
    CreatorTextInput(
        modifier = modifier
            .onFocusEvent {
                if (it.hasFocus) {
                    show?.invoke()
                }
            },
        readOnly = true,
        label = title,
        value = dropDownTitle,
        onValueChange = {},
        leadingIcon = leadingIcon,
        trailingIcon = {
            Dropdown(
                anchor = {
                    show = it
                    IconButton(onClick = it) {
                        Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                    }
                },
                content = dropDownContent
            )
        }
    )
}

@Composable
fun ModalInputGroup(
    title: String,
    modalTitle: String,
    leadingIcon: @Composable (() -> Unit)? = null,
    modal: @Composable (@Composable (() -> Unit) -> Unit) -> Unit,
) {
    var show by remember { mutableStateOf<(() -> Unit)?>(null) }
    CreatorTextInput(
        modifier = Modifier
            .fillMaxWidth()
            .onFocusEvent {
                if (it.hasFocus) {
                    show?.invoke()
                }
            },
        readOnly = true,
        label = title,
        value = modalTitle,
        onValueChange = {},
        leadingIcon = leadingIcon,
        trailingIcon = {
            modal {
                show = it
                IconButton(onClick = it) {
                    Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                }
            }
        }
    )
}

@Composable
fun ModalInputGroup(
    title: String,
    modalTitle: String,
    leadingIcon: @Composable (() -> Unit)? = null,
    header: @Composable () -> Unit,
    body: @Composable (() -> Unit) -> Unit,
    footer: @Composable ((() -> Unit) -> Unit)? = null
) {
    ModalInputGroup(
        title = title,
        modalTitle = modalTitle,
        leadingIcon = leadingIcon,
        modal = {
            ModalScaffold(
                anchor = it,
                header = header,
                body = body,
                footer = footer
            )
        }
    )
}
