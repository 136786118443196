package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Column
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.appcreator.blueprint.core.properties.DataValueSet
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.spec.inputs.DataValueSetInputSpec
import com.appcreator.creatorapp.editor.local.LocalDataOptions
import com.appcreator.creatorapp.editor.local.LocalSelectedComponent
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput

@Composable
fun DataValueSetInput(spec: DataValueSetInputSpec, save: () -> Unit) {

    val dataItems = LocalDataOptions.current
    val allowedPaths = spec.properties.dataOptionsPathFilter()
    val filtered = dataItems.map { it.specs.filter { spec -> allowedPaths.contains(spec.key) } }.flatten()

    val selected by LocalSelectedComponent.current
    selected?.let {

        filtered.forEach {
            if (it is ListDataSpec) {
                it.items.map { item ->
                    Column {
                        DataSourceTextInput(
                            label = item.key,
                            value = spec.value?.values?.get(item.key) ?: "",
                            allowDataItems = spec.properties.allowDataFields,
                            update = { update ->
                                val updated = (spec.value?.values ?: emptyMap()).toMutableMap()
                                updated[item.key] = update
                                spec.value = DataValueSet(updated)
                                save()
                            }
                        )
                    }
                }
            }
        }
    }
}
