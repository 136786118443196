package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable


@Serializable
class UserDefinedComponent(
    val id: String,
    val name: String
)
