package com.appcreator.creatorapp.editor.inputs

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AccountTree
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import com.appcreator.blueprint.spec.inputs.ConditionInputSpec
import com.appcreator.components.layouts.ModalInputGroup
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.creatorapp.editor.buildercondition.ConditionScreen

@Composable
fun ConditionInput(spec: ConditionInputSpec, save: () -> Unit) {
    ModalInputGroup(
        title = spec.properties.title,
        modalTitle = "Condition Creator",
        leadingIcon = {
            Icon(Icons.Default.AccountTree, contentDescription = null)
        },
        modal = {
            ModalScaffold(
                anchor = it,
                header = {
                    Text("Condition Creator")
                },
                body = {
                    ConditionScreen(spec, save)
                }
            )
        }
    )
}
