package com.appcreator.app.utils

import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.input.pointer.PointerEventType
import androidx.compose.ui.input.pointer.onPointerEvent
import androidx.compose.ui.layout.LayoutCoordinates
import androidx.compose.ui.layout.boundsInRoot
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import com.appcreator.creatorapp.editor.LocalMousePosition
import org.w3c.dom.TouchEvent
import org.w3c.dom.events.MouseEvent
import org.w3c.dom.get

actual fun Modifier.onHover(hovering: (Boolean) -> Unit): Modifier {
    return onPointerEvent(PointerEventType.Enter) {
        hovering(true)
    }.onPointerEvent(PointerEventType.Exit) {
        hovering(false)
    }

}

actual fun Modifier.click(onClick: () -> Unit): Modifier {
    return onPointerEvent(PointerEventType.Press) {
        onClick()
    }
}

actual fun Modifier.onTrack(location: (DpOffset) -> Unit): Modifier {
    return  onPointerEvent(PointerEventType.Move) { event ->
        (event.nativeEvent as? MouseEvent)?.let {
             location(DpOffset(it.x.dp, it.y.dp))
        }
        (event.nativeEvent as? TouchEvent)?.let {
            it.touches[0]?.let { touch ->
                 location(DpOffset(touch.pageX.dp, touch.pageY.dp))
            }
        }
    }
}

actual fun Modifier.onDragOver(hovering: (Boolean) -> Unit): Modifier {
    return composed {
        val mouseLocation = LocalMousePosition.current
        val globalPosition = remember { mutableStateOf<LayoutCoordinates?>(null) }
        val hover by remember { derivedStateOf {
            globalPosition.value?.boundsInRoot()?.contains(mouseLocation.value) == true
        } }
        LaunchedEffect(hover) {
            hovering(hover)
        }
        onGloballyPositioned {
            globalPosition.value = it
        }

    }
}

actual fun Modifier.onDrag(dragging: (Boolean) -> Unit): Modifier {
    return onPointerEvent(PointerEventType.Press) {
        dragging(true)
    }.onPointerEvent(PointerEventType.Release) {
        dragging(false)
    }
}