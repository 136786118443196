package com.appcreator.compose.conditions.logic

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.conditions.logic.OrCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.conditions.Evaluator
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator

class OrEvaluator(private val condition: OrCondition): Evaluator {

    @Composable
    override fun evaluate(envStore: EnvStore): Boolean? {
        val evals = condition.conditions.map {
            Container.evaluator(it)?.evaluate(envStore)
        }
        if(evals.any { it == true }) {
            return true
        }
        if(evals.any { it == null }) {
            return null
        }
        return false
    }

    override suspend fun evaluateAsync(envStore: EnvStore): Boolean {
        return condition.conditions.any {
            Container.evaluator(it)?.evaluateAsync(envStore)?: false
        }
    }

    override fun evaluateOnce(envStore: EnvStore): Boolean? {
        val evals = condition.conditions.map {
            Container.evaluator(it)?.evaluateOnce(envStore)
        }
        if(evals.any { it == true }) {
            return true
        }
        if(evals.any { it == null }) {
            return null
        }
        return false
    }

}