package com.appcreator.creatorapp.editor.inputs

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Outbond
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.components.layouts.ModalInputGroup
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.creatorapp.editor.builderaction.ActionScreen

@Composable
fun ActionInput(spec: ActionInputSpec, save: () -> Unit) {
    ModalInputGroup(
        title = spec.properties.title,
        supportingText = spec.properties.description,
        modalTitle = "Action Creator",
        leadingIcon = {
            Icon(Icons.Default.Outbond, contentDescription = null)
        },
        modal = {
            ModalScaffold(
                anchor = it,
                header = {
                    Text("Action Creator")
                },
                body = {
                    ActionScreen(spec, save)
                }
            )
        },
        trailingIcon = spec.value?.let {
            {
                IconButton(onClick = {
                    spec.value = null
                    save()
                }) {
                    Icon(Icons.Default.Delete, contentDescription = null)
                }
            }
        }
    )
}
