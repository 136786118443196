package com.appcreator.creatorapp.organisation

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.TopAppBar
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import coil3.compose.rememberAsyncImagePainter
import com.appcreator.components.layouts.Dropdown
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.components.layouts.ScrollBars
import com.appcreator.compose.tracingPaperBackground
import com.appcreator.creatorapp.Navigation
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.Organisation
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.OrganisationsResponse
import com.appcreator.dto.Project
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch


@Composable
fun OrganisationScreen(
    organisation: OrganisationResponse,
    organisations: OrganisationsResponse,
    logout: () -> Unit,
    navigate: (String) -> Unit
) {
    val client = LocalApiClient.current
    val scope = rememberCoroutineScope()
    OrganisationContent(
        organisations = organisations.organisations,
        organisation = organisation.organisation,
        projects = organisation.projects,
        create = { name ->
            scope.launch {
                try {
                    val newProject = client.createProject(organisation.organisation.id, name)
                    navigate("/${Navigation.Organisations.route}/${organisation.organisation.id}/${Navigation.Projects.route}/${newProject.id}")
                } catch (ex: Exception) {
                    ex.printStackTrace()
                    // TODO show error
                }
            }
        },
        open = { projectId ->
            navigate("/${Navigation.Organisations.route}/${organisation.organisation.id}/${Navigation.Projects.route}/$projectId")
        },
        openOrg = {
            println("Navigate")
            navigate("/${Navigation.Organisations.route}/${it}")
        },
        logout = logout
    )
}

@Composable
private fun OrganisationContent(
    organisations: List<Organisation>,
    organisation: Organisation,
    projects: List<Project>,
    create: (String) -> Unit,
    open: (String) -> Unit,
    openOrg: (String) -> Unit,
    logout: () -> Unit
) {

    Column {
        TopAppBar(
            title = {
                Text(organisation.name)
            },
            actions = {
                Dropdown(
                    anchor = {
                        IconButton(onClick = it) {
                            Box(
                                Modifier.size(30.dp)
                                    .background(MaterialTheme.colorScheme.primary, shape = CircleShape),
                                contentAlignment = Alignment.Center
                            ) {
                                Text(organisation.name.first().toString(), color = MaterialTheme.colorScheme.onPrimary)
                            }
                        }
                    },
                    content = { close ->
                        organisations.filterNot { it.id == organisation.id }.forEach {
                            DropdownItem(title = it.name) {
                                openOrg(it.id)
                                close()
                            }
                        }
                        // TODO Readd at some point after launch
//                        DropdownItem(title = "Create new organisation") {
//
//                        }
                        HorizontalDivider()
                        DropdownItem(title = "Sign out", onClick = logout)
                    }
                )

            }
        )

        val state = rememberScrollState()
        Box {
            Column(Modifier.padding(vertical = ThemeMargins.LargeMargins).verticalScroll(state)) {

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Text(
                        modifier = Modifier.padding(
                            vertical = ThemeMargins.SmallMargin,
                            horizontal = ThemeMargins.LargeMargins
                        ),
                        text = "Projects",
                        style = MaterialTheme.typography.headlineMedium
                    )
                    if (projects.isNotEmpty()) {
                        NewProjectModal(
                            anchor = {
                                TextButton(onClick = it) {
                                    Icon(Icons.Default.Add, contentDescription = null)
                                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                                    Text("Create new")
                                }
                            },
                            create = create
                        )
                    }
                }

                if (projects.isEmpty()) {
                    Spacer(Modifier.width(ThemeMargins.LargeMargins))
                    NewProjectModal(
                        anchor = {
                            StyledCard(onClick = it) {
                                Column(
                                    modifier = Modifier.fillMaxSize(),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.Center
                                ) {
                                    Icon(
                                        Icons.Default.Add,
                                        modifier = Modifier.size(30.dp),
                                        contentDescription = null,
                                        tint = MaterialTheme.colorScheme.primary
                                    )
                                    Spacer(Modifier.height(ThemeMargins.SmallMargin))
                                    Text(
                                        text = "Create project",
                                        style = MaterialTheme.typography.titleLarge,
                                        color = MaterialTheme.colorScheme.primary
                                    )
                                }
                            }
                        },
                        create = create
                    )
                }

                FlowRow {
                    projects.forEach { project ->
                        Column(
                            modifier = Modifier
                                .size(180.dp)
                                .padding(ThemeMargins.SmallMargin)
                                .clip(RoundedCornerShape(12.dp))
                                .clickable { open(project.id) },
                            horizontalAlignment = Alignment.CenterHorizontally,
                            verticalArrangement = Arrangement.Center
                        ) {
                            Box(Modifier.size(100.dp).clip(RoundedCornerShape(8.dp))) {
                                project.appIcon?.full?.let {
                                    Image(
                                        modifier = Modifier.fillMaxSize(),
                                        painter = rememberAsyncImagePainter(project.appIcon?.full),
                                        contentDescription = null
                                    )
                                } ?: Box(
                                    Modifier
                                        .fillMaxSize()
                                        .border(
                                            1.dp,
                                            color = Color.Black.copy(alpha = 0.6f),
                                            shape = RoundedCornerShape(8.dp)
                                        )
                                        .tracingPaperBackground()
                                )

                            }
                            Spacer(Modifier.height(ThemeMargins.SmallMargin))
                            Text(project.projectConfig.name)
                        }
                    }
                    Spacer(Modifier.width(ThemeMargins.LargeMargins))
                }

                HorizontalDivider(Modifier.padding(vertical = ThemeMargins.LargeMargins))

            }
            ScrollBars(state)
        }
    }
}

@Composable
private fun StyledCard(onClick: () -> Unit, content: @Composable ColumnScope.() -> Unit) {
    Card(
        modifier = Modifier
            .size(220.dp, 180.dp)
            .padding(ThemeMargins.SmallMargin),
        onClick = onClick,
        colors = CardDefaults.outlinedCardColors(containerColor = Color.White),
        elevation = CardDefaults.elevatedCardElevation(defaultElevation = 4.dp),
        content = content
    )

}

@Composable
private fun NewProjectModal(
    anchor: @Composable (() -> Unit) -> Unit,
    create: (String) -> Unit
) {
    // TODO
    anchor {
        create("New Project")
    }
}

//@Composable
//private fun NewProject(
//    create: (String) -> Unit
//) {
//    var name by remember { mutableStateOf("") }
//    ModalScaffold(
//        anchor = {
//            Column(modifier = Card
//                .toModifier(CardButtonVariant)
//                .then(SelectionCardSize.toModifier())
//                .onClick { it() },
//                verticalArrangement = Arrangement.Center,
//                horizontalAlignment = Alignment.CenterHorizontally
//            ) {
//                Text("Create new +")
//            }
//        },
//        header = {
//            Text("Create new project")
//        },
//        body = { _, _ ->
//            InputGroup(modifier = Modifier.fillMaxWidth()) {
//                LeftAddon { Text("Name:") }
//                TextInput(
//                    text = name,
//                    onTextChanged = {
//                        name = it
//                    }
//                )
//            }
//        },
//        footer = {
//            Button(onClick = {
//                create(name)
//                it()
//            }) {
//                Text("Create")
//            }
//        }
//    )
//}
