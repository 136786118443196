package com.appcreator.blueprint.actions

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.blueprint.spec.inputs.ConditionInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class ConditionActionBuilder(
  action: ConditionAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "ConditionAction",
          title = "Condition",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
      )

  public val condition: ConditionInputSpec = ConditionInputSpec(
          properties = InputProperties(
          section = "",
          title = "Condition",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(action?.condition?.toBuilder(newId)),
          
          
      )

  public val successAction: ActionInputSpec = ActionInputSpec(
          properties = InputProperties(
          section = "",
          title = "Success Action",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(action?.successAction?.toBuilder(newId)),
          
          
      )

  public val failureAction: ActionInputSpec = ActionInputSpec(
          properties = InputProperties(
          section = "",
          title = "Failure Action",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(action?.failureAction?.toBuilder(newId)),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(condition, successAction, failureAction)

  override fun build(): Action = ConditionAction(condition.value?.build(),
      successAction.value?.build(), failureAction.value?.build())
}
