package com.appcreator.creatorapp.editor


import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.Destination
import com.appcreator.blueprint.GlobalData
import com.appcreator.blueprint.ScreenSpec
import com.appcreator.blueprint.components.Screen
import com.appcreator.blueprint.components.ScreenBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.findDestination
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.compose.BlueprintProvider
import com.appcreator.compose.loaders.ScreenFetcher
import com.appcreator.creatorapp.api.MutableDataSource
import com.appcreator.creatorapp.editor.local.NodeExplorer
import com.appcreator.creatorapp.editor.local.ScreenHistory
import com.appcreator.dto.DataSource
import com.appcreator.dto.configurations.FirebaseConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfiguration
import com.appcreator.shared.api.ApiClient
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement

class BlueprintManager(
    blueprint: Blueprint,
    dataSources: List<DataSource>,
    val config: List<ThirdPartyConfiguration>,
    val json: Json,
    private val screenHistory: ScreenHistory,
    private val orgId: String,
    private val projectId: String,
    private val blueprintId: String,
    private val apiClient: ApiClient,
    private val coroutineScope: CoroutineScope
): ScreenFetcher, BlueprintProvider {

    var blueprint by mutableStateOf(blueprint)
    val destinationState = mutableStateOf(blueprint.rootDestination())

    var destination: Destination by destinationState
        private set

    var screenBuilder: ScreenBuilder? by mutableStateOf(null)
        private set

    val selectedComponent: MutableState<ComponentBuilder?> = mutableStateOf(null)

    var inDate: Boolean by mutableStateOf(true)

    private val updateFlow = MutableSharedFlow<Boolean>(1)

    val dataSources = mutableStateListOf(*dataSources.map { MutableDataSource(it) }.toTypedArray())
    val theme = MutableTheme(blueprint.theme)

    val editableGlobalData = mutableStateListOf(*(blueprint.globalData?: emptyList()).toTypedArray())
    private val configProvidedData = config.mapNotNull { it.globalState }.flatten().distinct().map { GlobalData(it, emptyMap(), false, false) }
    val globalData: List<GlobalData> = editableGlobalData.plus(configProvidedData)

    var defaultEnvironment by mutableStateOf(blueprint.defaultEnvironment?: GlobalData.defaultEnv)
    val customEnvironments = mutableStateListOf(*(blueprint.customEnvironments?: emptyList()).toTypedArray())

    init {
        coroutineScope.launch {
            updateFlow
                .debounce(500)
                .collectLatest {
                    if (it) notifyBlueprintUpdate()
                    else notifyScreenUpdate()
                }
        }

        val screen = restoreSelectedScreen()
        blueprint.screens.find { it.path == screen }?.let {
            selectScreen(it)
        }?: selectScreen(blueprint.screens.first())
    }

    private var blueprintOutOfDate: Boolean = false
    private var outOfDateScreens = mutableSetOf<String>()

    fun notifyUpdates(blueprintUpdate: Boolean) {
        updateFlow.tryEmit(blueprintUpdate)
    }

    private fun notifyBlueprintUpdate() {
        blueprint = blueprint.copy(
            theme = theme.toTheme(),
            loaderSpecs = dataSources.map { it.loaderSpec },
            globalData = editableGlobalData,
            defaultEnvironment = defaultEnvironment,
            customEnvironments = customEnvironments
        )
        screenBuilder?.let {
            // Reload current screen in case theme change changes how it looks
            val screen = it.build() as Screen
            screens[it.id.value]?.value = screen
        }
        blueprintOutOfDate = true
        inDate = false // Screen needs saved
    }

    private fun notifyScreenUpdate() {
        try {
            screenBuilder?.let {
                val screen = it.build() as Screen
                notifyScreenUpdate(screen)
                screenHistory.addHistory(screen)
            }

        } catch (ex: Exception) {
            println("Applying screen update failed")
        }
    }
    private fun notifyScreenUpdate(update: Screen) {
        val mutable = screens[update.id]?: return

        if(mutable.value.title != update.title || mutable.value.path != update.path) {
            val screenSpec = ScreenSpec(update.id, update.path?.value ?: "", update.title)
            val newScreens = blueprint.screens.map { screen -> if (screen.id == screenSpec.id) screenSpec else screen }
            blueprint = blueprint.copy(screens = newScreens)
        }

        mutable.value = update
        outOfDateScreens.add(update.id)
        inDate = false // Screen needs saved
    }
    fun revertTo(screen: Screen) {
        screenBuilder = screen.toBuilder() as ScreenBuilder
        notifyScreenUpdate(screen)
        selectedComponent.value = NodeExplorer(this)
            .findNode(selectedComponent.value?._nodeId?.value, screenBuilder)
    }

    fun selectScreen(screenSpec: ScreenSpec) {
        coroutineScope.launch {
            try {
                val screen = fetch(screenSpec.id).value
                val builder = screen.toBuilder() as ScreenBuilder
                screenBuilder = builder
                selectedComponent.value = screenBuilder

                val store = EnvStore.create(defaultLocale = "en", env = screen.path?.defaultParameters ?: emptyMap())
                val link = BlueprintLink(path = screen.path?.value?: "")
                val path = store.injectLink(link)
                blueprint.findDestination(path)?.let {
                    destination = it
                }
                screenHistory.reset(screen)
                setSelectedScreen(path)
            } catch (ex: Exception) {
                ex.printStackTrace()
                // TODO handle error/loading
            }
        }
    }

    suspend fun addScreen(title: String) {
        try {
            val screen = apiClient.createScreen(orgId, projectId, blueprintId, title).screen
            val builder = screen.toBuilder() as ScreenBuilder
            screens[screen.id] = mutableStateOf(screen)
            this.screenBuilder = builder
            selectedComponent.value = builder

            val screenSpec = ScreenSpec(screen.id, screen.path?.value ?: "", screen.title)
            blueprint = blueprint.copy(screens = blueprint.screens.plus(screenSpec))

            selectScreen(screenSpec)
        } catch (ex: Exception) {
            ex.printStackTrace()
            // TODO handle error
        }
    }

    suspend fun save() {
        if (blueprintOutOfDate) {
            apiClient.updateBlueprint(
                orgId,
                projectId,
                blueprintId,
                blueprint,
                dataSources.map { it.toDataSource() })
            blueprintOutOfDate = false
        }
        inDate = true

        outOfDateScreens.reversed().forEach {
            val screen = screens[it]!!
            apiClient.updateScreen(orgId, projectId, blueprintId, screen.value)
            outOfDateScreens.remove(it)
        }
    }

    private val screens: MutableMap<String, MutableState<Screen>> = mutableMapOf()

    override suspend fun fetch(id: String): State<Screen> {
        return screens[id]?: kotlin.run {
            val screen = mutableStateOf(apiClient.getScreen(orgId, projectId, blueprintId, id).screen)
            screens[id] = screen
            screen
        }
    }

    suspend fun deleteScreen(screen: ScreenBuilder) {
        screen.id.value?.let { screenId ->
            apiClient.deleteScreen(orgId, projectId, blueprintId, screenId)
            blueprint = blueprint.copy(screens = blueprint.screens.filterNot { it._nodeId == screenId })
            selectScreen(blueprint.screens.first())
        }
    }

    override fun provideBlueprint(): State<Blueprint?> = mutableStateOf(blueprint)
}

fun BlueprintManager.supportsFirestore(): Boolean {
    return config
        .find { it.type == "firebase" }
        ?.config?.let {
            json.decodeFromJsonElement<FirebaseConfiguration>(it).firestoreEnabled
        }?: false
}
