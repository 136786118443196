package com.appcreator.dto

import com.appcreator.blueprint.core.LoaderSpec
import kotlinx.serialization.Serializable

@Serializable
data class DataSource(
    val id: String,
    val title: String,
    val loaderSpec: LoaderSpec,
)


