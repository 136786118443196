package com.appcreator.compose.components.basic

import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.toFontFamily
import androidx.compose.ui.unit.sp
import com.appcreator.blueprint.components.basic.MaterialIconComponent
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.extensions.composeColor
import com.appcreator.shared_compose.generated.resources.*
import org.jetbrains.compose.resources.Font

@Composable
fun MaterialIconComposable(modifier: Modifier, component: MaterialIconComponent) {
    // Should load the fonts in somewhere else so its only done once
    // 10 mb for the font so might not be the best way to do this long term
    val iconFont = Font(resource = Res.font.material_outlined).toFontFamily()

    component.icon?.code?.let {
        val theme = LocalTheme.current
        val color = theme?.color(component.color)?.composeColor() ?: LocalContentColor.current
        Text(
            modifier = modifier,
            text = it,
            fontSize = component.size.sp,
            fontFamily = iconFont,
            color = color
        )
    }
}