package com.appcreator.compose.components.navigation

import androidx.compose.foundation.layout.Box
import androidx.compose.material3.Surface
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.navigation.BottomNavigationComponent
import com.appcreator.blueprint.components.navigation.NavigationComponent
import com.appcreator.blueprint.components.navigation.NavigationItemComponent
import com.appcreator.compose.components.ComponentComposable


@Composable
fun NavigationComposable(modifier: Modifier, component: NavigationComponent) {

    val tabbar = if(component.tabBar == true) {
        BottomNavigationComponent(
            _nodeLabel = null,
            _nodeId = null,
            color = component.tabBarBackgroundColor,
            selectedColor = component.tabBarSelectedColor,
            unselectedColor = component.tabBarUnselectedColor,
            labelSize = component.tabBarLabelSize,
            alwaysShowLabels = component.tabBarAlwaysShowLabels,
            tabs = component.tabBarTabs
        )
    } else null

    val start = (tabbar?.tabs?.firstOrNull() as? NavigationItemComponent)?.link?.path ?: component.rootScreen?.path ?: "screen"
    val navigator = rememberNavigator(true, start)

    val content: @Composable () -> Unit = {
        Box(modifier) {
            tabbar?.let {
                BottomNavigationContent(Modifier, it, navigator)
            } ?: run {
                Navigation(
                    modifier = Modifier,
                    navigator = navigator,
                )
            }
        }
    }

    if (component.navigationDrawer ==  true) {
        NavigationDrawer(
            showDrawerInPreview = component.showNavigationDrawer == true,
            navigator = navigator,
            content = content,
            drawerWidth = component.navigationDrawerWidth ?: 300,
            drawer = {
                Surface {
                    component.drawerContent?.let {
                        ComponentComposable(Modifier, it)
                    }
                }
            }
        )
    } else {
        content()
    }
}

// TODO waiting for this to become stable
//
//@Composable
//private fun AdaptiveBottomNavigation(component: NavigationComponent, navigator: Navigator) {
//
//    val envStore = LocalEnvStore.current
//    val theme = LocalTheme.current
//    val color = theme?.color(component.tabBarBackgroundColor)?.composeColor() ?: MaterialTheme.colorScheme.surface
//
//    NavigationSuiteScaffold(
//        containerColor = color,
//        navigationSuiteItems = {
//            component.tabBarTabs
//                .filterIsInstance<NavigationItemComponent>()
//                .forEach {
//                    val isSelected = false // TODO selected
//                    item(
//                        selected = isSelected,
//                        icon = {
//                            ComponentComposable(
//                                Modifier, MaterialIconComponent(
//                                    _nodeId = null,
//                                    _nodeLabel = null,
//                                    icon = it.materialIcon,
//                                    size = 24,
//                                    color = if (isSelected) component.tabBarSelectedColor else component.tabBarUnselectedColor
//                                )
//                            )
//                        },
//                        label = {
//                            CompositionLocalProvider(LocalSelected provides isSelected) {
//                                it.title?.value()?.let { value ->
//                                    CompositionLocalProvider(
//                                        LocalDensity provides Density(
//                                            LocalDensity.current.density, 1f
//                                        )
//                                    ) {
//                                        val font = theme?.font(component.tabBarLabelSize)
//                                        Text(
//                                            color = LocalContentColor.current,
//                                            text = value,
//                                            fontSize = font?.fontSize?.sp
//                                                ?: MaterialTheme.typography.labelSmall.fontSize,
//                                            fontWeight = font?.fontWeight?.let { weight -> FontWeight(weight) },
//                                            softWrap = false
//                                        )
//                                    }
//                                }
//                            }
//                        },
//                        alwaysShowLabel = component.tabBarAlwaysShowLabels == true,
//                        onClick = {
//                            it.link?.let { link ->
//                                navigator.navigate(link, envStore) {
//                                    popUpToRoot = true
////                                            singleTop = true
//                                }
//                            }
//                        }
//                    )
//                }
//        }) {
//        Navigation(
//            modifier = Modifier,
//            navigator = navigator,
//        )
//    }
//}
