package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.spec.SingleInputSpec

class DataPathInputSpec(
    properties: InputProperties,
    value: MutableState<DataPath?>
): SingleInputSpec<DataPath?>(properties, value)
