package com.appcreator.creatorapp.editor.panelsidebar

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.core.animateDpAsState
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Close
import androidx.compose.material.icons.filled.ContentCopy
import androidx.compose.material.icons.filled.DashboardCustomize
import androidx.compose.material.icons.filled.ViewCozy
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.NavigationRail
import androidx.compose.material3.Surface
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.app.utils.onHover
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.creatorapp.editor.DragCompleteInfo
import com.appcreator.creatorapp.editor.local.LocalComponentClipboard
import com.appcreator.creatorapp.editor.panelclipboard.PanelClipBoard
import com.appcreator.creatorapp.editor.panelcomponentpicker.ComponentListPanel
import com.appcreator.creatorapp.editor.panelcomponentpicker.ComponentTemplatePanel
import com.appcreator.styles.ThemeMargins


@Composable
fun RightSidebarPanel(
    modifier: Modifier,
    content: MutableState<(@Composable () -> Unit)?>,
    addComponent: (ComponentBuilder, DragCompleteInfo) -> Unit
) {

    var hovering by remember { mutableStateOf(false) }
    val width by animateDpAsState(
        when {
            content.value != null -> 600.dp
            hovering -> 180.dp
            else -> 64.dp
        }
    )


    Row(modifier.width(width).onHover { hovering = it }) {

        VerticalDivider()
        AnimatedContent(content.value) {

            it?.let { renderer ->
                Surface {
                    Column(Modifier.padding(top = ThemeMargins.MedMargins).fillMaxSize()) {
                        IconButton(
                            modifier = Modifier.align(Alignment.End),
                            onClick = {
                                content.value = null
                            }) {
                            Icon(Icons.Default.Close, contentDescription = null)
                        }
                        renderer()
                    }
                }
            }?: run {
                NavigationRail(Modifier.width(width)) {

                    Spacer(Modifier.height(ThemeMargins.LargeMargins))

                    BarItem(
                        showLabel = hovering,
                        icon = Icons.Default.ContentCopy,
                        label = "Clipboard",
                        badgeCount = LocalComponentClipboard.current.components.size,
                        onClick = {
                            content.value = {
                                PanelClipBoard(addComponent)
                            }
                        }
                    )

                    BarItem(
                        showLabel = hovering,
                        icon = Icons.Default.DashboardCustomize,
                        label = "Components",
                        onClick = {
                            content.value = {
                                ComponentListPanel(addComponent)
                            }
                        }
                    )

                    BarItem(
                        showLabel = hovering,
                        icon = Icons.Default.ViewCozy,
                        label = "Templates",
                        onClick = {
                            content.value = {
                                ComponentTemplatePanel(addComponent)
                            }
                        }
                    )
                }
            }
        }
    }
}