package com.appcreator.creatorapp.editor.local

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.staticCompositionLocalOf
import com.appcreator.blueprint.components.Screen

val LocalScreenHistory = staticCompositionLocalOf<ScreenHistory> {
    error("No instance of ScreenHistory provided")
}

class ScreenHistory {
    val history = mutableStateListOf<Screen>()

    fun reset(screen: Screen) {
        history.clear()
        history.add(screen)
    }

    fun addHistory(screen: Screen) {
        if (history.size > 12) {
            history.removeFirst()
        }
        history.add(screen)
    }
}