package com.appcreator.creatorapp.api

import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonObject

class DataShapeParser {

    fun parse(jsonObject: JsonElement?): List<DataSpec> {
        return parse(emptyList(), jsonObject)
    }

    private fun parse(paths: List<String>, element: JsonElement?): List<DataSpec> {
        element?: return emptyList()

        return when(element) {
            is JsonObject -> element.entries.map { (key, element) ->
                parse(paths.plus(key), element)
            }.flatten()
            is JsonArray -> listOf(
                ListDataSpec(
                    key = paths.joinToString(separator = "."),
                    items = parse(element.jsonArray.firstOrNull()?.jsonObject) // TODO what if not an object?
                )
            )
            is JsonPrimitive -> listOf(
                ValueDataSpec(key = paths.joinToString(separator = "."), valueType = ValueDataSpec.Type.String, value = element.content, visible = true)
            )
        }
    }
}
