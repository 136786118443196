package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.properties.MaterialIcon
import com.appcreator.blueprint.spec.SingleInputSpec

class MaterialIconInputSpec(
    properties: InputProperties,
    value: MutableState<MaterialIcon?>
): SingleInputSpec<MaterialIcon?>(properties, value)
