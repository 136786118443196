package com.appcreator.compose.components.navigation

import androidx.compose.runtime.Composable
import com.appcreator.compose.KotlinXNavigator

@Composable
actual fun NavigationPlatformSetup(
    startDestination: String,
    navigator: KotlinXNavigator
) {
}