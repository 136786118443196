package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.snapshots.SnapshotStateList
import com.appcreator.blueprint.spec.ConditionBuilder
import com.appcreator.blueprint.spec.ListInputSpec

class ConditionListInputSpec(
    properties: InputProperties,
    value: SnapshotStateList<ConditionBuilder>
): ListInputSpec<ConditionBuilder>(properties, value)
