package com.appcreator.creatorapp.editor.centerdata.firestore

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.editor.supportsFirestore

@Composable
fun FirestoreDataScreen() {
    val blueprintManager = LocalBlueprintManager.current
    val supportsFirestore = remember { blueprintManager.supportsFirestore() }
    if (supportsFirestore) {

        var selectedItem by remember { mutableStateOf(blueprintManager.dataSources.firstOrNull { it.loaderSpec is FirebaseFirestoreLoaderSpec }) }

        Row(Modifier.fillMaxSize()) {

            FirestoreSideBar(blueprintManager, selectedItem) {
                selectedItem = it
            }
            VerticalDivider()

            selectedItem?.let {
                FirestoreRequestDetails(item = it, save = {
                    blueprintManager.notifyUpdates(true)
                }) {
                    blueprintManager.dataSources.remove(it)
                    selectedItem = null
                }
            }
        }

    } else {

        // TODO make look better
        Text("Enable in settings")
    }
}