package com.appcreator.compose.conversions

import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.conversions.TodayConversion
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.json.JsonElement

class TodayConvertor(
    val conversion: TodayConversion
): Convertor {
    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String {
        val date =
            Clock.System.now()
                .toLocalDateTime(TimeZone.currentSystemDefault())
                .date
        return date.atStartOfDayIn(TimeZone.currentSystemDefault()).toString()
    }

}
