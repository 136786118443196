package com.appcreator.app

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.BrowserRouter
import app.softwork.routingcompose.Router
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.Navigation
import com.appcreator.creatorapp.RootScreen
import com.appcreator.creatorapp.editor.ProjectEditorScreen
import com.appcreator.creatorapp.organisation.OrganisationScreen
import com.appcreator.creatorapp.project.ProjectScreen
import kotlinx.browser.window

@Composable
fun MainNavigation(
    logout: () -> Unit
) {
    BrowserRouter("/") {
        val router = Router.current
        val notFound = {
            router.navigate("/")
        }

        LoadingComposable(loader = { it.getOrganisations() }, notFound = notFound) { organisations ->

            route(Navigation.Root.route) {
                RootScreen(organisations) { router.navigate(it) }
            }
            route(Navigation.Organisations.route) {
                string { orgId ->

                    LoadingComposable(key = orgId, loader = { it.getOrganisation(orgId) }, notFound = notFound) { organisation ->

                        route(Navigation.Projects.route) {
                            string { projectId ->

                                LoadingComposable(loader = { it.getProject(orgId, projectId) }, notFound = notFound) { project ->

                                    route(Navigation.Blueprints.route) {
                                        string { blueprintId ->

                                            noMatch {
                                                ProjectEditorScreen(
                                                    organisation = organisation.organisation,
                                                    projectId = projectId,
                                                    blueprintId = blueprintId,
                                                    navigate = {
                                                        router.navigate(it)
                                                    },
                                                    notFound = notFound,
                                                    openNewTab = {
                                                        window.open(it, "_blank")?.focus()
                                                    }
                                                )
                                            }
                                        }
                                        noMatch {
//                                LaunchedEffect(Unit) { router.navigate("/organisations/$orgId/projects/$projectId") }
                                        }
                                    }
                                    noMatch {
                                        ProjectScreen(
                                            organisation = organisation,
                                            project = project,
                                            navigate = { router.navigate(it) },
                                            refresh = { window.location.reload() },
                                            openDownload = { window.open(it, "_blank")?.focus() }
                                        )
                                    }
                                }
                            }
                            noMatch {
//                        LaunchedEffect(Unit) { router.navigate("/organisations/$orgId") }
                            }
                        }
                        noMatch {
                            OrganisationScreen(organisation, organisations, logout) {
                                router.navigate(it)
                            }
                        }

                        noMatch {
//                    LaunchedEffect(Unit) { router.navigate("/") }
                        }
                    }
                }
            }
        }
    }
}
