package com.appcreator.creatorapp.editor.centerdata.rest

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.components.layouts.CreatorTextInput


@Composable
fun KeyValuePairList(values: List<Pair<String, String>>, update: (List<Pair<String, String>>) -> Unit) {
    Box(Modifier.fillMaxSize()) {
        Column(Modifier.fillMaxWidth().verticalScroll(rememberScrollState())) {
            values.forEachIndexed { index, pair ->
                Row(
                    Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    CreatorTextInput(
                        value = pair.first,
                        onValueChange = {
                            val mutable = values.toMutableList()
                            mutable[index] = it to pair.second
                            update(mutable)
                        },
                        label = "Key"
                    )

                    Box(Modifier.padding(horizontal = 6.dp)) {
                        Text(":")
                    }

                    CreatorTextInput(
                        value = pair.second,
                        onValueChange = {
                            val mutable = values.toMutableList()
                            mutable[index] = pair.first to it
                            update(mutable)
                        },
                        label = "Value"
                    )

                    IconButton(onClick = {
                        update(values.minus(pair))
                    }) {
                        Icon(Icons.Default.Delete, contentDescription = null)
                    }
                }
            }
            Spacer(Modifier.height(64.dp))
        }

        ExtendedFloatingActionButton(
            modifier = Modifier.align(Alignment.BottomCenter),
            text = {
                Text("Add")
            },
            icon = {
                Icon(Icons.Default.Add, contentDescription = null)
            },
            onClick = {
                update(values.plus("" to ""))
            }
        )

    }
}
