package com.appcreator.creatorapp.organisation

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Check
import androidx.compose.material.icons.filled.CheckCircle
import androidx.compose.material.icons.filled.RemoveCircle
import androidx.compose.material3.Badge
import androidx.compose.material3.BadgedBox
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.TopAppBar
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import coil3.compose.rememberAsyncImagePainter
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.Dropdown
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.components.layouts.ScrollBars
import com.appcreator.compose.tracingPaperBackground
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.Navigation
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.project.configurations.LoadingButton
import com.appcreator.dto.Invitation
import com.appcreator.dto.Organisation
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.OrganisationsResponse
import com.appcreator.dto.Project
import com.appcreator.dto.configurations.ProjectTemplate
import com.appcreator.local.LocalAnalytics
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.LocalUserState
import com.appcreator.local.SnackbarInfo
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch


@Composable
fun OrganisationScreen(
    organisation: OrganisationResponse,
    organisations: OrganisationsResponse,
    logout: () -> Unit,
    navigate: (String) -> Unit
) {
    val client = LocalApiClient.current
    val analytics = LocalAnalytics.current
    OrganisationContent(
        organisations = organisations.organisations,
        response = organisation,
        organisation = organisation.organisation,
        projects = organisation.projects,
        create = { template ->
            val newProject = client.createProject(organisation.organisation.id, template.id)
            navigate("/${Navigation.Organisations.route}/${organisation.organisation.id}/${Navigation.Projects.route}/${newProject.id}")
            analytics.log("create_project", mapOf("template" to template.name))
        },
        open = { projectId ->
            navigate("/${Navigation.Organisations.route}/${organisation.organisation.id}/${Navigation.Projects.route}/$projectId")
        },
        openOrg = {
            println("Navigate")
            navigate("/${Navigation.Organisations.route}/${it}")
        },
        logout = logout
    )
}

@Composable
private fun OrganisationContent(
    organisations: List<Organisation>,
    response: OrganisationResponse,
    organisation: Organisation,
    projects: List<Project>,
    create: suspend (ProjectTemplate) -> Unit,
    open: (String) -> Unit,
    openOrg: (String) -> Unit,
    logout: () -> Unit
) {

    Column {
        TopAppBar(
            title = {
                Text(organisation.name)
            },
            actions = {
                LoadingComposable(loader = {
                    mutableStateListOf(*(it.getInvitations().invitations.toTypedArray()))
                }) { invitations ->
                    Dropdown(
                        anchor = {
                            BadgedBox(badge = {
                                if (invitations.isNotEmpty()) {
                                    Badge { Text(invitations.size.toString()) }
                                }
                            }) {
                                IconButton(onClick = it) {
                                    Box(
                                        Modifier.size(30.dp)
                                            .background(
                                                MaterialTheme.colorScheme.primary,
                                                shape = CircleShape
                                            ),
                                        contentAlignment = Alignment.Center
                                    ) {
                                        Text(
                                            organisation.name.first().toString(),
                                            color = MaterialTheme.colorScheme.onPrimary
                                        )
                                    }
                                }
                            }
                        },
                        content = { close ->
                            organisations.filterNot { it.id == organisation.id }.forEach {
                                DropdownItem(title = it.name) {
                                    openOrg(it.id)
                                    close()
                                }
                            }
                            if (organisations.size > 1) {
                                HorizontalDivider()
                            }

                            Invitations(invitations) {
                                openOrg(it)
                                close()
                            }

                            if (LocalUserState.current.admin) {
                                NewOrgModal(
                                    anchor = {
                                        DropdownItem(title = "Create new organisation", onClick = it)
                                    },
                                    onCreated =  {
                                        close()
                                        openOrg(it)
                                    }
                                )
                                HorizontalDivider()
                            }

                            DropdownItem(title = "Sign out", onClick = logout)
                        }
                    )
                }
            }
        )

        val state = rememberScrollState()
        Box {
            Column(Modifier.padding(vertical = ThemeMargins.LargeMargins).verticalScroll(state)) {

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Text(
                        modifier = Modifier.padding(
                            vertical = ThemeMargins.SmallMargin,
                            horizontal = ThemeMargins.LargeMargins
                        ),
                        text = "Projects",
                        style = MaterialTheme.typography.headlineMedium
                    )
                    if (projects.isNotEmpty()) {
                        NewProjectModal(
                            orgId = organisation.id,
                            anchor = {
                                TextButton(onClick = it) {
                                    Icon(Icons.Default.Add, contentDescription = null)
                                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                                    Text("Create new")
                                }
                            },
                            create = create
                        )
                    }
                }

                if (projects.isEmpty()) {
                    Spacer(Modifier.width(ThemeMargins.LargeMargins))
                    NewProjectModal(
                        orgId = organisation.id,
                        anchor = {
                            StyledCard(onClick = it) {
                                Column(
                                    modifier = Modifier.fillMaxSize(),
                                    horizontalAlignment = Alignment.CenterHorizontally,
                                    verticalArrangement = Arrangement.Center
                                ) {
                                    Icon(
                                        Icons.Default.Add,
                                        modifier = Modifier.size(30.dp),
                                        contentDescription = null,
                                        tint = MaterialTheme.colorScheme.primary
                                    )
                                    Spacer(Modifier.height(ThemeMargins.SmallMargin))
                                    Text(
                                        text = "Create project",
                                        style = MaterialTheme.typography.titleLarge,
                                        color = MaterialTheme.colorScheme.primary
                                    )
                                }
                            }
                        },
                        create = create
                    )
                }

                FlowRow {
                    projects.forEach { project ->
                        Column(
                            modifier = Modifier
                                .size(180.dp)
                                .padding(ThemeMargins.SmallMargin)
                                .clip(RoundedCornerShape(12.dp))
                                .clickable { open(project.id) },
                            horizontalAlignment = Alignment.CenterHorizontally,
                            verticalArrangement = Arrangement.Center
                        ) {
                            Box(Modifier.size(100.dp).clip(RoundedCornerShape(8.dp))) {
                                project.appIcon?.full?.let {
                                    Image(
                                        modifier = Modifier.fillMaxSize(),
                                        painter = rememberAsyncImagePainter(project.appIcon?.full),
                                        contentDescription = null
                                    )
                                } ?: Box(
                                    Modifier
                                        .fillMaxSize()
                                        .border(
                                            1.dp,
                                            color = Color.Black.copy(alpha = 0.6f),
                                            shape = RoundedCornerShape(8.dp)
                                        )
                                        .tracingPaperBackground()
                                )

                            }
                            Spacer(Modifier.height(ThemeMargins.SmallMargin))
                            Text(project.projectConfig.name)
                        }
                    }
                    Spacer(Modifier.width(ThemeMargins.LargeMargins))
                }

                HorizontalDivider(Modifier.padding(vertical = ThemeMargins.LargeMargins))

                OrganisationMembers(response)

            }
            ScrollBars(state)
        }
    }
}

@Composable
private fun StyledCard(onClick: () -> Unit, content: @Composable ColumnScope.() -> Unit) {
    Card(
        modifier = Modifier
            .size(220.dp, 180.dp)
            .padding(ThemeMargins.SmallMargin),
        onClick = onClick,
        colors = CardDefaults.outlinedCardColors(containerColor = Color.White),
        elevation = CardDefaults.elevatedCardElevation(defaultElevation = 4.dp),
        content = content
    )

}

@Composable
private fun NewProjectModal(
    orgId: String,
    anchor: @Composable (() -> Unit) -> Unit,
    create: suspend (ProjectTemplate) -> Unit
) {
    ModalScaffold(
        anchor = anchor,
        header = {
            Text("Select project template")
        },
        body = {

            var selected by remember { mutableStateOf<ProjectTemplate?>(null) }

            Column(Modifier.fillMaxSize()) {

                LoadingComposable(
                    loader = {
                        it.getProjectTemplates(orgId)
                    }
                ) {
                    FlowRow(modifier = Modifier
                        .verticalScroll(rememberScrollState())
                        .padding(ThemeMargins.LargeMargins),
                        horizontalArrangement = Arrangement.spacedBy(ThemeMargins.LargeMargins)
                    ) {
                        it.forEach { template ->
                            Card(
                                modifier = Modifier.size(width = 300.dp, height = 425.dp),
                                onClick = {
                                    selected = if (selected == template) {
                                        null
                                    } else {
                                        template
                                    }
                                }
                            ) {
                                Box {
                                    Column {
                                        Column(
                                            Modifier.weight(0.4f).padding(ThemeMargins.LargeMargins)
                                        ) {
                                            Text(
                                                text = template.name,
                                                style = MaterialTheme.typography.headlineMedium
                                            )
                                            Text(
                                                text = template.description,
                                                style = MaterialTheme.typography.bodyMedium
                                            )
                                        }
                                        AsyncImage(
                                            modifier = Modifier
                                                .fillMaxWidth()
                                                .weight(0.6f),
                                            contentScale = ContentScale.FillHeight,
                                            model = template.image,
                                            contentDescription = null
                                        )
                                    }
                                    if (selected == template) {
                                        Box(
                                            Modifier.fillMaxSize().background(
                                                MaterialTheme.colorScheme.onBackground.copy(alpha = 0.6f)
                                            ),
                                            contentAlignment = Alignment.Center
                                        ) {
                                            Icon(
                                                Icons.Default.Check,
                                                contentDescription = null,
                                                tint = Color.Green,
                                                modifier = Modifier.size(60.dp)
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }

                    Spacer(Modifier.weight(1f))

                    LoadingButton(
                        modifier = Modifier.align(Alignment.End),
                        enabled = selected != null,
                        label = "Create",
                        action = {
                            selected?.let {
                                create(it)
                            }
                        })
                }
            }
        }
    )
}

@Composable
private fun NewOrgModal(
    anchor: @Composable (() -> Unit) -> Unit,
    onCreated: (String) -> Unit
) {
    ModalScaffold(
        anchor = anchor,
        size = ModalSize.Small,
        header = {
            Text("Create Organisation")
        },
        body = { close ->

            var name by remember { mutableStateOf("") }

            Column(Modifier.fillMaxSize()) {

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Name",
                    value = name,
                    onValueChange = {
                        name = it
                    }
                )


                Spacer(Modifier.weight(1f))

                val apiClient = LocalApiClient.current
                LoadingButton(
                    modifier = Modifier.align(Alignment.End),
                    enabled = name.isNotEmpty(),
                    label = "Create",
                    action = {
                        val result = apiClient.createOrganisation(name)
                        onCreated(result.id)
                        close()
                    }
                )
            }
        }
    )
}


@Composable
private fun Invitations(
    invitations: SnapshotStateList<Invitation>,
    navigate: (String) -> Unit
) {
    val apiClient = LocalApiClient.current
    val localSnackbar = LocalSnackbar.current
    val scope = rememberCoroutineScope()

    if (invitations.isNotEmpty()) {

        Text(
            modifier = Modifier
                .padding(start = ThemeMargins.SmallMargin)
                .padding(horizontal = ThemeMargins.SmallMargin),
            style = MaterialTheme.typography.titleMedium,
            text = "Invitations"
        )

        HorizontalDivider()
    }
    invitations.forEach {
        Row(
            modifier = Modifier.padding(start = ThemeMargins.SmallMargin),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(it.organisationName)
            IconButton(onClick = {
                scope.launch {
                    try {
                        apiClient.acceptInvitations(it)
                        navigate(it.organisationId)
                    } catch (ex: Exception) {
                        localSnackbar.send(SnackbarInfo.Builder.fromNetworkException(ex))
                    }
                }
            }) {
                Icon(Icons.Default.CheckCircle, contentDescription = null, tint = Color(0xff4BB543))
            }
            IconButton(onClick = {
                scope.launch {
                    try {
                        apiClient.rejectInvitations(it)
                        invitations.remove(it)
                    } catch (ex: Exception) {
                        localSnackbar.send(SnackbarInfo.Builder.fromNetworkException(ex))
                    }
                }
            }) {
                Icon(Icons.Default.RemoveCircle, contentDescription = null, tint = Color.Red)
            }
        }
    }
}
