package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("DateAdditionDataConversion")
@ConversionClass(
    title = "Add time",
    availableFrom = "0.0.1"
)
data class DateAdditionConversion(
    @InputProperty(
        title = "To format",
        availableFrom = "0.0.1"
    )
    val datePath: DataPath?,

    @InputProperty(
        title = "Seconds",
        availableFrom = "0.0.1"
    )
    val seconds: String?,

    @InputProperty(
        title = "Minutes",
        availableFrom = "0.0.1"
    )
    val minutes: String?,

    @InputProperty(
        title = "Hours",
        availableFrom = "0.0.1"
    )
    val hours: String?,

    @InputProperty(
        title = "Days",
        availableFrom = "0.0.1"
    )
    val days: String?,

    ): Conversion
