package com.appcreator.blueprint.core

import kotlinx.serialization.Serializable

@Serializable
data class UrlSpec(
    val url: String,
    val verb: Verb = Verb.Get,
    val queries: Map<String, String> = emptyMap(),
    val headers: Map<String, String> = emptyMap(),
    val bodyType: BodyType = BodyType.Json,
    val body: String = ""
) {

    enum class Verb {
        Get,
        Post,
        Put,
        Delete
    }
    enum class BodyType {
        Json,
        GraphQl,
        Form
    }
}
