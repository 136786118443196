package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("WebView")
@ComponentClass(
    group = "Basic",
    title = "Web View",
    availableFrom = "0.0.1"
)
data class WebViewComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "URL",
        availableFrom = "0.0.1"
    )
    val url: String?,

): Component
