package com.appcreator.compose.components.basic

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.layout.ContentScale
import coil3.compose.SubcomposeAsyncImage
import coil3.compose.rememberAsyncImagePainter
import com.appcreator.blueprint.components.basic.ImageComponent
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.extensions.SizingView
import com.appcreator.compose.extensions.value

@Composable
fun ImageComposable(modifier: Modifier, component: ImageComponent) {
    component.url?.let { url ->
        SizingView(component.width, component.height) { mod, hasWidth, hasHeight ->
            SubcomposeAsyncImage(
                modifier = mod.then(modifier),
                model = url.value(),
                loading = {
                    component.loading?.let { loading ->
                        ComponentComposable(Modifier, loading)
                    }
                },
                error = {
                    component.fallback?.let { fallback ->
                        ComponentComposable(Modifier, fallback)
                    }
                },
                contentScale = when {
                    hasWidth && !hasHeight -> ContentScale.FillWidth
                    hasHeight && !hasWidth -> ContentScale.FillHeight
                    else -> ContentScale.Fit
                },
                contentDescription = null,
            )
        }
    }
}

@Composable
fun Modifier.backgroundImage(
    url: LocalizableString?,
    opacity: String?
): Modifier {
    url?.value()?.let { localizedUrl ->
        val painter: Painter = rememberAsyncImagePainter(localizedUrl)
        return drawBehind {
            with(painter) {
                draw(size, alpha = opacity?.toFloatOrNull()?: 1f)
            }
        }
    }
    return this
}