package com.appcreator.creatorapp.editor.centerdata.rest

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.material3.Button
import androidx.compose.material3.FilterChip
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.dataspecs.ConversionDataSpec
import com.appcreator.blueprint.dataspecs.ConversionDataSpecBuilder
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpecBuilder
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.DataSpecBuilder
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.creatorapp.editor.local.DataOption
import com.appcreator.styles.ThemeMargins

@Composable
private fun JsonDataItem(item: ValueDataSpecBuilder, save: () -> Unit) {
    CreatorTextInput(
        label = "Name",
        value = item.key.value?: "",
        onValueChange = {
            item.key.value = it
                save()
        }
    )
}

@Composable
fun AddDataItemModal(
    options: List<DataOption>,
    anchor: @Composable (() -> Unit) -> Unit,
    onAdd: (DataSpecBuilder) -> Unit,
) {

    val valueDI by remember { mutableStateOf(ValueDataSpec("", valueType = ValueDataSpec.Type.String,"", false).toBuilder() as ValueDataSpecBuilder) }
    val conversionDI by remember { mutableStateOf(ConversionDataSpec("", "", null).toBuilder() as ConversionDataSpecBuilder) }
    var dataItem by remember { mutableStateOf<DataSpecBuilder>(valueDI) }

    ModalScaffold(
        size = ModalSize.Small,
        anchor = anchor,
        header = {


            Row(
                modifier = Modifier,
                horizontalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text("Add data item")

                FilterChip(
                    selected = dataItem is ValueDataSpecBuilder,
                    onClick = {
                        dataItem = valueDI
                    },
                    label = {
                        Text("Json")
                    }
                )
                FilterChip(
                    selected = dataItem is ConversionDataSpecBuilder,
                    onClick = {
                        dataItem = conversionDI
                    },
                    label = {
                        Text("Function")
                    }
                )
            }
        },
        body = {
            when(dataItem) {
                is ValueDataSpecBuilder -> JsonDataItem(dataItem as ValueDataSpecBuilder) {}
                is ConversionDataSpecBuilder -> DataItemFunction(options, dataItem as ConversionDataSpecBuilder) {}
            }
        },
        footer = { close ->
            Button(onClick = {
                onAdd(dataItem)
                close()
            }) {
                Text("Add")
            }
        }
    )
}

@Composable
fun EditDataItemModal(
    options: List<DataOption>,
    anchor: @Composable (() -> Unit) -> Unit,
    dataItem: ConversionDataSpecBuilder,
    save: () -> Unit
) {
    ModalScaffold(
        anchor = anchor,
        header = {
            Text("Edit data item")
        },
        body = {
            DataItemFunction(options, dataItem, save)
        },
        footer = { close ->
            Button(onClick = {
                close()
            }) {
                Text("Close")
            }
        }
    )
}