package com.appcreator.compose.extensions

import androidx.compose.foundation.shape.CornerSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.CornerValues

fun CornerValues.toShape(): Shape = RoundedCornerShape(
    topStart = CornerSize(size = (topStart?: 0).dp),
    topEnd = CornerSize(size = (topEnd?: 0).dp),
    bottomStart = CornerSize(size = (bottomStart?: 0).dp),
    bottomEnd = CornerSize(size = (bottomEnd?: 0).dp),
)
