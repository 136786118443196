package com.appcreator.creatorapp.project.configurations

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.contentful.ContentfulConsts
import com.appcreator.components.layouts.CheckboxGroup
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.dto.configurations.ContentfulConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfigurationTypes
import com.appcreator.styles.ThemeMargins
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonObject

private fun ThirdPartyConfiguration.configOrDefault(json: Json): ContentfulConfiguration {
    return config?.let { json.decodeFromJsonElement(it) } ?: ContentfulConfiguration(
        space = "",
        environment = "",
        accessToken = ""
    )
}

@Composable
fun ContentfulConfiguration(
    org: OrganisationResponse,
    project: Project
) {
    LoadingComposable(loader = {
        mutableStateOf(it.getThirdPartyConfiguration(org.organisation.id, project.id, ThirdPartyConfigurationTypes.contentful))
    }) {

        val json = LocalApiClient.current.json
        var display by remember { mutableStateOf(it.value) }
        val startConfig = remember { it.value.configOrDefault(json) }
        var displayConfig by remember { mutableStateOf(startConfig) }

        Column(Modifier.padding(horizontal = ThemeMargins.LargeMargins)) {
            CheckboxGroup(
                label = "Enabled",
                checked = display.enabled,
                onCheckedChange = {
                    display = display.copy(enabled = it, components = listOf(ContentfulConsts.group))
                }
            )

            val client = LocalApiClient.current
            LoadingButton(
                label = "Save Changes",
                enabled = it.value != display || startConfig != displayConfig,
                action = {
                    client.setThirdPartyConfiguration(
                        org.organisation.id,
                        project.id,
                        display.copy(config = json.encodeToJsonElement(displayConfig).jsonObject)
                    )
                    it.value = display
                }
            )
        }

        if (display.enabled) {
            Column(Modifier.padding(ThemeMargins.LargeMargins)) {
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Space",
                    value = displayConfig.space,
                    onValueChange = {
                        displayConfig = displayConfig.copy(space = it)
                    }
                )

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Environment",
                    value = displayConfig.environment,
                    onValueChange = {
                        displayConfig = displayConfig.copy(environment = it)
                    }
                )

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Access Token",
                    value = displayConfig.accessToken,
                    onValueChange = {
                        displayConfig = displayConfig.copy(accessToken = it)
                    }
                )
            }
        }
    }
}

