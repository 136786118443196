package com.appcreator.creatorapp.editor.centerdata.rest.functions

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.spec.ConversionBuilder
import com.appcreator.creatorapp.editor.inputs.InputItem
import com.appcreator.styles.ThemeMargins

@Composable
fun FunctionBuilder(builder: ConversionBuilder, save: () -> Unit) {

    builder.inputSpecs.forEach {
        InputItem(it, save)
        Box(Modifier.height(ThemeMargins.SmallMargin))
    }

}