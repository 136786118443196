package com.appcreator.creatorapp.editor.inputs

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.spec.inputs.FloatInputSpec
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput

@Composable
fun FloatInput(spec: FloatInputSpec, save: () -> Unit) {
    DataSourceTextInput(
        label = spec.properties.title,
        value = spec.value?.toString() ?: "",
        allowDataItems = spec.properties.allowDataFields,
        update = {
            spec.value = it.toFloatOrNull()
            save()
        }
    )
}