package com.appcreator.creatorapp.editor.paneldevicepreview

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.key
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.appcreator.app.utils.click
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.Destination
import com.appcreator.blueprint.core.Component
import com.appcreator.compose.LocalDrawOnRoot
import com.appcreator.compose.components.navigation.Navigation
import com.appcreator.compose.components.navigation.rememberNavigator
import com.appcreator.compose.theme.Theme
import com.appcreator.creatorapp.editor.HoverAndDragManager

@Composable
fun ComposeAppPreview(
    blueprint: Blueprint,
    destination: State<Destination>,
    dragManager: HoverAndDragManager,
    selectNode: (Component) -> Unit
) {

    Box(modifier = Modifier.background(Color.White).click {
        dragManager.hoveredPreviewNode?.let(selectNode)
    }) {
        val drawOnRoot by LocalDrawOnRoot.current

        AnimatedContent(destination.value) {
            Box {
                blueprint.Theme {
                    val navigator = rememberNavigator(
                        false,
                        it.screen.path,
                        parent = null
                    )
                    Navigation(Modifier, navigator)
                    drawOnRoot?.let {
                        Box(Modifier.fillMaxSize().background(Color.Black.copy(alpha = 0.6f))) {
                            it()
                        }
                    }
                }
            }
        }
    }
}
