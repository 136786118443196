package com.appcreator.compose.components.navigation

import androidx.compose.foundation.layout.padding
import androidx.compose.material.BottomNavigation
import androidx.compose.material.BottomNavigationItem
import androidx.compose.material.ContentAlpha
import androidx.compose.material.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.sp
import com.appcreator.blueprint.components.basic.MaterialIconComponent
import com.appcreator.blueprint.components.navigation.BottomNavigationComponent
import com.appcreator.blueprint.components.navigation.NavigationItemComponent
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.compose.KotlinXNavigator
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalSelected
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.Navigator
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.value

@Composable
fun BottomNavigationComposable(
    modifier: Modifier,
    component: BottomNavigationComponent,
) {
    val start = (component.tabs.firstOrNull() as? NavigationItemComponent)?.link?.path ?: "screen"
    val navigator = rememberNavigator(true, start)
    BottomNavigationContent(modifier, component, navigator)
}

@Composable
fun BottomNavigationContent(
    modifier: Modifier,
    component: BottomNavigationComponent,
    navigator: Navigator
) {

    val envStore = LocalEnvStore.current
    val theme = LocalTheme.current
    val color = theme?.color(component.color)?.composeColor() ?: MaterialTheme.colorScheme.surface

    Scaffold(
        modifier = modifier,
        bottomBar = {
            BottomNavigation(
                modifier = modifier,
                backgroundColor = color
            ) {
                component.tabs
                    .filterIsInstance<NavigationItemComponent>()
                    .forEach {
                        val isSelected = bottomNavigationSelected(navigator, it.link)
                        val alwaysShowLabels = component.alwaysShowLabels == true
                        CompositionLocalProvider(LocalSelected provides isSelected) {
                            BottomNavigationItem(
                                selected = isSelected,
                                alwaysShowLabel = alwaysShowLabels,
                                selectedContentColor = theme?.color(component.selectedColor)?.composeColor()?: LocalContentColor.current,
                                unselectedContentColor = theme?.color(component.unselectedColor)?.composeColor()?: LocalContentColor.current.copy(alpha = ContentAlpha.medium),
                                onClick = {
                                    it.link?.let { link ->
                                        navigator.navigate(link, envStore) {
                                            popUpToRoot = true
//                                            singleTop = true
                                        }
                                    }

                                }, icon = {
                                    ComponentComposable(
                                        Modifier, MaterialIconComponent(
                                            _nodeId = null,
                                            _nodeLabel = null,
                                            icon = it.materialIcon,
                                            size = 24,
                                            color = if (isSelected) component.selectedColor else component.unselectedColor
                                        )
                                    )
                                }, label = {
                                    it.title?.value()?.let { value ->
                                        CompositionLocalProvider(LocalDensity provides Density(LocalDensity.current.density, 1f)) {
                                            val font = theme?.font(component.labelSize)
                                            Text(
                                                color = LocalContentColor.current,
                                                text = value,
                                                fontSize = font?.fontSize?.sp ?: MaterialTheme.typography.labelSmall.fontSize,
                                                fontWeight = font?.fontWeight?.let { weight -> FontWeight(weight) },
                                                softWrap = false
                                            )
                                        }
                                    }
                                })
                        }
                    }
            }
        }) { padding ->
        Navigation(
            modifier = Modifier.padding(padding),
            navigator = navigator,
        )
    }
}

@Composable
fun bottomNavigationSelected(
    navigator: Navigator?,
    link: BlueprintLink?
): Boolean = (navigator as? KotlinXNavigator)?.inStack(link) == true