package com.appcreator.compose.components.navigation

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.DrawerValue
import androidx.compose.material3.ModalDrawerSheet
import androidx.compose.material3.ModalNavigationDrawer
import androidx.compose.material3.rememberDrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.navigation.NavigationDrawerComponent
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.LocalNavigationDrawer
import com.appcreator.compose.LocalNavigator
import com.appcreator.compose.Navigator
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.InputEnvComposable

@Composable
fun NavigationDrawerComposable(modifier: Modifier, component: NavigationDrawerComponent) {
    InputEnvComposable(
        mapOf("drawer-state" to "Closed")
    ) {
        val envStore = LocalEnvStore.current
        val inputEnvStore = LocalInputEnvStore.current

        val inPreview = LocalInPreview.current

        val state = rememberDrawerState(if(inPreview) DrawerValue.Open else DrawerValue.Closed)

        val navigator = component.link?.let {
            rememberNavigator(false, it.path)
        } ?: LocalNavigator.current

        ModalNavigationDrawer(
            modifier = modifier,
            drawerState = state,
            gesturesEnabled = !inPreview,
            drawerContent = {
                ModalDrawerSheet(Modifier.widthIn(max = 250.dp)) {
                    CompositionLocalProvider(LocalNavigator provides navigator) {
                        component.drawer?.let {
                            ComponentComposable(Modifier, it)
                        }
                    }
                }
            },
            content = {
                CompositionLocalProvider(LocalNavigationDrawer provides true) {
                    component.link?.let {
                        navigator?.let {
                            Navigation(Modifier, navigator)
                        }
                    }
                }
            }
        )

        LaunchedEffect(envStore.env["drawer-state"]) {
            envStore.env["drawer-state"]?.let { value ->
                DrawerValue.entries.find { it.name == value }?.let {
                    when (it) {
                        DrawerValue.Closed -> state.close()
                        DrawerValue.Open -> state.open()
                    }
                }
            }
        }
        LaunchedEffect(state.currentValue) {
            inputEnvStore.set("drawer-state", state.currentValue.name)
        }
    }
}

@Composable
fun NavigationDrawer(
    navigator: Navigator,
    drawerWidth: Int,
    showDrawerInPreview: Boolean,
    drawer: @Composable () -> Unit,
    content: @Composable () -> Unit
) {

    InputEnvComposable(
        mapOf("drawer-state" to "Closed")
    ) {
        val envStore = LocalEnvStore.current
        val inputEnvStore = LocalInputEnvStore.current

        val inPreview = LocalInPreview.current

        val state = rememberDrawerState(DrawerValue.Closed)

//        val navigator = component.link?.let {
//            rememberNavigator(false, it.path)
//        } ?: LocalNavigator.current

        ModalNavigationDrawer(
//            modifier = modifier,
            drawerState = state,
            gesturesEnabled = !inPreview,
            drawerContent = {
                ModalDrawerSheet(Modifier.width(drawerWidth.dp)) {
                    CompositionLocalProvider(LocalNavigator provides navigator) {
                        drawer()
                    }
                }
            },
            content = {
                CompositionLocalProvider(LocalNavigationDrawer provides true) {
                    content()
                }
            }
        )

        LaunchedEffect(envStore.env["drawer-state"]) {
            envStore.env["drawer-state"]?.let { value ->
                DrawerValue.entries.find { it.name == value }?.let {
                    when (it) {
                        DrawerValue.Closed -> state.close()
                        DrawerValue.Open -> state.open()
                    }
                }
            }
        }

        if(inPreview) {
            LaunchedEffect(showDrawerInPreview) {
                if (showDrawerInPreview) {
                    state.open()
                } else {
                    state.close()
                }
            }
        }

        LaunchedEffect(state.currentValue) {
            inputEnvStore.set("drawer-state", state.currentValue.name)
        }
    }
}