package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.ScreenPath
import com.appcreator.blueprint.core.findParameters
import com.appcreator.blueprint.spec.inputs.ScreenPathInputSpec
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput
import com.appcreator.styles.ThemeMargins

@Composable
fun ScreenPathInput(spec: ScreenPathInputSpec, save: () -> Unit) {

    DataSourceTextInput(
        label = spec.properties.title,
        value = spec.value?.value ?: "",
        allowDataItems = spec.properties.allowDataFields,
        update = {
            val defaultParams = it.findParameters().associateWith { key ->
                spec.value?.defaultParameters?.get(key) ?: ""
            }
            spec.value = ScreenPath(value = it, defaultParams)
            save()
        }
    )


    Column(Modifier.padding(start = ThemeMargins.LargeMargins)) {

        spec.value?.defaultParameters
            ?.forEach { parameter ->

                Box(Modifier.height(ThemeMargins.SmallMargin))
                DataSourceTextInput(
                    label = parameter.key,
                    value = parameter.value,
                    allowDataItems = false,
                    update = { input ->
                        spec.value?.let {
                            spec.value =
                                it.copy(defaultParameters = it.defaultParameters?.plus(parameter.key to input))
                            save()
                        }
                    }
                )
            }
    }
}