package com.appcreator.blueprint.spec


class ConditionSpec(
    val name: String,
    val title: String,
    val description: String,
    val availableFrom: String,
    val accessible: Boolean
)
