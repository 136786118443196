package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.spec.SingleInputSpec

class EnumInputSpec(
    properties: InputProperties,
    val options: List<String>,
    value: MutableState<String?>
): SingleInputSpec<String?>(properties, value)
