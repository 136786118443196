package com.appcreator.styles

import androidx.compose.ui.unit.dp


object ThemeMargins {
    val SmallMargin = 10.dp
    val MedMargins = 15.dp
    val LargeMargins = 30.dp
}
