package com.appcreator.blueprint.actions.`data`

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.DataPathInputSpec
import com.appcreator.blueprint.spec.inputs.DataValueSetInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class RemoveListItemActionBuilder(
  action: RemoveListItemAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "RemoveListItemAction",
          title = "Remove List Item",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
      )

  public val listPath: DataPathInputSpec = DataPathInputSpec(
          properties = InputProperties(
          section = "",
          title = "List",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = true,
          settableDataOnly = true,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(action?.listPath),
          
          
      )

  public val values: DataValueSetInputSpec = DataValueSetInputSpec(
          properties = InputProperties(
          section = "",
          title = "Where equals",
          description = "Leave value blank to match any",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { listOfNotNull(listPath.value?.value) },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(action?.values),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(listPath, values)

  override fun build(): Action = RemoveListItemAction(listPath.value, values.value)
}
