package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.SingleInputSpec

class ComponentInputSpec(
    properties: InputProperties,
    override val allowedComponents: List<String> = emptyList(),
    value: MutableState<ComponentBuilder?>
): SingleInputSpec<ComponentBuilder?>(properties, value), ComponentInput