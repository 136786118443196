package com.appcreator.creatorapp.editor.centertypography

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.DragHandle
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.appcreator.blueprint.core.properties.Font
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.creatorapp.editor.centerdata.rest.randomStringByKotlinRandom
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.styles.ThemeMargins
import sh.calvin.reorderable.ReorderableItem
import sh.calvin.reorderable.rememberReorderableLazyListState

@Composable
fun TypographyCenterModal(
    anchor: @Composable (() -> Unit) -> Unit,
) {
    ModalScaffold(
        anchor = anchor,
        header = {
            Text("Typography")
        },
        body = {
            TypographyCenterScreen()
        }
    )
}

@Composable
fun TypographyCenterScreen() {

    val blueprintManager = LocalBlueprintManager.current

    val fonts = blueprintManager.theme.fonts
    var selected by remember { mutableStateOf(fonts.firstOrNull()) }
    Row(Modifier.fillMaxSize()) {


        Box(Modifier.width(240.dp).fillMaxHeight()) {

            val lazyListState = rememberLazyListState()
            val reorderableLazyListState = rememberReorderableLazyListState(lazyListState) { from, to ->
                fonts.add(to.index, fonts.removeAt(from.index))
                blueprintManager.notifyUpdates(true)
            }

            LazyColumn(
                state = lazyListState,
                modifier = Modifier.fillMaxSize(),
                contentPadding = PaddingValues(bottom = 64.dp)
            ) {
                items(fonts, key = { it }) { font ->
                    ReorderableItem(reorderableLazyListState, key = font) {
                        NavigationDrawerItem(
                            colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified),
                            selected = selected == font,
                            label = {
                                Row(
                                    modifier = Modifier.fillMaxWidth(),
                                    verticalAlignment = Alignment.CenterVertically,
                                    horizontalArrangement = Arrangement.SpaceBetween
                                ) {
                                    Text(font.name)
                                    Text(
                                        text = font.fontSize.toString(),
                                        fontWeight = FontWeight(font.fontWeight)
                                    )
                                }
                            },
                            icon = {
                                IconButton(modifier = Modifier.draggableHandle(), onClick = {}) {
                                    Icon(Icons.Default.DragHandle, contentDescription = null)
                                }
                            },
                            onClick = {
                                selected = font
                            }
                        )
                    }
                }
            }

            ExtendedFloatingActionButton(
                modifier = Modifier.align(Alignment.BottomCenter),
                text = {
                    Text("Add")
                },
                icon = {
                    Icon(Icons.Default.Add, contentDescription = null)
                },
                onClick = {
                    val newFont = Font(
                        id = randomStringByKotlinRandom(),
                        name = "New Font",
                        fontName = "Roboto",
                        fontSize = 16,
                        fontWeight = 400,
                        userCreated = true
                    )
                    fonts.add(newFont)
                    selected = newFont
                    blueprintManager.notifyUpdates(true)
                }
            )
        }

        Spacer(Modifier.width(ThemeMargins.SmallMargin))
        VerticalDivider()
        Spacer(Modifier.width(ThemeMargins.SmallMargin))

        selected?.let {
            Column(modifier = Modifier
                .padding(horizontal = ThemeMargins.SmallMargin)
            ) {
                val index = fonts.indexOf(it)
                FontConfig(
                    font = it,
                    update =  { newFont ->
                        fonts[index] = newFont
                        selected = newFont
                        blueprintManager.notifyUpdates(true)
                    },
                    delete = {
                        fonts.remove(it)
                        selected = null
                        blueprintManager.notifyUpdates(true)
                    }
                )
            }
        }
    }
}


@Composable
fun FontConfig(
    font: Font,
    update: (Font) -> Unit,
    delete: () -> Unit
) {
    Column {

        if (font.userCreated == true) {
            CreatorTextInput(
                label = "Title",
                value = font.name,
                onValueChange = {
                    update(font.copy(name = it))
                }
            )
            Box(Modifier.height(ThemeMargins.SmallMargin))
        }

        // TODO add different font support
//        CreatorTextInput(
//            label = "Font Name",
//            value = font.fontName,
//            onValueChange = {
//                update(font.copy(fontName = it))
//            }
//        )
//        Box(Modifier.height(ThemeMargins.SmallMargin))

        var size by remember(font) { mutableStateOf(font.fontSize.toString()) }
        CreatorTextInput(
            label = "Size",
            value = size,
            onValueChange = {
                size = it
            }
        )
        LaunchedEffect(size) {
            size.toIntOrNull()?.let { size -> update(font.copy(fontSize = size)) }
        }
        Box(Modifier.height(ThemeMargins.SmallMargin))

        var weight by remember(font) { mutableStateOf(font.fontWeight.toString()) }
        CreatorTextInput(
            label = "Weight",
            value = weight,
            onValueChange = {
                weight = it
            }
        )
        LaunchedEffect(weight) {
            weight.toIntOrNull()?.let { weight -> update(font.copy(fontWeight = weight)) }
        }
        Box(Modifier.height(ThemeMargins.SmallMargin))

        if (font.userCreated == true) {
            TextButton(
                modifier = Modifier.align(Alignment.End),
                onClick = delete
            ) {
                Icon(Icons.Default.Delete, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Delete")
            }
            Box(Modifier.height(ThemeMargins.SmallMargin))
        }

        Box(Modifier.height(ThemeMargins.LargeMargins))

        Text(
            text = "Preview",
            style = MaterialTheme.typography.headlineSmall
        )

        Box(Modifier.height(ThemeMargins.SmallMargin))
        HorizontalDivider()
        Box(Modifier.height(ThemeMargins.SmallMargin))

        Text(
            text = "Lorem Ipsum",
            style = TextStyle(
                fontSize = font.fontSize.sp,
                fontWeight = FontWeight(font.fontWeight),
            )
        )
    }

//    val leftAddOn = LeftAddonStyle.toModifier()

//    if (font.userCreated == true) {
//        InputGroup(modifier = Modifier.fillMaxWidth()) {
//            LeftAddon(leftAddOn) {
//                Text("Title")
//            }
//            TextInput(
//                text = font.name,
//                onTextChanged = {
//                    update(font.copy(name = it))
//                }
//            )
//        }
//        Box(Modifier.height(ThemeMargins.SmallMargin))
//    }
//
//    // TODO add validation and drop downs
//    InputGroup(modifier = Modifier.fillMaxWidth()) {
//        LeftAddon(leftAddOn) {
//            Text("Name")
//        }
//        TextInput(
//            text = font.fontName,
//            onTextChanged = {
//                update(font.copy(fontName = it))
//            }
//        )
//    }
//    Box(Modifier.height(ThemeMargins.SmallMargin))
//    var size by remember(font) { mutableStateOf(font.fontSize.toString()) }
//    InputGroup(modifier = Modifier.fillMaxWidth()) {
//        LeftAddon(leftAddOn) {
//            Text("Size")
//        }
//        TextInput(
//            text = size,
//            onTextChanged = {
//                size = it
//            }
//        )
//    }
//    LaunchedEffect(size) {
//        size.toIntOrNull()?.let { size -> update(font.copy(fontSize = size)) }
//    }
//
//    Box(Modifier.height(ThemeMargins.SmallMargin))
//    var weight by remember(font) { mutableStateOf(font.fontWeight.toString()) }
//    InputGroup(modifier = Modifier.fillMaxWidth()) {
//        LeftAddon(leftAddOn) {
//            Text("Weight")
//        }
//        TextInput(
//            text = weight,
//            onTextChanged = {
//                weight = it
//            }
//        )
//    }
//    LaunchedEffect(weight) {
//        weight.toIntOrNull()?.let { weight -> update(font.copy(fontWeight = weight)) }
//    }
//
//
//    Box(Modifier.height(ThemeMargins.SmallMargin))
//
//    if (font.userCreated == true) {
//        Button(
//            type = ButtonType.Reset,
//            onClick = {
//                delete()
//            }) {
//            Text("Delete")
//        }
//        Box(Modifier.height(ThemeMargins.SmallMargin))
//    }
}