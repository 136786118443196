package com.appcreator.creatorapp.editor.panelcomponent.views

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ContentCopy
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Done
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material.icons.filled.MoveUp
import androidx.compose.material.icons.filled.Visibility
import androidx.compose.material.icons.filled.VisibilityOff
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.key
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.ScreenBuilder
import com.appcreator.blueprint.components.data.PagedLoaderComponentBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.mergedInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.creatorapp.editor.LocalHighlightSelected
import com.appcreator.creatorapp.editor.inputs.InputItem
import com.appcreator.creatorapp.editor.local.DataOption
import com.appcreator.creatorapp.editor.local.LocalComponentClipboard
import com.appcreator.creatorapp.editor.local.LocalDataOptions
import com.appcreator.creatorapp.editor.local.LocalNodeExplorer
import com.appcreator.creatorapp.editor.local.LocalSelectedComponent
import com.appcreator.creatorapp.editor.utils.displayLabel
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch

@Composable
fun ComponentItem(
    builder: ComponentBuilder,
    save: () -> Unit,
    delete: suspend (ScreenBuilder) -> Unit,
    deleteComponent: (ComponentBuilder, ComponentBuilder) -> Unit
) {

    val nodeExplorer = LocalNodeExplorer.current
    val parent = nodeExplorer.parent(builder)
    val selectedComponent = LocalSelectedComponent.current

    var edit by remember { mutableStateOf(false) }

    Row(
        modifier = Modifier.fillMaxWidth().padding(top = ThemeMargins.LargeMargins),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.End
    ) {
        if (builder is ScreenBuilder) {
            DeleteScreenButton(builder, delete)
        } else {
            parent?.let {
                TextButton(
                    onClick = {
                        selectedComponent.value = parent
                    }) {
                    Icon(Icons.Default.MoveUp, contentDescription = null)
                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                    Text(it.displayLabel())
                }
            }

            Spacer(Modifier.weight(1f))

            SaveToClipBoardButton(builder)
            TextButton(onClick = {
                parent?.let {
                    deleteComponent(builder, parent)
                    save()
                    selectedComponent.value = parent
                }
            }) {
                Icon(Icons.Default.Delete, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Delete")
            }
        }
    }

    Row(
        modifier = Modifier.fillMaxWidth().padding(bottom = ThemeMargins.LargeMargins),
        verticalAlignment = Alignment.CenterVertically,
    ) {

        if (edit) {
            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = builder.spec.title,
                value = builder._nodeLabel.value ?: "",
                onValueChange = {
                    builder._nodeLabel.value = it
                    save()
                },
                trailingIcon = {
                    IconButton(
                        onClick = {
                            edit = false
                        }
                    ) {
                        Icon(Icons.Default.Done, contentDescription = null)
                    }
                }
            )
        } else {
            if (builder !is ScreenBuilder) {
                Text(
                    text = builder.displayLabel(),
                    style = MaterialTheme.typography.headlineMedium
                )
            }
        }

        parent?.let {
            if(!edit) {
                IconButton(
                    onClick = {
                        edit = true
                    }) {

                    Icon(Icons.Default.Edit, contentDescription = null)
                }

                Spacer(Modifier.weight(1f))
                var highlight by LocalHighlightSelected.current
                IconButton(
                    onClick = {
                        highlight = !highlight
                    }) {
                    if(highlight) {
                        Icon(Icons.Default.VisibilityOff, contentDescription = null)
                    } else {
                        Icon(Icons.Default.Visibility, contentDescription = null)
                    }
                }
            }
        }
    }

    key(builder._nodeId.value) {

        builder.mergedInputSpec()
            .filter { it.properties.visible() }
            .groupBy { it.properties.section }
            .onEachIndexed { _, (section, values) ->
                Spacer(Modifier.height(ThemeMargins.MedMargins))
                if (section.isNotEmpty()) {
                    Text(
                        modifier = Modifier.padding(
                            top = ThemeMargins.MedMargins,
                            bottom = ThemeMargins.MedMargins
                        ),
                        text = section,
                        style = MaterialTheme.typography.titleLarge
                    )

                }
                values.forEach {

                    // Seems like this might be bad for performance should maybe think about making better
                    // Don't really want to do the often but the paged loader is kind of weird
                    val dataItems = when (builder) {
                        is PagedLoaderComponentBuilder -> {
                            val loaderDataOptions =
                                nodeExplorer.loaderSpecDataShape(builder.config)?.options?.let {
                                    listOf(DataOption(builder.displayLabel(), false, it))
                                } ?: emptyList()
                            rememberDataOptionsFromShapes(
                                inclusive = it.properties.includeSiblingData,
                                plus = loaderDataOptions
                            )
                        }

                        else -> rememberDataOptionsFromShapes(inclusive = it.properties.includeSiblingData)
                    }

                    key(it.properties.title, it.properties.section) {
                        CompositionLocalProvider(LocalDataOptions provides dataItems) {
                            InputItem(it, save)
                            Box(Modifier.height(ThemeMargins.SmallMargin))
                        }
                    }
                }
            }
    }
}

@Composable
private fun DeleteScreenButton(
    builder: ScreenBuilder,
    delete: suspend (ScreenBuilder) -> Unit
) {
    ModalScaffold(
        size = ModalSize.Custom(Modifier.size(400.dp, 300.dp)),
        header = {
            Text("Delete Screen")
        },
        body = {
            Text("Are you sure this can not be undone.")
        },
        footer = {
            val scope = rememberCoroutineScope()
            TextButton(
                onClick = {
                    scope.launch {
                        try {
                            delete(builder)
                            it()
                        } catch (ex: Exception) {
                            ex.printStackTrace()
                        }
                    }
                }) {
                Text("Delete")
            }
        },
        anchor = {
            TextButton(onClick = it) {
                Icon(Icons.Default.Delete, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Delete Screen")
            }
        }
    )
}

@Composable
private fun SaveToClipBoardButton(
    builder: ComponentBuilder,
) {
    val componentClipboard = LocalComponentClipboard.current
    TextButton(
        onClick = {
            if(componentClipboard.components.size >= 8) {
                componentClipboard.components.removeFirst()
            }
            componentClipboard.components.add(builder.build())
        }) {
        Icon(Icons.Default.ContentCopy, contentDescription = null)
        Spacer(Modifier.width(ThemeMargins.SmallMargin))
        Text("Copy")
    }
}
