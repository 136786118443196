package com.appcreator.compose.extensions

import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.SideValues


fun SideValues.toPadding() = PaddingValues(
    start = (start?: 0).dp,
    top = (top?: 0).dp,
    end = (end?: 0).dp,
    bottom = (bottom?: 0).dp
)