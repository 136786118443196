package com.appcreator.blueprint.components.basic

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.blueprint.spec.inputs.ColorReferenceInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.CornerValuesInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.IntInputSpec
import com.appcreator.blueprint.spec.inputs.SizeValueInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class ButtonComponentBuilder(
  component: ButtonComponent?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "Basic",
          name = "ButtonComponent",
          title = "Button",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  public val content: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Content",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.content?.toBuilder(newId)),
          allowedComponents = listOf("TextComponent","MaterialIconComponent"),
          
      )

  public val buttonType: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "",
          title = "Type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf((component?.buttonType?: ButtonComponent.ButtonType.Default).name),
          
          options = ButtonComponent.ButtonType.entries.map { it.name },
      )

  public val width: SizeValueInputSpec = SizeValueInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Width",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.width),
          
          
      )

  public val height: SizeValueInputSpec = SizeValueInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Height",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.height),
          
          
      )

  public val color: ColorReferenceInputSpec = ColorReferenceInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Color",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && buttonType.value != ButtonComponent.ButtonType.Icon.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.color),
          
          
      )

  public val borderColor: ColorReferenceInputSpec = ColorReferenceInputSpec(
          properties = InputProperties(
          section = "Border",
          title = "Color",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && buttonType.value != ButtonComponent.ButtonType.Icon.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.borderColor),
          
          
      )

  public val borderRadius: CornerValuesInputSpec = CornerValuesInputSpec(
          properties = InputProperties(
          section = "Border",
          title = "Radius",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && buttonType.value != ButtonComponent.ButtonType.Icon.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.borderRadius),
          
          
      )

  public val borderThickness: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "Border",
          title = "Thickness",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && buttonType.value != ButtonComponent.ButtonType.Icon.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.borderThickness),
          
          
      )

  public val action: ActionInputSpec = ActionInputSpec(
          properties = InputProperties(
          section = "On Click",
          title = "Action",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.action?.toBuilder(newId)),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, content, buttonType, width,
      height, color, borderColor, borderRadius, borderThickness, action)

  override fun build(): Component = ButtonComponent(_nodeId.value?: Uuid.random().toString(),
      _nodeLabel.value, content.value?.build(), buttonType.value?.let {
      ButtonComponent.ButtonType.valueOf(it) }?: ButtonComponent.ButtonType.Default, width.value,
      height.value, color.value, borderColor.value, borderRadius.value, borderThickness.value,
      action.value?.build())
}
