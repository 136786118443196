package com.appcreator.app.file

import okio.internal.commonToUtf8String

class FileInfo(
    val data: ByteArray,
    val mimeType: String,
    val name: String,
    val size: Long
)

expect fun openFilePicker(accept: String, onPick: (FileInfo) -> Unit)

fun openTextFilePicker(accept: String, onPick: (String) -> Unit) {
    openFilePicker(accept) {
        onPick(it.data.commonToUtf8String())
    }
}
