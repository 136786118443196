package com.appcreator.compose.components.navigation

import androidx.compose.foundation.layout.Box
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.rememberNavController
import androidx.navigation.navArgument
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.findDestination
import com.appcreator.compose.KotlinXNavigator
import com.appcreator.compose.LocalBlueprint
import com.appcreator.compose.LocalNavigator
import com.appcreator.compose.Navigator
import com.appcreator.compose.ScreenComposable

@Composable
fun rememberNavigator(includesToolbar: Boolean, startDestination: String, parent: Navigator? = LocalNavigator.current, blueprint: Blueprint = LocalBlueprint.current): Navigator {
    val navController = rememberNavController()
    val navigator = remember { KotlinXNavigator(includesToolbar, parent, startDestination, blueprint, navController) }
    NavigationPlatformSetup(startDestination, navigator)
    return navigator
}

@Composable
expect fun NavigationPlatformSetup(startDestination: String, navigator: KotlinXNavigator)

@Composable
fun Navigation(modifier: Modifier, navigator: Navigator) {

    val androidNavigator = navigator as KotlinXNavigator

    CompositionLocalProvider(
        LocalNavigator provides navigator
    ) {
        Box {
            NavHost(
                modifier = modifier,
                navController = androidNavigator.navController,
                startDestination = "screen"
            ) {
                composable(
                    "screen?route={route}",
                    arguments = listOf(navArgument("route") {
                        defaultValue = androidNavigator.startDestination
                    })
                ) { stackEntry ->
                    val route = stackEntry.arguments?.getString("route")
                        ?: androidNavigator.startDestination
                    androidNavigator.blueprint.findDestination(route)?.let {
                        ScreenComposable(destination = it)
                    }
                }
            }
        }
    }
}