package com.appcreator.blueprint.conditions

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.ScriptContent
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Javascript")
@ConditionClass(
    group = "Logic",
    title = "Javascript",
    availableFrom = "0.0.1"
)
data class JavascriptCondition(
    @InputProperty(
        title = "Script",
        availableFrom = "0.0.1"
    )
    val scriptContent: ScriptContent?
): Condition
