package com.appcreator.dto

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class Organisation(
    val id: String,
    val name: String,
    val subscription: OrganisationSubscription?
)


@Serializable
data class OrganisationSubscription(
    val isValid: Boolean,
    val expires: Instant,
    val customerId: String
)