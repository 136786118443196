package com.appcreator.creatorapp.editor.builderaction

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.ArrowDownward
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.actions.ConditionActionBuilder
import com.appcreator.blueprint.actions.SequenceActionBuilder
import com.appcreator.blueprint.actions.actionOptions
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.components.layouts.Dropdown
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.compose.tracingPaperBackground
import com.appcreator.styles.ThemeMargins


@Composable
fun RowScope.ActionGraphRoot(
    actionSpec: ActionInputSpec,
    save: () -> Unit,
    selected: (ActionBuilder, remove: () -> Unit) -> Unit
) {
    Column(
        modifier = Modifier.weight(1f)
            .fillMaxHeight()
            .tracingPaperBackground()
            .verticalScroll(rememberScrollState()),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Spacer(Modifier.height(ThemeMargins.LargeMargins))
        ActionGraph(
            buttonName = "Add action",
            actionSpec = actionSpec,
            save = save,
            selected = selected
        )
    }
}

@Composable
fun ActionGraph(
    buttonName: String,
    actionSpec: ActionInputSpec,
    save: () -> Unit,
    selected: (ActionBuilder, remove: () -> Unit) -> Unit
) {

    LaunchedEffect(Unit) {
        // Could do with a better way to do this
        if (actionSpec.value !is SequenceActionBuilder) {
            val sequenceActionProvider =
                actionOptions().entries.first { it.key.name == "SequenceAction" }
            actionSpec.value = sequenceActionProvider.value.block()
        }
    }

    (actionSpec.value as? SequenceActionBuilder)?.let { sequence ->

        sequence.actions.value.forEachIndexed { index, action ->
            ActionItem(action) {
                selected(action) {
                    sequence.actions.value.remove(action)
                }
            }
            if (index < sequence.actions.value.lastIndex) {
                Icon(Icons.Default.ArrowDownward, contentDescription = null)
            }
        }

        val lastAction = sequence.actions.value.lastOrNull()
        when {
            lastAction is ConditionActionBuilder -> {
                Spacer(Modifier.height(ThemeMargins.SmallMargin))
                Row {
                    Column(horizontalAlignment = Alignment.CenterHorizontally) {

                        Text("Success")
                        Icon(Icons.Default.ArrowDownward, contentDescription = null)

                        ActionGraph(
                            buttonName = "Add",
                            actionSpec = lastAction.successAction,
                            save = save,
                            selected = selected
                        )
                    }
                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                    Column(horizontalAlignment = Alignment.CenterHorizontally) {

                        Text("Failure")
                        Icon(Icons.Default.ArrowDownward, contentDescription = null)

                        ActionGraph(
                            buttonName = "Add",
                            actionSpec = lastAction.failureAction,
                            save = save,
                            selected = selected
                        )
                    }
                }
            }
            lastAction?.inputSpecs?.any { it is ActionInputSpec } == true -> {
                val subAction = lastAction.inputSpecs.first { it is ActionInputSpec } as ActionInputSpec
                Icon(Icons.Default.ArrowDownward, contentDescription = null)
                ActionGraph(
                    buttonName = buttonName,
                    actionSpec = subAction,
                    save = save,
                    selected = selected
                )
            }
            else -> {

                if (sequence.actions.value.isNotEmpty()) {
                    Icon(Icons.Default.ArrowDownward, contentDescription = null)
                }

                Box {
                    Dropdown(
                        anchor = {
                            Button(onClick = it) {
                                Icon(Icons.Default.Add, contentDescription = null)
                                Text(buttonName)
                            }
                        },
                        content = { dismiss ->
                            val options = remember { actionOptions().filter { it.key.accessible } }
                            options.forEach {
                                DropdownItem(title = it.key.title) {
                                    val new = it.value.block()
                                    sequence.actions.value.add(new)
                                    save()
                                    selected(new) { sequence.actions.value.remove(sequence) }
                                    dismiss()
                                }
                            }
                        }
                    )
                }
            }
        }
    }
}

@Composable
private fun ActionItem(
    action: ActionBuilder,
    selected: (ActionBuilder) -> Unit
) {

    Card(onClick = { selected(action) }) {
        Box(Modifier
            .size(width = 120.dp, height = 80.dp)
            .padding(ThemeMargins.SmallMargin)) {
            Text(action.spec.title)
        }
    }
}