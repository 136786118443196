package com.appcreator.compose.conditions.data

import com.appcreator.blueprint.conditions.data.DateCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.conditions.Evaluator
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

class DateEvaluator(private val condition: DateCondition): Evaluator {

    override fun evaluateOnce(envStore: EnvStore): Boolean {
        return try {
            val lhs = Instant.parse(envStore.injectVariables(condition.lhs))
            val rhs = Instant.parse(envStore.injectVariables(condition.rhs))

            when(condition.comparison) {
                DateCondition.Comparison.SameDay -> lhs.toLocalDateTime(TimeZone.currentSystemDefault()).date == rhs.toLocalDateTime(
                    TimeZone.currentSystemDefault()).date
                DateCondition.Comparison.Before -> lhs < rhs
                DateCondition.Comparison.After -> lhs > rhs
            }
        } catch (ex: Exception) {
            false
        }
    }
}