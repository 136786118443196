package com.appcreator.compose.components.logic

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.logic.ABComponent
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator

@Composable
fun ABComposable(modifier: Modifier, component: ABComponent) {
    val envStore = LocalEnvStore.current
    val inPreview = LocalInPreview.current
    val value = if(inPreview) {
        component.previewComponent != ABComponent.PreviewComponent.ContentB
    } else {
        component.condition?.let { Container.evaluator(it)?.evaluate(envStore) } ?: true
    }
    if (value) {
        component.contentA?.let { contentA ->
            ComponentComposable(modifier, contentA)
        }
    } else {
        component.contentB?.let { contentB ->
            ComponentComposable(modifier, contentB)
        }
    }
}

