package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.snapshots.SnapshotStateList
import com.appcreator.blueprint.spec.DataSpecBuilder
import com.appcreator.blueprint.spec.ListInputSpec

class DataSpecListInputSpec(
    properties: InputProperties,
    value: SnapshotStateList<DataSpecBuilder>
): ListInputSpec<DataSpecBuilder>(properties, value)
