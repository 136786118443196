package com.appcreator.compose.conversions

import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.extensions.format
import com.appcreator.blueprint.conversions.DateFormatConversion
import kotlinx.serialization.json.JsonElement

data class DateFormatConvertor(
    val conversion: DateFormatConversion
): Convertor {
    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String? {
        with(conversion) {
            val date = envStore.env[datePath?.value] as? String ?: return null
            return format(
                date,
                "",
                ""
            ) // TODO how to support different formats, maybe just enum of options
        }
    }

}
