package com.appcreator.creatorapp.editor.local

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.staticCompositionLocalOf
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.creatorapp.editor.BlueprintManager

val LocalSelectedComponent = staticCompositionLocalOf<MutableState<ComponentBuilder?>> {
    error("No LocalSelectedComponent provided")
}

val LocalBlueprintManager = staticCompositionLocalOf<BlueprintManager> {
    error("No instance of BlueprintManager provided")
}