package com.appcreator.shared.api

import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.components.Screen
import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.core.properties.TemplateInfo
import com.appcreator.dto.AccessLevel
import com.appcreator.dto.configurations.AndroidConfigurationResponse
import com.appcreator.dto.BlueprintResponse
import com.appcreator.dto.BlueprintUpdateRequest
import com.appcreator.dto.BuildResponse
import com.appcreator.dto.BuildServerStatus
import com.appcreator.dto.ComponentTemplate
import com.appcreator.dto.ComponentTemplatesResponse
import com.appcreator.dto.DataSource
import com.appcreator.dto.DownloadResponse
import com.appcreator.dto.FeedbackRequest
import com.appcreator.dto.FileResponse
import com.appcreator.dto.FilesResponse
import com.appcreator.dto.configurations.IOSConfigurationResponse
import com.appcreator.dto.IconResponse
import com.appcreator.dto.Invitation
import com.appcreator.dto.InvitationAcceptance
import com.appcreator.dto.InvitationRequest
import com.appcreator.dto.InvitationsResponse
import com.appcreator.dto.ManagePaymentSession
import com.appcreator.dto.Organisation
import com.appcreator.dto.OrganisationCreateRequest
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.OrganisationsResponse
import com.appcreator.dto.PlansResponse
import com.appcreator.dto.PreviewCreateRequest
import com.appcreator.dto.PreviewResponse
import com.appcreator.dto.Project
import com.appcreator.dto.ProjectConfig
import com.appcreator.dto.ProjectCreateRequest
import com.appcreator.dto.ProjectTemplatesResponse
import com.appcreator.dto.ReleaseResponse
import com.appcreator.dto.ReleasesRequest
import com.appcreator.dto.ReleasesResponse
import com.appcreator.dto.ScreenCreateRequest
import com.appcreator.dto.ScreenResponse
import com.appcreator.dto.ScreenUpdateRequest
import com.appcreator.dto.UpdateResponse
import com.appcreator.dto.UserComponent
import com.appcreator.dto.UserComponentCreateRequest
import com.appcreator.dto.UserComponentsResponse
import com.appcreator.dto.configurations.ProjectAdminConfiguration
import com.appcreator.dto.configurations.ProjectTemplate
import com.appcreator.dto.configurations.ThirdPartyConfiguration
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.websocket.webSocketSession
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.delete
import io.ktor.client.request.forms.ChannelProvider
import io.ktor.client.request.forms.formData
import io.ktor.client.request.forms.submitFormWithBinaryData
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.client.request.post
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.Headers
import io.ktor.http.HttpHeaders
import io.ktor.http.HttpStatusCode
import io.ktor.http.appendPathSegments
import io.ktor.http.contentType
import io.ktor.utils.io.ByteReadChannel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.receiveAsFlow
import kotlinx.serialization.InternalSerializationApi
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.serializer
import kotlin.reflect.KClass

class FileUploadInfo(
    val contentType: String,
    val size: Long,
    val file: ByteReadChannel,
    val filename: String
)

interface ApiClient {
    val json: Json
    suspend fun getPlans(): PlansResponse
    suspend fun getOrganisations(): OrganisationsResponse
    suspend fun getInvitations(): InvitationsResponse
    suspend fun acceptInvitations(invitation: Invitation): InvitationAcceptance
    suspend fun rejectInvitations(invitation: Invitation)
    suspend fun createOrganisation(name: String): Organisation
    suspend fun getOrganisation(orgId: String): OrganisationResponse
    suspend fun createManagePaymentsSession(orgId: String): ManagePaymentSession
    suspend fun getInvitations(orgId: String): InvitationsResponse
    suspend fun deleteInvitation(orgId: String, invitation: String)
    suspend fun invite(orgId: String, email: String, accessLevel: AccessLevel): Invitation

    suspend fun getProjectTemplates(orgId: String): List<ProjectTemplate>
    suspend fun createProject(orgId: String, templateId: String): Project
    suspend fun deleteProject(orgId: String, projectId: String)
    suspend fun getProject(orgId: String, projectId: String): Project
    suspend fun getFiles(orgId: String, projectId: String, extensions: String): FilesResponse
//    suspend fun uploadFile(
//        orgId: String,
//        projectId: String,
//        name: String,
//        fileName: String,
//        contentType: String,
//        size: Long,
//        file: ByteReadChannel
//    ): FileResponse
    suspend fun uploadFile(
        orgId: String,
        projectId: String,
        name: String,
        fileInfo: FileUploadInfo,
    ): FileResponse
    suspend fun uploadAppIcon(
        orgId: String,
        projectId: String,
        full: FileUploadInfo,
        foreground: FileUploadInfo,
        foregroundPadding: Long,
        background: FileUploadInfo?,
        backgroundColor: String?,
    ): IconResponse
    suspend fun deleteFile(orgId: String, projectId: String, fileId: String): UpdateResponse

    @Deprecated("This is for SDK builds not currently in use")
    suspend fun getBuild(orgId: String, projectId: String): BuildResponse?
    @Deprecated("This is for SDK builds not currently in use")
    suspend fun startBuild(orgId: String, projectId: String): BuildResponse
    @Deprecated("This is for SDK not currently in use")
    suspend fun getUserComponents(
        orgId: String,
        projectId: String
    ): UserComponentsResponse
    @Deprecated("This is for SDK not currently in use")
    suspend fun createUserComponents(
        orgId: String,
        projectId: String,
        name: String
    ): UserComponent

    suspend fun getBlueprint(orgId: String, projectId: String, blueprintId: String): BlueprintResponse
    suspend fun updateBlueprint(
        orgId: String,
        projectId: String,
        blueprintId: String,
        blueprint: Blueprint,
        datasources: List<DataSource>
    ): UpdateResponse

    suspend fun getScreen(
        orgId: String,
        projectId: String,
        blueprintId: String,
        screenId: String
    ): ScreenResponse
    suspend fun createScreen(
        orgId: String,
        projectId: String,
        blueprintId: String,
        title: String
    ): ScreenResponse
    suspend fun updateScreen(
        orgId: String,
        projectId: String,
        blueprintId: String,
        screen: Screen
    ): UpdateResponse
    suspend fun deleteScreen(
        orgId: String,
        projectId: String,
        blueprintId: String,
        screenId: String
    ): UpdateResponse
    suspend fun getPreview(orgId: String, projectId: String): PreviewResponse
    suspend fun getReleases(orgId: String, projectId: String): ReleasesResponse
    suspend fun startRelease(orgId: String, projectId: String, platform: String, version: String, host: String?, prerender: Boolean): ReleaseResponse
    suspend fun getDownload(orgId: String, projectId: String, releaseId: String, downloadType: String): DownloadResponse
    suspend fun getIOSConfiguration(orgId: String, projectId: String): IOSConfigurationResponse
    suspend fun uploadP8(orgId: String, projectId: String, fileInfo: FileUploadInfo)
    suspend fun setIOSConfiguration(orgId: String, projectId: String, config: IOSConfigurationResponse)
    suspend fun getAndroidConfiguration(orgId: String, projectId: String): AndroidConfigurationResponse
    suspend fun setAndroidConfiguration(orgId: String, projectId: String, config: AndroidConfigurationResponse)
    suspend fun setProjectConfiguration(orgId: String, projectId: String, config: ProjectConfig)
    suspend fun getBuildStatus(releaseId: String): Flow<BuildServerStatus>
    suspend fun getThirdPartyConfiguration(orgId: String, projectId: String, type: String): ThirdPartyConfiguration
    suspend fun setThirdPartyConfiguration(orgId: String, projectId: String, config: ThirdPartyConfiguration)
    suspend fun sendFeedback(feedbackType: String, feedback: String, href: String)
    suspend fun getComponentTemplates(orgId: String, projectId: String): ComponentTemplatesResponse

    // Admin
    suspend fun getProjectAdminConfiguration(orgId: String, projectId: String): ProjectAdminConfiguration
    suspend fun setProjectAdminConfiguration(orgId: String, projectId: String, config: ProjectAdminConfiguration)

    suspend fun createComponentTemplate(orgId: String, projectId: String, component: ContainerComponent, info: TemplateInfo): TemplateInfo
    suspend fun updateComponentTemplate(orgId: String, projectId: String, component: ContainerComponent, info: TemplateInfo): TemplateInfo
    suspend fun deleteComponentTemplate(orgId: String, projectId: String, templateId: String)
}

class KtorApiClient(
    override val json: Json,
    private val client: HttpClient,
    private val tokenProvider: suspend () -> String
): ApiClient {
    override suspend fun getPlans(): PlansResponse =
        client.get {
            url { appendPathSegments("plans") }
            authorize()
        }.body()
    override suspend fun getOrganisations(): OrganisationsResponse =
        client.get {
            url { appendPathSegments("organisations") }
            authorize()
        }.body()

    override suspend fun getInvitations(): InvitationsResponse =
        client.get {
            url { appendPathSegments("invitations") }
            authorize()
        }.body()

    override suspend fun acceptInvitations(invitation: Invitation): InvitationAcceptance =
        client.post {
            setBody("{}")
            contentType(ContentType.Application.Json)
            url { appendPathSegments("invitations", invitation.key, "accept") }
            authorize()
        }.body()


    override suspend fun rejectInvitations(invitation: Invitation) {
        client.post {
            setBody("{}")
            contentType(ContentType.Application.Json)
            url { appendPathSegments("invitations", invitation.key, "reject") }
            authorize()
        }.bodyAsText()
    }

    override suspend fun createOrganisation(name: String): Organisation =
        client.post {
            setBody(OrganisationCreateRequest(name = name))
            contentType(ContentType.Application.Json)
            url { appendPathSegments("organisations") }
            authorize()
        }.body()

    override suspend fun getOrganisation(orgId: String): OrganisationResponse =
        client.get {
            url { appendPathSegments("organisations", orgId) }
            authorize()
        }.body()

    override suspend fun createManagePaymentsSession(orgId: String): ManagePaymentSession =
        client.post {
            setBody("{}")
            contentType(ContentType.Application.Json)
            url { appendPathSegments("organisations", orgId, "payments", "manage") }
            authorize()
        }.body()

    override suspend fun invite(
        orgId: String,
        email: String,
        accessLevel: AccessLevel
    ): Invitation =
        client.post {
            setBody(InvitationRequest(email, accessLevel))
            contentType(ContentType.Application.Json)
            url { appendPathSegments("organisations", orgId, "invitations") }
            authorize()
        }.body()

    override suspend fun getInvitations(orgId: String): InvitationsResponse =
        client.get {
            url { appendPathSegments("organisations", orgId, "invitations") }
            authorize()
        }.body()

    override suspend fun deleteInvitation(orgId: String, invitation: String) {
        client.delete {
            url {
                appendPathSegments("organisations", orgId, "invitations", invitation)
            }
            authorize()
        }
    }

    override suspend fun getProjectTemplates(orgId: String): List<ProjectTemplate> =
        client.get {
            url { appendPathSegments("organisations", orgId, "projects", "templates") }
            authorize()
        }.body<ProjectTemplatesResponse>().templates

    override suspend fun createProject(orgId: String, templateId: String): Project =
        client.post {
            setBody(ProjectCreateRequest(templateId = templateId))
            contentType(ContentType.Application.Json)
            url { appendPathSegments("organisations", orgId, "projects") }
            authorize()
        }.body()

    override suspend fun deleteProject(orgId: String, projectId: String) {
        client.delete {
            url { appendPathSegments("organisations", orgId, "projects", projectId) }
            authorize()
        }
    }

    override suspend fun getProject(orgId: String, projectId: String): Project =
        client.get {
            url { appendPathSegments("organisations", orgId, "projects", projectId) }
            authorize()
        }.body()

    override suspend fun getFiles(orgId: String, projectId: String, extensions: String): FilesResponse =
        client.get {
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "files")
                parameters.append("extensions", extensions)
            }
            authorize()
        }.body()

    override suspend fun uploadFile(
        orgId: String,
        projectId: String,
        name: String,
        fileInfo: FileUploadInfo
    ): FileResponse {
        val parts = formData {
            append("title", name)
            append(
                "file",
                ChannelProvider(fileInfo.size) { fileInfo.file },
                Headers.build {
                    append(HttpHeaders.ContentDisposition, "filename=${fileInfo.filename}")
                    append(HttpHeaders.ContentType, fileInfo.contentType)
                }
            )
        }
        return client.submitFormWithBinaryData(parts) {
            url { appendPathSegments("organisations", orgId, "projects", projectId, "files") }
            authorize()
        }.body()
    }

    override suspend fun uploadAppIcon(
        orgId: String,
        projectId: String,
        full: FileUploadInfo,
        foreground: FileUploadInfo,
        foregroundPadding: Long,
        background: FileUploadInfo?,
        backgroundColor: String?
    ): IconResponse {
        val parts = formData {
            backgroundColor?.let {
                append("backgroundColor", backgroundColor)
            }
            append(
                "full",
                ChannelProvider(full.size) { full.file },
                Headers.build {
                    append(HttpHeaders.ContentDisposition, "filename=${full.filename}")
                    append(HttpHeaders.ContentType, full.contentType)
                }
            )
            append(
                "foreground",
                ChannelProvider(foreground.size) { foreground.file },
                Headers.build {
                    append(HttpHeaders.ContentDisposition, "filename=${foreground.filename}")
                    append(HttpHeaders.ContentType, foreground.contentType)
                }
            )
            append("foregroundPadding", foregroundPadding)
            background?.let {
                append(
                    "background",
                    ChannelProvider(background.size) { background.file },
                    Headers.build {
                    append(HttpHeaders.ContentDisposition, "filename=${background.filename}")
                        append(HttpHeaders.ContentType, background.contentType)
                    }
                )
            }
        }
        return client.submitFormWithBinaryData(parts) {
            url { appendPathSegments("organisations", orgId, "projects", projectId, "app-icon") }
            authorize()
        }.body()
    }

    override suspend fun deleteFile(
        orgId: String,
        projectId: String,
        fileId: String
    ): UpdateResponse =
        client.delete {
            url { appendPathSegments("organisations", orgId, "projects", projectId, "files", fileId) }
            authorize()
        }.body()

    override suspend fun getBuild(orgId: String, projectId: String): BuildResponse? =
        try {
            client.get {
                url { appendPathSegments("organisations", orgId, "projects", projectId, "build") }
                authorize()
            }.body()
        } catch (ex: ClientRequestException) {
            if(ex.response.status == HttpStatusCode.NotFound) null
            else throw ex
        }
    override suspend fun startBuild(orgId: String, projectId: String): BuildResponse =
        client.post {
            setBody("{}")
            contentType(ContentType.Application.Json)
            url { appendPathSegments("organisations", orgId, "projects", projectId, "build") }
            authorize()
        }.body()

    override suspend fun getUserComponents(
        orgId: String,
        projectId: String
    ): UserComponentsResponse =
        client.get {
            url { appendPathSegments("organisations", orgId, "projects", projectId, "components") }
            authorize()
        }.body()

    override suspend fun createUserComponents(
        orgId: String,
        projectId: String,
        name: String
    ): UserComponent =
        client.post {
            setBody(UserComponentCreateRequest(name))
            contentType(ContentType.Application.Json)
            url { appendPathSegments("organisations", orgId, "projects", projectId, "components") }
            authorize()
        }.body()

    override suspend fun getBlueprint(
        orgId: String,
        projectId: String,
        blueprintId: String
    ): BlueprintResponse =
        client.get {
            url { appendPathSegments("organisations", orgId, "projects", projectId, "blueprints", blueprintId) }
            authorize()
        }.body()

    override suspend fun updateBlueprint(
        orgId: String,
        projectId: String,
        blueprintId: String,
        blueprint: Blueprint,
        datasources: List<DataSource>
    ): UpdateResponse =
        client.put {
            setBody(BlueprintUpdateRequest(blueprint, datasources))
            contentType(ContentType.Application.Json)
            url { appendPathSegments("organisations", orgId, "projects", projectId, "blueprints", blueprintId) }
            authorize()
        }.body()

    override suspend fun getScreen(
        orgId: String,
        projectId: String,
        blueprintId: String,
        screenId: String
    ): ScreenResponse =
        client.get {
            url { appendPathSegments("organisations", orgId, "projects", projectId, "blueprints", blueprintId, "screens", screenId) }
            authorize()
        }.body()

    override suspend fun createScreen(
        orgId: String,
        projectId: String,
        blueprintId: String,
        title: String
    ): ScreenResponse =
        client.post {
            setBody(ScreenCreateRequest(title))
            contentType(ContentType.Application.Json)
            url { appendPathSegments("organisations", orgId, "projects", projectId, "blueprints", blueprintId, "screens") }
            authorize()
        }.body()

    override suspend fun updateScreen(
        orgId: String,
        projectId: String,
        blueprintId: String,
        screen: Screen
    ): UpdateResponse =
        client.post {
            setBody(ScreenUpdateRequest(screen))
            contentType(ContentType.Application.Json)
            url { appendPathSegments("organisations", orgId, "projects", projectId, "blueprints", blueprintId, "screens", screen.id) }
            authorize()
        }.body()

    override suspend fun deleteScreen(
        orgId: String,
        projectId: String,
        blueprintId: String,
        screenId: String
    ): UpdateResponse =
        client.delete {
            url { appendPathSegments("organisations", orgId, "projects", projectId, "blueprints", blueprintId, "screens", screenId) }
            authorize()
        }.body()

    override suspend fun getPreview(orgId: String, projectId: String): PreviewResponse =
        client.get {
            url { appendPathSegments("organisations", orgId, "projects", projectId, "preview") }
            authorize()
        }.body()

    override suspend fun getReleases(
        orgId: String,
        projectId: String,
    ): ReleasesResponse {
        return client.get {
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "release")
            }
            authorize()
        }.body()
    }

    override suspend fun startRelease(
        orgId: String,
        projectId: String,
        platform: String,
        version: String,
        host: String?,
        prerender: Boolean
    ): ReleaseResponse {
        return client.post {
            url {
                setBody(ReleasesRequest(platform, version, false, host, prerender))
                contentType(ContentType.Application.Json)
                appendPathSegments("organisations", orgId, "projects", projectId, "release")
            }
            authorize()
        }.body()
    }

    override suspend fun getDownload(
        orgId: String,
        projectId: String,
        releaseId: String,
        downloadType: String
    ): DownloadResponse {
        return client.get {
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "release", releaseId, "download")
                parameters.append("downloadType", downloadType)
            }
            authorize()
        }.body()
    }

    override suspend fun getIOSConfiguration(
        orgId: String,
        projectId: String
    ): IOSConfigurationResponse {
        return client.get {
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "ios-configuration")
            }
            authorize()
        }.body()
    }

    override suspend fun uploadP8(orgId: String, projectId: String, fileInfo: FileUploadInfo) {
        val parts = formData {
            append(
                "file",
                ChannelProvider(fileInfo.size) { fileInfo.file },
                Headers.build {
                    append(HttpHeaders.ContentDisposition, "filename=${fileInfo.filename}")
                    append(HttpHeaders.ContentType, fileInfo.contentType)
                }
            )
        }
        return client.submitFormWithBinaryData(parts) {
            url { appendPathSegments("organisations", orgId, "projects", projectId, "ios-configuration", "p8") }
            authorize()
        }.body()
    }

    override suspend fun setIOSConfiguration(
        orgId: String,
        projectId: String,
        config: IOSConfigurationResponse
    ) {
        client.post {
            setBody(config)
            contentType(ContentType.Application.Json)
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "ios-configuration")
            }
            authorize()
        }
    }

    override suspend fun getAndroidConfiguration(
        orgId: String,
        projectId: String
    ): AndroidConfigurationResponse {
        return client.get {
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "android-configuration")
            }
            authorize()
        }.body()
    }

    override suspend fun setAndroidConfiguration(
        orgId: String,
        projectId: String,
        config: AndroidConfigurationResponse
    ) {
        client.post {
            setBody(config)
            contentType(ContentType.Application.Json)
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "android-configuration")
            }
            authorize()
        }
    }

    override suspend fun setProjectConfiguration(
        orgId: String,
        projectId: String,
        config: ProjectConfig
    ) {
        client.post {
            setBody(config)
            contentType(ContentType.Application.Json)
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "configuration")
            }
            authorize()
        }
    }

    override suspend fun getBuildStatus(releaseId: String): Flow<BuildServerStatus> {
        val session = client.webSocketSession("wss://build.yourappcreator.com/status")
        return session.incoming
            .receiveAsFlow()
            .map { json.decodeFromString<BuildServerStatus>(it.data.decodeToString()) }
            .filter { it.id == releaseId }
    }

    override suspend fun getThirdPartyConfiguration(
        orgId: String,
        projectId: String,
        type: String
    ): ThirdPartyConfiguration {
        return client.get {
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "third-party-configuration", type)
            }
            authorize()
        }.body<ThirdPartyConfiguration>()
    }

    override suspend fun setThirdPartyConfiguration(
        orgId: String,
        projectId: String,
        config: ThirdPartyConfiguration
    ) {
        client.post {
            setBody(config)
            contentType(ContentType.Application.Json)
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "third-party-configuration", config.type)
            }
            authorize()
        }
    }

    override suspend fun sendFeedback(feedbackType: String, feedback: String, href: String) {
        client.post {
            setBody(FeedbackRequest(feedbackType, feedback, href))
            contentType(ContentType.Application.Json)
            url {
                appendPathSegments("feedback")
            }
            authorize()
        }
    }

    override suspend fun getComponentTemplates(
        orgId: String,
        projectId: String
    ): ComponentTemplatesResponse =
        client.get {
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "templates", "components")
            }
            authorize()
        }.body()

    override suspend fun getProjectAdminConfiguration(
        orgId: String,
        projectId: String
    ): ProjectAdminConfiguration {
        return client.get {
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "admin-configuration")
            }
            authorize()
        }.body()
    }

    override suspend fun setProjectAdminConfiguration(
        orgId: String,
        projectId: String,
        config: ProjectAdminConfiguration
    ) {
        client.post {
            setBody(config)
            contentType(ContentType.Application.Json)
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "admin-configuration")
            }
            authorize()
        }
    }

    override suspend fun createComponentTemplate(
        orgId: String,
        projectId: String,
        component: ContainerComponent,
        info: TemplateInfo
    ): TemplateInfo =
        client.post {
            setBody(ComponentTemplate(info, component))
            contentType(ContentType.Application.Json)
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "templates", "components")
            }
            authorize()
        }.body<ComponentTemplate>().info

    override suspend fun updateComponentTemplate(
        orgId: String,
        projectId: String,
        component: ContainerComponent,
        info: TemplateInfo
    ): TemplateInfo =
        client.put {
            setBody(ComponentTemplate(info, component))
            contentType(ContentType.Application.Json)
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "templates", "components", info.id ?: throw IllegalStateException("ID is required to update"))
            }
            authorize()
        }.body<ComponentTemplate>().info

    override suspend fun deleteComponentTemplate(
        orgId: String,
        projectId: String,
        templateId: String
    ) {
        client.delete {
            url {
                appendPathSegments("organisations", orgId, "projects", projectId, "templates", "components", templateId)
            }
            authorize()
        }
    }

    private suspend fun HttpRequestBuilder.authorize() {
        header(HttpHeaders.Authorization, "Bearer ${tokenProvider()}")
    }

}