package com.appcreator.blueprint.components.pager

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.LocalizableStringInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class TabbedPageComponentBuilder(
  component: TabbedPageComponent?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "Pager",
          name = "TabbedPageComponent",
          title = "Tapped Page",
          description = "",
          availableFrom = "0.0.1",
          accessible = false,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  public val titleStyle: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "",
          title = "Title Style",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf((component?.titleStyle?:
          TabbedPageComponent.TitleStyle.Text).name),
          
          options = TabbedPageComponent.TitleStyle.entries.map { it.name },
      )

  public val title: LocalizableStringInputSpec = LocalizableStringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Title",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && titleStyle.value == TabbedPageComponent.TitleStyle.Text.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.title),
          
          
      )

  public val titleContent: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Title",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && titleStyle.value == TabbedPageComponent.TitleStyle.Custom.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true
      ) ,
          value =  mutableStateOf(component?.titleContent?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val selectedTitleContent: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Selected Title",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && titleStyle.value == TabbedPageComponent.TitleStyle.Custom.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true
      ) ,
          value =  mutableStateOf(component?.selectedTitleContent?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val content: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Content",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true
      ) ,
          value =  mutableStateOf(component?.content?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, titleStyle, title,
      titleContent, selectedTitleContent, content)

  override fun build(): Component = TabbedPageComponent(_nodeId.value?: Uuid.random().toString(),
      _nodeLabel.value, titleStyle.value?.let { TabbedPageComponent.TitleStyle.valueOf(it) }?:
      TabbedPageComponent.TitleStyle.Text, title.value, titleContent.value?.build(),
      selectedTitleContent.value?.build(), content.value?.build())
}
