package com.appcreator.creatorapp.editor.centerdata.rest

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Checkbox
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.GlobalData
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.styles.ThemeMargins

@Composable
fun DataParametersConfig(
    globalData: List<GlobalData>,
    values: List<Pair<String, LoaderSpec.ParameterSetting>>,
    update: (List<Pair<String, LoaderSpec.ParameterSetting>>) -> Unit
) {
    Column(Modifier.fillMaxWidth().verticalScroll(rememberScrollState())) {
        values.forEachIndexed { index, pair ->

            val globalValue = globalData.find { it.key == pair.first }
            Spacer(Modifier.height(ThemeMargins.SmallMargin))

            Row(Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    modifier = Modifier.width(154.dp),
                    text = pair.first,
                    style = MaterialTheme.typography.titleSmall
                )
                Spacer(Modifier.width(ThemeMargins.SmallMargin))

                globalValue?.let {

                    Text(
                        text = it.value(GlobalData.defaultEnv),
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )

                } ?: run {

                    CreatorTextInput(
                        modifier = Modifier.fillMaxWidth(),
                        label = "Default",
                        value = pair.second.default,
                        onValueChange = {
                            val mutable = values.toMutableList()
                            mutable[index] = pair.first to pair.second.copy(default = it)
                            update(mutable)
                        }
                    )
                }
            }

            if (globalValue == null) {

                Row(verticalAlignment = Alignment.CenterVertically) {
                    Checkbox(
                        checked = pair.second.perComponentConfigurable,
                        onCheckedChange = {
                            val mutable = values.toMutableList()
                            mutable[index] =
                                pair.first to pair.second.copy(perComponentConfigurable = it)
                            update(mutable)
                        },
                    )
                    Text("Set from component")
                }
                Spacer(Modifier.height(ThemeMargins.LargeMargins))
            }
        }
    }
}
