package com.appcreator.blueprint.components.pager

import com.appcreator.blueprint.components.data.DataRepeaterComponent
import com.appcreator.blueprint.core.AllowedComponent
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("TabbedPager")
@ComponentClass(
    group = "Pager",
    title = "Tabbed Pager",
    availableFrom = "0.0.1",
    producesDataFields = "\"pager-selected-page\"",
    producedFieldsEditable = true
)
data class TabbedPagerComponent(
    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "Tab Height",
        availableFrom = "0.0.1"
    )
    @GenericDefault("50")
    val tabHeight: Int?,

    @InputProperty(
        title = "Indicator Color",
        availableFrom = "0.0.1"
    )
    val indicatorColor: ColorReference?,

    @InputProperty(
        title = "Background Color",
        availableFrom = "0.0.1"
    )
    val backgroundColor: ColorReference?,

    @InputProperty(
        title = "Style",
        availableFrom = "0.0.1"
    )
    @GenericDefault("TabbedPagerComponent.Style.Fixed")
    val style: Style?,

    @InputProperty(
        title = "Pages",
        availableFrom = "0.0.1"
    )
    @AllowedComponent(TabbedPageComponent::class)
    @AllowedComponent(DataRepeaterComponent::class)
    val content: List<Component>

): Component {

    enum class Style {
        Fixed,
        Scrolling
    }

}

