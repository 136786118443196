package com.appcreator.blueprint.spec

class ComponentSpec(
    val group: String,
    val name: String,
    val title: String,
    val description: String,
    val availableFrom: String,
    val accessible: Boolean,
    val producesDataFields: List<String>,
    val producedFieldsEditable: Boolean
)