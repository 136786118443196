package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.spec.SingleInputSpec

class BlueprintLinkInputSpec(
    properties: InputProperties,
    value: MutableState<BlueprintLink?>
): SingleInputSpec<BlueprintLink?>(properties, value)
