package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.core.properties.SizeValue
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Input")
@ComponentClass(
    group = "Basic",
    title = "Input",
    availableFrom = "0.0.1"
)
data class InputComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "Label",
        availableFrom = "0.0.1"
    )
    val placeholder: LocalizableString?,

    @InputProperty(
        title = "Data Value",
        availableFrom = "0.0.1",
        allowDataFields = false,
        settableDataOnly = true
    )
    val data: DataPath?,

    @InputProperty(
        title = "Content Type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("InputComponent.ContentType.Text")
    val contentType: ContentType?,

    @InputProperty(
        title = "Show Visibility Toggle",
        availableFrom = "0.0.1"
    )
    @DisplayIf("contentType.value == InputComponent.ContentType.Password.name || contentType.value == InputComponent.ContentType.NewPassword.name")
    val passwordVisibilityToggle: Boolean?,

    @InputProperty(
        section = "Layout",
        title = "Width",
        availableFrom = "0.0.1"
    )
    val width: SizeValue?,

    @InputProperty(
        section = "Layout",
        title = "Layout Type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("InputComponent.LayoutType.Basic")
    val layoutType: LayoutType?,

    @InputProperty(
        section = "Layout",
        title = "Border Color",
        availableFrom = "0.0.1"
    )
    @DisplayIf("layoutType.value == InputComponent.LayoutType.Bordered.name")
    val borderColor: ColorReference?,

    ): Component {

    enum class ContentType {
        Text,
        Username,
        Password,
        NewPassword,
        Number,
        Email,
        Phone
    }
    enum class LayoutType {
        Basic,
        Bordered
    }
}
