package com.appcreator.blueprint.components.basic

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.LocalizableStringInputSpec
import com.appcreator.blueprint.spec.inputs.SizeValueInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class ImageComponentBuilder(
  component: ImageComponent?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "Basic",
          name = "ImageComponent",
          title = "Image",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  public val url: LocalizableStringInputSpec = LocalizableStringInputSpec(
          properties = InputProperties(
          section = "",
          title = "URL",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.url),
          
          
      )

  public val width: SizeValueInputSpec = SizeValueInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Width",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.width),
          
          
      )

  public val height: SizeValueInputSpec = SizeValueInputSpec(
          properties = InputProperties(
          section = "Layout",
          title = "Height",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.height),
          
          
      )

  public val loading: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Progress",
          title = "Loading",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.loading?.toBuilder(newId)),
          
          
      )

  public val fallback: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Progress",
          title = "Fallback",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.fallback?.toBuilder(newId)),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, url, width, height,
      loading, fallback)

  override fun build(): Component = ImageComponent(_nodeId.value?: Uuid.random().toString(),
      _nodeLabel.value, url.value, width.value, height.value, loading.value?.build(),
      fallback.value?.build())
}
