package com.appcreator.components.layouts

import androidx.compose.foundation.HorizontalScrollbar
import androidx.compose.foundation.ScrollState
import androidx.compose.foundation.VerticalScrollbar
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.lazy.grid.LazyGridState
import androidx.compose.foundation.rememberScrollbarAdapter
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier

@Composable
actual fun BoxScope.ScrollBars(state: ScrollState) {
    VerticalScrollbar(
        adapter = rememberScrollbarAdapter(state),
        modifier = Modifier.fillMaxHeight().align(Alignment.CenterEnd),
    )
}

@Composable
actual fun BoxScope.ScrollBars(state: LazyGridState) {
    VerticalScrollbar(
        adapter = rememberScrollbarAdapter(state),
        modifier = Modifier.fillMaxHeight().align(Alignment.CenterEnd),
    )
}

@Composable
actual fun BoxScope.ScrollbarHorizontal(state: ScrollState) {
    HorizontalScrollbar(
        adapter = rememberScrollbarAdapter(state),
        modifier = Modifier.fillMaxWidth().align(Alignment.BottomCenter),
    )
}