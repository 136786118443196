package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.properties.UserDefinedComponent
import com.appcreator.blueprint.spec.SingleInputSpec

class UserDefinedComponentInputSpec(
    properties: InputProperties,
    value: MutableState<UserDefinedComponent?>
): SingleInputSpec<UserDefinedComponent?>(properties, value)
