package com.appcreator.compose.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalEnvStore

@Composable
fun EnvComposable(
    properties: Map<String, Any> = emptyMap(),
    content: @Composable () -> Unit
) {
    CompositionLocalProvider(LocalEnvStore provides EnvStore.create(parent = LocalEnvStore.current, env = properties), content = content)
}