package com.appcreator.blueprint.conditions

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("RemoteConfig")
@ConditionClass(
    group = "Firebase",
    title = "Remote config",
    availableFrom = "0.0.1",
    accessible = false
)
data class RemoteConfigCondition(
    @InputProperty(
        title = "Name",
        availableFrom = "0.0.1"
    )
    val name: String?
): Condition
