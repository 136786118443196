package com.appcreator.creatorapp.editor.builderaction

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.creatorapp.editor.inputs.InputItem
import com.appcreator.creatorapp.editor.local.LocalDataOptions
import com.appcreator.creatorapp.editor.panelcomponent.views.rememberDataOptionsFromAction
import com.appcreator.creatorapp.editor.panelcomponent.views.rememberDataOptionsFromShapes
import com.appcreator.styles.ThemeMargins

@Composable
fun ActionBuilder(
    actionBuilder: ActionBuilder,
    save: () -> Unit,
    remove: () -> Unit
) {

    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(actionBuilder.spec.title)

        TextButton(onClick = remove) {
            Text("Remove")
            Spacer(Modifier.width(ThemeMargins.SmallMargin))
            Icon(Icons.Default.Delete, contentDescription = null)
        }
    }

    val actionItems = rememberDataOptionsFromAction(actionBuilder)
    val dataItems = rememberDataOptionsFromShapes(true, plus = actionItems)
    CompositionLocalProvider(LocalDataOptions provides dataItems) {
        actionBuilder.inputSpecs
            .filterNot { it is ActionInputSpec }
            .filter { it.properties.visible() }
            .forEach {
            InputItem(it, save)
            Box(Modifier.height(ThemeMargins.SmallMargin))
        }
    }
}