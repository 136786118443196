package com.appcreator.compose.actions.permission

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.appcreator.blueprint.actions.permission.PermissionRequestAction
import com.appcreator.blueprint.conditions.permission.CheckPermissionCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.actions.Performer

class PermissionRequestPerformer(private val action: PermissionRequestAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        return PermissionRequestPerformerDeferred(action)
    }

}

@Composable
expect fun PermissionRequestPerformerDeferred(action: PermissionRequestAction): Performer.Deferred