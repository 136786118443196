package com.appcreator.creatorapp.local

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshotFlow
import androidx.compose.runtime.staticCompositionLocalOf
import com.appcreator.BuildConfig
import com.appcreator.creatorapp.api.DataShapeClient
import com.appcreator.creatorapp.api.DataShapeParser
import com.appcreator.creatorapp.api.KtorDataShapeClient
import com.appcreator.local.LocalUserState
import com.appcreator.local.UserState
import com.appcreator.shared.api.ApiClient
import com.appcreator.shared.api.KtorApiClient
import io.ktor.client.HttpClient
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.plugins.websocket.WebSockets
import io.ktor.serialization.kotlinx.json.json
import kotlinx.coroutines.flow.filterIsInstance
import kotlinx.coroutines.flow.first
import kotlinx.serialization.json.Json

val LocalApiClient = staticCompositionLocalOf<ApiClient> {
    error("No instance of Navigator provided")
}

val LocalDataShapeClient = staticCompositionLocalOf<DataShapeClient> {
    error("No instance of Navigator provided")
}

@Composable
fun ProvideApiClient(
    json: Json,
    content: @Composable () -> Unit
) {
    val userState = LocalUserState.current
    val apiClient = remember {
        val client = HttpClient {
            install(ContentNegotiation) { json(json) }
            install(WebSockets)
            defaultRequest {
                url(BuildConfig.BASE_URL)
            }
            expectSuccess = true
        }
        KtorApiClient(json, client) {
            val knownState = snapshotFlow { userState.value }
                .filterIsInstance<UserState.LoggedIn>()
                .first()
            knownState.token()
        }
    }

    val dataShapeClient = remember {
        val client = HttpClient {
            install(ContentNegotiation) { json(Json {
                ignoreUnknownKeys = true
            }) }
            defaultRequest {
                url(BuildConfig.BASE_URL)
            }
            expectSuccess = true
        }
        KtorDataShapeClient(client, DataShapeParser())
    }

    CompositionLocalProvider(
        LocalApiClient provides apiClient,
        LocalDataShapeClient provides dataShapeClient
    ) {
        content()
    }
}