package com.appcreator.blueprint.conditions.data

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.StringDefault
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Date")
@ConditionClass(
    group = "Data",
    title = "Date Compare",
    availableFrom = "0.0.1"
)
data class DateCondition(
    @InputProperty(
        title = "Date 1",
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    val lhs: String,

    @InputProperty(
        title = "Comparison",
        availableFrom = "0.0.1"
    )
    @GenericDefault("DateCondition.Comparison.SameDay")
    val comparison: Comparison,

    @InputProperty(
        title = "Date 2",
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    val rhs: String,
): Condition {

    enum class Comparison {
        SameDay,
        Before,
        After
    }

}
