package com.appcreator

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.blueprintSerializer
import com.appcreator.creatorapp.local.ProvideApiClient
import com.appcreator.local.ProvideAnalytics
import com.appcreator.theme.AppTheme
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule

@Composable
fun MyApp(content: @Composable () -> Unit) {
    val json = remember {
        Json {
            serializersModule = SerializersModule {
                blueprintSerializer()()
            }
            ignoreUnknownKeys = true
            encodeDefaults = true
            explicitNulls = false
            isLenient = true
        }
    }

    ProvideAnalytics {
        ProvideApiClient(json) {
            Column(Modifier.fillMaxSize()) {
                AppTheme {
                    content()
                }
            }
        }
    }
}