package com.appcreator.blueprint.spec.inputs

data class InputProperties(
    val section: String,
    val title: String,
    val description: String,
    val availableFrom: String,
    val displayInline: Boolean,
    val allowDataFields: Boolean,
    val listDataOptions: Boolean,
    val settableDataOnly: Boolean,
    val includeSiblingData: Boolean,
    val visible: () -> Boolean,
    val dataOptionsPathFilter: () -> List<String>,
    val alwaysContainer: Boolean
)
