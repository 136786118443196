package com.appcreator.compose.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.remember
import com.appcreator.blueprint.core.matches
import com.appcreator.compose.LocalInputEnvStore

class InputEnvStore(
    initial: Map<String, Any>,
    private val parent: InputEnvStore?
) {

    private val _input = mutableStateMapOf(*initial.toList().toTypedArray())
    val input: Map<String, Any> = _input

    fun set(key: String, value: Any) {
        if(_input.contains(key)) {
            _input[key] = value
        } else {
            parent?.set(key, value)?: println("No store for key $key")
        }
    }

    fun add(key: String, value: Any) {
        if(_input.contains(key)) {
            (_input[key] as? List<Any>)?.let { list ->
                _input[key] = list.plus(value)
            }
        } else {
            parent?.add(key, value)?: println("No store for key $key")
        }
    }

    fun remove(key: String, toRemove: Map<String, String>) {
        if(_input.contains(key)) {
            (_input[key] as? List<Map<String, Any>>)?.let { list ->
                _input[key] = list.filterNot { current ->
                    toRemove matches current
                }
            }
        } else {
            parent?.remove(key, toRemove)?: println("No store for key $key")
        }
    }
}

@Composable
fun InputEnvComposable(
    initial: Map<String, Any>,
    content: @Composable () -> Unit
) {
    val parent = LocalInputEnvStore.current
    val inputEnvStore = remember { InputEnvStore(initial, parent) }
    CompositionLocalProvider(LocalInputEnvStore provides inputEnvStore) {
        EnvComposable(inputEnvStore.input, content = content)
    }
}
