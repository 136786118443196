package com.appcreator.compose.conditions

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.conditions.RemoteConfigCondition
import com.appcreator.blueprint.core.EnvStore

class RemoteConfigEvaluator(
    private val condition: RemoteConfigCondition
): Evaluator {

    override fun evaluateOnce(envStore: EnvStore): Boolean {
        return true
    }
}