package com.appcreator.creatorapp.editor.inputs

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.blueprint.spec.inputs.BlueprintLinkInputSpec
import com.appcreator.blueprint.spec.inputs.BooleanInputSpec
import com.appcreator.blueprint.spec.inputs.ColorReferenceInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentListInputSpec
import com.appcreator.blueprint.spec.inputs.ConditionInputSpec
import com.appcreator.blueprint.spec.inputs.ConversionInputSpec
import com.appcreator.blueprint.spec.inputs.CornerValuesInputSpec
import com.appcreator.blueprint.spec.inputs.DataPathInputSpec
import com.appcreator.blueprint.spec.inputs.DataSpecListInputSpec
import com.appcreator.blueprint.spec.inputs.DataValueSetInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.FloatInputSpec
import com.appcreator.blueprint.spec.inputs.FontReferenceInputSpec
import com.appcreator.blueprint.spec.inputs.IntInputSpec
import com.appcreator.blueprint.spec.inputs.LoaderConfigInputSpec
import com.appcreator.blueprint.spec.inputs.LocalizableStringInputSpec
import com.appcreator.blueprint.spec.inputs.MaterialIconInputSpec
import com.appcreator.blueprint.spec.inputs.ScreenPathInputSpec
import com.appcreator.blueprint.spec.inputs.ScriptContentInputSpec
import com.appcreator.blueprint.spec.inputs.SideValuesInputSpec
import com.appcreator.blueprint.spec.inputs.SizeValueInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import com.appcreator.blueprint.spec.inputs.TypedDataValueListInputSpec
import com.appcreator.blueprint.spec.inputs.UserDefinedComponentInputSpec

@Composable
fun InputItem(spec: InputSpec, save: () -> Unit) {
    when(spec) {
        is BooleanInputSpec -> BooleanInput(spec, save)
        is ComponentInputSpec -> ComponentInput(spec, save)
        is ComponentListInputSpec -> ComponentListInput(spec, save)
        is BlueprintLinkInputSpec -> LinkInput(spec, save)
        is StringInputSpec -> StringInput(spec, save)
        is LoaderConfigInputSpec -> LoaderConfigInput(spec, save)
        is IntInputSpec -> IntInput(spec, save)
        is FloatInputSpec -> FloatInput(spec, save)
        is DataPathInputSpec -> DataPathInput(spec, save)
        is ConditionInputSpec -> ConditionInput(spec, save)
        is ActionInputSpec -> ActionInput(spec, save)
        is FontReferenceInputSpec -> FontInput(spec, save)
        is ColorReferenceInputSpec -> ColorInput(spec, save)
        is MaterialIconInputSpec -> MaterialIconInput(spec, save)
        is EnumInputSpec -> EnumInput(spec, save)
        is CornerValuesInputSpec -> CornerValuesInput(spec, save)
        is SideValuesInputSpec -> SideValuesInput(spec, save)
        is SizeValueInputSpec -> SizeInput(spec, save)
        is LocalizableStringInputSpec -> LocalizableString(spec, save)
        is ScriptContentInputSpec -> ScriptContentInput(spec, save)
        is DataSpecListInputSpec -> DataSpecListInput(spec, save)
        is ConversionInputSpec -> DataConversionInput(spec, save)
        is ScreenPathInputSpec -> ScreenPathInput(spec, save)
        is DataValueSetInputSpec -> DataValueSetInput(spec, save)
        is UserDefinedComponentInputSpec -> UserDefinedComponentInput(spec, save)
        is TypedDataValueListInputSpec -> TypedDataValueListInput(spec, save)
    }
}
