package com.appcreator.creatorapp.editor.centerdata.global

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.CustomEnvironment
import com.appcreator.blueprint.GlobalData
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.editor.BlueprintManager
import com.appcreator.creatorapp.editor.centerdata.rest.randomStringByKotlinRandom
import com.appcreator.styles.ThemeMargins

@Composable
fun GlobalEnvironments(
    blueprintManager: BlueprintManager,
    save: () -> Unit
) {
    Column(modifier = Modifier.padding(horizontal = ThemeMargins.SmallMargin)) {
        val title = blueprintManager.customEnvironments.find { it.id == blueprintManager.defaultEnvironment }?.name ?: GlobalData.defaultEnv
        DropDownInputGroup(title = "Selected", dropDownTitle = title) {
            DropdownItem(GlobalData.defaultEnv) {
                blueprintManager.defaultEnvironment = GlobalData.defaultEnv
                save()
            }
            blueprintManager.customEnvironments.forEach {
                DropdownItem(it.name) {
                    blueprintManager.defaultEnvironment = it.id
                    save()
                }
            }
        }

        Box(modifier = Modifier.height(ThemeMargins.LargeMargins))
        Text("Custom Environments")
        blueprintManager.customEnvironments.forEachIndexed { index, env ->
//            val leftAddOn = LeftAddonStyle.toModifier()
//            InputGroup(modifier = Modifier.fillMaxWidth()) {
//                LeftAddon(leftAddOn) {
//                    Text("Name")
//                }
//                TextInput(
//                    text = env.name,
//                    onTextChanged = {
//                        blueprintManager.customEnvironments[index] = env.copy(name = it)
//                        save()
//                    }
//                )
//                RightAddon(
//                    Modifier
//                        .backgroundColor(Colors.Transparent)
//                        .height(2.25.cssRem)
//                        .padding(0.px)
//                ) {
//                    Button(
//                        variant = IconButtonVariant,
//                        onClick = {
//                            blueprintManager.customEnvironments.remove(env)
//                            save()
//                        }
//                    ) { MdiDelete() }
//                }
//            }
            Box(modifier = Modifier.height(ThemeMargins.SmallMargin))
        }

        Button(onClick = {
            blueprintManager.customEnvironments.add(
                CustomEnvironment(
                    id = randomStringByKotlinRandom(),
                    name = ""
                )
            )
            save()
        }) {
            Text("Add +")
        }
    }
}
