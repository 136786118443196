import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.window.CanvasBasedWindow
import com.appcreator.MyApp
import com.appcreator.app.LoginScreen
import com.appcreator.app.MainNavigation
import com.appcreator.local.LocalUserState
import com.appcreator.local.UserState
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.analytics.analytics
import dev.gitlive.firebase.auth.auth
import dev.gitlive.firebase.initialize
import kotlinx.browser.window
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.configureWebResources
import org.jetbrains.skiko.wasm.onWasmReady


fun main() {
    Firebase.initialize(options = FirebaseOptions(
        apiKey = "AIzaSyC0nOE6edrPYxsmcSyov1LbjgJv5vvLlm0",
        authDomain = "app.yourappcreator.com",
        projectId = "i-enterprise-399921",
        storageBucket = "i-enterprise-399921.appspot.com",
        gcmSenderId = "712587872660",
        applicationId = "1:712587872660:web:0123f7ba357e2c4957e781",
        gaTrackingId = "G-78NWW8FGRQ"
    ))

    val origin = window.location.origin
    configureWebResources {
        resourcePathMapping { "$origin/$it" }
    }

    startApp()
}

private fun startApp() {
    onWasmReady {
        CanvasBasedWindow("Your app creator") {
            val scope = rememberCoroutineScope()
            val userState = remember { mutableStateOf<UserState>(UserState.Unknown) }
            LaunchedEffect(Unit) {
                Firebase.auth.authStateChanged.collectLatest {
                    userState.value = it?.let { user ->

                        val analytics = Firebase.analytics
                        analytics.setUserId(user.uid)

                        UserState.Firebase(user.email) {
                            user.getIdToken(false) ?: ""
                        }
                    } ?: UserState.LoggedOut
                    println("User token ${it?.getIdToken(false)}")
                }
            }

            CompositionLocalProvider(LocalUserState provides userState) {
                MyApp {
                    when(userState.value) {
                        is UserState.Firebase -> MainNavigation {
                            scope.launch {
                                Firebase.auth.signOut()
                            }
                        }
                        UserState.LoggedOut -> LoginScreen()
                        else -> {}
                    }
                }
            }

        }
    }
}

