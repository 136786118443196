package com.appcreator.compose.components.data

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material.pullrefresh.PullRefreshIndicator
import androidx.compose.material.pullrefresh.pullRefresh
import androidx.compose.material.pullrefresh.rememberPullRefreshState
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import app.cash.paging.COUNT_UNDEFINED
import app.cash.paging.LoadStateError
import app.cash.paging.LoadStateLoading
import app.cash.paging.MAX_SIZE_UNBOUNDED
import app.cash.paging.Pager
import app.cash.paging.PagingConfig
import app.cash.paging.PagingSource
import app.cash.paging.PagingSourceLoadParams
import app.cash.paging.PagingSourceLoadResult
import app.cash.paging.PagingSourceLoadResultError
import app.cash.paging.PagingSourceLoadResultPage
import app.cash.paging.PagingState
import app.cash.paging.compose.LazyPagingItems
import app.cash.paging.compose.collectAsLazyPagingItems
import app.cash.paging.compose.itemKey
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.components.data.PagedLoaderComponent
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.LoaderConfig
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.compose.LocalBlueprint
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.conditions.Evaluator
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator
import com.appcreator.compose.loaders.Loader


private class DataSource(
    val envStore: EnvStore,
    val config: LoaderConfig?,
    val loadMore: Condition?,
    val path: String?,
    blueprint: Blueprint
): PagingSource<EnvStore, EnvStore>() {

    val spec: LoaderSpec?
    val pageLoader: Loader?
    val evaluator: Evaluator?

    init {
        spec = config?.let { blueprint.loaderSpec(it.loaderSpec) }
        pageLoader = spec?.let { Container.loaderRegistry[it::class]?.invoke(it) }
        evaluator = loadMore?.let { Container.evaluator(it) }
    }

    override suspend fun load(params: PagingSourceLoadParams<EnvStore>): PagingSourceLoadResult<EnvStore, EnvStore> {
        return try {
            val data = pageLoader!!.load(params.key ?: envStore, config!!.parameters)
            val envStore = EnvStore.create(parent = envStore, env = data.second)
            val listItems = envStore.envFromList(path ?: "")
            val nextKey = if(evaluator!!.evaluateAsync(envStore)) envStore else null
            PagingSourceLoadResultPage(
                data = listItems,
                prevKey = null, // Only paging forward.
                nextKey = nextKey
            )
        } catch (ex: Throwable) {
            ex.printStackTrace()
            PagingSourceLoadResultError<EnvStore, EnvStore>(ex)
        } as PagingSourceLoadResult<EnvStore, EnvStore>
    }

    override fun getRefreshKey(state: PagingState<EnvStore, EnvStore>): EnvStore? {
        return null
    }

}

@Composable
fun PagedLoaderComposable(modifier: Modifier, component: PagedLoaderComponent) {
    val envStore = LocalEnvStore.current
    val blueprint = LocalBlueprint.current

    val key = component.config?.parameters?.map { envStore.injectVariables(it.value) }
    val pager = remember(key) {
        Pager(
            config =
            PagingConfig(
                pageSize = 20,
                prefetchDistance = 20,
                enablePlaceholders = false,
                initialLoadSize = 20,
                maxSize = MAX_SIZE_UNBOUNDED,
                jumpThreshold = COUNT_UNDEFINED
            ), initialKey = null
        ) {
            DataSource(
                envStore = envStore,
                config = component.config,
                loadMore = component.condition,
                path = component.itemsPath?.value,
                blueprint = blueprint
            )
        }
    }

    val items = pager.flow.collectAsLazyPagingItems()
    val refreshing = items.loadState.refresh == LoadStateLoading
    val pullToRefresh = rememberPullRefreshState(refreshing = refreshing, onRefresh = {
        items.refresh()
    })
    val pullToRefreshEnabled = component.pullToRefresh == true
    Box(Modifier.fillMaxSize()) {
        when(items.loadState.refresh) {
            LoadStateLoading -> when(component.loadingView) {
                PagedLoaderComponent.ViewType.Nothing -> {}
                PagedLoaderComponent.ViewType.Custom -> component.loadingContent?.let { ComponentComposable(modifier, it) }
                else -> {
                    if (!pullToRefreshEnabled) {
                        DefaultLoadingComposable(modifier)
                    }
                }
            }
            is LoadStateError -> when(component.errorView) {
                PagedLoaderComponent.ViewType.Nothing -> {}
                PagedLoaderComponent.ViewType.Custom -> component.errorContent?.let { ComponentComposable(modifier, it) }
                else -> DefaultErrorComposable(modifier, (items.loadState.refresh as LoadStateError).error) {
                    items.refresh()
                }
            }
            else -> {
                LoadedContent(modifier.pullRefresh(pullToRefresh, enabled = pullToRefreshEnabled), component, items)
            }
        }
        if (pullToRefreshEnabled) {
            PullRefreshIndicator(refreshing, pullToRefresh, Modifier.align(Alignment.TopCenter))
        }
    }
}

@Composable
private fun LoadedContent(
    modifier: Modifier,
    component: PagedLoaderComponent,
    items: LazyPagingItems<EnvStore>,
) {
    if(items.itemCount == 0) {
        component.empty?.let {
            ComponentComposable(Modifier, it)
        }
    } else {
        LazyColumn(modifier = modifier) {
            component.header?.let {
                item {
                    ComponentComposable(Modifier, it)
                }
            }

            items(items.itemCount, key = items.itemKey()) {
                items[it]?.let { item ->
                    CompositionLocalProvider(LocalEnvStore provides item) {
                        component.content?.let {
                            ComponentComposable(Modifier, it)
                        }
                    }
                }
            }

            when (items.loadState.append) {
                is LoadStateLoading -> item {
                    Box(
                        Modifier.fillMaxWidth().padding(8.dp),
                        contentAlignment = Alignment.Center
                    ) {
                        CircularProgressIndicator()
                    }
                }

                else -> {}
            }
        }
    }
}