package com.appcreator.blueprint.firebase.firestore

import com.appcreator.blueprint.core.DataShape
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.blueprint.core.findParameters
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
@SerialName("FirebaseFirestoreLoaderSpec")
data class FirebaseFirestoreLoaderSpec(
    override val id: String,
    override val parameterSettings: Map<String, LoaderSpec.ParameterSetting>?,
    val dataPath: String?,
    val limit: Int?,
    val orderBy: List<Order>?,
    val where: List<Where>?,
    val dataValues: List<ValueDataSpec>?
): LoaderSpec {

    val documentFetch: Boolean
        get() {
            val parts = dataPath?.split("/")?: emptyList()
            return parts.size % 2 == 0
        }

    override val dataShape: DataShape
        get() {
            val parts = dataPath?.split("/")?: emptyList()
            return if (parts.size % 2 == 0) {
                DataShape(dataValues?: emptyList())
            } else {
                DataShape(listOf(ListDataSpec(parts.lastOrNull()?: "result", dataValues?: emptyList())))
            }
        }

    override val parameters: Set<String>
        get() {
            val pathParams = (dataPath?.findParameters()?: emptyList()) +
                    (where?.map {
                        when(it) {
                            is Where.Group -> TODO()
                            is Where.In -> TODO()
                            is Where.Value -> it.value.findParameters()
                        }
                    }?.flatten()?: emptyList())
            return pathParams.toSet()
        }

    @Serializable
    data class Order(
        val field: String,
        val direction: OrderBy
    )

    enum class OrderBy {
        Ascending,
        Descending
    }

    enum class WhereType(val display: String) {
        Equal("=="),
        GreaterThan(">"),
        GreaterThanOrEqual(">="),
        LessThan("<"),
        LessThanOrEqual("<=")
    }

    enum class WhereInType {
        In,
        NotIn,
    }

    enum class WhereGroupType {
        And,
        Or,
    }

    @Serializable
    sealed class Where {
        @Serializable
        @SerialName("Value")
        data class Value(val field: String, val value: String, val type: WhereType): Where()
        @Serializable
        @SerialName("In")
        data class In(val field: String, val value: List<String>, val type: WhereInType): Where()
        @Serializable
        @SerialName("Group")
        data class Group(val children: List<Where>, val type: WhereGroupType): Where()
    }

}
