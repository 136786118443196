package com.appcreator.compose

import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Surface
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.material3.rememberTopAppBarState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.nestedscroll.nestedScroll
import com.appcreator.blueprint.Destination
import com.appcreator.blueprint.ScreenSpec
import com.appcreator.blueprint.components.Screen
import com.appcreator.blueprint.components.data.LoadingComponent
import com.appcreator.blueprint.components.navigation.BottomNavigationComponent
import com.appcreator.blueprint.components.navigation.NavigationDrawerComponent
import com.appcreator.blueprint.core.LoaderConfig
import com.appcreator.blueprint.core.LoaderSpecReference
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.EnvComposable
import com.appcreator.compose.components.data.LoadingComposable
import com.appcreator.compose.components.navigation.Navigation
import com.appcreator.compose.components.navigation.NavigationBar
import com.appcreator.compose.components.navigation.rememberNavigator
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer

@Composable
fun ScreenComposable(destination: Destination) {
    EnvComposable(destination.properties) {
        LoadingComposable(
            Modifier,
            LoadingComponent(
                _nodeId = null,
                _nodeLabel = null,
                config = LoaderConfig(
                    loaderSpec = LoaderSpecReference("screen-loader"),
                    parameters = mapOf("id" to destination.screen.id)
                ),
                destination.screen,
                action = null,
                pullToRefresh = false,
                errorView = LoadingComponent.ViewType.Default,
                errorContent = null,
                loadingView = LoadingComponent.ViewType.Default,
                loadingContent = null,
                displayInPreview = null
            )
        )
    }
}

@Composable
fun ScreenSpecComposable(modifier: Modifier, screenSpec: ScreenSpec) {
    val screen by LocalScreen.current
    ScreenComposable(modifier, screen)
}

@Composable
fun ScreenComposable(modifier: Modifier, screen: Screen) {
    when(screen.screenType) {
        Screen.ScreenType.Navigation -> ScreenNavigationComposable(modifier, screen)
        else -> ScreenContentComposable(modifier, screen)
    }
    screen.onLoadAction?.let {
        val deferred = Container.performer(it)?.deferred()
        val env = LocalEnvStore.current
        LaunchedEffect(Unit) {
            deferred?.perform(env)
        }
    }
}


@Composable
private fun ScreenNavigationComposable(modifier: Modifier, screen: Screen) {
    Surface(modifier.fillMaxSize()) {
        screen.navigation?.let {
            ComponentComposable(Modifier, it)
        }
    }
}

@Composable
private fun ScreenContentComposable(modifier: Modifier, screen: Screen) {
    val navigator = LocalNavigator.current?: return
    val willAddNavigation = screen.content is BottomNavigationComponent || screen.content is NavigationDrawerComponent
    if(!navigator.includesToolbar && !willAddNavigation) {
        val startPath = LocalEnvStore.current.injectVariables(screen.path?.value ?: "")
        val childNavigator = rememberNavigator(includesToolbar = true, startDestination = startPath, parent = navigator)
        Navigation(modifier, childNavigator)
    } else {
        ScreenComposableContent(modifier, screen, navigator, !willAddNavigation)
    }
}

@Composable
private fun ScreenComposableContent(
    modifier: Modifier,
    screen: Screen,
    navigator: Navigator,
    includeToolbar: Boolean
) {
    if (includeToolbar && !LocalModal.current) {
        val scrollBehavior = if (screen.navigationStyle == Screen.NavigationStyle.Large) {
            TopAppBarDefaults.enterAlwaysScrollBehavior(rememberTopAppBarState())
        } else null
        Scaffold(
            modifier = scrollBehavior?.let {
                modifier.nestedScroll(it.nestedScrollConnection)
            } ?: modifier,
            topBar = { NavigationBar(navigator, screen, scrollBehavior) }
        ) { padding ->
            ScreenComposableContentInner(Modifier.padding(padding), screen)
        }
    } else {
        ScreenComposableContentInner(modifier, screen)
    }

}

@Composable
private fun ScreenComposableContentInner(modifier: Modifier, screen: Screen) {
    BoxWithConstraints(modifier) {
        CompositionLocalProvider(LocalAbsoluteSize provides AbsoluteSize(this)) {
            screen.content?.let {
                ComponentComposable(Modifier, it)
            }
        }
    }
}