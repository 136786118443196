package com.appcreator.blueprint.components.navigation

import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.core.properties.MaterialIcon
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("NavigationItem")
@ComponentClass(
    group = "Navigation",
    title = "Tab Item",
    availableFrom = "0.0.1",
    accessible = false
)
data class NavigationItemComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "Screen",
        availableFrom = "0.0.1"
    )
    val link: BlueprintLink?,

    @InputProperty(
        title = "Title",
        availableFrom = "0.0.1"
    )
    @GenericDefault("com.appcreator.blueprint.core.properties.LocalizableString(values = mapOf(\"gb\" to \"Tab\"))")
    val title: LocalizableString?,

    @InputProperty(
        title = "Icon",
        availableFrom = "0.0.1"
    )
    val materialIcon: MaterialIcon?,

 ): Component