package com.appcreator.creatorapp.project.configurations

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.app.file.openFilePicker
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.shared.api.FileUploadInfo
import com.appcreator.styles.ThemeMargins
import io.ktor.utils.io.ByteReadChannel
import kotlinx.coroutines.launch

@Composable
fun IOSConfiguration(organisationResponse: OrganisationResponse, project: Project) {
    LoadingComposable(loader = {
        mutableStateOf(it.getIOSConfiguration(organisationResponse.organisation.id, project.id))
    }) {

        val client = LocalApiClient.current
        var display by remember { mutableStateOf(it.value) }

        Column(Modifier.padding(ThemeMargins.LargeMargins)) {
            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Bundle Identifier",
                value = display.bundleIdentifier ?: "",
                onValueChange = {
                    display = display.copy(bundleIdentifier = it)
                }
            )
            Spacer(Modifier.height(ThemeMargins.SmallMargin))
            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Team ID",
                value = display.teamId ?: "",
                onValueChange = {
                    display = display.copy(teamId = it)
                }
            )

            Spacer(Modifier.height(ThemeMargins.SmallMargin))

            Column(
                Modifier.border(
                    1.dp,
                    color = MaterialTheme.colorScheme.onBackground,
                    shape = RoundedCornerShape(8.dp)
                ).padding(ThemeMargins.SmallMargin)
            ) {

                UploadP8Button(organisationResponse, project, display.p8File) {
                    display = display.copy(p8File = true)
                    it.value = display
                }

                Spacer(Modifier.height(ThemeMargins.SmallMargin))
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Key ID",
                    value = display.keyId ?: "",
                    onValueChange = {
                        display = display.copy(keyId = it)
                    }
                )
                Spacer(Modifier.height(ThemeMargins.SmallMargin))
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Issuer ID",
                    value = display.issuerId ?: "",
                    onValueChange = {
                        display = display.copy(issuerId = it)
                    }
                )
            }

            Spacer(Modifier.height(ThemeMargins.LargeMargins))

            LoadingButton(
                label = "Save Changes",
                enabled = it.value != display,
                action = {
                    client.setIOSConfiguration(
                        organisationResponse.organisation.id,
                        project.id,
                        display
                    )
                    it.value = display
                }
            )
        }
    }
}

@Composable
fun LoadingButton(
    modifier: Modifier = Modifier,
    label: String,
    enabled: Boolean = true,
    action: suspend () -> Unit
) {
    var loading by remember { mutableStateOf(false) }
    val scope = rememberCoroutineScope()
    Button(
        modifier = modifier,
        enabled = enabled && !loading,
        onClick = {
            scope.launch {
                loading = true
                try {
                    action()
                } catch (ex: Exception) {
                    ex.printStackTrace()
                    // TODO
                }
                loading = false
            }
        }) {
        AnimatedContent(loading) { showing ->
            if (showing) {
                CircularProgressIndicator(
                    strokeWidth = 2.dp,
                    modifier = Modifier.size(24.dp)
                )
            } else {
                Text(label)
            }
        }
    }
}


@Composable
private fun UploadP8Button(
    organisationResponse: OrganisationResponse,
    project: Project,
    hasP8: Boolean,
    onSuccess: () -> Unit
) {
    var loading by remember { mutableStateOf(false) }
    val scope = rememberCoroutineScope()
    val client = LocalApiClient.current
    Button(
        enabled = !loading,
        onClick = {
            openFilePicker(".p8") {
                scope.launch {
                    loading = true
                    try {
                        client.uploadP8(organisationResponse.organisation.id, project.id, FileUploadInfo(
                            contentType = it.mimeType,
                            size = it.size,
                            file = ByteReadChannel(it.data),
                            filename = it.name
                        ))
                        onSuccess()
                    } catch (ex: Exception) {
                        ex.printStackTrace()
                        // TODO
                    }
                    loading = false
                }
            }
        }
    ) {
        AnimatedContent(loading) { showing ->
            if (showing) {
                CircularProgressIndicator(
                    strokeWidth = 2.dp,
                    modifier = Modifier.size(24.dp)
                )
            } else {
                Text(if (hasP8) { "Replace .p8 file" } else "Upload .p8 file")
            }
        }
    }

}