package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.properties.CornerValues
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.core.properties.SideValues
import com.appcreator.blueprint.core.properties.SizeValue
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Container")
@ComponentClass(
    group = "Basic",
    title = "Container",
    availableFrom = "0.0.1"
)
data class ContainerComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "Components",
        availableFrom = "0.0.1"
    )
    val content: List<Component>,

    @InputProperty(
        section = "Background",
        title = "Background",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ContainerComponent.Background.None")
    val backgroundType: Background?,

    @InputProperty(
        section = "Background",
        title = "Color",
        availableFrom = "0.0.1"
    )
    @DisplayIf("backgroundType.value == ContainerComponent.Background.Color.name")
    val backgroundColor: ColorReference?,

    @InputProperty(
        section = "Background",
        title = "Apply gradient",
        availableFrom = "0.0.1"
    )
    @DisplayIf("backgroundType.value == ContainerComponent.Background.Color.name")
    val gradient: Boolean?,

    @InputProperty(
        section = "Background",
        title = "Image",
        availableFrom = "0.0.1"
    )
    @DisplayIf("backgroundType.value == ContainerComponent.Background.Image.name")
    val backgroundImage: LocalizableString?,

    @InputProperty(
        section = "Background",
        title = "Opacity",
        availableFrom = "0.0.1"
    )
    @DisplayIf("backgroundType.value != ContainerComponent.Background.None.name")
    val opacity: String?,

    @InputProperty(
        section = "Layout",
        title = "Scrollable",
        availableFrom = "0.0.1",
    )
    @GenericDefault("false")
    @DisplayIf("axis.value != ContainerComponent.Axis.Z.name")
    val scrolling: Boolean,

    @InputProperty(
        section = "Layout",
        title = "Keep offscreen views alive",
        availableFrom = "0.0.1",
    )
    @GenericDefault("false")
    @DisplayIf("scrolling.value == true")
    @DisplayIf("axis.value != ContainerComponent.Axis.Z.name")
    val keepOffScreenViewsAlive: Boolean?,

    @InputProperty(
        section = "Layout",
        title = "Axis",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ContainerComponent.Axis.Y")
    val axis: Axis,

    @InputProperty(
        section = "Layout",
        title = "Width",
        availableFrom = "0.0.1"
    )
    val width: SizeValue?,
    @InputProperty(
        section = "Layout",
        title = "Height",
        availableFrom = "0.0.1"
    )
    val height: SizeValue?,
    @InputProperty(
        section = "Layout",
        title = "Padding",
        availableFrom = "0.0.1"
    )
    val padding: SideValues?,
    @InputProperty(
        section = "Layout",
        title = "Margin",
        availableFrom = "0.0.1"
    )
    val margin: SideValues?,

    @InputProperty(
        section = "Alignment",
        title = "Horizontal",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ContainerComponent.HorizontalAlignment.Start")
    val horizontalAlignment: HorizontalAlignment?,
    @InputProperty(
        section = "Alignment",
        title = "Vertical",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ContainerComponent.VerticalAlignment.Top")
    val verticalAlignment: VerticalAlignment?,
    @InputProperty(
        section = "Alignment",
        title = "Spacing",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ContainerComponent.Spacing.None")
    @DisplayIf("axis.value != ContainerComponent.Axis.Z.name")
    val spacing: Spacing?,
    @InputProperty(
        section = "Alignment",
        title = "Space by",
        availableFrom = "0.0.1"
    )
    @DisplayIf("axis.value != ContainerComponent.Axis.Z.name")
    @DisplayIf("spacing.value == ContainerComponent.Spacing.ByValue.name")
    val spacingValue: Int?,

    @InputProperty(
        section = "Border",
        title = "Color",
        availableFrom = "0.0.1"
    )
    val borderColor: ColorReference?,
    @InputProperty(
        section = "Border",
        title = "Radius",
        availableFrom = "0.0.1"
    )
    val borderRadius: CornerValues?,
    @InputProperty(
        section = "Border",
        title = "Thickness",
        availableFrom = "0.0.1"
    )
    val borderThickness: Int?,

    @InputProperty(
        section = "Effects",
        title = "Shimmer",
        availableFrom = "0.0.1"
    )
    val shimmer: Boolean?,

    @InputProperty(
        section = "Effects",
        title = "Opacity",
        availableFrom = "0.0.1"
    )
    val viewOpacity: String?,

    @InputProperty(
        section = "On click",
        title = "Action",
        availableFrom = "0.0.1"
    )
    val action: Action?,

    @InputProperty(
        section = "Layout Advanced",
        title = "Min Width",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    @GenericDefault("40")
    val minWidth: Int?,
    @InputProperty(
        section = "Layout Advanced",
        title = "Min Height",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    @GenericDefault("40")
    val minHeight: Int?,

    @InputProperty(
        section = "Layout Advanced",
        title = "Max Width",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    val maxWidth: Int?,
    @InputProperty(
        section = "Layout Advanced",
        title = "Max Height",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    val maxHeight: Int?,


): Component {
    enum class Axis {
        X,
        Y,
        Z
    }

    enum class HorizontalAlignment {
        Start,
        Center,
        End
    }

    enum class VerticalAlignment {
        Top,
        Center,
        Bottom
    }

    enum class Spacing {
        None,
        Between,
        Around,
        ByValue
    }

    enum class Background {
        None,
        Color,
        Image,
    }
}
