package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.lazy.grid.rememberLazyGridState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Interests
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.Card
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.capitalize
import androidx.compose.ui.text.intl.Locale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import app_creator.app.generated.resources.Res
import com.appcreator.blueprint.components.basic.MaterialIconComponent
import com.appcreator.blueprint.core.properties.MaterialIcon
import com.appcreator.blueprint.spec.inputs.MaterialIconInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.ModalInputGroup
import com.appcreator.components.layouts.ScrollBars
import com.appcreator.compose.components.basic.MaterialIconComposable
import com.appcreator.creatorapp.RawLoadingComposable
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.debounce
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import org.jetbrains.compose.resources.ExperimentalResourceApi


@Composable
fun MaterialIconInput(spec: MaterialIconInputSpec, save: () -> Unit) {
    ModalInputGroup(
        title = spec.properties.title,
        modalTitle = spec.value?.name ?: spec.properties.title,
        header =  {
            Text("Select Icon")
        },
        body = { close ->
            IconPicker {
                spec.value = it
                save()
                close()
            }
        },
        leadingIcon = {
            Icon(Icons.Default.Interests, contentDescription = null)
        }
    )
}

@Serializable
private data class Mdi(
    val icons: List<MdiSpec>
)

@Serializable
private data class MdiSpec(
    val name: String,
    val popularity: Int,
    val codepoint: Int,
    @SerialName("unsupported_families")
    val unsupported: List<String>
)

@OptIn(ExperimentalResourceApi::class)
@Composable
private fun IconPicker(onPick: (MaterialIcon) -> Unit) {

    Column(Modifier.fillMaxSize()) {

        val search = remember { mutableStateOf("") }
        CreatorTextInput(
            label = "Search",
            value = search.value,
            onValueChange = {
                search.value = it
            },
            leadingIcon = {
                Icon(Icons.Default.Search, contentDescription = null)
            }
        )


            // TODO Need to load when dialog is shown
            RawLoadingComposable(loader = {
//                readResourceBytes("mdi.json").decodeToString()
                Json {
                    ignoreUnknownKeys = true
                }.decodeFromString<Mdi>(Res.readBytes("files/mdi.json").decodeToString())
//                resourceClient.get("mdi.json")
//                    .body<Mdi>()
                    .icons
                    .filterNot { it.unsupported.contains("Material Icons") }
                    .sortedByDescending { it.popularity }
                    .map { MaterialIcon(
                        it.name.split("_").joinToString(separator = " ") { part -> part.capitalize(Locale.current) },
                        it.codepoint.toChar().toString()
                    ) }
            }) { data ->

                var items by remember { mutableStateOf(data) }
                LaunchedEffect(Unit) {
                    snapshotFlow { search.value }
                        .debounce(500)
                        .collectLatest { search ->
                            items = if (search.isEmpty()) data else data.filter {
                                val name = it.name.split("_")
                                    .joinToString(separator = " ") { part -> part.capitalize(Locale.current) }
                                name.contains(search, ignoreCase = true)
                            }
                        }
                }

                Box {
                    val state = rememberLazyGridState()
                    LazyVerticalGrid(
                        columns = GridCells.Adaptive(140.dp),
                    ) {
                        items(items) {
                            Card(modifier = Modifier
                                .padding(ThemeMargins.SmallMargin)
                                .height(140.dp),
                                onClick = {
                                    onPick(it)
                                }
                            ) {
                                Box(
                                    modifier = Modifier.fillMaxSize()
                                        .padding(ThemeMargins.SmallMargin),
                                    contentAlignment = Alignment.Center
                                ) {
                                    Column(horizontalAlignment = Alignment.CenterHorizontally) {
                                        MaterialIconComposable(
                                            modifier = Modifier,
                                            component = MaterialIconComponent(
                                                _nodeId = null,
                                                _nodeLabel = null,
                                                icon = it,
                                                size = 24,
                                                color = null
                                            )
                                        )
                                        Spacer(Modifier.height(ThemeMargins.MedMargins))
                                        Text(
                                            text = it.name,
                                            textAlign = TextAlign.Center
                                        )
                                    }
                                }
                            }
                        }
                    }

                    ScrollBars(state)
                }
            }
        }
}
