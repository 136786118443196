package com.appcreator.blueprint.components

import com.appcreator.blueprint.core.Component
import kotlinx.serialization.Serializable

@Serializable
data object UnsupportedComponent: Component {
    override val _nodeId: String?
        get() = null
    override val _nodeLabel: String?
        get() = null
}
