package com.appcreator.blueprint.components.navigation

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.BlueprintLinkInputSpec
import com.appcreator.blueprint.spec.inputs.BooleanInputSpec
import com.appcreator.blueprint.spec.inputs.ColorReferenceInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentListInputSpec
import com.appcreator.blueprint.spec.inputs.FontReferenceInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.IntInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class NavigationComponentBuilder(
  component: NavigationComponent?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "Navigation",
          name = "NavigationComponent",
          title = "Navigation",
          description = "Add screens to navigate between",
          availableFrom = "0.0.1",
          accessible = false,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  public val tabBar: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Navigation Options",
          title = "Tab Bar",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.tabBar),
          
          
      )

  public val navigationDrawer: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Navigation Options",
          title = "Navigation Drawer",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.navigationDrawer),
          
          
      )

  public val tabBarBackgroundColor: ColorReferenceInputSpec = ColorReferenceInputSpec(
          properties = InputProperties(
          section = "Tab Bar",
          title = "Background Color",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && tabBar.value == true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.tabBarBackgroundColor),
          
          
      )

  public val tabBarSelectedColor: ColorReferenceInputSpec = ColorReferenceInputSpec(
          properties = InputProperties(
          section = "Tab Bar",
          title = "Label Selected Color",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && tabBar.value == true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.tabBarSelectedColor),
          
          
      )

  public val tabBarUnselectedColor: ColorReferenceInputSpec = ColorReferenceInputSpec(
          properties = InputProperties(
          section = "Tab Bar",
          title = "Label Unselected Color",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && tabBar.value == true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.tabBarUnselectedColor),
          
          
      )

  public val tabBarLabelSize: FontReferenceInputSpec = FontReferenceInputSpec(
          properties = InputProperties(
          section = "Tab Bar",
          title = "Label Size",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && tabBar.value == true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.tabBarLabelSize),
          
          
      )

  public val tabBarAlwaysShowLabels: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Tab Bar",
          title = "Always Show Label",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && tabBar.value == true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.tabBarAlwaysShowLabels?: true),
          
          
      )

  public val tabBarTabs: ComponentListInputSpec = ComponentListInputSpec(
          properties = InputProperties(
          section = "Tab Bar",
          title = "Tab Items",
          description = "Max 5 tabs",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && tabBar.value == true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateListOf(*(component?.tabBarTabs?.map { it.toBuilder(newId) }?:
          emptyList()).toTypedArray()),
          allowedComponents = listOf("NavigationItemComponent"),
          
      )

  public val drawerContent: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Navigation Drawer",
          title = "Drawer Content",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && navigationDrawer.value == true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true
      ) ,
          value =  mutableStateOf(component?.drawerContent?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val navigationDrawerWidth: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "Navigation Drawer",
          title = "Drawer Width",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && navigationDrawer.value == true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.navigationDrawerWidth?: 300),
          
          
      )

  public val showNavigationDrawer: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Preview ",
          title = "Show Navigation Drawer",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && navigationDrawer.value == true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.showNavigationDrawer),
          
          
      )

  public val rootScreen: BlueprintLinkInputSpec = BlueprintLinkInputSpec(
          properties = InputProperties(
          section = "Navigation",
          title = "Root Screen",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && tabBar.value != true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.rootScreen),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, tabBar, navigationDrawer,
      tabBarBackgroundColor, tabBarSelectedColor, tabBarUnselectedColor, tabBarLabelSize,
      tabBarAlwaysShowLabels, tabBarTabs, drawerContent, navigationDrawerWidth,
      showNavigationDrawer, rootScreen)

  override fun build(): Component = NavigationComponent(_nodeId.value?: Uuid.random().toString(),
      _nodeLabel.value, tabBar.value, navigationDrawer.value, tabBarBackgroundColor.value,
      tabBarSelectedColor.value, tabBarUnselectedColor.value, tabBarLabelSize.value,
      tabBarAlwaysShowLabels.value?: true, tabBarTabs.value.map { it.build() },
      drawerContent.value?.build(), navigationDrawerWidth.value?: 300, showNavigationDrawer.value,
      rootScreen.value)
}
