package com.appcreator.creatorapp.editor.builderaction

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.components.layouts.ScrollBars
import com.appcreator.styles.ThemeMargins

private class SelectedAction(
    val builder: ActionBuilder,
    val remove: () -> Unit
)

@Composable
fun ActionScreen(actionSpec: ActionInputSpec, save: () -> Unit) {

    var selected by remember { mutableStateOf<SelectedAction?>(null) }

    Row(modifier = Modifier.fillMaxSize()) {

        selected?.let {
            Row(modifier = Modifier
                .width(300.dp)
                .fillMaxHeight()
                .padding(horizontal = ThemeMargins.SmallMargin)) {
                val state = rememberScrollState()
                Box {
                    Column(
                        Modifier.padding(end = ThemeMargins.SmallMargin).verticalScroll(state)
                    ) {
                        ActionBuilder(
                            actionBuilder = it.builder,
                            save = save,
                            remove = {
                                it.remove()
                                selected = null
                            }
                        )
                    }
                    ScrollBars(state)
                }
                VerticalDivider()
            }
        }

        ActionGraphRoot(actionSpec, save) { builder, remove ->
            selected = SelectedAction(builder, remove)
        }
    }
}
