package com.appcreator.compose.extensions

import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.coerceAtLeast
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.SizeValue
import com.appcreator.compose.AbsoluteSize
import com.appcreator.compose.LocalAbsoluteSize
import com.appcreator.compose.LocalHorizontalScrollingParent
import com.appcreator.compose.LocalVerticalScrollingParent


@Composable
fun SizingView(
    width: SizeValue?,
    height: SizeValue?,
    minWidth: Int? = null,
    maxWidth: Int? = null,
    minHeight: Int? = null,
    maxHeight: Int? = null,
    padding: PaddingValues = PaddingValues(),
    margin: PaddingValues = PaddingValues(),
    content: @Composable (Modifier, hasWidth: Boolean, hasHeight: Boolean) -> Unit
) {
    val absoluteSize = LocalAbsoluteSize.current
    val widthDp = absoluteSize?.width(width, margin)?.coerceIn(
        minimumValue = minWidth?.dp,
        maximumValue = maxWidth?.dp?.coerceIn(minimumValue = minWidth?.dp, maximumValue = null)
    )
    val heightDp = absoluteSize?.height(height, margin)?.coerceIn(
        minimumValue = minHeight?.dp,
        maximumValue = maxHeight?.dp?.coerceIn(minimumValue = minHeight?.dp, maximumValue = null)
    )

    val modifier = Modifier
        .sizeIn(
            minWidth = minWidth?.dp ?: Dp.Unspecified,
            maxWidth = maxWidth?.dp ?: Dp.Unspecified,
            minHeight = minHeight?.dp ?: Dp.Unspecified,
            maxHeight = maxHeight?.dp ?: Dp.Unspecified,
        )
        .let {
            if (absoluteSize != null) {
                widthDp?.let { dp -> it.width(dp) }?: it
            } else {
                when (width) {
                    is SizeValue.Percent -> it.fillMaxWidth((width.value.toFloat() / 100f).coerceAtMost(1f))
                    is SizeValue.Px -> it.width(width.value.dp)
                    null -> it
                }
            }
        }
        .let {
            if (absoluteSize != null) {
                heightDp?.let { dp -> it.height(dp) }?: it
            } else {
                when (height) {
                    is SizeValue.Percent -> it.fillMaxHeight((height.value.toFloat() / 100f).coerceAtMost(1f))
                    is SizeValue.Px -> it.height(height.value.dp)
                    null -> it
                }
            }
        }


    CompositionLocalProvider(LocalAbsoluteSize provides absoluteSize?.let { AbsoluteSize(absoluteSize, widthDp, heightDp, padding) }) {
        content(modifier, width != null, height != null)
    }

}

