package com.appcreator.creatorapp.editor.local

import androidx.compose.runtime.staticCompositionLocalOf
import com.appcreator.blueprint.actions.data.DataConversionActionBuilder
import com.appcreator.blueprint.components.ScreenBuilder
import com.appcreator.blueprint.components.data.DataConversionComponentBuilder
import com.appcreator.blueprint.core.DataShape
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.linkParameter
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.blueprint.spec.inputs.ActionListInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentListInputSpec
import com.appcreator.blueprint.spec.inputs.DataPathInputSpec
import com.appcreator.blueprint.spec.inputs.DataSpecListInputSpec
import com.appcreator.blueprint.spec.inputs.LoaderConfigInputSpec
import com.appcreator.creatorapp.editor.BlueprintManager
import com.appcreator.creatorapp.editor.utils.displayLabel2

val LocalNodeExplorer = staticCompositionLocalOf<NodeExplorer> {
    error("No instance of Navigator provided")
}

class NodeExplorer(
    private val blueprintManager: BlueprintManager,
) {

    fun findNode(id: String?, componentBuilder: ComponentBuilder?): ComponentBuilder? {
        if (componentBuilder?._nodeId?.value == id) return componentBuilder
        componentBuilder?.inputSpecs?.forEach { inputSpec ->
            when(inputSpec) {
                is ComponentInputSpec -> {
                    findNode(id, inputSpec.value)?.let { return it }
                }
                is ComponentListInputSpec -> inputSpec.value.forEach { value ->
                    findNode(id, value)?.let { return it }
                }
            }
        }
        return null
    }

    fun parent(component: ComponentBuilder): ComponentBuilder? {
        return blueprintManager.screenBuilder?.let { root -> parent(root, component) }
    }

    private fun parent(parent: ComponentBuilder, componentBuilder: ComponentBuilder): ComponentBuilder? {
        parent.inputSpecs.forEach { inputSpec ->
            when(inputSpec) {
                is ComponentInputSpec -> if (inputSpec.value == componentBuilder) return parent
                else {
                    inputSpec.value?.let {
                        parent(it, componentBuilder)?.let { found ->
                            return found
                        }
                    }
                }
                is ComponentListInputSpec -> if (inputSpec.value.contains(componentBuilder)) return parent
                else {
                    inputSpec.value.forEach { item ->
                        parent(item, componentBuilder)?.let { found ->
                            return found
                        }
                    }
                }
            }
        }
        return null
    }

    fun parents(component: ComponentBuilder?, inclusive: Boolean): List<ComponentBuilder> {
        val parents = mutableListOf<ComponentBuilder>()
        if (inclusive) {
            component?.let { parents.add(component) }
        }
        var item: ComponentBuilder? = component

        do {
            item = item?.let { parent(it) }
            item?.let { parents.add(item) }
        } while (item != null)

        return parents
    }

    fun allDataItems(forNode: ComponentBuilder?, inclusive: Boolean): List<DataOption> {
        val parentNodes = parents(forNode, inclusive)
        val data = mutableListOf<DataOption>()

        val globalShape = DataOption(
            title = "Global",
            settable = true,
            blueprintManager.globalData
                .filterNot{ it.forLoadersOnly }
                .map { ValueDataSpec(it.key, valueType = ValueDataSpec.Type.String, value = null, visible = true) }
        )

        data.add(globalShape)

        parentNodes.reversed().forEach { component ->
            when(component) {
                is ScreenBuilder -> {
                    val options = component.path.value?.value?.linkParameter()?.map {
                        ValueDataSpec(it, valueType = ValueDataSpec.Type.String, null,true)
                    }
                    if (!options.isNullOrEmpty()) {
                        data.add(DataOption("Screen Params", false, options))
                    }
                }
                is DataConversionComponentBuilder -> {
                    component.outputPath.value?.let {
                        data.add(DataOption(component.displayLabel2(), false, listOf(ValueDataSpec(it, valueType = ValueDataSpec.Type.String, null, true))))
                    }
                }
            }

            component.inputSpecs.forEach { spec ->
                when(spec) {
                    is DataPathInputSpec -> {
                        data.firstNotNullOfOrNull {
                            it.specs.filterIsInstance<ListDataSpec>()
                                .find { it.key == spec.value?.value }
                        }?.let {
                            data.add(DataOption(it.key, false, it.items))
                        }
                    }
                    is LoaderConfigInputSpec -> {
                        loaderSpecDataShape(spec)?.let {
                            data.add(DataOption(component.displayLabel2(), false, it.options))
                        }
                    }
                    is DataSpecListInputSpec -> {

//                        val shape = spec.value.mapToDataShape()
                        if (spec.value.isNotEmpty()) {
                            // TODO name
                            data.add(DataOption("Local", true, spec.value.map { it.build() }))
                        }
                    }
                }
            }


            if (component.spec.producesDataFields.isNotEmpty()) {
                val option = DataOption(component.displayLabel2(), component.spec.producedFieldsEditable, specs = component.spec.producesDataFields.map {
                    ValueDataSpec(it, valueType = ValueDataSpec.Type.String, null, true)
                })
                data.add(option)
            }

        }

        //// This auto creates a property for an input field but not sure it works right now, might force creating local state
        // Must use local state
//        val inputs = mutableListOf<DataSpec>()
//        val root = blueprintManager.screenBuilder?: return emptyList()
//        loopAllNodes(root) {
//            if(it != forNode) { // Maybe this is fine but kind of seems a weird thing to do
//                if (it is InputComponentBuilder) {
//                    it.data.value?.let { inputs.add(ValueDataSpec(key = it, null, true)) }
//                }
//            }
//        }
//        if (inputs.isNotEmpty()) {
//            data.add(DataOption("Input", true, inputs))
//        }
        //

        return data.toList()
    }

    fun loaderSpecDataShape(spec: LoaderConfigInputSpec): DataShape? {
        return blueprintManager.dataSources.find { ds -> spec.value?.loaderSpec?.id == ds.id }?.loaderSpec?.dataShape
    }

    fun actionsDataItems(forNode: ComponentBuilder?, actionBuilder: ActionBuilder): List<DataOption> {
        forNode?.inputSpecs?.forEach { spec ->
            when(spec) {
                is ActionInputSpec -> {
                    spec.value?.let {
                        if (it.contains(actionBuilder)) {
                            val data = mutableListOf<DataOption>()
                            appendActionDataItems(it, actionBuilder, data)
                            return data
                        }
                    }
                }
                is ActionListInputSpec -> {
                    spec.value.forEach {
                        if (it.contains(actionBuilder)) {
                            val data = mutableListOf<DataOption>()
                            appendActionDataItems(it, actionBuilder, data)
                            return data
                        }
                    }
                }
                else -> {}
            }
        }
        return emptyList()
    }

    private fun ActionBuilder.contains(other: ActionBuilder): Boolean {
        inputSpecs.forEach { spec ->
            when(spec) {
                is ActionInputSpec -> {
                    spec.value?.let {
                        if (it == other || it.contains(other)) return true
                    }
                }
                is ActionListInputSpec -> {
                    if(spec.value.any {
                        it == other || it.contains(other)
                    }) return true
                }
                else -> {}
            }
        }
        return false
    }
    private fun appendActionDataItems(from: ActionBuilder, until: ActionBuilder, append: MutableList<DataOption>) {

        (from as? DataConversionActionBuilder)?.let { action ->
            action.outputPath.value?.let {
                append.add(DataOption("Conversion", false, listOf(ValueDataSpec(it, valueType = ValueDataSpec.Type.String,null, true))))
            }
        }

        from.inputSpecs.forEach { spec ->
            when(spec) {
                is LoaderConfigInputSpec -> {
                    val actual = blueprintManager.dataSources.find { ds -> spec.value?.loaderSpec?.id == ds.id }
                    actual?.loaderSpec?.dataShape?.let {
                        append.add(DataOption(actual.title, false, it.options))
                    }
                }
                is ActionInputSpec -> {
                    spec.value?.let {
                        if (it == until) return
                        else appendActionDataItems(it, until, append)
                    }
                }
                is ActionListInputSpec -> {
                    spec.value.forEach {
                        if (it == until) return
                        else appendActionDataItems(it, until, append)
                    }
                }
                else -> {}
            }
        }
    }
}
