package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.properties.DataValueSet
import com.appcreator.blueprint.spec.SingleInputSpec

class DataValueSetInputSpec(
    properties: InputProperties,
    value: MutableState<DataValueSet?>
): SingleInputSpec<DataValueSet?>(properties, value)
