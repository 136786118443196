package com.appcreator.blueprint.conversions

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ConversionBuilder
import com.appcreator.blueprint.spec.ConversionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.ScriptContentInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class JavascriptConversionBuilder(
  conversion: JavascriptConversion?,
  newId: Boolean = false,
) : ConversionBuilder {
  override val spec: ConversionSpec = ConversionSpec(
          name = "JavascriptConversion",
          title = "Javascript",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
      )

  public val script: ScriptContentInputSpec = ScriptContentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Script",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(conversion?.script),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(script)

  override fun build(): Conversion = JavascriptConversion(script.value)
}
