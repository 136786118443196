package com.appcreator.creatorapp.editor.buildercondition

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.conditions.conditionOptions
import com.appcreator.blueprint.conditions.logic.AndConditionBuilder
import com.appcreator.blueprint.conditions.logic.NotConditionBuilder
import com.appcreator.blueprint.conditions.logic.OrConditionBuilder
import com.appcreator.blueprint.spec.ConditionBuilder
import com.appcreator.blueprint.spec.inputs.ConditionInputSpec
import com.appcreator.components.layouts.Dropdown
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.compose.tracingPaperBackground
import com.appcreator.styles.ThemeMargins


@Composable
fun RowScope.ConditionsGraph(
    conditionSpec: ConditionInputSpec,
    save: () -> Unit,
    selected: (ConditionBuilder, remove: () -> Unit) -> Unit
) {

    LaunchedEffect(Unit) {
        // Could do with a better way to do this
        if (conditionSpec.value !is AndConditionBuilder) {
            val conditionOptionsProvider =
                conditionOptions().entries.first { it.key.name == "AndCondition" }
            conditionSpec.value = conditionOptionsProvider.value.block()
        }
    }

    Column(
        modifier = Modifier.weight(1f)
            .fillMaxHeight()
            .tracingPaperBackground()
            .verticalScroll(rememberScrollState()),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Spacer(Modifier.height(ThemeMargins.LargeMargins))

        (conditionSpec.value as? AndConditionBuilder)?.let {

            it.conditions.value.forEachIndexed { index, condition ->
                ConditionRow(
                    builder = condition,
                    save = save,
                    remove = {
                        it.conditions.value.remove(condition)
                    },
                    selected = selected
                )
                if (index < it.conditions.value.lastIndex) {
                    Text(
                        modifier = Modifier
                            .padding(vertical = ThemeMargins.SmallMargin)
                            .padding(end = 120.dp),
                        text = "AND",
                        style = MaterialTheme.typography.labelSmall
                    )
                }
            }

            if (it.conditions.value.isNotEmpty()) {
                Spacer(Modifier.height(ThemeMargins.LargeMargins))
            }

            val title = if(it.conditions.value.isEmpty()) "Add Condition" else "And"
            ConditionPicker(title, it.conditions.value.isEmpty()) { condition ->
                val orConditionBuilder =
                    conditionOptions().entries.first { it.key.name == "OrCondition" }.value.block() as OrConditionBuilder
                orConditionBuilder.conditions.value.add(condition.wrapInNot())
                it.conditions.value.add(orConditionBuilder)
                save()
            }
         }
    }
}

@Composable
private fun ConditionRow(
    builder: ConditionBuilder,
    save: () -> Unit,
    remove: () -> Unit,
    selected: (ConditionBuilder, remove: () -> Unit) -> Unit
) {
    if (builder !is OrConditionBuilder) return
    Row(verticalAlignment = Alignment.CenterVertically) {

        builder.conditions.value.forEachIndexed { index, condition ->
            Card(onClick = {
                selected(condition) {
                    builder.conditions.value.remove(condition)
                    if (builder.conditions.value.isEmpty()) {
                        remove()
                    }
                    save()
                }
            }) {
                Box(Modifier.size(120.dp, 80.dp), contentAlignment = Alignment.Center) {
                    val title = (condition as? NotConditionBuilder)?.notCondition?.value?.spec?.title ?: "Condition"
                    Text(
                        text = title,
                        textAlign = TextAlign.Center
                    )
                }
            }

            if (index < builder.conditions.value.lastIndex) {

                Text(
                    modifier = Modifier.padding(horizontal = ThemeMargins.SmallMargin),
                    text = "OR",
                    style = MaterialTheme.typography.labelSmall
                )
            }
        }

        Spacer(Modifier.width(ThemeMargins.LargeMargins))

        ConditionPicker("OR", false) {
            builder.conditions.value.add(it.wrapInNot())
            save()
        }

    }
}

private fun ConditionBuilder.wrapInNot(): ConditionBuilder {
    val notConditionBuilder = conditionOptions().entries.first { it.key.name == "NotCondition" }.value.block() as NotConditionBuilder
    notConditionBuilder.notCondition.value = this
    return notConditionBuilder
}

@Composable
private fun ConditionPicker(
    buttonTitle: String,
    colored: Boolean,
    add: (ConditionBuilder) -> Unit
) {
    Box {
        Dropdown(
            anchor = {
                if (colored) {
                    Button(onClick = it) {
                        Icon(Icons.Default.Add, contentDescription = null)
                        Text(buttonTitle)
                    }
                } else {
                    TextButton(
                        modifier = Modifier.width(90.dp), onClick = it) {
                        Icon(Icons.Default.Add, contentDescription = null)
                        Text(buttonTitle)
                    }
                }
            },
            content = { dismiss ->
                val options = remember { conditionOptions().filter { it.key.accessible } }
                options.forEach {
                    DropdownItem(title = it.key.title) {
                        add(it.value.block())
                        dismiss()
                    }
                }
            }
        )
    }
}