package com.appcreator.compose.actions.alert

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.alerts.SnackbarAlertAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalSnackbarAlert
import com.appcreator.compose.SnackbarData
import com.appcreator.compose.actions.Performer


class SnackbarAlertPerformer(private val action: SnackbarAlertAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        val localSnakcbar = LocalSnackbarAlert.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.message?.let {
                    localSnakcbar?.send(SnackbarData(
                        message = envStore.injectVariables(it),
                        contentColor = action.textColor,
                        containerColor = action.backgroundColor
                    ))
                }
            }
        }
    }
}