package com.appcreator.creatorapp.editor.centerdata.global

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.GlobalData
import com.appcreator.creatorapp.editor.BlueprintManager
import com.appcreator.styles.ThemeMargins

@Composable
fun DataItemsSideBar(
    blueprintManager: BlueprintManager,
    selectedItem: GlobalData?,
    selectItem: (GlobalData?) -> Unit

) {
    Box(modifier = Modifier
        .width(250.dp)
        .fillMaxHeight()
        .padding(horizontal = ThemeMargins.SmallMargin)
    ) {

        Column(Modifier
            .verticalScroll(rememberScrollState())
        ) {

            NavigationDrawerItem(
                label = {
                    Text(
                        text = "Environments",
                        style = MaterialTheme.typography.titleMedium
                    )
                },
                selected = selectedItem == null,
                onClick = {
                    selectItem(null)
                },
                colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified)
            )

            Spacer(Modifier.height(ThemeMargins.LargeMargins))

            blueprintManager.editableGlobalData
                .forEach { item ->

                    NavigationDrawerItem(
                        label = {
                            val title = item.key.ifBlank { "New Item" }
                            Text(title, maxLines = 1, overflow = TextOverflow.Ellipsis)
                        },
                        selected = item == selectedItem,
                        onClick = {
                            selectItem(item)
                        },
                        colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified)
                    )
                }

            Spacer(Modifier.height(64.dp))
        }


        ExtendedFloatingActionButton(
            modifier = Modifier.align(Alignment.BottomCenter),
            text = {
                Text("Add")
            },
            icon = {
                Icon(Icons.Default.Add, contentDescription = null)
            },
            onClick = {
                val newItem = GlobalData(
                    key = "",
                    values = emptyMap(),
                    perEnvironment = false,
                    forLoadersOnly = false
                )
                blueprintManager.editableGlobalData.add(newItem)
                blueprintManager.notifyUpdates(true)
                selectItem(newItem)
            }
        )
    }

//
//    Column(
//        modifier = Modifier
//            .fillMaxHeight()
//            //.borderRight(sideBorder())
//    ) {
//
//        Box(
//            modifier = ListButtonStyle
////                .toModifier(if (selectedItem == null) ListButtonSelectedVariant else null)
//                .fillMaxWidth()
//                .clickable {
//                    selectItem(null)
//                },
//            contentAlignment = Alignment.CenterStart
//        ) {
//            Text("Environments")
//        }
//
//        Box(modifier = Modifier.height(ThemeMargins.LargeMargins))
//
//        Column(
//            modifier = Modifier.width(200.dp)
////                .overflow {
////                y(Overflow.Auto)
////            }
//        ) {
//            blueprintManager.globalData
//                .forEach { item ->
//                    Box(
//                        modifier = ListButtonStyle//.toModifier(if (item == selectedItem) ListButtonSelectedVariant else null)
//                            .fillMaxWidth()
//                            .clickable {
//                                selectItem(item)
//                            },
//                        contentAlignment = Alignment.CenterStart
//                    ) {
//                        val title = item.key.ifBlank { "new item" }
//                        Text(title)
//                    }
//                }
//        }
//
//        Spacer(Modifier)
//
//        Box(
//            modifier = ListButtonStyle
//                .fillMaxWidth()
//                .clickable {
//                    val newItem = GlobalData(
//                        key = "",
//                        values = emptyMap(),
//                        perEnvironment = false,
//                        forLoadersOnly = false
//                    )
//                    blueprintManager.globalData.add(newItem)
//                    blueprintManager.notifyUpdates(true)
//                    selectItem(newItem)
//                },
//            contentAlignment = Alignment.CenterStart
//        ) {
//            Text("Add +")
//        }
//    }
}
