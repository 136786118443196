@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package app_creator.app.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val logo_firebase: DrawableResource by 
      lazy { init_logo_firebase() }

  public val logo_firebase_28dp: DrawableResource by 
      lazy { init_logo_firebase_28dp() }
}

internal val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/app_creator.app.generated.resources/drawable/logo.xml", -1, -1),
    )
)

internal val Res.drawable.logo_firebase: DrawableResource
  get() = CommonMainDrawable0.logo_firebase

private fun init_logo_firebase(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_firebase",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/app_creator.app.generated.resources/drawable/logo_firebase.xml", -1, -1),
    )
)

internal val Res.drawable.logo_firebase_28dp: DrawableResource
  get() = CommonMainDrawable0.logo_firebase_28dp

private fun init_logo_firebase_28dp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_firebase_28dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/app_creator.app.generated.resources/drawable/logo_firebase_28dp.png", -1, -1),
    )
)
