package com.appcreator.blueprint.actions.data

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("DataConversionAction")
@ActionClass(
    group = "",
    title = "Data Conversion",
    availableFrom = "0.0.1"
)
class DataConversionAction(
    @InputProperty(
        title = "Output Path",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    val outputPath: String?,

    @InputProperty(
        title = "Conversion",
        availableFrom = "0.0.1",
    )
    val conversion: Conversion?,

    @InputProperty(
        title = "Next Action",
        availableFrom = "0.0.1"
    )
    val subAction: Action?,

): Action
