package com.appcreator.compose.components.basic

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.basic.WebViewComponent
import com.appcreator.compose.HtmlView
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import kotlinx.browser.window

@Composable
actual fun WebViewComposable(
    modifier: Modifier,
    component: WebViewComponent
) {

    if (LocalInPreview.current) {
        Box(Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            Text(
                modifier = Modifier.padding(horizontal = 12.dp),
                text = "WebView - use preview to load content\nSome content will not show in an iFrame but will load fine on Android and iOS\nif you are having issues test on a real device"
            )
        }
    } else {
        val envStore = LocalEnvStore.current
        component.url?.let {
            HtmlView(
                modifier = Modifier.fillMaxSize(),
                factory = {
                val iframe = window.document.createElement("iframe")
                iframe.setAttribute("src", envStore.injectVariables(it))
                iframe
            })
        }
    }

}