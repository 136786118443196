package com.appcreator.compose.components.basic

import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.sp
import com.appcreator.blueprint.components.basic.TextComponent
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.value
import com.mikepenz.markdown.compose.Markdown
import com.mikepenz.markdown.m3.markdownColor
import com.mikepenz.markdown.m3.markdownTypography

@Composable
fun TextComposable(modifier: Modifier, component: TextComponent) {
    val theme = LocalTheme.current
    val font = theme?.font(component.font)
    val color = theme?.color(component.textColor)
    component.value?.value()?.let {
        val style = TextStyle(
            fontSize = font?.fontSize?.sp ?: TextUnit.Unspecified,
            fontWeight = font?.fontWeight?.let { weight -> FontWeight(weight) },
        )
        val alignment = when(component.alignment) {
            TextComponent.Alignment.Start -> TextAlign.Start
            TextComponent.Alignment.Center -> TextAlign.Center
            TextComponent.Alignment.End -> TextAlign.End
            null -> null
        }


        when {
            component.markdown == true -> {
                Markdown(
                    modifier = modifier,
                    content = it,
                    colors = markdownColor(text = color?.composeColor() ?: MaterialTheme.colorScheme.onBackground),
                    typography = markdownTypography(paragraph = style)
                )
            }
            component.redacted == true -> {
                val composeColor = color?.composeColor() ?: Color.Gray
                Text(
                    modifier = modifier.drawWithContent {
                        drawRoundRect(composeColor, cornerRadius = CornerRadius(4f, 4f))
                    },
                    text = it,
                    style = style,
                    textAlign = alignment
                )
            }
            else -> {
                Text(
                    modifier = modifier,
                    text = it,
                    style = style,
                    color = color?.composeColor() ?: Color.Unspecified,
                    textAlign = alignment
                )
            }
        }
    }
}
