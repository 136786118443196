package com.appcreator.blueprint.components.navigation

import com.appcreator.blueprint.core.AllowedComponent
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.properties.FontReference
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.ListRange
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

// TODO I think this is for the bin
@Serializable
@SerialName("BottomNavigation")
@ComponentClass(
    group = "Navigation",
    title = "Bottom Navigation",
    description = "Add screens to navigate between",
    availableFrom = "0.0.1",
    accessible = false
)
data class BottomNavigationComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "Background Color",
        availableFrom = "0.0.1"
    )
    val color: ColorReference?,

    @InputProperty(
        section = "Label",
        title = "Selected Color",
        availableFrom = "0.0.1"
    )
    val selectedColor: ColorReference?,

    @InputProperty(
        section = "Label",
        title = "Unselected Color",
        availableFrom = "0.0.1"
    )
    val unselectedColor: ColorReference?,

    @InputProperty(
        section = "Label",
        title = "Size",
        availableFrom = "0.0.1"
    )
    val labelSize: FontReference?,

    @InputProperty(
        section = "Label",
        title = "Always Show",
        availableFrom = "0.0.1"
    )
    @GenericDefault("true")
    val alwaysShowLabels: Boolean?,

    @InputProperty(
        title = "Items",
        description = "Max 5 tabs",
        availableFrom = "0.0.1"
    )
    @ListRange(
        min = 1,
        max = 5
    )
    @AllowedComponent(NavigationItemComponent::class)
    val tabs: List<Component>
): Component