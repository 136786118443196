package com.appcreator.dto.configurations

import kotlinx.serialization.Serializable

@Serializable
data class FirebaseConfiguration(
    val androidNotificationsEnabled: Boolean,
    val iosNotificationsEnabled: Boolean,
    val inAppMessaging: Boolean,
    val analyticsEnabled: Boolean,
    val authenticationEnabled: Boolean,
    val firestoreEnabled: Boolean,
    val androidServiceJson: String?,
    val iosServicePlist: String?,
    val webConfig: FirebaseWebConfiguration
)

@Serializable
data class FirebaseWebConfiguration(
    val apiKey: String,
    val authDomain: String,
    val projectId: String,
    val storageBucket: String,
    val messagingSenderId: String,
    val appId: String,
    val measurementId: String,
)