package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.ScriptContent
import com.appcreator.blueprint.spec.inputs.ScriptContentInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.editor.local.LocalDataOptions
import com.appcreator.creatorapp.editor.panelcomponent.views.DataDropdown

@Composable
fun ScriptContentInput(spec: ScriptContentInputSpec, save: () -> Unit) {
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth().height(300.dp),
        label = "Script",
        value = spec.value?.script ?: "",
        onValueChange = {
            spec.value = ScriptContent(it)
            save()
        },
        trailingIcon = {
            DataDropdown(
                options = LocalDataOptions.current,
                filter = { true },
                onSelect = {
                    val insert = "{${it.value}}"
                    val current = spec.value?.script?: ""
                    spec.value = ScriptContent(if (current.isEmpty()) insert else "$current $insert")
                    save()
                }
            )
        }
    )
}
