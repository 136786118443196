package com.appcreator.blueprint.components

import com.appcreator.blueprint.components.CustomClientComponentBuilder
import com.appcreator.blueprint.components.CustomComponentBuilder
import com.appcreator.blueprint.components.ScreenBuilder
import com.appcreator.blueprint.components.`data`.CountdownComponentBuilder
import com.appcreator.blueprint.components.`data`.DataConversionComponentBuilder
import com.appcreator.blueprint.components.`data`.DataRepeaterComponentBuilder
import com.appcreator.blueprint.components.`data`.DatePartsComponentBuilder
import com.appcreator.blueprint.components.`data`.LoadingComponentBuilder
import com.appcreator.blueprint.components.`data`.LocalStateComponentBuilder
import com.appcreator.blueprint.components.`data`.PagedLoaderComponentBuilder
import com.appcreator.blueprint.components.basic.ButtonComponentBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.basic.ImageComponentBuilder
import com.appcreator.blueprint.components.basic.InputComponentBuilder
import com.appcreator.blueprint.components.basic.MaterialIconComponentBuilder
import com.appcreator.blueprint.components.basic.TextComponentBuilder
import com.appcreator.blueprint.components.logic.ABComponentBuilder
import com.appcreator.blueprint.components.logic.ConditionalComponentBuilder
import com.appcreator.blueprint.components.modal.DatePickerModalComponentBuilder
import com.appcreator.blueprint.components.modal.SheetComponentBuilder
import com.appcreator.blueprint.components.navigation.BottomNavigationComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationDrawerComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationItemComponentBuilder
import com.appcreator.blueprint.components.pager.TabbedPageComponentBuilder
import com.appcreator.blueprint.components.pager.TabbedPagerComponentBuilder
import com.appcreator.blueprint.spec.ComponentBuilderProvider
import com.appcreator.blueprint.spec.ComponentSpec
import kotlin.collections.Map

public fun componentOptions(): Map<ComponentSpec, ComponentBuilderProvider> = mapOf(
        ComponentSpec(
    group = "Other",
    name = "CustomComponent",
    title = "Custom",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { CustomComponentBuilder(null) },
        ComponentSpec(
    group = "Other",
    name = "CustomClientComponent",
    title = "Custom",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { CustomClientComponentBuilder(null) },
        ComponentSpec(
    group = "",
    name = "Screen",
    title = "Screen",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ScreenBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "ButtonComponent",
    title = "Button",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ButtonComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "ContainerComponent",
    title = "Container",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ContainerComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "ImageComponent",
    title = "Image",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ImageComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "InputComponent",
    title = "Input",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { InputComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "MaterialIconComponent",
    title = "Icon",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { MaterialIconComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "TextComponent",
    title = "Text",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { TextComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "CountdownComponent",
    title = "Countdown",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf("countdown.years",
    "countdown.months",
    "countdown.days",
    "countdown.hours",
    "countdown.minutes",
    "countdown.seconds"),
    producedFieldsEditable = false
) to ComponentBuilderProvider { CountdownComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "DataConversionComponent",
    title = "Data Transformer",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { DataConversionComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "DataRepeaterComponent",
    title = "Data Repeater",
    description = "Repeats child component base on data",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf("index"),
    producedFieldsEditable = false
) to ComponentBuilderProvider { DataRepeaterComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "DatePartsComponent",
    title = "DateParts",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf("date-parts.year",
    "date-parts.month-number",
    "date-parts.month-name",
    "date-parts.month-name-short",
    "date-parts.day-number",
    "date-parts.day-name",
    "date-parts.day-name-short",
    "date-parts.hour-24",
    "date-parts.minute",
    "date-parts.second"),
    producedFieldsEditable = false
) to ComponentBuilderProvider { DatePartsComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "LoadingComponent",
    title = "Loader",
    description = "Loads data",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { LoadingComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "LocalStateComponent",
    title = "Local State",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { LocalStateComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "PagedLoaderComponent",
    title = "Paged Loader",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { PagedLoaderComponentBuilder(null) },
        ComponentSpec(
    group = "Logic",
    name = "ABComponent",
    title = "A/B",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ABComponentBuilder(null) },
        ComponentSpec(
    group = "Logic",
    name = "ConditionalComponent",
    title = "Conditional",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ConditionalComponentBuilder(null) },
        ComponentSpec(
    group = "Modal",
    name = "DatePickerModalComponent",
    title = "Date Picker",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { DatePickerModalComponentBuilder(null) },
        ComponentSpec(
    group = "Modal",
    name = "SheetComponent",
    title = "Sheet",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { SheetComponentBuilder(null) },
        ComponentSpec(
    group = "Navigation",
    name = "BottomNavigationComponent",
    title = "Bottom Navigation",
    description = "Add screens to navigate between",
    availableFrom = "0.0.1",
    accessible = false,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { BottomNavigationComponentBuilder(null) },
        ComponentSpec(
    group = "Navigation",
    name = "NavigationComponent",
    title = "Navigation",
    description = "Add screens to navigate between",
    availableFrom = "0.0.1",
    accessible = false,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { NavigationComponentBuilder(null) },
        ComponentSpec(
    group = "Navigation",
    name = "NavigationDrawerComponent",
    title = "Navigation Drawer",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    producesDataFields = listOf("drawer-state"),
    producedFieldsEditable = true
) to ComponentBuilderProvider { NavigationDrawerComponentBuilder(null) },
        ComponentSpec(
    group = "Navigation",
    name = "NavigationItemComponent",
    title = "Tab Item",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { NavigationItemComponentBuilder(null) },
        ComponentSpec(
    group = "Pager",
    name = "TabbedPageComponent",
    title = "Tapped Page",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { TabbedPageComponentBuilder(null) },
        ComponentSpec(
    group = "Pager",
    name = "TabbedPagerComponent",
    title = "Tabbed Pager",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    producesDataFields = listOf("pager-selected-page"),
    producedFieldsEditable = true
) to ComponentBuilderProvider { TabbedPagerComponentBuilder(null) }
    )
