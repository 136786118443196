package com.appcreator.creatorapp.project

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.material.icons.filled.OpenInBrowser
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedCard
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.dto.OrganisationResponse
import com.appcreator.styles.ThemeMargins

@Composable
fun Checklist(
    org: OrganisationResponse,
    projectId: String,
    openSettingsOptions: (SettingsOptions) -> Unit,
    openEditor: () -> Unit
) {

    Column(modifier = Modifier.padding(ThemeMargins.LargeMargins).widthIn(max = 600.dp)) {

        LoadingComposable(loader = {
            it.getProject(org.organisation.id, projectId = projectId)
        }) { project ->

            val uriHandler = LocalUriHandler.current

            OutlinedCard(
                modifier = Modifier.padding(vertical = ThemeMargins.SmallMargin / 2),
                onClick = openEditor,
            ) {
                Row(
                    Modifier
                        .fillMaxWidth()
                        .padding(ThemeMargins.LargeMargins),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    Icon(Icons.Default.OpenInBrowser, contentDescription = null)
                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                    Text("Open Editor")
                }
            }
            Spacer(Modifier.height(ThemeMargins.LargeMargins))

            Column(
                verticalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin / 2),
                modifier = Modifier.verticalScroll(rememberScrollState())
            ) {
                project.checklist?.forEach {

                    val content: @Composable ColumnScope.() -> Unit = {
                        Row(
                            Modifier.padding(horizontal = ThemeMargins.MedMargins),
                            verticalAlignment = Alignment.CenterVertically
                        ) {

                            Column(modifier = Modifier.fillMaxWidth(0.6f)) {
                                Spacer(Modifier.height(ThemeMargins.MedMargins))
                                Text(
                                    text = it.title,
                                    style = MaterialTheme.typography.bodyLarge
                                )
                                Text(
                                    text = it.description,
                                    style = MaterialTheme.typography.bodySmall
                                )
                                it.links?.let {
                                    Row {
                                        it.forEach { link ->
                                            TextButton(onClick = {
                                                uriHandler.openUri(link.link)
                                            }) {
                                                Text(link.title)
                                            }
                                        }
                                    }
                                } ?: Spacer(Modifier.height(ThemeMargins.MedMargins))
                            }
                            Spacer(Modifier.weight(1f))
                            if (it.completed) {
                                Icon(
                                    imageVector = Icons.Default.Check,
                                    contentDescription = null
                                )
                            }
                        }
                    }
                    val colors = CardDefaults.outlinedCardColors(
                        containerColor = if (it.completed) Color(0x664BB543) else Color.Unspecified
                    )

                    it.settings?.let {
                        OutlinedCard(
                            modifier = Modifier.padding(vertical = ThemeMargins.SmallMargin / 2),
                            colors = colors,
                            onClick = {
                                openSettingsOptions(
                                    SettingsOptions.valueOf(it)
                                )
                            },
                            content = content
                        )
                    }?: OutlinedCard(
                        modifier = Modifier.padding(vertical = ThemeMargins.SmallMargin / 2),
                        colors = colors,
                        content = content
                    )

                } ?: Text("Checklist completed!!")
            }
        }
    }
}

