package com.appcreator.compose.conversions

import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.conversions.JavascriptConversion
import com.appcreator.blueprint.core.envRegex
import com.appcreator.compose.extensions.JSEvaluationConfig
import com.appcreator.compose.extensions.evaluateJavaScript
import com.appcreator.compose.extensions.evaluateJavaScriptSync
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement

data class JavascriptConvertor(
    val conversion: JavascriptConversion
): Convertor {
    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String? =
        createScript(envStore, jsonElement)?.let {
            evaluateJavaScriptSync(it)
        }

    override suspend fun convert(
        config: JSEvaluationConfig,
        envStore: EnvStore,
        jsonElement: JsonElement?
    ): String? {
        return createScript(envStore, jsonElement)?.let {
            evaluateJavaScript(config, it)
        }
    }

    private fun createScript(
        envStore: EnvStore,
        jsonElement: JsonElement?
    ): String? {
        with(conversion) {
            return script?.script?.let {
                var updated = it
                envRegex.findAll(it).toList().reversed().forEach { match ->
                    val key = match.value.trimStart('{').trimEnd('}')

                    val replaceWith = jsonValue(jsonElement, key)?.toString()
                        ?: (envStore.env[key] as? String)?.let { mapValue -> "\"$mapValue\"" }
                        ?: (envStore.env[key] as? List<Map<String, String>>)?.let { list ->
                            Json.encodeToString(
                                list
                            )
                        } // TODO make the conversion to json allow nested lists

                    replaceWith?.let { replacement ->
                        updated = updated.replaceRange(match.range, replacement)
                    } ?: run {
                        updated = updated.replaceRange(match.range, "")
                    }
                }
                updated
            }
        }
    }
}
