package com.appcreator.blueprint.conditions

import com.appcreator.blueprint.conditions.JavascriptConditionBuilder
import com.appcreator.blueprint.conditions.RemoteConfigConditionBuilder
import com.appcreator.blueprint.conditions.`data`.ContainsItemConditionBuilder
import com.appcreator.blueprint.conditions.`data`.DateConditionBuilder
import com.appcreator.blueprint.conditions.`data`.EqualsConditionBuilder
import com.appcreator.blueprint.conditions.`data`.NumberCompareConditionBuilder
import com.appcreator.blueprint.conditions.logic.AndConditionBuilder
import com.appcreator.blueprint.conditions.logic.NotConditionBuilder
import com.appcreator.blueprint.conditions.logic.OrConditionBuilder
import com.appcreator.blueprint.conditions.permission.CheckPermissionConditionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoggedInConditionBuilder
import com.appcreator.blueprint.spec.ConditionBuilderProvider
import com.appcreator.blueprint.spec.ConditionSpec
import kotlin.collections.Map

public fun conditionOptions(): Map<ConditionSpec, ConditionBuilderProvider> = mapOf(
        ConditionSpec(
    name = "JavascriptCondition",
    title = "Javascript",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ConditionBuilderProvider { JavascriptConditionBuilder(null) },
        ConditionSpec(
    name = "RemoteConfigCondition",
    title = "Remote config",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
) to ConditionBuilderProvider { RemoteConfigConditionBuilder(null) },
        ConditionSpec(
    name = "ContainsItemCondition",
    title = "Contains Item",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ConditionBuilderProvider { ContainsItemConditionBuilder(null) },
        ConditionSpec(
    name = "DateCondition",
    title = "Date Compare",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ConditionBuilderProvider { DateConditionBuilder(null) },
        ConditionSpec(
    name = "EqualsCondition",
    title = "Equals",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ConditionBuilderProvider { EqualsConditionBuilder(null) },
        ConditionSpec(
    name = "NumberCompareCondition",
    title = "Number Compare",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ConditionBuilderProvider { NumberCompareConditionBuilder(null) },
        ConditionSpec(
    name = "AndCondition",
    title = "And",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
) to ConditionBuilderProvider { AndConditionBuilder(null) },
        ConditionSpec(
    name = "NotCondition",
    title = "Not",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
) to ConditionBuilderProvider { NotConditionBuilder(null) },
        ConditionSpec(
    name = "OrCondition",
    title = "Or",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
) to ConditionBuilderProvider { OrConditionBuilder(null) },
        ConditionSpec(
    name = "CheckPermissionCondition",
    title = "Has Permission",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ConditionBuilderProvider { CheckPermissionConditionBuilder(null) },
        ConditionSpec(
    name = "FirebaseAuthLoggedInCondition",
    title = "Is logged in",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ConditionBuilderProvider { FirebaseAuthLoggedInConditionBuilder(null) }
    )
