package com.appcreator.blueprint.dataspecs

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.DataSpecBuilder
import com.appcreator.blueprint.spec.DataSpecSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.BooleanInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class ValueDataSpecBuilder(
  dataSpec: ValueDataSpec?,
  newId: Boolean = false,
) : DataSpecBuilder {
  override val spec: DataSpecSpec = DataSpecSpec(
          name = "ValueDataSpec",
          title = "Value",
          availableFrom = "0.0.1",
      )

  public val key: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Key",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(dataSpec?.key?: ""),
          
          
      )

  public val valueType: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "",
          title = "Value Type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf((dataSpec?.valueType?: ValueDataSpec.Type.String).name),
          
          options = ValueDataSpec.Type.entries.map { it.name },
      )

  public val `value`: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Preview Value",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(dataSpec?.value),
          
          
      )

  public val visible: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "",
          title = "Visible",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(dataSpec?.visible),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(key, valueType, value, visible)

  override fun build(): DataSpec = ValueDataSpec(key.value?: "", valueType.value?.let {
      ValueDataSpec.Type.valueOf(it) }?: ValueDataSpec.Type.String, value.value, visible.value)
}
