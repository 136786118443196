package com.appcreator.creatorapp.editor.centerdata.rest

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material.OutlinedTextField
import androidx.compose.material3.FilterChip
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.UrlSpec
import com.appcreator.blueprint.loaderspec.JsonLoaderSpec
import com.appcreator.styles.ThemeMargins

@Composable
fun RestBody(loaderSpec: JsonLoaderSpec, update: (JsonLoaderSpec) -> Unit) {
    Column(Modifier.fillMaxWidth()) {

        val options = UrlSpec.BodyType.entries
            .filter { it == UrlSpec.BodyType.Json || loaderSpec.urlSpec.verb == UrlSpec.Verb.Post }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.spacedBy(ThemeMargins.MedMargins, alignment = Alignment.CenterHorizontally)
        ) {

            options
                .forEach { type ->
                    FilterChip(
                        selected = loaderSpec.urlSpec.bodyType == type,
                        onClick = {
                            update(loaderSpec.copy(urlSpec = loaderSpec.urlSpec.copy(bodyType = type)))
                        },
                        label = {
                            Text(
                                text = type.name,
                            )
                        }
                    )
                }
        }

        OutlinedTextField(
            value = loaderSpec.urlSpec.body,
            onValueChange = {
                update(loaderSpec.copy(urlSpec = loaderSpec.urlSpec.copy(body = it)))
            },
            modifier = Modifier
                .fillMaxWidth()
                .weight(1f)
        )
    }
}
