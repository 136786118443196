package com.appcreator.blueprint.dataspecs

import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.dataspecs.ConversionDataSpec
import com.appcreator.blueprint.dataspecs.ConversionDataSpecBuilder
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ListDataSpecBuilder
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpecBuilder
import com.appcreator.blueprint.spec.DataSpecBuilder
import kotlin.Boolean

public fun DataSpec.toBuilder(newId: Boolean = false): DataSpecBuilder {
  val builder = when(this) {
      is ConversionDataSpec -> ConversionDataSpecBuilder(this, newId)
      is ListDataSpec -> ListDataSpecBuilder(this, newId)
      is ValueDataSpec -> ValueDataSpecBuilder(this, newId)
      else -> throw IllegalStateException("Trying to convert $this which has no mapping")
  }
  return builder
}
