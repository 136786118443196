package com.appcreator.creatorapp.project

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.RowScope
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Android
import androidx.compose.material.icons.filled.PhoneIphone
import androidx.compose.material.icons.filled.Settings
import androidx.compose.material.icons.filled.SystemUpdate
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import app_creator.app.generated.resources.logo_firebase
import com.appcreator.creatorapp.project.configurations.AndroidConfiguration
import com.appcreator.creatorapp.project.configurations.FirebaseConfiguration
import com.appcreator.creatorapp.project.configurations.IOSConfiguration
import com.appcreator.creatorapp.project.configurations.ProjectGeneralConfiguration
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import org.jetbrains.compose.resources.painterResource


enum class SettingsCategory(
    val title: String?
) {
    Overview(null),
    General("General Settings"),
    ThirdPart("Third Party Plugins")
}

enum class SettingsOptions(
    val category: SettingsCategory,
    val label:  @Composable RowScope.() -> Unit,
    val content: @Composable (OrganisationResponse, Project, (String) -> Unit) -> Unit
) {
    Releases(
        category = SettingsCategory.Overview,
        label = {
            Icon(Icons.Default.SystemUpdate, contentDescription = null)
            Text("Releases")
        },
        content = { org, project, openDownload ->
            ReleasesSection(org, project, openDownload)
        }
    ),
    Configuration(
        category = SettingsCategory.General,
        label = {
            Icon(Icons.Default.Settings, contentDescription = null)
            Text("Configuration")
        },
        content = { org, project, openDownload ->
            ProjectGeneralConfiguration(org, project, openDownload)
        }
    ),
    Android(
        category = SettingsCategory.General,
        label = {
            Icon(Icons.Default.Android, contentDescription = null)
            Text("Android")
        },
        content = { org, project, openDownload ->
            AndroidConfiguration(org, project, openDownload)
        }
    ),
    IOS(
        category = SettingsCategory.General,
        label = {
            Icon(Icons.Default.PhoneIphone, contentDescription = null)
            Text("IOS")
        },
        content = { org, project, _ ->
            IOSConfiguration(org, project)
        }
    ),
    Firebase(
        category = SettingsCategory.ThirdPart,
        label = {
            Image(
                painter = painterResource(app_creator.app.generated.resources.Res.drawable.logo_firebase),
                contentDescription = null
            )
        }, content = { org, project, _ ->
            FirebaseConfiguration(org, project)
        }
    )
}
