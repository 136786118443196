package com.appcreator.blueprint.actions

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class AlertActionBuilder(
  action: AlertAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "AlertAction",
          title = "Alert",
          description = "",
          availableFrom = "0.0.1",
          accessible = false,
      )

  public val title: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Title",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(action?.title),
          
          
      )

  public val message: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Message",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(action?.message),
          
          
      )

  public val button: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Button",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(action?.button),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(title, message, button)

  override fun build(): Action = AlertAction(title.value, message.value, button.value)
}
