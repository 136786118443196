package com.appcreator.compose.components.modal

import androidx.compose.foundation.clickable
import androidx.compose.material3.DatePicker
import androidx.compose.material3.DatePickerDialog
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.rememberDatePickerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.modal.DatePickerModalComponent
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.extensions.parseToMilliseconds
import kotlinx.datetime.Instant

@Composable
fun DataPickerModalComposable(modifier: Modifier, component: DatePickerModalComponent) {

    var showDialog by remember { mutableStateOf(false) }

    component.button?.let {
        ComponentComposable(
            modifier = modifier.clickable {
                showDialog = true
            },
            component = it
        )
    }

    val inPreview = LocalInPreview.current
    if(showDialog && component.date != null && !inPreview) {
        component.date?.let { datePath ->

            val envStore = LocalEnvStore.current
            val inputEnvStore = LocalInputEnvStore.current

            val dateString = envStore.injectVariables("{${datePath.value}}")
            val date = try {
                parseToMilliseconds(dateString)
            } catch (ex: Throwable) {
                null
            }
            val earliest = remember {
                    component.earliestAllowed?.let { parseToMilliseconds(envStore.injectVariables(it)) }
            }
            val latest = remember {
                    component.latestAllowed?.let { parseToMilliseconds(envStore.injectVariables(it)) }
            }

            val datePickerState = rememberDatePickerState(initialSelectedDateMillis = date)
            DatePickerDialog(
                onDismissRequest = { showDialog = false },
                confirmButton = {
                    TextButton(onClick = {
                        datePickerState.selectedDateMillis?.let {
                            val instant = Instant.fromEpochMilliseconds(it)
                            inputEnvStore.set(datePath.value, instant.toString())
                        }
                        showDialog = false
                    }) {
                        Text("Ok")
                    }
                },
                dismissButton = {
                    TextButton(onClick = { showDialog = false }) {
                        Text("Cancel")
                    }
                }
            ) {
                // TODO
                DatePicker(
                    state = datePickerState,
//                    dateValidator = {
//                        (earliest == null || it > earliest) && (latest == null || it < latest)
//                    }
                )
            }
        }
    }

}
