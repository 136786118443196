package com.appcreator.creatorapp.editor

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.Stroke
import com.appcreator.app.utils.onDragOver
import com.appcreator.blueprint.ScreenSpec
import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.compose.components.ComponentContentWrapper
import com.appcreator.compose.extensions.value
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.editor.local.LocalSelectedComponent
import com.appcreator.styles.ThemeMargins

interface DragHolder {
    val dragItem: DragItem?
    fun startDrag(item: DragItem)
    fun completeDrag(): Component?
}

class DragItem(
    val componentBuilder: ComponentBuilder,
    val render: @Composable () -> Unit
)

class HoverState(
    val hovering: MutableState<Boolean> = mutableStateOf(false),
    val childHoverCount: MutableState<Int> = mutableStateOf(0),
    val parent: HoverState? = null
) {

    fun addChildHovering() {
        childHoverCount.value += 1
        parent?.addChildHovering()
    }
    fun removeChildHovering() {
        childHoverCount.value = (childHoverCount.value - 1).coerceAtLeast(0)
        parent?.removeChildHovering()
    }

}

val LocalParentHoverState = staticCompositionLocalOf {
    HoverState()
}

val LocalHighlightSelected = staticCompositionLocalOf {
    mutableStateOf(false)
}

class HoverAndDragManager(
    private val blueprintManager: BlueprintManager
): ComponentContentWrapper, DragHolder {

    var hoveredPreviewNode: Component? by mutableStateOf(null)

    override var dragItem: DragItem? by mutableStateOf(null)
        private set

    @Composable
    override fun Wrap(modifier: Modifier, component: Component, content: @Composable (Modifier) -> Unit) {

        var showLinked by remember { mutableStateOf(false) }
        LaunchedEffect(Unit) {
            // Doing this in a funny way to support crossfade animation, so show linked becomes stale by design
            showLinked = component is ScreenSpec && blueprintManager.destination.screen.path != component.path
        }

        if (showLinked && component is ScreenSpec) {
            LinkedScreen(component, blueprintManager)
            return
        }

        val parent = LocalParentHoverState.current
        val hovering = remember { HoverState(parent = parent) }
        val canReceive by remember { derivedStateOf { component.canReceive(dragItem?.componentBuilder) } }
        val visible by remember { derivedStateOf {
            hovering.hovering.value && hovering.childHoverCount.value == 0 && (canReceive || dragItem == null)
        } }
        val alpha by animateFloatAsState(if(visible) 0.3f else 0f)

        LaunchedEffect(visible) {
            if(visible) {
                hoveredPreviewNode = component
            } else if(hoveredPreviewNode == component) {
                hoveredPreviewNode = null
            }
        }

        val selectedNode = LocalSelectedComponent.current
        val shouldHighlight = LocalHighlightSelected.current
        val highlight = shouldHighlight.value && selectedNode.value?._nodeId?.value == component._nodeId

        val mod = modifier
            .onDragOver {
                hovering.hovering.value = it
                if(it) {
                    parent.addChildHovering()
                } else {
                    parent.removeChildHovering()
                }
            }
            .drawWithContent {
                drawContent()
                if (highlight) {
                    drawRect(Color.Green.copy(alpha = 0.3f))
                }

                drawRect(Color.Black.copy(alpha = alpha))

                if(canReceive) {
                    drawRect(
                        color = Color(0xFF636363), style = Stroke(width = 1f)
                    )
                }
            }

        CompositionLocalProvider(LocalParentHoverState provides hovering) {
            content(mod)
        }
    }

    override fun startDrag(item: DragItem) {
        dragItem = item
    }

    override fun completeDrag(): Component? {
        dragItem = null
        return hoveredPreviewNode.also {
            hoveredPreviewNode = null
        }
    }

}

private fun Component.canReceive(builder: ComponentBuilder?): Boolean {
    // TODO Drop logic
    return builder != null && this is ContainerComponent
}

@Composable
private fun LinkedScreen(screenSpec: ScreenSpec, blueprintManager: BlueprintManager) {
    val color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.1f)
    Box(
        Modifier.fillMaxSize().drawWithCache {
            onDrawBehind {
                val step = 25f
                val stepInt = step.toInt()
                val pathEffect = PathEffect.dashPathEffect(floatArrayOf(step, step), 0f)
                for (i in 0..size.height.toInt() step stepInt * 2) {
                    drawLine(
                        color = color,
                        start = Offset(0f, i.toFloat()),
                        end = Offset(size.width, i.toFloat()),
                        pathEffect = pathEffect,
                        strokeWidth = step
                    )
                }
                val pathEffectOds = PathEffect.dashPathEffect(floatArrayOf(step, step), step)
                for (i in stepInt..size.height.toInt() step stepInt * 2) {
                    drawLine(
                        color = color,
                        start = Offset(0f, i.toFloat()),
                        end = Offset(size.width, i.toFloat()),
                        pathEffect = pathEffectOds,
                        strokeWidth = step
                    )
                }
            }
        },
        contentAlignment = Alignment.Center
    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Text(
                text = "Nested screen ${screenSpec.title?.value()}",
                style = MaterialTheme.typography.headlineMedium
            )
            Spacer(Modifier.width(ThemeMargins.SmallMargin))
            TextButton(onClick = {
                blueprintManager.selectScreen(screenSpec)
            }) {
                Icon(Icons.Default.Edit, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Edit")
            }
        }
    }
}