package com.appcreator.creatorapp.editor.panelcomponenttree

import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AddCircle
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.ArrowDropUp
import androidx.compose.material.icons.filled.DragHandle
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.unit.dp
import com.appcreator.app.utils.onDrag
import com.appcreator.app.utils.onDragOver
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentListInputSpec
import com.appcreator.blueprint.spec.mergedInputSpec
import com.appcreator.components.layouts.ScrollBars
import com.appcreator.components.layouts.ScrollbarHorizontal
import com.appcreator.creatorapp.editor.BlueprintManager
import com.appcreator.creatorapp.editor.DragItem
import com.appcreator.creatorapp.editor.HoverAndDragManager
import com.appcreator.creatorapp.editor.LocalDrag
import com.appcreator.creatorapp.editor.local.LocalNodeExplorer
import com.appcreator.creatorapp.editor.local.LocalSelectedComponent
import com.appcreator.creatorapp.editor.utils.displayLabel
import com.appcreator.styles.ThemeMargins

@Composable
fun ComponentTreePanel(
    hoverAndDragManager: HoverAndDragManager,
    addNode: (ComponentBuilder, Component, Boolean) -> Unit,
    deleteComponent: (ComponentBuilder, ComponentBuilder) -> Unit,
    manager: BlueprintManager
) {
    Box(modifier = Modifier.fillMaxHeight().width(400.dp).padding(ThemeMargins.SmallMargin)) {
        val vertical = rememberScrollState()
        val horizontal = rememberScrollState()

        val expandedMap = remember { mutableStateMapOf<String, Boolean>() }
        val nodeExplorer = LocalNodeExplorer.current

        Column(Modifier.verticalScroll(vertical).horizontalScroll(horizontal)) {
            manager.screenBuilder?.let { screen ->
                ComponentTreeItem(
                    hoverAndDragManager = hoverAndDragManager,
                    addNode = { componentBuilder, component ->
                        val parent = nodeExplorer.parent(componentBuilder)
                        parent?.let {
                            deleteComponent(componentBuilder, parent)
                        }
                        addNode(componentBuilder, component, true)
                    },
                    root = screen,
                    expandedMap = expandedMap
                )
            }
        }

        ScrollbarHorizontal(horizontal)
        ScrollBars(vertical)
    }

}

@Composable
private fun ComponentTreeItem(
    hoverAndDragManager: HoverAndDragManager,
    addNode: (ComponentBuilder, Component) -> Unit,
    root: ComponentBuilder,
    expandedMap: MutableMap<String, Boolean>
) {

    val children = root.mergedInputSpec()
        .filter { it.properties.visible() }
        .mapNotNull {
        when (it) {
            is ComponentInputSpec -> {
                it.value?.let { listOf(it) }
            }
            is ComponentListInputSpec -> {
                it.value
            }
            else -> null
        }
    }.flatten()

    val dragHolder = LocalDrag.current
    var isDragging by remember { mutableStateOf(false) }
    ComponentTreeInternal(
        modifier = Modifier.onDrag {
            isDragging = it
            if (it) {
                dragHolder.startDrag(
                    DragItem(root.build().toBuilder(true)) {
                        Column {
                            ComponentTreeInternal(
                                hoverAndDragManager = hoverAndDragManager,
                                addNode = addNode,
                                root = root,
                                children = children,
                                expandedMap = expandedMap
                            )
                        }
                    }
                )
            } else {
                dragHolder.completeDrag()?.let { targetComponent ->
                    addNode(root, targetComponent)
                }
            }
        },
        hoverAndDragManager = hoverAndDragManager,
        addNode = addNode,
        contentAlpha = if(isDragging) 0f else 1f,
        root = root,
        children = children,
        expandedMap = expandedMap
    )

}

@Composable
private fun ComponentTreeInternal(
    modifier: Modifier = Modifier,
    hoverAndDragManager: HoverAndDragManager,
    addNode: (ComponentBuilder, Component) -> Unit,
    contentAlpha: Float = 1f,
    root: ComponentBuilder,
    children: List<ComponentBuilder>,
    expandedMap: MutableMap<String, Boolean>
) {

    val showChildren = expandedMap[root._nodeId.value ?: ""] != false
    var selected by LocalSelectedComponent.current

    Row(
        modifier = Modifier.alpha(contentAlpha),
        verticalAlignment = Alignment.CenterVertically
    ) {

        val dragging = LocalDrag.current.dragItem
        val dropTarget = dragging != null && dragging.componentBuilder != root && root is ContainerComponentBuilder

        Box(modifier) {
            if(dropTarget) {
                Icon(Icons.Default.AddCircle, contentDescription = null)
            } else {
                Icon(Icons.Default.DragHandle, contentDescription = null)
            }
        }


        var draqOver by remember { mutableStateOf(false) }
        TextButton(
            modifier = Modifier.onDragOver {
                if (dropTarget) {
                    if(it) {
                        hoverAndDragManager.hoveredPreviewNode = root.build()
                    } else if (hoverAndDragManager.hoveredPreviewNode?._nodeId == root._nodeId.value) {
                        hoverAndDragManager.hoveredPreviewNode = null
                    }
                }
                draqOver = it && dropTarget
            },
            enabled = selected != root,
            colors = ButtonDefaults.textButtonColors(
                disabledContainerColor = if(draqOver && dropTarget) MaterialTheme.colorScheme.secondary else MaterialTheme.colorScheme.tertiaryContainer,
                disabledContentColor = MaterialTheme.colorScheme.onTertiaryContainer,
                containerColor = when {
                    draqOver && dropTarget -> MaterialTheme.colorScheme.secondary
                    dropTarget -> Color.LightGray
                    else -> Color.Transparent
                }
            ),
            onClick = {
                selected = root
            }
        ) {
            Text(root.displayLabel())
        }

        if (children.isNotEmpty()) {
            IconButton(
                onClick = {
                    expandedMap[root._nodeId.value ?: ""] = !showChildren
                }) {
                Icon(
                    if (showChildren) Icons.Default.ArrowDropUp else Icons.Default.ArrowDropDown,
                    contentDescription = null
                )
            }
        }
    }

    if (showChildren) {
        val pathEffect = PathEffect.dashPathEffect(floatArrayOf(3f, 3f), 0f)
        val lineColor = MaterialTheme.colorScheme.tertiary
        children.forEachIndexed { index, componentBuilder ->
            Row(
                modifier = Modifier.drawWithCache {
                    onDrawBehind {
                        if (selected == root) {
                            val horizontalInset = 14.dp.toPx()
                            val verticalInset = 24.dp.toPx()
                            val height = if (index < children.lastIndex) size.height else verticalInset
                            drawLine(
                                color = lineColor,
                                start = Offset(horizontalInset, 0f),
                                end = Offset(horizontalInset, height),
                                pathEffect = pathEffect
                            )

                            drawLine(
                                color = lineColor,
                                start = Offset(horizontalInset, verticalInset),
                                end = Offset(35.dp.toPx(), verticalInset),
                                pathEffect = pathEffect
                            )
                        }
                    }
                }
            ) {
                Column(modifier = Modifier.padding(start = 35.dp).alpha(contentAlpha)) {
                    ComponentTreeItem(hoverAndDragManager, addNode, componentBuilder, expandedMap)
                }
            }
        }
    }
}
