package com.appcreator.creatorapp.editor.local

import androidx.compose.runtime.staticCompositionLocalOf
import com.appcreator.blueprint.core.DataSpec

class DataOption(
    val title: String,
    val settable: Boolean,
    val specs: List<DataSpec>,
)

val LocalDataOptions = staticCompositionLocalOf<List<DataOption>> {
    error("No instance of DataOptions provided")
}
