package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class Project(
    val id: String,
    val blueprintId: String,
//    val token: String,
    val appIcon: IconResponse?,
    val projectConfig: ProjectConfig,
    val showChecklist: Boolean,
    val checklist: List<ChecklistItem>?
)

@Serializable
data class ProjectConfig(
    val name: String,
    val appName: String
)

@Serializable
data class ChecklistItem(
    val tag: String,
    val title: String,
    val description: String,
    val completed: Boolean,
    val settings: String?,
    val links: List<ChecklistLink>?
)

@Serializable
data class ChecklistLink(
    val title: String,
    val link: String
)