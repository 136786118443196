package com.appcreator.creatorapp.editor.utils

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.spec.ComponentBuilder

@Composable
fun ComponentBuilder.displayLabel(): String =
    if (_nodeLabel.value.isNullOrBlank()) {
        spec.title
    } else {
        _nodeLabel.value ?: spec.title
    }

fun ComponentBuilder.displayLabel2(): String =
    if (_nodeLabel.value.isNullOrBlank()) {
        spec.title
    } else {
        _nodeLabel.value ?: spec.title
    }