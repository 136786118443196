package com.appcreator.blueprint.components

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.SizeValueInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import com.appcreator.blueprint.spec.inputs.UserDefinedComponentInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class CustomComponentBuilder(
  component: CustomComponent?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "Other",
          name = "CustomComponent",
          title = "Custom",
          description = "",
          availableFrom = "0.0.1",
          accessible = false,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  public val previewStyle: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Preview",
          title = "Style",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf((component?.previewStyle?:
          CustomComponent.PreviewStyle.Size).name),
          
          options = CustomComponent.PreviewStyle.entries.map { it.name },
      )

  public val width: SizeValueInputSpec = SizeValueInputSpec(
          properties = InputProperties(
          section = "Preview",
          title = "Width",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && previewStyle.value == CustomComponent.PreviewStyle.Size.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.width?:
          com.appcreator.blueprint.core.properties.SizeValue.Percent(100)),
          
          
      )

  public val height: SizeValueInputSpec = SizeValueInputSpec(
          properties = InputProperties(
          section = "Preview",
          title = "Height",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && previewStyle.value == CustomComponent.PreviewStyle.Size.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.height?:
          com.appcreator.blueprint.core.properties.SizeValue.Px(400)),
          
          
      )

  public val previewComponent: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Preview",
          title = "Component",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && previewStyle.value == CustomComponent.PreviewStyle.Component.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.previewComponent?.toBuilder(newId)),
          
          
      )

  public val userDefinedComponent: UserDefinedComponentInputSpec = UserDefinedComponentInputSpec(
          properties = InputProperties(
          section = "Component",
          title = "Type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.userDefinedComponent),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, previewStyle, width,
      height, previewComponent, userDefinedComponent)

  override fun build(): Component = CustomComponent(_nodeId.value?: Uuid.random().toString(),
      _nodeLabel.value, previewStyle.value?.let { CustomComponent.PreviewStyle.valueOf(it) }?:
      CustomComponent.PreviewStyle.Size, width.value?:
      com.appcreator.blueprint.core.properties.SizeValue.Percent(100), height.value?:
      com.appcreator.blueprint.core.properties.SizeValue.Px(400), previewComponent.value?.build(),
      userDefinedComponent.value)
}
