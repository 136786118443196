package com.appcreator.blueprint.dataspecs

import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.DataSpecClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.StringDefault
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("List")
@DataSpecClass(
    title = "List",
    availableFrom = "0.0.1",
)
data class ListDataSpec(
    @InputProperty(
        title = "Key",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    @StringDefault("")
    override val key: String,

    @InputProperty(
        title = "Items",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    @StringDefault("")
    val items: List<DataSpec>
): DataSpec