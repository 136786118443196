package com.appcreator.blueprint.actions

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Sequence")
@ActionClass(
    group = "",
    title = "Sequence",
    availableFrom = "0.0.1",
    accessible = false
)
class SequenceAction(
    @InputProperty(
        title = "Actions",
        availableFrom = "0.0.1"
    )
    val actions: List<Action>,
): Action
