package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class Project(
    val id: String,
    val blueprintId: String,
//    val token: String,
    val appIcon: IconResponse?,
    val projectConfig: ProjectConfig
)

@Serializable
data class ProjectConfig(
    val name: String,
    val appName: String
)
