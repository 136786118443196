package com.appcreator.creatorapp.editor.inputs

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput

@Composable
fun StringInput(spec: StringInputSpec, save: () -> Unit) {
    DataSourceTextInput(
        label = spec.properties.title,
        value = spec.value ?: "",
        allowDataItems = spec.properties.allowDataFields,
        update = {
            spec.value = it
            save()
        }
    )
}