package com.appcreator.blueprint.actions.navigation

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("OpenScreen")
@ActionClass(
    group = "Navigation",
    title = "Open Screen",
    availableFrom = "0.0.1"
)
class OpenScreenAction(
    @InputProperty(
        title = "Type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("OpenScreenAction.OpenType.Standard")
    val openType: OpenType?,
    @InputProperty(
        title = "Link",
        availableFrom = "0.0.1"
    )
    val link: BlueprintLink?,
): Action {

    enum class OpenType {
        Standard,
        Sheet,
        Tab
    }

}
