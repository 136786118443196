package com.appcreator.compose.components.modal

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.navigationBarsPadding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.BottomSheetDefaults
import androidx.compose.material3.BottomSheetDefaults.SheetMaxWidth
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.SheetState
import androidx.compose.material3.Surface
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.basic.ButtonComponent
import com.appcreator.blueprint.components.modal.SheetComponent
import com.appcreator.compose.AbsoluteSize
import com.appcreator.compose.LocalAbsoluteSize
import com.appcreator.compose.LocalDrawOnRoot
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.LocalNavigator
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.LocalTriggerBus
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.basic.ButtonComposableInternal
import com.appcreator.compose.extensions.composeColor
import kotlinx.coroutines.launch

@Composable
fun SheetComposable(modifier: Modifier, component: SheetComponent) {

    var showSheet by remember { mutableStateOf(false) }
    val inPreview = LocalInPreview.current

    component.button?.let {
        if (it is ButtonComponent) {
            ButtonComposableInternal(modifier, it) {
                if(!inPreview) {
                    showSheet = true
                }
            }
        } else {
            ComponentComposable(
                modifier = modifier.clickable {
                    if(!inPreview) {
                        showSheet = true
                    }
                },
                component = it
            )
        }
    }

    if (inPreview && component.showInPreview == true) {
        PreviewSheet(modifier, component)
    } else {

        component.triggerId?.let {
            val triggerBus = LocalTriggerBus.current
            DisposableEffect(it) {
                triggerBus.addListener(it) { showSheet = true }
                onDispose { triggerBus.removeListener(it) }
            }
        }

        if (showSheet || (inPreview && component.showInPreview == true)) {
            val sheetState = rememberModalBottomSheetState(skipPartiallyExpanded = true)
            val color = LocalTheme.current?.color(component.sheetColor)?.composeColor()
                ?: BottomSheetDefaults.ContainerColor
            val scope = rememberCoroutineScope()
            SheetComponentContent(
                sheetState = sheetState,
                color = color,
                size = component.size,
                dismiss = {
                    showSheet = false
                }
            ) {
                component.content?.let {
                    ComponentComposable(Modifier, it)
                }
            }
            val navigator = LocalNavigator.current
            DisposableEffect(Unit) {
                navigator?.registerModal {
                    scope.launch {
                        sheetState.hide()
                        showSheet = false
                    }
                }
                onDispose {
                    navigator?.unregisterModal()
                }
            }
        }
    }
}

@Composable
internal fun SheetComponentContent(
    sheetState: SheetState,
    color: Color,
    size: SheetComponent.Size?,
    dismiss: () -> Unit,
    content: @Composable () -> Unit
) {
    ModalBottomSheet(
        tonalElevation = 0.dp,
        onDismissRequest = dismiss,
        containerColor = color,
        sheetState = sheetState,
        content = {
            val size = when(size) {
                SheetComponent.Size.Full -> Modifier.fillMaxHeight(0.95f)
                SheetComponent.Size.Half -> Modifier.fillMaxHeight(0.5f)
                else -> Modifier
            }
            BoxWithConstraints(modifier = size.navigationBarsPadding()) {
                CompositionLocalProvider(
                    LocalAbsoluteSize provides AbsoluteSize(this)
                ) {
                    content()
                }
            }
        }
    )
}

@Composable
internal fun PreviewSheet(modifier: Modifier, component: SheetComponent) {
    val drawOnRoot = LocalDrawOnRoot.current
    DisposableEffect(component) {
        drawOnRoot.value = {
            val size = when(component.size) {
                SheetComponent.Size.Full -> Modifier.fillMaxHeight(0.95f)
                SheetComponent.Size.Half -> Modifier.fillMaxHeight(0.5f)
                else -> Modifier
            }

            Surface(modifier.align(Alignment.BottomCenter).sizeIn(maxWidth = SheetMaxWidth), shape = BottomSheetDefaults.ExpandedShape) {
                Column(size) {
                    BottomSheetDefaults.DragHandle(Modifier.align(Alignment.CenterHorizontally))
                    component.content?.let {
                        ComponentComposable(Modifier, it)
                    }
                }
            }

        }
        onDispose {
            drawOnRoot.value = null
        }
    }


}