package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.snapshots.SnapshotStateList
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ListInputSpec

class ComponentListInputSpec(
    properties: InputProperties,
    override val allowedComponents: List<String> = emptyList(),
    value: SnapshotStateList<ComponentBuilder>
): ListInputSpec<ComponentBuilder>(properties, value), ComponentInput