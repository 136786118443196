package com.appcreator.blueprint.actions

import com.appcreator.blueprint.actions.AlertActionBuilder
import com.appcreator.blueprint.actions.ConditionActionBuilder
import com.appcreator.blueprint.actions.SequenceActionBuilder
import com.appcreator.blueprint.actions.TriggerActionBuilder
import com.appcreator.blueprint.actions.`data`.AddListItemActionBuilder
import com.appcreator.blueprint.actions.`data`.ApiRequestActionBuilder
import com.appcreator.blueprint.actions.`data`.DataConversionActionBuilder
import com.appcreator.blueprint.actions.`data`.RemoveListItemActionBuilder
import com.appcreator.blueprint.actions.`data`.SetValueActionBuilder
import com.appcreator.blueprint.actions.navigation.CloseScreenActionBuilder
import com.appcreator.blueprint.actions.navigation.OpenScreenActionBuilder
import com.appcreator.blueprint.actions.permission.PermissionRequestActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthDeleteUserActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoginActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLogoutActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthResetPasswordActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSignUpActionBuilder
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreSetDataActionBuilder
import com.appcreator.blueprint.spec.ActionBuilderProvider
import com.appcreator.blueprint.spec.ActionSpec
import kotlin.collections.Map

public fun actionOptions(): Map<ActionSpec, ActionBuilderProvider> = mapOf(
        ActionSpec(
    name = "AlertAction",
    title = "Alert",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
) to ActionBuilderProvider { AlertActionBuilder(null) },
        ActionSpec(
    name = "ConditionAction",
    title = "Condition",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { ConditionActionBuilder(null) },
        ActionSpec(
    name = "SequenceAction",
    title = "Sequence",
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
) to ActionBuilderProvider { SequenceActionBuilder(null) },
        ActionSpec(
    name = "TriggerAction",
    title = "Trigger",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { TriggerActionBuilder(null) },
        ActionSpec(
    name = "AddListItemAction",
    title = "Add List Item",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { AddListItemActionBuilder(null) },
        ActionSpec(
    name = "ApiRequestAction",
    title = "Api Request",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { ApiRequestActionBuilder(null) },
        ActionSpec(
    name = "DataConversionAction",
    title = "Data Conversion",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { DataConversionActionBuilder(null) },
        ActionSpec(
    name = "RemoveListItemAction",
    title = "Remove List Item",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { RemoveListItemActionBuilder(null) },
        ActionSpec(
    name = "SetValueAction",
    title = "Set Value",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { SetValueActionBuilder(null) },
        ActionSpec(
    name = "CloseScreenAction",
    title = "Close Screen",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { CloseScreenActionBuilder(null) },
        ActionSpec(
    name = "OpenScreenAction",
    title = "Open Screen",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { OpenScreenActionBuilder(null) },
        ActionSpec(
    name = "PermissionRequestAction",
    title = "Request Permission",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { PermissionRequestActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthDeleteUserAction",
    title = "Delete User",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { FirebaseAuthDeleteUserActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthLoginAction",
    title = "Login",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { FirebaseAuthLoginActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthLogoutAction",
    title = "Logout",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { FirebaseAuthLogoutActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthResetPasswordAction",
    title = "Reset Password",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { FirebaseAuthResetPasswordActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthSignUpAction",
    title = "Sign Up",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { FirebaseAuthSignUpActionBuilder(null) },
        ActionSpec(
    name = "FirebaseFirestoreSetDataAction",
    title = "Set Data",
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
) to ActionBuilderProvider { FirebaseFirestoreSetDataActionBuilder(null) }
    )
