package com.appcreator.creatorapp.editor.newscreen

import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.Dropdown
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.creatorapp.editor.BlueprintManager
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.SnackbarInfo
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch

@Composable
fun ScreenPicker(
    manager: BlueprintManager
) {
    val screenTitle = manager.screenBuilder?.path?.value?.value ?: ""
    NewScreenModal(
        anchor = { openNewScreen ->
            Dropdown(
                anchor = {
                    TextButton(
                        onClick = { it() }
                    ) {
                        Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                        Spacer(Modifier.width(ThemeMargins.SmallMargin))
                        Text("Screen: $screenTitle", style = MaterialTheme.typography.titleSmall)
                    }
                },
                content = { close ->
                    manager.blueprint.screens.forEach {
                        DropdownItem(it.path) {
                            manager.selectScreen(it)
                            close()
                        }
                    }
                    HorizontalDivider()
                    DropdownItem("Add Screen") {
                        close()
                        openNewScreen()
                    }
                }
            )
        },
        save = {
            manager.addScreen(it)
        }
    )
}

@Composable
private fun NewScreenModal(
    anchor: @Composable (() -> Unit) -> Unit,
    save: suspend (String) -> Unit,
) {
    var title by remember { mutableStateOf("") }

    ModalScaffold(
        size = ModalSize.Small,
        anchor = anchor,
        header = {
            Text("Add screen")
        },
        body = {
            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Name",
                value = title,
                onValueChange = {
                    title = it
                }
            )
        },
        footer = { close ->

            val scope = rememberCoroutineScope()
            val snackbarChannel = LocalSnackbar.current
            TextButton(
                enabled = title.isNotEmpty(),
                onClick = {
                    scope.launch {
                        try {
                            save(title)
                            close()
                        } catch (ex: Exception) {
                            ex.printStackTrace()
                            snackbarChannel.send(SnackbarInfo.Builder.fromNetworkException(ex))
                        }
                    }
                }) {
                Icon(Icons.Default.Add, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Add")
            }
        }
    )
}
