package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.core.properties.SizeValue

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Image")
@ComponentClass(
    group = "Basic",
    title = "Image",
    availableFrom = "0.0.1"
)
data class ImageComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "URL",
        availableFrom = "0.0.1"
    )
    val url: LocalizableString?,

    @InputProperty(
        section = "Layout",
        title = "Width",
        availableFrom = "0.0.1"
    )
    val width: SizeValue?,
    @InputProperty(
        section = "Layout",
        title = "Height",
        availableFrom = "0.0.1"
    )
    val height: SizeValue?,

    @InputProperty(
        section = "Progress",
        title = "Loading",
        availableFrom = "0.0.1"
    )
    val loading: Component?,

    @InputProperty(
        section = "Progress",
        title = "Fallback",
        availableFrom = "0.0.1"
    )
    val fallback: Component?

): Component