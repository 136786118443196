package com.appcreator.dto.configurations

import kotlinx.serialization.Serializable

@Serializable
data class ProjectAdminConfiguration(
    val isTemplate: Boolean,
    val templateConfiguration: ProjectTemplate?
)

@Serializable
data class ProjectTemplate(
    val enabled: Boolean,
    val id: String,
    val group: String,
    val name: String,
    val description: String,
    val image: String
)
