package com.appcreator.blueprint.conditions.`data`

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ConditionBuilder
import com.appcreator.blueprint.spec.ConditionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.DataPathInputSpec
import com.appcreator.blueprint.spec.inputs.DataValueSetInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class ContainsItemConditionBuilder(
  condition: ContainsItemCondition?,
  newId: Boolean = false,
) : ConditionBuilder {
  override val spec: ConditionSpec = ConditionSpec(
          name = "ContainsItemCondition",
          title = "Contains Item",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
      )

  public val listPath: DataPathInputSpec = DataPathInputSpec(
          properties = InputProperties(
          section = "",
          title = "List",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = true,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(condition?.listPath),
          
          
      )

  public val values: DataValueSetInputSpec = DataValueSetInputSpec(
          properties = InputProperties(
          section = "",
          title = "Where equals",
          description = "Leave value blank to match any",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { listOfNotNull(listPath.value?.value) },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(condition?.values),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(listPath, values)

  override fun build(): Condition = ContainsItemCondition(listPath.value, values.value)
}
