package com.appcreator.firebase

object FirebaseAuthUI {
    val EmailAuthProviderID = "password"
    val PhoneAuthProviderID = "phone"
    val GoogleAuthProviderID = "google.com"
    val FacebookAuthProviderID = "facebook.com"
    val TwitterAuthProviderID = "twitter.com"
    val GitHubAuthProviderID = "github.com"
    val AppleAuthProviderID = "apple.com"
    val YahooAuthProviderID = "yahoo.com"
    val MicrosoftAuthProviderID = "hotmail.com"
}

object FirebaseEmailAuthMethod {
    val EmailPasswordSignIn = "password"
    val EmailLinkSignIn = "emailLink"
}