package com.appcreator.creatorapp.project

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.ArrowDropUp
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.components.layouts.ScrollBars
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.dto.ReleaseResponse
import com.appcreator.local.LocalDateTimeFormatter
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.SnackbarInfo
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch

@Composable
fun ReleasesSection(
    organisationResponse: OrganisationResponse,
    project: Project,
    openDownload: (String) -> Unit
) {

    LoadingComposable(loader = {
        mutableStateListOf(*it.getReleases(organisationResponse.organisation.id, project.id).releases.toTypedArray())
    }) { releases ->
        Box {
            val state = rememberScrollState()
            Column(Modifier.padding(horizontal = ThemeMargins.LargeMargins).verticalScroll(state)) {

                Spacer(Modifier.height(ThemeMargins.LargeMargins))

                if (releases.isEmpty()) {

                    Card {
                        Column(Modifier.padding(ThemeMargins.SmallMargin).fillMaxWidth()) {
                            Text(
                                "Creating a release of your app generates the custom app files so you can test on device. iOS app will be automatically uploaded to Testflight and Android will create an App Bundle file ready to upload to the PlayStore. Make sure the Android and iOS configuration has been set up. ",
                                style = MaterialTheme.typography.bodyMedium
                            )
                            Spacer(Modifier.height(ThemeMargins.SmallMargin))
                            ReleaseModal(
                                organisation = organisationResponse.organisation,
                                project = project,
                                lastRelease = null,
                                anchor = {
                                    Button(onClick = it) {
                                        Text("Create Release")
                                    }
                                },
                                onCreate = {
                                    releases.add(it)
                                }
                            )
                        }
                    }
                } else {

                    val inProgressBuild = releases.firstOrNull()?.status?.inProgressStatus() == true
                    val apiClient = LocalApiClient.current
                    val scope = rememberCoroutineScope()
                    Column(Modifier.fillMaxWidth()) {

                        Surface {

                            Row(
                                modifier = Modifier.fillMaxWidth().padding(ThemeMargins.SmallMargin)
                            ) {
                                Text(
                                    modifier = Modifier.weight(1f),
                                    text = "Version",
                                    style = MaterialTheme.typography.labelLarge
                                )
                                Text(
                                    modifier = Modifier.weight(1f),
                                    text = "Status",
                                    style = MaterialTheme.typography.labelLarge
                                )
                                Text(
                                    modifier = Modifier.weight(2f),
                                    text = "Updated",
                                    style = MaterialTheme.typography.labelLarge
                                )
                                Spacer(modifier = Modifier.width(44.dp))
                            }
                        }

                        var expanded by remember { mutableStateOf(releases.firstOrNull()) }
                        releases.forEach {
                            val selected by remember(it) { derivedStateOf { expanded == it } }
                            Row(
                                modifier = Modifier.padding(horizontal = ThemeMargins.SmallMargin),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    modifier = Modifier.weight(1f),
                                    text = it.version,
                                    style = MaterialTheme.typography.bodyMedium
                                )
                                Status(
                                    modifier = Modifier.weight(1f),
                                    releaseResponse = it,
                                    onCompleted = {
                                        scope.launch {
                                            try {
                                                val refreshed = apiClient.getReleases(
                                                    organisationResponse.organisation.id,
                                                    project.id
                                                )
                                                releases.clear()
                                                releases.addAll(refreshed.releases)
                                            } catch (ex: Exception) {
                                                ex.printStackTrace()
                                            }
                                        }
                                    }
                                )

                                val formatter = LocalDateTimeFormatter.current
                                val formattedDate = remember { formatter.format(it.updatedAt) }
                                Text(
                                    modifier = Modifier.weight(2f),
                                    text = formattedDate,
                                    style = MaterialTheme.typography.bodyMedium
                                )
                                Box(modifier = Modifier.size(44.dp)) {
                                    if (it.canDownload || it.hasLogs) {
                                        IconButton(
                                            modifier = Modifier.align(Alignment.CenterEnd),
                                            onClick = {
                                                expanded = if (selected) null else it
                                            }) {
                                            AnimatedContent(selected) {
                                                Icon(
                                                    if (it) Icons.Default.ArrowDropDown else Icons.Default.ArrowDropUp,
                                                    contentDescription = null
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                            AnimatedVisibility(selected) {
                                Column(verticalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin)) {
                                    if (it.canDownload || it.hasLogs || it.hasWebsiteZip) {
                                        Text(
                                            text = "Downloads",
                                            style = MaterialTheme.typography.titleSmall
                                        )
                                        FlowRow(
                                            modifier = Modifier.align(Alignment.End)
                                                .padding(bottom = ThemeMargins.SmallMargin),
                                            horizontalArrangement = Arrangement.spacedBy(
                                                ThemeMargins.SmallMargin
                                            )
                                        ) {
                                            if (it.canDownload) {
                                                DownloadButton(
                                                    title = "APK",
                                                    organisation = organisationResponse.organisation.id,
                                                    project = project.id,
                                                    releaseId = it.releaseId,
                                                    file = "apk",
                                                    openDownload = openDownload
                                                )
                                                DownloadButton(
                                                    title = "App Bundle",
                                                    organisation = organisationResponse.organisation.id,
                                                    project = project.id,
                                                    releaseId = it.releaseId,
                                                    file = "bundle",
                                                    openDownload = openDownload
                                                )
                                            }
                                            if(it.hasWebsiteZip) {
                                                DownloadButton(
                                                    title = "Website",
                                                    organisation = organisationResponse.organisation.id,
                                                    project = project.id,
                                                    releaseId = it.releaseId,
                                                    file = "website",
                                                    openDownload = openDownload
                                                )
                                            }
                                            Spacer(Modifier.weight(1f))

                                            if (it.hasLogs) {
                                                DownloadButton(
                                                    title = "Build Log",
                                                    organisation = organisationResponse.organisation.id,
                                                    project = project.id,
                                                    releaseId = it.releaseId,
                                                    file = "logs",
                                                    openDownload = openDownload
                                                )
                                            }
                                        }
                                    }
                                }
                            }

                            HorizontalDivider()
                        }
                    }
                    Spacer(Modifier.height(ThemeMargins.LargeMargins))
                    ReleaseModal(
                        organisation = organisationResponse.organisation,
                        project = project,
                        lastRelease = releases.firstOrNull(),
                        anchor = {
                            Button(
                                enabled = !inProgressBuild,
                                onClick = it
                            ) {
                                if (inProgressBuild) {
                                    Text(
                                        "Build in progress..."
                                    )
                                } else {
                                    Text("Create Release")
                                }
                            }
                        },
                        onCreate = {
                            releases.add(0, it)
                        }
                    )
                    if (inProgressBuild) {
                        Spacer(Modifier.height(ThemeMargins.SmallMargin))
                        Text(
                            text = "Time for a cuppa, build can take between 10 minutes to a few hours depending of queues",
                            style = MaterialTheme.typography.labelMedium
                        )
                    }
                }
                Spacer(Modifier.height(ThemeMargins.LargeMargins))
            }
            ScrollBars(state)
        }
    }
}

private fun String.inProgressStatus(): Boolean {
    return this == "pending" || this == "started"
}

@Composable
private fun DownloadButton(
    title: String,
    organisation: String,
    project: String,
    releaseId: String,
    file: String,
    openDownload: (String) -> Unit

) {
    val client = LocalApiClient.current
    val scope = rememberCoroutineScope()
    var loading by remember { mutableStateOf(false) }
    val snackbarChannel = LocalSnackbar.current
    TextButton(
        enabled = !loading,
        onClick = {
            scope.launch {
                loading = true
                try {
                    val response = client.getDownload(organisation, project, releaseId, file)
                    openDownload(response.url)
                } catch (ex: Exception) {
                    ex.printStackTrace()
                    snackbarChannel.send(SnackbarInfo.Builder.fromNetworkException(ex))
                }
                loading = false
            }

        }) {
        AnimatedContent(loading) {
            if (it) {
                CircularProgressIndicator(
                    strokeWidth = 2.dp,
                    modifier = Modifier.size(24.dp)
                )
            } else {
                Text(title)
            }
        }
    }
}

@Composable
private fun Status(
    modifier: Modifier,
    releaseResponse: ReleaseResponse,
    onCompleted: () -> Unit
) {
    var status by remember(releaseResponse.releaseId) { mutableStateOf(releaseResponse.status) }


    if(status.inProgressStatus()) {

        var dots by remember { mutableStateOf("") }

        Text(
            modifier = modifier,
            text = status + dots,
            style = MaterialTheme.typography.bodyMedium
        )

        val apiClient = LocalApiClient.current
        LaunchedEffect(releaseResponse.releaseId) {
            try {

                apiClient
                    .getBuildStatus(releaseResponse.releaseId)
                    .catch { }
                    .collectLatest {
                        status = it.status
                        if (!it.status.inProgressStatus()) {
                            onCompleted()
                        }
                    }
            } catch (ex: Exception) {
                ex.printStackTrace()
            }
        }
        LaunchedEffect(status) {
            var count = 0
            while (true) {
                dots = "".padEnd(count, '.')
                count++
                if (count > 3) count = 0
                delay(600)
            }
        }
    } else {
        Text(
            modifier = modifier,
            text = status,
            style = MaterialTheme.typography.bodyMedium
        )
    }
}