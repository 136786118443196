package com.appcreator.dto

import com.appcreator.blueprint.Blueprint
import com.appcreator.dto.configurations.ThirdPartyConfiguration
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class BlueprintResponse(
    val blueprint: Blueprint,
    val revision: Long,
    val updatedAt: Instant,
    val dataSources: List<DataSource>,
    val thirdPartyConfiguration: List<ThirdPartyConfiguration>
)
