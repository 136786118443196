package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class OrganisationResponse(
    val organisation: Organisation,
    val accessLevel: AccessLevel,
    val projects: List<Project>
)
