package com.appcreator.compose.conversions

import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

fun jsonValue(json: JsonElement?, key: String?): JsonElement? {
    key?: return null
    return try {
        var item: JsonElement? = json
        key.split(".").forEach { part ->
            item = item?.jsonObject?.get(part)
        }
        item
    } catch (ex: Exception) {
        ex.printStackTrace()
        null
    }
}
