package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.spec.SingleInputSpec

class LocalizableStringInputSpec(
    properties: InputProperties,
    value: MutableState<LocalizableString?>
): SingleInputSpec<LocalizableString?>(properties, value)
