package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.properties.FontReference
import com.appcreator.blueprint.spec.SingleInputSpec

class FontReferenceInputSpec(
    properties: InputProperties,
    value: MutableState<FontReference?>
): SingleInputSpec<FontReference?>(properties, value)
