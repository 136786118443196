package com.appcreator.creatorapp.project.configurations

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.styles.ThemeMargins

@Composable
fun ProjectGeneralConfiguration(
    organisationResponse: OrganisationResponse,
    project: Project,
    openDownload: (String) -> Unit
) {
    var savedProject by remember { mutableStateOf(project.projectConfig) }
    var display by remember { mutableStateOf(project.projectConfig) }
    Column(Modifier.padding(ThemeMargins.LargeMargins)) {
        CreatorTextInput(
            label = "Project Name",
            value = display.name,
            onValueChange = {
                display = display.copy(name = it)
            }
        )
        Spacer(Modifier.height(ThemeMargins.SmallMargin))
        CreatorTextInput(
            label = "App Name",
            value = display.appName,
            onValueChange = {
                display = display.copy(appName = it)
            }
        )

        Spacer(Modifier.height(ThemeMargins.LargeMargins))

        val client = LocalApiClient.current
        LoadingButton(
            label = "Save Changes",
            enabled = savedProject != display,
            action = {
                client.setProjectConfiguration(organisationResponse.organisation.id, project.id, display)
                savedProject = display
            }
        )

        Spacer(Modifier.height(ThemeMargins.LargeMargins))
    }
}
