package com.appcreator.blueprint.actions.navigation

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("CloseScreen")
@ActionClass(
    group = "Navigation",
    title = "Close Screen",
    availableFrom = "0.0.1"
)
class CloseScreenAction(
    @InputProperty(
        title = "Type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("CloseScreenAction.CloseType.Single")
    val closeType: CloseType?,
    @InputProperty(
        title = "Count",
        availableFrom = "0.0.1"
    )
    @DisplayIf("closeType.value == CloseScreenAction.CloseType.Count.name")
    val count: Int?,
): Action {

    enum class CloseType {
        Single,
        Count,
        ToRoot,
        AllModal
    }

}
