package com.appcreator.creatorapp.editor

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.OpenInNew
import androidx.compose.material.icons.filled.Settings
import androidx.compose.material.icons.filled.Visibility
import androidx.compose.material.icons.filled.VisibilityOff
import androidx.compose.material3.CenterAlignedTopAppBar
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.SegmentedButton
import androidx.compose.material3.SingleChoiceSegmentedButtonRow
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.unit.dp
import com.appcreator.creatorapp.editor.newscreen.ScreenPicker
import com.appcreator.dto.Organisation
import com.appcreator.styles.ThemeMargins


@Composable
fun HeaderBar(
    blueprintManager: BlueprintManager,
    organisation: Organisation,
    previewSize: PreviewSize,
    setPreviewSize: (PreviewSize) -> Unit,
    showingNodes: Boolean,
    showNodes: (Boolean) -> Unit,
    openPreview: () -> Unit,
    openSettings: () -> Unit,
    openOrganisation: () -> Unit
) {

    var darkTheme by remember { mutableStateOf(false) }
    Column {
        CenterAlignedTopAppBar(
            title = {
                Row(verticalAlignment = Alignment.CenterVertically) {

                    SingleChoiceSegmentedButtonRow {
                        SegmentedButton(
                            selected = previewSize == PreviewSize.Phone,
                            onClick = {
                                setPreviewSize(PreviewSize.Phone)
                            },
                            shape = RoundedCornerShape(
                                topStartPercent = 25,
                                bottomStartPercent = 25
                            )
                        ) {
                            Text(text = "Phone")
                        }
                        SegmentedButton(
                            selected = previewSize == PreviewSize.Tablet,
                            onClick = {
                                setPreviewSize(PreviewSize.Tablet)
                            },
                            shape = RectangleShape
                        ) {
                            Text("Tablet")
                        }
                        SegmentedButton(
                            selected = previewSize == PreviewSize.Fill,
                            onClick = {
                                setPreviewSize(PreviewSize.Fill)
                            },
                            shape = RoundedCornerShape(
                                topEndPercent = 25,
                                bottomEndPercent = 25
                            )
                        ) {
                            Text("Fill")
                        }
                    }

                    Row(Modifier.width(420.dp).padding(start = ThemeMargins.LargeMargins)) {
                       TextButton(onClick = openPreview) {
                           Icon(Icons.AutoMirrored.Filled.OpenInNew, contentDescription = null)
                           Spacer(Modifier.width(ThemeMargins.SmallMargin))
                           Text("Preview", style = MaterialTheme.typography.titleSmall)
                       }
                    }

                    // TODO
                    /*
                Spacer(Modifier.width(ThemeMargins.LargeMargins))
                Text("Mode   ", style = MaterialTheme.typography.titleSmall)
                SingleChoiceSegmentedButtonRow {
                    SegmentedButton(
                        selected = !darkTheme,
                        onClick = {
                            darkTheme = false
                        },
                        shape = RoundedCornerShape(
                            topStartPercent = 25,
                            bottomStartPercent = 25
                        )
                    ) {
                        Icon(Icons.Default.LightMode, contentDescription = null)
                    }
                    SegmentedButton(
                        selected = darkTheme,
                        onClick = {
                            darkTheme = true
                        },
                        shape = RoundedCornerShape(
                            topEndPercent = 25,
                            bottomEndPercent = 25
                        )
                    ) {
                        Icon(Icons.Default.DarkMode, contentDescription = null)
                    }
                }
                 */
                }
            },
            navigationIcon = {
                ScreenPicker(blueprintManager)
            },
            actions = {
                TextButton(onClick = {
                    showNodes(!showingNodes)
                }) {
                    if (showingNodes) {
                        Icon(Icons.Default.VisibilityOff, contentDescription = null)
                        Spacer(Modifier.width(ThemeMargins.SmallMargin))
                        Text("Hide Tree")
                    } else {
                        Icon(Icons.Default.Visibility, contentDescription = null)
                        Spacer(Modifier.width(ThemeMargins.SmallMargin))
                        Text("Show Tree")
                    }

                }
                IconButton(onClick = openSettings) {
                    Icon(Icons.Default.Settings, contentDescription = null)
                }
                IconButton(onClick = openOrganisation) {
                    Box(
                        Modifier.size(30.dp)
                            .background(MaterialTheme.colorScheme.primary, shape = CircleShape),
                        contentAlignment = Alignment.Center
                    ) {
                        Text(organisation.name.firstOrNull().toString(), color = MaterialTheme.colorScheme.onPrimary)
                    }
                }
            }
        )
        HorizontalDivider()
    }
}
