package com.appcreator.compose

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.navigation.NavHostController
import androidx.navigation.compose.currentBackStackEntryAsState
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.Destination
import com.appcreator.blueprint.ScreenSpec
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.findDestination
import io.ktor.http.encodeURLParameter
import io.ktor.utils.io.charsets.Charsets
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch

interface Navigator {

    val includesToolbar: Boolean
    fun navigate(link: BlueprintLink, envStore: EnvStore, options: OptionsBuilder.() -> Unit = {})
    fun navigate(destination: Destination, options: Navigator.OptionsBuilder.() -> Unit)
    fun findDestination(link: BlueprintLink, envStore: EnvStore): Destination?
    @Composable
    fun selected(): ScreenSpec?
    fun canGoBack(): Boolean
    fun back()
    fun back(count: Int)
    fun closeModal()
    fun registerModal(dismiss: () -> Unit)
    fun unregisterModal()
    fun popToRoot()

    class OptionsBuilder {
        // TODO work out how to best break this down with iOS in mind
        var popUpToRoot: Boolean = false
        var singleTop: Boolean = false
    }

}

class KotlinXNavigator(
    override val includesToolbar: Boolean,
    private val parent: Navigator?, // TODO allow for root screen changes and bottom sheets
    val startDestination: String,
    val blueprint: Blueprint,
    val navController: NavHostController
): Navigator {

    companion object {
        const val route = "route"
    }

    private var modelStack = mutableListOf<() -> Unit>()

    override fun navigate(
        link: BlueprintLink,
        envStore: EnvStore,
        options: Navigator.OptionsBuilder.() -> Unit
    ) {

        // Close all models when navigating
        modelStack.reversed().forEach {
            it()
        }

        val builder = Navigator.OptionsBuilder().apply(options)
        val query: String =  envStore.injectLink(link).encodeURLParameter()

        // Single top
        val currentPath = navController.currentBackStackEntry?.arguments?.getString(route)
        if (currentPath == query) return

        navController.navigate("screen?$route=$query") {
            if (builder.popUpToRoot) {
                navController.graph.startDestinationRoute?.let {
                    popUpTo(it) {
                        saveState = true
                        inclusive = true
                    }
                }
            }
            if (builder.singleTop) {
                launchSingleTop = true
                restoreState = true
            }
        }
    }

    override fun navigate(destination: Destination, options: Navigator.OptionsBuilder.() -> Unit) {
        val builder = Navigator.OptionsBuilder().apply(options)
        val query: String =  destination.screen.path.encodeURLParameter()

        navController.navigate("screen?$route=$query") {
            if (builder.popUpToRoot) {
                navController.graph.startDestinationRoute?.let {
                    popUpTo(it) {
                        saveState = true
                        inclusive = false
                    }
                }
            }
            if (builder.singleTop) {
                launchSingleTop = true
                restoreState = true
            }
        }
    }

    override fun findDestination(link: BlueprintLink, envStore: EnvStore): Destination? =
        blueprint.findDestination(envStore.injectLink(link))

    @Composable
    override fun selected(): ScreenSpec? {
        val backStackEntry by navController.currentBackStackEntryAsState()
        val route = backStackEntry?.arguments?.getString(route)
        return route?.let {
            blueprint.findDestination(route)?.screen
        }
    }

    override fun canGoBack(): Boolean =
        navController.currentBackStack.value.count() > 2 || modelStack.isNotEmpty()

    override fun back() {
        println("BACK CALLED")
        when {
            modelStack.isNotEmpty() -> modelStack.last()()
            canGoBack() -> navController.popBackStack()
            else -> parent?.back()
        }
    }

    override fun back(count: Int) {
        modelStack.reversed().forEach {
            it()
        }
        val stack = navController.currentBackStack.value
        val target = stack.getOrElse(stack.lastIndex - count) {
            stack.first()
        }
        target.destination.route?.let {
            navController.popBackStack(it, inclusive = true)
        }
    }

    override fun closeModal() {
        modelStack.reversed().forEach {
            it()
        }
    }

    override fun registerModal(dismiss: () -> Unit) {
        modelStack.add(dismiss)
    }

    override fun unregisterModal() {
        modelStack.removeLast()
    }

    override fun popToRoot() {
        navController.graph.startDestinationRoute?.let {
            navController.popBackStack(it, inclusive = false)
        }
    }

    @Composable
    fun inStack(link: BlueprintLink?): Boolean {
        link?.path ?: return false
        return navController
            .currentBackStack
            .collectAsState()
            .value
            .map {
                it.arguments?.getString(route)
            }
            .any {
                it == link.path
            }
    }

}