package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class CornerValues(
    val locked: Boolean,
    val topStart: Int?,
    val topEnd: Int?,
    val bottomStart: Int?,
    val bottomEnd: Int?
)
