package com.appcreator.compose.conversions

import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.conversions.UppercaseConversion
import kotlinx.serialization.json.JsonElement

data class UppercaseConvertor(
    val conversion: UppercaseConversion
): Convertor {
    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String? {
        with(conversion) {
            val input = envStore.env[dataPath?.value] as? String ?: return null
            return input.uppercase()
        }
    }

}
