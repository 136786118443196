package com.appcreator.creatorapp.editor.centertypography

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.DragHandle
import androidx.compose.material.icons.filled.Upload
import androidx.compose.material3.Button
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.appcreator.app.file.FileInfo
import com.appcreator.app.file.openFilePicker
import com.appcreator.blueprint.core.properties.CustomFont
import com.appcreator.blueprint.core.properties.Font
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.compose.components.basic.toTextStyle
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.editor.centerdata.rest.randomStringByKotlinRandom
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.project.configurations.LoadingButton
import com.appcreator.shared.api.FileUploadInfo
import com.appcreator.styles.ThemeMargins
import io.ktor.utils.io.ByteReadChannel
import kotlinx.coroutines.launch
import sh.calvin.reorderable.ReorderableItem
import sh.calvin.reorderable.rememberReorderableLazyListState

@Composable
fun TypographyCenterModal(
    anchor: @Composable (() -> Unit) -> Unit,
) {
    ModalScaffold(
        anchor = anchor,
        header = {
            Text("Typography")
        },
        body = {
            TypographyCenterScreen()
        }
    )
}

@Composable
fun TypographyCenterScreen() {

    val blueprintManager = LocalBlueprintManager.current

    val fonts = blueprintManager.theme.fonts
    var selected by remember { mutableStateOf(fonts.firstOrNull()) }
    Row(Modifier.fillMaxSize()) {

        LoadingComposable(loader = {
            it.getFiles(blueprintManager.orgId, blueprintManager.projectId, "ttf,otf,woff")
                .files
                .map { file ->
                    CustomFont(
                        id = file.id,
                        name = file.title,
                        extension = file.fileExtension,
                        url = file.url
                    )
                }.let { fonts ->
                    mutableStateListOf(*fonts.toTypedArray())
                }
        }) { customFontList ->


            Box(Modifier.width(240.dp).fillMaxHeight()) {

                val lazyListState = rememberLazyListState()
                val reorderableLazyListState =
                    rememberReorderableLazyListState(lazyListState) { from, to ->
                        fonts.add(to.index, fonts.removeAt(from.index))
                        blueprintManager.notifyUpdates(true)
                    }

                LazyColumn(
                    state = lazyListState,
                    modifier = Modifier.fillMaxSize(),
                    contentPadding = PaddingValues(bottom = 64.dp)
                ) {
                    items(fonts, key = { it }) { font ->
                        ReorderableItem(reorderableLazyListState, key = font) {
                            NavigationDrawerItem(
                                colors = NavigationDrawerItemDefaults.colors(
                                    unselectedContainerColor = Color.Unspecified
                                ),
                                selected = selected == font,
                                label = {
                                    Row(
                                        modifier = Modifier.fillMaxWidth(),
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.SpaceBetween
                                    ) {
                                        Text(font.name)
                                        Text(
                                            text = font.fontSize.toString(),
                                            fontWeight = FontWeight(font.fontWeight)
                                        )
                                    }
                                },
                                icon = {
                                    IconButton(
                                        modifier = Modifier.draggableHandle(),
                                        onClick = {}) {
                                        Icon(Icons.Default.DragHandle, contentDescription = null)
                                    }
                                },
                                onClick = {
                                    selected = font
                                }
                            )
                        }
                    }
                }

                ExtendedFloatingActionButton(
                    modifier = Modifier.align(Alignment.BottomCenter),
                    text = {
                        Text("Add")
                    },
                    icon = {
                        Icon(Icons.Default.Add, contentDescription = null)
                    },
                    onClick = {
                        val newFont = Font(
                            id = randomStringByKotlinRandom(),
                            name = "New Font",
                            fontSize = 16,
                            fontWeight = 400,
                            userCreated = true,
                            customFont = null,
                            lineHeight = null
                        )
                        fonts.add(newFont)
                        selected = newFont
                        blueprintManager.notifyUpdates(true)
                    }
                )
            }

            Spacer(Modifier.width(ThemeMargins.SmallMargin))
            VerticalDivider()
            Spacer(Modifier.width(ThemeMargins.SmallMargin))

            selected?.let {
                Column(
                    modifier = Modifier
                        .padding(horizontal = ThemeMargins.SmallMargin)
                ) {
                    val index = fonts.indexOf(it)
                    FontConfig(
                        customFontList = customFontList,
                        font = it,
                        update = { newFont ->
                            fonts[index] = newFont
                            selected = newFont
                            blueprintManager.notifyUpdates(true)
                        },
                        delete = {
                            fonts.remove(it)
                            selected = null
                            blueprintManager.notifyUpdates(true)
                        }
                    )
                }
            }
        }
    }
}


@Composable
fun FontConfig(
    customFontList: SnapshotStateList<CustomFont>,
    font: Font,
    update: (Font) -> Unit,
    delete: () -> Unit
) {
    Column {

        if (font.userCreated == true) {
            CreatorTextInput(
                label = "Title",
                value = font.name,
                onValueChange = {
                    update(font.copy(name = it))
                }
            )
            Box(Modifier.height(ThemeMargins.SmallMargin))
        }

        var size by remember(font) { mutableStateOf(font.fontSize.toString()) }
        CreatorTextInput(
            label = "Size",
            value = size,
            onValueChange = {
                size = it
            }
        )
        LaunchedEffect(size) {
            size.toIntOrNull()?.let { size -> update(font.copy(fontSize = size)) }
        }
        Box(Modifier.height(ThemeMargins.SmallMargin))

        var weight by remember(font) { mutableStateOf(font.fontWeight.toString()) }
        CreatorTextInput(
            label = "Weight",
            value = weight,
            onValueChange = {
                weight = it
            }
        )
        LaunchedEffect(weight) {
            weight.toIntOrNull()?.let { weight -> update(font.copy(fontWeight = weight)) }
        }
        Box(Modifier.height(ThemeMargins.SmallMargin))

        CustomFontPicker(customFontList, font.customFont) {
            update(font.copy(customFont = it))
        }

        var lineHeight by remember(font) { mutableStateOf(font.lineHeight?.toString() ?: "") }
        CreatorTextInput(
            label = "Custom Line Height",
            value = lineHeight,
            onValueChange = {
                lineHeight = it
            }
        )
        LaunchedEffect(lineHeight) {
            update(font.copy(lineHeight = lineHeight.toIntOrNull()))
        }
        Box(Modifier.height(ThemeMargins.SmallMargin))


        Box(Modifier.height(ThemeMargins.SmallMargin))

        if (font.userCreated == true) {
            TextButton(
                modifier = Modifier.align(Alignment.End),
                onClick = delete
            ) {
                Icon(Icons.Default.Delete, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Delete")
            }
            Box(Modifier.height(ThemeMargins.SmallMargin))
        }

        Box(Modifier.height(ThemeMargins.LargeMargins))

        Text(
            text = "Preview",
            style = MaterialTheme.typography.headlineSmall
        )

        Box(Modifier.height(ThemeMargins.SmallMargin))
        HorizontalDivider()
        Box(Modifier.height(ThemeMargins.SmallMargin))

        Text(
            text = "Lorem Ipsum",
            style = font.toTextStyle()
        )
    }
}

private val systemFonts = listOf<CustomFont>(
//    CustomFont(
//        name = "sans-serif",
//        family = "FontFamily.SansSerif"
//    ),
//    CustomFont(
//        name = "serif",
//        family = "FontFamily.Serif"
//    ),
//    CustomFont(
//        name = "monospace",
//        family = "FontFamily.Monospace"
//    ),
//    CustomFont(
//        name = "cursive",
//        family = "FontFamily.Cursive"
//    )
)

@Composable
private fun CustomFontPicker(
    customFontList: SnapshotStateList<CustomFont>,
    customFont: CustomFont?,
    onSelect: (CustomFont?) -> Unit
) {

   DropDownInputGroup(
       modifier = Modifier,
       title = "Font Family",
       dropDownTitle = customFont?.name ?: "System",
       dropDownContent = { close ->
           Text(
               modifier = Modifier.padding(ThemeMargins.SmallMargin),
               text = "Custom Fonts"
           )
           customFontList.forEach {
               DropdownMenuItem(
                   onClick = {
                       onSelect(it)
                       close()
                   },
                   text = {
                       Row(verticalAlignment = Alignment.CenterVertically) {
                           Text(it.name)
                           Spacer(Modifier.width(ThemeMargins.SmallMargin))

                           val apiClient = LocalApiClient.current
                           val blueprintManager = LocalBlueprintManager.current
                           val scope = rememberCoroutineScope()
                           IconButton(onClick = {
                               scope.launch {
                                   try {
                                       apiClient.deleteFile(blueprintManager.orgId, blueprintManager.projectId, it.id)
                                       customFontList.remove(it)
                                       if (customFont?.id == it.id) {
                                           onSelect(null)
                                       }
                                   } catch (ex: Exception) {
                                       ex.printStackTrace()
                                   }
                               }
                           }) {
                               Icon(Icons.Default.Delete, contentDescription = null)
                           }
                       }
                   }
               )
           }
           FontUpload(customFontList) {
               DropdownMenuItem(
                   onClick = it,
                   text = {
                       Row {
                           Icon(Icons.Default.Upload, contentDescription = null)
                           Spacer(Modifier.width(ThemeMargins.SmallMargin))
                           Text("Upload Font")
                       }
                   }
               )
           }
           HorizontalDivider()
           systemFonts.forEach {
               DropdownItem(title = it.name) {
                   onSelect(it)
                   close()
               }
           }
           DropdownItem(title = "System") {
               onSelect(null)
               close()
           }
       }
   )
}

@Composable
private fun FontUpload(
    customFontList: SnapshotStateList<CustomFont>,
    anchor: @Composable (() -> Unit) -> Unit
) {
    var fileInfo by remember { mutableStateOf<FileInfo?>(null) }
    var fontName by remember { mutableStateOf("") }

    ModalScaffold(
        size = ModalSize.Small,
        header = { Text("Upload Custom Font") },
        body = {
            Column {
                CreatorTextInput(
                    label = "Font Name",
                    value = fontName,
                    onValueChange = { fontName = it }
                )
                Spacer(Modifier.height(ThemeMargins.LargeMargins))
                Button(
                    onClick = {
                        openFilePicker(".ttf,.otf,.woff") {
                            fileInfo = it
                            if (fontName.isEmpty()) {
                                fontName = it.name.split(".").dropLast(1).joinToString(separator = "")
                            }
                        }
                    }
                ) {
                    fileInfo?.let {
                        Text(it.name)
                    } ?: Text("Select File")
                }
            }
        },
        footer = { close ->

            val apiClient = LocalApiClient.current
            val blueprintManager = LocalBlueprintManager.current
            LoadingButton(
                label = "Upload",
                enabled = fileInfo != null && fontName.isNotEmpty()
            ) {
                fileInfo?.let { info ->
                    val response = apiClient.uploadFile(
                        orgId = blueprintManager.orgId,
                        projectId = blueprintManager.projectId,
                        name = fontName,
                        fileInfo = FileUploadInfo(
                            contentType = info.mimeType,
                            size = info.size,
                            file = ByteReadChannel(info.data),
                            filename = info.name
                        )
                    )
                    customFontList.add(
                        CustomFont(
                            id = response.id,
                            name = response.title,
                            extension = response.fileExtension,
                            url = response.url
                        )
                    )
                    close()
                }
            }
        },
        anchor = anchor
    )
}


