package com.appcreator.creatorapp.editor.inputs

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.spec.inputs.IntInputSpec
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput

@Composable
fun IntInput(spec: IntInputSpec, save: () -> Unit) {

// TODO use number input which is currently not working with kobweb
    DataSourceTextInput(
        label = spec.properties.title,
        value = spec.value?.toString() ?: "",
        allowDataItems = spec.properties.allowDataFields,
        update = {
            spec.value = it.toIntOrNull()
            save()
        }
    )
}