package com.appcreator.components.layouts

import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue


@Composable
fun <T> Dropdown(
    anchor: @Composable (() -> Unit) -> Unit,
    items: List<T>,
    titleForItem: (T) -> String = { it.toString() },
    onSelection: (T) -> Unit
) {
    Dropdown(anchor = anchor) { close ->
        items.forEach { item ->
            DropdownItem(title = titleForItem(item)) {
                onSelection(item)
                close()
            }
        }
    }
}

@Composable
fun DropdownItem(
    title: String,
    onClick: () -> Unit
) {
    DropdownMenuItem(
        onClick = onClick,
        text = {
            Text(title)
        }
    )
}

@Composable
fun Dropdown(
    anchor: @Composable (() -> Unit) -> Unit,
    content: @Composable (() -> Unit) -> Unit
) {

    var visible by remember { mutableStateOf(false) }

    anchor {
        visible = true
    }

    DropdownMenu(
        expanded = visible,
        onDismissRequest = {
            visible = false
        }
    ) {
        content {
            visible = false
        }
    }
}

