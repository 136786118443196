package com.appcreator.compose.components.data

import androidx.compose.foundation.layout.Box
import androidx.compose.material.pullrefresh.PullRefreshIndicator
import androidx.compose.material.pullrefresh.pullRefresh
import androidx.compose.material.pullrefresh.rememberPullRefreshState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.ProvidedValue
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.components.data.LoadingComponent
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalBlueprint
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer
import com.appcreator.compose.loaders.createPreviewData
import kotlinx.coroutines.launch

private sealed class LoadingState {
    data object Loading: LoadingState()
    data class Loaded(val providedValue: ProvidedValue<*>?, val env: Map<String, Any>): LoadingState()
    data class Error(val ex: Exception): LoadingState()
}

@Composable
fun LoadingComposable(modifier: Modifier, component: LoadingComponent) {
    val envStore = LocalEnvStore.current
    val blueprint = LocalBlueprint.current
    var state: LoadingState by remember(component.config) { mutableStateOf(LoadingState.Loading) }
    val deferred = component.action?.let { Container.performer(it)?.deferred() }

    val key = component.config?.parameters?.map { envStore.injectVariables(it.value) }
    val inPreview = LocalInPreview.current

    val load: suspend () -> Unit = {
        load(inPreview, component, blueprint, envStore, deferred,
            onLoading = {
                state = LoadingState.Loading
            },
            onLoad = {
                state = it
            },
            onError = {
                state = it
            }
        )
    }

    LaunchedEffect(key, component.displayInPreview) {
        load()
    }
    val scope = rememberCoroutineScope()
    when(state) {
        is LoadingState.Error -> when(component.errorView) {
            LoadingComponent.ViewType.Nothing -> {}
            LoadingComponent.ViewType.Custom -> component.errorContent?.let { ComponentComposable(modifier, it) }
            else -> DefaultErrorComposable(modifier, (state as LoadingState.Error).ex) {
                scope.launch { load() }
            }
        }
        is LoadingState.Loaded -> {
            val loaded = (state as LoadingState.Loaded)
            val providers = listOfNotNull(loaded.providedValue, LocalEnvStore provides EnvStore.create(parent = envStore, loaded.env))
            CompositionLocalProvider(*providers.toTypedArray()) {
                component.content?.let { content ->
                    if (component.pullToRefresh == true) {
                        var refreshing by remember { mutableStateOf(false) }
                        val pullToRefresh = rememberPullRefreshState(refreshing = refreshing, onRefresh = {
                            refreshing = true
                            scope.launch {
                                load(inPreview, component, blueprint, envStore, deferred,
                                    onLoading = {

                                    },
                                    onLoad = {
                                        state = it
                                    },
                                    onError = {
                                        // TODO show error snackbar or something
                                    }
                                )
                                refreshing = false
                            }
                        })
                        Box {
                            ComponentComposable(modifier.pullRefresh(pullToRefresh), content)
                            PullRefreshIndicator(refreshing, pullToRefresh, Modifier.align(Alignment.TopCenter))
                        }
                    } else {
                        ComponentComposable(modifier, content)
                    }
                }
            }
        }
        LoadingState.Loading -> when(component.loadingView) {
            LoadingComponent.ViewType.Nothing -> {}
            LoadingComponent.ViewType.Custom -> component.loadingContent?.let { ComponentComposable(modifier, it) }
            else -> DefaultLoadingComposable(modifier)
        }
    }
}

private suspend fun load(
    inPreview: Boolean,
    component: LoadingComponent,
    blueprint: Blueprint,
    envStore: EnvStore,
    deferred: Performer.Deferred?,
    onLoading: () -> Unit,
    onLoad: (LoadingState.Loaded) -> Unit,
    onError: (LoadingState.Error) -> Unit
) {
    component.config?.let { config ->
        val loaderSpec = blueprint.loaderSpec(config.loaderSpec)?: run {
            println("----- No loader spec found for ${config.loaderSpec.id} -----")
            return@let
        }
        if (component.displayInPreview != null && inPreview) {
            when (component.displayInPreview) {
                LoadingComponent.DisplayInPreview.Loading -> { onLoading() }
                LoadingComponent.DisplayInPreview.Error -> { onError(LoadingState.Error(PreviewException())) }
                else -> {
                    onLoad(LoadingState.Loaded(null, loaderSpec.createPreviewData()))
                }
            }
        } else {
            Container.loaderRegistry[loaderSpec::class]?.let {
                try {
                    val (data, updatedEnv) = it(loaderSpec).load(envStore, config.parameters)
                    deferred?.perform(EnvStore.create(parent = envStore, updatedEnv))
                    onLoad(LoadingState.Loaded(data, updatedEnv))
                } catch (ex: Exception) {
                    ex.printStackTrace()
                    onError(LoadingState.Error(ex))
                }
            } ?: run {
                println("----- No loader spec registered for ${config.loaderSpec::class} -----")
            }
        }
    }
}

private class PreviewException: Exception()