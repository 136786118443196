package com.appcreator.compose.components.data

import androidx.compose.foundation.lazy.LazyListScope
import androidx.compose.foundation.lazy.items
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.data.DataRepeaterComponent
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.conditions.Evaluator
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator

@Composable
fun DataRepeaterComposable(modifier: Modifier, component: DataRepeaterComponent) {
    val envStore = LocalEnvStore.current
    val items = rememberRepeaterItems(envStore, component)
    items?.let { list ->
        list.forEach {
            CompositionLocalProvider(LocalEnvStore provides it) {
                component.content?.let { component ->
                    ComponentComposable(Modifier, component)
                }
            }
        }
    }
}

fun LazyListScope.dataRepeaterComposable(
    component: DataRepeaterComponent,
    items: List<EnvStore>?
) {
    items?.let { list ->
        items(list) {
            CompositionLocalProvider(LocalEnvStore provides it) {
                component.content?.let { component ->
                    ComponentComposable(Modifier, component)
                }
            }
        }
    }
}

@Composable
fun rememberRepeaterItems(
    envStore: EnvStore,
    component: DataRepeaterComponent
): List<EnvStore>? {

    return component.path?.value?.let { path ->
        component.filter?.let {
            val items = envStore.filterItemsSync(path, Container.evaluator(it))// by remember { mutableStateOf(envStore.filterItemsSync(path, Container.evaluator(it))) }
//            LaunchedEffect(envStore.env) {
//                items = Container.evaluator(it)?.let {
//                    envStore.envFromList(path).filter { store ->
//                        it.evaluateAsync(store)
//                    }
//                }
//            }
            return items
        } ?: envStore.envFromList(path)
    }
}

@Composable
private fun EnvStore.filterItemsSync(path: String, evaluator: Evaluator?): List<EnvStore> =
        envFromList(path).filter { store ->
            evaluator?.evaluate(store)!!
        }

