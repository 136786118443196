package com.appcreator.blueprint.components.`data`

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.ConversionInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class DataConversionComponentBuilder(
  component: DataConversionComponent?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "Data",
          name = "DataConversionComponent",
          title = "Data Transformer",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  public val outputPath: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Output Path",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = false,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.outputPath),
          
          
      )

  public val conversion: ConversionInputSpec = ConversionInputSpec(
          properties = InputProperties(
          section = "",
          title = "Conversion",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false
      ) ,
          value = mutableStateOf(component?.conversion?.toBuilder(newId)),
          
          
      )

  public val content: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Component",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          listDataOptions = false,
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true
      ) ,
          value =  mutableStateOf(component?.content?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, outputPath, conversion,
      content)

  override fun build(): Component = DataConversionComponent(_nodeId.value?:
      Uuid.random().toString(), _nodeLabel.value, outputPath.value, conversion.value?.build(),
      content.value?.build())
}
