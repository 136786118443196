package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.spec.SingleInputSpec

class ColorReferenceInputSpec(
    properties: InputProperties,
    value: MutableState<ColorReference?>
): SingleInputSpec<ColorReference?>(properties, value)
