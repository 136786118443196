package com.appcreator.blueprint.firebase.firestore

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.DataOptionsFrom
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.DataValueSet
import com.appcreator.blueprint.core.properties.TypedDataValue
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseFirestoreSetData")
@ActionClass(
    group = "Firebase Firestore",
    title = "Set Data",
    availableFrom = "0.0.1"
)
class FirebaseFirestoreSetDataAction(
    @InputProperty(
        title = "Collection Path",
        availableFrom = "0.0.1"
    )
    val path: String?,
    @InputProperty(
        title = "Data",
        availableFrom = "0.0.1"
    )
    val data: List<TypedDataValue>?,
): Action
