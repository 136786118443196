package com.appcreator.creatorapp.editor.centerimages

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.FileCopy
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import com.appcreator.app.utils.addToClipboard
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.crm.ImageUploaderModal
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch

@Composable
fun ImageCenterModal(
    orgId: String,
    projectId: String,
    anchor: @Composable (() -> Unit) -> Unit
) {
    ModalScaffold(
        anchor = anchor,
        header = {
            Text("Image center")
        },
        body = {
            ImageCenterContent(orgId, projectId)
        }
    )
}

@Composable
private fun ImageCenterContent(
    orgId: String,
    projectId: String
) {
    val scope = rememberCoroutineScope()
    LoadingComposable(loader = {
        mutableStateListOf(*it.getFiles(orgId, projectId).files.toTypedArray())
    }) { data ->


        var selected by remember { mutableStateOf(data.firstOrNull()) }

        Row(Modifier.fillMaxSize()) {

            Box(Modifier.width(240.dp).fillMaxHeight()) {

                LazyColumn(
                    modifier = Modifier.fillMaxSize(),
                    contentPadding = PaddingValues(bottom = 64.dp)
                ) {
                    items(data, key = { it }) { image ->
                        NavigationDrawerItem(
                            colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified),
                            selected = selected == image,
                            label = {
                                Row(
                                    modifier = Modifier.fillMaxWidth(),
                                    verticalAlignment = Alignment.CenterVertically,
                                    horizontalArrangement = Arrangement.SpaceBetween
                                ) {
                                    Text(image.title)
                                }
                            },
                            onClick = {
                                selected = image
                            }
                        )
                    }
                }

                if (data.isEmpty()) {
                    Text("No images")
                }

                ExtendedFloatingActionButton(
                    modifier = Modifier.align(Alignment.BottomCenter),
                    text = {
                        Text("Add")
                    },
                    icon = {
                        Icon(Icons.Default.Add, contentDescription = null)
                    },
                    onClick = {
                       selected = null
                    }
                )
            }

            Spacer(Modifier.width(ThemeMargins.SmallMargin))
            VerticalDivider()
            Spacer(Modifier.width(ThemeMargins.SmallMargin))

            selected?.let {
                Column {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Text(
                            text = it.title,
                            style = MaterialTheme.typography.headlineMedium
                        )
                        Spacer(Modifier.width(ThemeMargins.LargeMargins))
                        TextButton(onClick = {
                            addToClipboard(it.url)
                        }) {
                            Icon(Icons.Default.FileCopy, contentDescription = null)
                            Spacer(Modifier.width(ThemeMargins.SmallMargin))
                            Text("Copy URL")
                        }

                        Spacer(Modifier.width(ThemeMargins.LargeMargins))

                        val client = LocalApiClient.current
                        TextButton(onClick = {
                            scope.launch {
                                try {
                                    client.deleteFile(orgId, projectId, it.id)
                                    data.remove(it)
                                    selected = data.firstOrNull()
                                } catch (ex: Exception) {
                                    ex.printStackTrace()
                                    // TODO
                                }
                            }
                        }) {
                            Icon(Icons.Default.Delete, contentDescription = null)
                            Spacer(Modifier.width(ThemeMargins.SmallMargin))
                            Text("Delete")
                        }
                    }

                    AsyncImage(it.url, contentDescription = null)

                }
            }?: run {
                ImageUploaderModal(orgId = orgId, projectId = projectId) {
                    data.add(it)
                    selected = it
                }
            }
        }
    }
}
