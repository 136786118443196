package com.appcreator.creatorapp

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.shared.api.ApiClient
import io.ktor.client.plugins.ClientRequestException
import io.ktor.http.HttpStatusCode
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

sealed class LoadingState<T> {
    class PreLoading<T>: LoadingState<T>()
    class Loading<T>: LoadingState<T>()
    class Error<T>: LoadingState<T>()
    data class Loaded<T>(val data: T): LoadingState<T>()
}

@Composable
fun <T> LoadingComposable(
    key: Any? = Unit,
    loader: suspend (ApiClient) -> T,
    notFound: (() -> Unit)? = null,
    content: @Composable (T) -> Unit
) {
    val apiClient = LocalApiClient.current
    RawLoadingComposable(
        key = key,
        loader = { loader(apiClient) },
        notFound = notFound,
        content = content
    )
}

@Composable
fun <T> RawLoadingComposable(key: Any? = Unit, loader: suspend () -> T, notFound: (() -> Unit)? = null, content: @Composable (T) -> Unit) {
    var state by remember { mutableStateOf<LoadingState<T>>(LoadingState.PreLoading()) }
    LaunchedEffect(key) {
        val job = launch {
            delay(300)
            state = LoadingState.Loading()
        }
        state = try {
            LoadingState.Loaded(loader()).also {
                job.cancel()
            }
        } catch (ex: Throwable) {
            ex.printStackTrace()
            LoadingState.Error<T>().also {
                job.cancel()
                if (ex is ClientRequestException && ex.response.status == HttpStatusCode.NotFound) {
                    notFound?.invoke()
                }
            }
        }
    }

    val scope = rememberCoroutineScope()

    when(state) {
        is LoadingState.Loading -> Box(Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            CircularProgressIndicator()
        }
        is LoadingState.Error -> TextButton(onClick = {
            scope.launch {
                state = LoadingState.Loading()
                state = try {
                    LoadingState.Loaded(loader())
                } catch (ex: Throwable) {
                    ex.printStackTrace()
                    LoadingState.Error()
                }
            }
        }) {
            Text("Try again")
        }
        is LoadingState.Loaded -> content((state as LoadingState.Loaded<T>).data)
        else -> {}
    }
}
