package com.appcreator.compose.theme

import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Typography
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.theme.Theme
import com.appcreator.blueprint.theme.ThemeColors
import com.appcreator.blueprint.theme.ThemeType
import com.appcreator.compose.extensions.composeColor

@Composable
fun Blueprint.Theme(content: @Composable () -> Unit) {
    MaterialTheme(
        colorScheme = lightColorScheme(
            primary = theme color ThemeColors.primary,
            onPrimary = theme color ThemeColors.onPrimary,
            secondary = theme color ThemeColors.secondary,
            onSecondary = theme color ThemeColors.onSecondary,
            tertiary = theme color ThemeColors.tertiary,
            onTertiary = theme color ThemeColors.onTertiary,
            background = theme color ThemeColors.background,
            onBackground = theme color ThemeColors.onBackground,
            surface = theme color ThemeColors.surface,
            onSurface = theme color ThemeColors.onSurface,
        ),
        typography = Typography(
            displayLarge = theme type ThemeType.displayLarge,
            displayMedium = theme type ThemeType.displayMedium,
            displaySmall = theme type ThemeType.displaySmall,
            headlineLarge = theme type ThemeType.headlineLarge,
            headlineMedium = theme type ThemeType.headlineMedium,
            headlineSmall = theme type ThemeType.headlineSmall,
            titleLarge = theme type ThemeType.titleLarge,
            titleMedium = theme type ThemeType.titleMedium,
            titleSmall = theme type ThemeType.titleSmall,
            bodyLarge = theme type ThemeType.bodyLarge,
            bodyMedium = theme type ThemeType.bodyMedium,
            bodySmall = theme type ThemeType.bodySmall,
            labelLarge = theme type ThemeType.labelLarge,
            labelMedium = theme type ThemeType.labelMedium,
            labelSmall = theme type ThemeType.labelSmall,
        ),
        content = content
    )
}

@Composable
private infix fun Theme.color(id: String): Color =
    colors.find { it.id == id }?.composeColor() ?: Color.Black

@Composable
private infix fun Theme.type(id: String): TextStyle =
    fonts.find { it.id == id }?.let {
        TextStyle(
            fontSize = it.fontSize.sp,
            fontWeight = FontWeight(it.fontWeight),
        )
    }?: MaterialTheme.typography.bodyMedium
