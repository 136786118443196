package com.appcreator.creatorapp.editor.panelcomponent

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.layout.Column
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.creatorapp.editor.BlueprintManager
import com.appcreator.creatorapp.editor.local.LocalSelectedComponent
import com.appcreator.creatorapp.editor.panelcomponent.views.ComponentItem


@Composable
fun ComponentPanel(
    navigator: BlueprintManager,
    deleteComponent: (ComponentBuilder, ComponentBuilder) -> Unit
) {
    val selected by LocalSelectedComponent.current
    AnimatedContent(
        targetState = selected
    ) {
        Column {
            it?.let {
                ComponentItem(
                    builder = it,
                    save = {
                        navigator.notifyUpdates(false)
                    },
                    delete = {
                        navigator.deleteScreen(it)
                    },
                    deleteComponent = deleteComponent
                )
            }
        }
    }
}
