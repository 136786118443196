package com.appcreator.compose

import com.appcreator.compose.api.JsonFetcher
import com.appcreator.compose.extensions.JSEvaluationConfig
import io.ktor.client.HttpClient
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json

// This is the shared code that would go in the build apps or SDK
class SharedContainer(
    val jsEvaluationConfig: JSEvaluationConfig
) {

    val jsonFetcher: JsonFetcher

    init {
        val client = HttpClient {
            install(ContentNegotiation) { json(Json) }
            expectSuccess = true
        }
        jsonFetcher = JsonFetcher(client, jsEvaluationConfig)
    }

}
