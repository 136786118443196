package com.appcreator.creatorapp.iconcreator

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.Image
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.Check
import androidx.compose.material.icons.filled.FileUpload
import androidx.compose.material.icons.filled.Save
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Slider
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Canvas
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.FilterQuality
import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.asComposeImageBitmap
import androidx.compose.ui.graphics.asSkiaBitmap
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.IntSize
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImagePainter
import coil3.compose.rememberAsyncImagePainter
import coil3.toBitmap
import com.appcreator.app.file.FileInfo
import com.appcreator.app.file.openFilePicker
import com.appcreator.blueprint.core.properties.ColorValue
import com.appcreator.components.layouts.Dropdown
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.compose.extensions.toColor
import com.appcreator.compose.tracingPaperBackground
import com.appcreator.creatorapp.editor.inputs.ColorConfig
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.shared.api.FileUploadInfo
import com.appcreator.styles.ThemeMargins
import io.ktor.utils.io.ByteReadChannel
import kotlinx.coroutines.launch
import org.jetbrains.skia.EncodedImageFormat
import org.jetbrains.skia.Image

enum class BackgroundType {
    Color,
    Image
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun IconCreatorScreen(
    orgId: String,
    projectId: String,
    hasSaved: () -> Unit
) {

    Row {

        var foreground by remember { mutableStateOf<FileInfo?>(null) }
        var padding by remember { mutableStateOf(0.2f) }

        var backgroundType by remember { mutableStateOf(BackgroundType.Color) }
        var backgroundColor by remember { mutableStateOf(ColorValue("", "",  "#5271FF", emptyMap(), false)) }
        var backgroundImage by remember { mutableStateOf<FileInfo?>(null) }
        var imageIcon by remember { mutableStateOf<ImageBitmap?>(null) }

        var changed by remember { mutableStateOf(true) }

        Column(Modifier.width(320.dp)) {

            Spacer(Modifier.height(ThemeMargins.MedMargins))

            Text(
                text = "Foreground",
                style = MaterialTheme.typography.headlineMedium
            )
            TextButton(onClick = {
                openFilePicker(
                    accept = ".png,.svg"
                ) {
                    foreground = it
                }
            }) {
                Icon(Icons.Default.FileUpload, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.MedMargins))
                Text("Select File")
            }

            Text(
                text = "Padding",
                style = MaterialTheme.typography.headlineSmall
            )
            Slider(
                value = padding,
                onValueChange = {
                    padding = it
                },
                valueRange = 0f..0.4f
            )
            Spacer(Modifier.height(ThemeMargins.MedMargins))
            Text(
                text = "Background",
                style = MaterialTheme.typography.headlineMedium
            )
            Dropdown(anchor = {
                TextButton(onClick = it) {
                    Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                    Spacer(Modifier.width(ThemeMargins.MedMargins))
                    Text(backgroundType.name)
                }
            }) { close ->
                BackgroundType.entries.forEach {
                    DropdownItem(title = it.name) {
                        backgroundType = it
                        close()
                    }
                }
            }

            when(backgroundType) {
                BackgroundType.Color -> ColorConfig(
                    color = backgroundColor,
                    update = { backgroundColor = it }
                )
                BackgroundType.Image -> {
                    TextButton(onClick = {
                        openFilePicker(
                            accept = ".png"
                        ) {
                            backgroundImage = it
                        }
                    }) {
                        Icon(Icons.Default.FileUpload, contentDescription = null)
                        Spacer(Modifier.width(ThemeMargins.MedMargins))
                        Text("Select File")
                    }
                }
            }

            val scope = rememberCoroutineScope()
            val apiClient = LocalApiClient.current
            val enabled by remember { derivedStateOf {
                imageIcon != null && foreground != null && (backgroundType == BackgroundType.Color || backgroundImage != null)
            } }
            var loading by remember { mutableStateOf(false) }

            Spacer(Modifier.height(ThemeMargins.LargeMargins))

            Button(
                enabled = enabled && !loading && changed,
                onClick = {
                    imageIcon?.let { full ->
                        foreground?.let { foreground ->
                            scope.launch {
                                loading = true
                                try {
                                    val encodedBytes = Image.makeFromBitmap(full.asSkiaBitmap())
                                        .encodeToData(EncodedImageFormat.PNG, 100)?.bytes!!
                                    val channel = ByteReadChannel(encodedBytes)

                                    apiClient.uploadAppIcon(
                                        orgId = orgId,
                                        projectId = projectId,
                                        full = FileUploadInfo(
                                            contentType = "image/png",
                                            size = encodedBytes.size.toLong(),
                                            file = channel,
                                            filename = "icon.png"
                                        ),
                                        foreground = FileUploadInfo(
                                            contentType = foreground.mimeType,
                                            size = foreground.size,
                                            file = ByteReadChannel(foreground.data),
                                            filename = foreground.name
                                        ),
                                        foregroundPadding = (padding * 100f).toLong(),
                                        background = if (backgroundType == BackgroundType.Image && backgroundImage != null) {
                                            FileUploadInfo(
                                                contentType = backgroundImage!!.mimeType,
                                                size = backgroundImage!!.size,
                                                file = ByteReadChannel(backgroundImage!!.data),
                                                filename = backgroundImage!!.name
                                            )
                                        } else null,
                                        backgroundColor = if (backgroundType == BackgroundType.Color) backgroundColor.baseHex else null,
                                    )

                                    hasSaved()
                                    changed = false

                                } catch (ex: Exception) {
                                    ex.printStackTrace()
                                    // TODO
                                }
                                loading = false
                            }
                        }
                    }

                }) {
                AnimatedContent(loading) {
                    if (it) {
                        CircularProgressIndicator(
                            strokeWidth = 2.dp,
                            modifier = Modifier.size(24.dp)
                        )
                    } else {
                        Row {
                            Icon(Icons.Default.Save, contentDescription = null)
                            Spacer(Modifier.width(ThemeMargins.MedMargins))
                            if(changed) {
                                Text("Save")
                            } else {
                                Text("Saved")
                                Spacer(Modifier.width(ThemeMargins.MedMargins))
                                Icon(
                                    Icons.Default.Check,
                                    tint = MaterialTheme.colorScheme.primary,
                                    contentDescription = null
                                )
                            }
                        }
                    }
                }
            }
        }

        VerticalDivider()

        Box(Modifier.fillMaxSize().tracingPaperBackground(), contentAlignment = Alignment.Center) {
            FlowRow(
                horizontalArrangement = Arrangement.spacedBy(ThemeMargins.LargeMargins),
                verticalArrangement = Arrangement.spacedBy(ThemeMargins.LargeMargins)
            ) {

                val foregroundState = foreground?.data?.let {
                    rememberAsyncImagePainter(
                        it,
                        filterQuality = FilterQuality.High
                    ).state.collectAsState().value
                }
                val backgroundState = backgroundImage?.data?.let {
                    rememberAsyncImagePainter(
                        it,
                        filterQuality = FilterQuality.High
                    ).state.collectAsState().value
                }

                LaunchedEffect(
                    foregroundState,
                    padding,
                    backgroundType,
                    backgroundColor,
                    backgroundState
                ) {

                    val sizeInPx = 1024f
                    val image = ImageBitmap(sizeInPx.toInt(), sizeInPx.toInt())
                    val canvas = Canvas(image)

                    when (backgroundType) {
                        BackgroundType.Color -> {
                            val paint = Paint()
                            paint.color = backgroundColor.baseHex.toColor()
                            canvas.drawRect(0f, 0f, sizeInPx, sizeInPx, paint)
                        }

                        BackgroundType.Image -> {
                            (backgroundState as? AsyncImagePainter.State.Success)?.let { success ->
                                canvas.drawImageRect(
                                    image = success.result.image.toBitmap()
                                        .asComposeImageBitmap(),
                                    dstSize = IntSize(sizeInPx.toInt(), sizeInPx.toInt()),
                                    paint = Paint()
                                )
                            }
                        }
                    }

                    (foregroundState as? AsyncImagePainter.State.Success)?.let { success ->

                        val paddingInt = padding * sizeInPx
                        val drawSize = sizeInPx - (paddingInt * 2)
                        val imageSize = Size(
                            success.result.image.width.toFloat(),
                            success.result.image.height.toFloat()
                        )
                        val maxDstSize = Size(drawSize, drawSize)
                        val scaleFactor =
                            ContentScale.Fit.computeScaleFactor(imageSize, maxDstSize)
                        val dstSize = Size(
                            imageSize.width * scaleFactor.scaleX,
                            imageSize.height * scaleFactor.scaleY
                        )

                        val paint = Paint()
                        paint.filterQuality = FilterQuality.High

                        canvas.drawImageRect(
                            image = success.result.image.toBitmap().asComposeImageBitmap(),
                            dstOffset = IntOffset(
                                (sizeInPx - dstSize.width).toInt() / 2,
                                (sizeInPx - dstSize.height).toInt() / 2
                            ),
                            dstSize = IntSize(dstSize.width.toInt(), dstSize.height.toInt()),
                            paint = paint
                        )
                    }
                    changed = true
                    imageIcon = image
                }

                Box(
                    Modifier.size(300.dp)
                        .clip(RoundedCornerShape(8.dp)),
                    contentAlignment = Alignment.Center
                ) {
                    Box(
                        Modifier.fillMaxSize()
                            .border(2.dp, color = Color.Black, shape = RoundedCornerShape(8.dp))
                    )

                    imageIcon?.let { bitmap ->
                        Image(
                            modifier = Modifier.fillMaxSize(),
                            bitmap = bitmap,
                            contentDescription = null
                        )
                    }
                }

                // Round preview?
//                Box(
//                    Modifier.size(300.dp).clip(CircleShape),
//                    contentAlignment = Alignment.Center
//                ) {
//                    Box(
//                        Modifier.fillMaxSize()
//                            .border(2.dp, color = Color.Black, shape = RoundedCornerShape(8.dp))
//                    )
//
//                    imageIcon?.let { bitmap ->
//                        Image(
//                            modifier = Modifier.fillMaxSize(),
//                            bitmap = bitmap,
//                            contentDescription = null
//                        )
//                    }
//                }
            }
        }
    }
}
