package com.appcreator.dto.configurations

import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject

@Serializable
data class ThirdPartyConfiguration(
    val type: String,
    val enabled: Boolean,
    val components: List<String>,
    val globalState: Set<String>?,
    val config: JsonObject?
)