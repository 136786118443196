package com.appcreator.app

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import app_creator.app.generated.resources.Res
import app_creator.app.generated.resources.logo
import com.appcreator.compose.tracingPaperBackground
import com.appcreator.firebase.FirebaseAuthUI
import com.appcreator.firebase.FirebaseEmailAuthMethod
import com.appcreator.styles.ThemeMargins
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth
import dev.gitlive.firebase.auth.js
import kotlinx.browser.window
import org.jetbrains.compose.resources.vectorResource
import kotlin.js.json

@Composable
fun LoginScreen() {
    DisposableEffect(Unit) {
        val loginOuterDiv = window.document.createElement("div")
        loginOuterDiv.id = "login-container-outer"
        window.document.body?.appendChild(loginOuterDiv)

        val loginDiv = window.document.createElement("div")
        loginDiv.id = "login-container"
        loginOuterDiv.appendChild(loginDiv)

        val firebaseui = com.appcreator.app.utils.require("firebaseui")
        val json = json(
            "signInOptions" to arrayOf(
                json(
                    "provider" to FirebaseAuthUI.EmailAuthProviderID,
                    "signInMethod" to FirebaseEmailAuthMethod.EmailLinkSignIn
                ),
                FirebaseAuthUI.GoogleAuthProviderID
            ),
            "tosUrl" to "/tos",
            "signInFlow" to "popup",
            "privacyPolicyUrl" to "/privacyPolicy",
            "credentialHelper" to firebaseui.auth.CredentialHelper.GOOGLE_YOLO
        )

        // Don't delete the unused, they are used in the js code line
        val auth = Firebase.auth.js
        val authUI: dynamic = js("new firebaseui.auth.AuthUI(auth)")
        val result = authUI.start("#login-container", json)

        onDispose {
            window.document.body?.removeChild(loginOuterDiv)
            authUI.delete()
        }
    }

    Box(Modifier.fillMaxSize().tracingPaperBackground()) {
        Card(
            Modifier.size(450.dp).align(Alignment.Center).offset(y = -(70.dp)),
            colors = CardDefaults.outlinedCardColors(containerColor = Color.White)
        ) {
            Column(modifier = Modifier.fillMaxWidth().padding(ThemeMargins.LargeMargins), horizontalAlignment = Alignment.CenterHorizontally) {
                Icon(
                    modifier = Modifier.size(80.dp),
                    tint = MaterialTheme.colorScheme.primary,
                    imageVector = vectorResource(Res.drawable.logo),
                    contentDescription = null
                )
                Spacer(Modifier.height(ThemeMargins.MedMargins))
                Text(
                    "Your App Creator",
                    style = MaterialTheme.typography.headlineMedium
                )
                Text("Login or Sign up")
            }
        }
    }
}
