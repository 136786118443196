package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.properties.TypedDataValue
import com.appcreator.blueprint.dataspecs.ValueDataSpecBuilder
import com.appcreator.blueprint.spec.inputs.TypedDataValueListInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput
import com.appcreator.styles.ThemeMargins

@Composable
fun TypedDataValueListInput(spec: TypedDataValueListInputSpec, save: () -> Unit) {

    Text("Items")

    Box(Modifier.height(ThemeMargins.SmallMargin))

    spec.value.forEachIndexed { index, item ->
        DataItem(
            item = item,
            update = {
                spec.value[index] = it
                save()
            },
            save = save,
            delete = {
                spec.value.remove(item)
                save()
            }
        )
        Box(Modifier.height(ThemeMargins.MedMargins))
    }

    TextButton(
        onClick = {
            spec.value.add(TypedDataValue(key = "", value = "", type = TypedDataValue.Type.String))
            save()
        }) {
        Icon(Icons.Default.Add, contentDescription = null)
        Spacer(Modifier.width(ThemeMargins.SmallMargin))
        Text("Add")
    }
}


@Composable
private fun DataItem(
    item: TypedDataValue,
    update: (TypedDataValue) -> Unit,
    save: () -> Unit,
    delete: () -> Unit
) {

    Row(verticalAlignment = Alignment.CenterVertically) {
        DropDownInputGroup(
            title = "Data Type",
            dropDownTitle = item.type.name,
        ) { close ->
            TypedDataValue.Type.entries.forEach {
                DropdownItem(it.name) {
                    update(item.copy(type = it))
                    close()
                }
            }
        }
        IconButton(onClick = {
            delete()
        }) {
            Icon(Icons.Default.Delete, contentDescription = null)
        }
    }

    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Key",
        value = item.key,
        onValueChange = {
            update(item.copy(key = it))
            save()
        }
    )

    DataSourceTextInput(
        label = "Value",
        value = item.value,
        allowDataItems = true,
        update = {
            update(item.copy(value = it))
            save()
        },
    )

}

@Composable
private fun FlatDataItem(
    item: ValueDataSpecBuilder,
    rootItem: Boolean,
    save: () -> Unit,
    delete: () -> Unit
) {
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Key",
        value = item.key.value ?: "",
        onValueChange = {
            item.key.value = it
            save()
        },
        trailingIcon = {
            IconButton(onClick = {
                delete()
            }) {
                Icon(Icons.Default.Delete, contentDescription = null)
            }
        }
    )

    if (rootItem) {
        DataSourceTextInput(
            label = "Initial",
            value = item.value.value ?: "",
            allowDataItems = true,
            update = {
                item.value.value = it
                save()
            }
        )
    }
}
