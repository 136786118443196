package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.OpenInNew
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.linkParameter
import com.appcreator.blueprint.spec.inputs.BlueprintLinkInputSpec
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.editor.local.LocalScreenOptions
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput
import com.appcreator.styles.ThemeMargins


@Composable
fun LinkInput(spec: BlueprintLinkInputSpec, save: () -> Unit) {

    val screens = LocalScreenOptions.current

    DropDownInputGroup(
        title = spec.properties.title,
        leadingIcon = {
            Icon(Icons.Default.OpenInNew, contentDescription = null)
        },
        dropDownTitle = spec.value?.path ?: spec.properties.title,
        dropDownContent = { close ->
            screens.forEach {
                DropdownItem(it.path) {
                    spec.value = spec.value?.copy(path = it.path) ?: BlueprintLink(it.path)
                    save()
                    close()
                }
            }
        }
    )

    spec.value?.path?.let {
        val specValue = spec.value!!

        Column(Modifier.padding(start = ThemeMargins.LargeMargins)) {

            it.linkParameter().forEach { parameter ->

                Box(Modifier.height(ThemeMargins.SmallMargin))

                DataSourceTextInput(
                    label = parameter,
                    value = specValue.params[parameter] ?: "",
                    update = { input ->
                        spec.value =
                            spec.value?.copy(params = specValue.params.plus(parameter to input))
                        save()
                    }
                )
            }
        }

    }
}