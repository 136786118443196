package com.appcreator.creatorapp.editor

import androidx.compose.runtime.toMutableStateList
import com.appcreator.blueprint.theme.Theme

class MutableTheme(theme: Theme) {

    val colors = theme.colors.toMutableStateList()
    val fonts = theme.fonts.toMutableStateList()

    fun toTheme(): Theme = Theme(
        colors = colors,
        fonts = fonts
    )

}
