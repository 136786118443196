package com.appcreator.creatorapp.editor.centerdata.global

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.GlobalData
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.editor.BlueprintManager
import com.appcreator.styles.ThemeMargins


@Composable
fun RowScope.GlobalDataItemDetails(
    blueprintManager: BlueprintManager,
    item: GlobalData,
    update: (GlobalData) -> Unit,
    remove: () -> Unit
) {

    Column(modifier = Modifier.weight(1f)
        .padding(horizontal = ThemeMargins.SmallMargin)
    ) {

        CreatorTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Key",
            value = item.key,
            onValueChange = {
                update(item.copy(key = it))
            },
            trailingIcon = {
                TextButton(onClick = remove) {
                    Text("Delete")
                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                    Icon(Icons.Default.Delete, contentDescription = null)
                }
            }
        )


        Row(verticalAlignment = Alignment.CenterVertically) {
            Checkbox(
                    checked = item.perEnvironment,
                    onCheckedChange = {
                        update(item.copy(perEnvironment = it))
                    },
                )
            Text("Value Per environment")
        }

        Row(verticalAlignment = Alignment.CenterVertically) {
            Checkbox(
                checked = item.forLoadersOnly,
                onCheckedChange = {
                    update(item.copy(forLoadersOnly = it))
                },
            )
            Text("Only show for API calls")
        }

        val label = if (item.perEnvironment) {
            GlobalData.defaultEnv
        } else {
            "Value"
        }
        CreatorTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = label,
            value = item.value(GlobalData.defaultEnv),
            onValueChange = {
                update(item.copy(values = item.values.plus(GlobalData.defaultEnv to it)))
            }
        )


        if (item.perEnvironment) {
            blueprintManager.customEnvironments.forEach { env ->
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = env.name,
                    value = item.value(env.id),
                    onValueChange = {
                        update(item.copy(values = item.values.plus(env.id to it)))
                    }
                )
            }
        }
    }
}

