package com.appcreator.blueprint.components.navigation

import com.appcreator.blueprint.core.AllowedComponent
import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.properties.FontReference
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.ListRange
import com.appcreator.blueprint.core.properties.BlueprintLink
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("NavigationComponent")
@ComponentClass(
    group = "Navigation",
    title = "Navigation",
    description = "Add screens to navigate between",
    availableFrom = "0.0.1",
    accessible = false
)
data class NavigationComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        section = "Navigation Options",
        title = "Tab Bar",
        availableFrom = "0.0.1"
    )
    val tabBar: Boolean?,

    @InputProperty(
        section = "Navigation Options",
        title = "Navigation Drawer",
        availableFrom = "0.0.1"
    )
    val navigationDrawer: Boolean?,

    @InputProperty(
        section = "Tab Bar",
        title = "Background Color",
        availableFrom = "0.0.1"
    )
    @DisplayIf("tabBar.value == true")
    val tabBarBackgroundColor: ColorReference?,

    @InputProperty(
        section = "Tab Bar",
        title = "Label Selected Color",
        availableFrom = "0.0.1"
    )
    @DisplayIf("tabBar.value == true")
    val tabBarSelectedColor: ColorReference?,

    @InputProperty(
        section = "Tab Bar",
        title = "Label Unselected Color",
        availableFrom = "0.0.1"
    )
    @DisplayIf("tabBar.value == true")
    val tabBarUnselectedColor: ColorReference?,

    @InputProperty(
        section = "Tab Bar",
        title = "Label Size",
        availableFrom = "0.0.1"
    )
    @DisplayIf("tabBar.value == true")
    val tabBarLabelSize: FontReference?,

    @InputProperty(
        section = "Tab Bar",
        title = "Always Show Label",
        availableFrom = "0.0.1"
    )
    @DisplayIf("tabBar.value == true")
    @GenericDefault("true")
    val tabBarAlwaysShowLabels: Boolean?,

    @InputProperty(
        section = "Tab Bar",
        title = "Tab Items",
        description = "Max 5 tabs",
        availableFrom = "0.0.1"
    )
    @ListRange(
        min = 1,
        max = 5
    )
    @DisplayIf("tabBar.value == true")
    @AllowedComponent(NavigationItemComponent::class)
    val tabBarTabs: List<Component>,

    @InputProperty(
        section = "Navigation Drawer",
        title = "Drawer Content",
        availableFrom = "0.0.1"
    )
    @DisplayIf("navigationDrawer.value == true")
    @AlwaysContainer
    val drawerContent: Component?,

    @InputProperty(
        section = "Navigation Drawer",
        title = "Drawer Width",
        availableFrom = "0.0.1"
    )
    @DisplayIf("navigationDrawer.value == true")
    @GenericDefault("300")
    val navigationDrawerWidth: Int?,

    @InputProperty(
        section = "Preview ",
        title = "Show Navigation Drawer",
        availableFrom = "0.0.1"
    )
    @DisplayIf("navigationDrawer.value == true")
    val showNavigationDrawer: Boolean?,

    @InputProperty(
        section = "Navigation",
        title = "Root Screen",
        availableFrom = "0.0.1"
    )
    @DisplayIf("tabBar.value != true")
    val rootScreen: BlueprintLink?,
): Component