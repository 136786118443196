package com.appcreator.blueprint.firebase.auth

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class FirebaseAuthDeleteUserActionBuilder(
  action: FirebaseAuthDeleteUserAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "FirebaseAuthDeleteUserAction",
          title = "Delete User",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
      )

  override val inputSpecs: List<InputSpec> = listOf()

  override fun build(): Action = FirebaseAuthDeleteUserAction()
}
