package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class IconResponse(
    val full: String,
    val foreground: String?,
    val background: String?,
    val backgroundColor: String?
)

