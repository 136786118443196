package com.appcreator.compose.extensions

import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import com.appcreator.blueprint.core.properties.ColorValue

@Composable
fun ColorValue.composeColor(): Color = baseHex.toColor()
// TODO theme values
//    if(isSystemInDarkTheme()) {
//        (themeValues["dark"]?: baseHex).toColor()
//    } else {
//        (themeValues["light"]?: baseHex).toColor()
//    }

fun String.toColor(): Color {
    val color = if (length == 7) {
        "ff" + removePrefix("#").lowercase()
    } else {
        removePrefix("#").lowercase()
    }
    return Color(color.toLong(16))
}
