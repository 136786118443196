package com.appcreator.creatorapp.project.configurations

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.styles.ThemeMargins


@Composable
fun AndroidConfiguration(
    organisationResponse: OrganisationResponse,
    project: Project,
    openDownload: (String) -> Unit
) {
    LoadingComposable(loader = {
        mutableStateOf(it.getAndroidConfiguration(organisationResponse.organisation.id, project.id))
    }) {

        val client = LocalApiClient.current
        var display by remember { mutableStateOf(it.value) }

        Column(Modifier.padding(ThemeMargins.LargeMargins)) {
            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Package Name",
                value = display.packageId ?: "",
                onValueChange = {
                    display = display.copy(packageId = it)
                }
            )

            display.sha1?.let {
                Spacer(Modifier.height(ThemeMargins.SmallMargin))
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    singleLine = true,
                    readOnly = true,
                    label = "SHA1",
                    value = it,
                    onValueChange = {},
                    colors = OutlinedTextFieldDefaults.colors(
                        unfocusedContainerColor = Color.LightGray,
                        focusedContainerColor = Color.LightGray,
                    )
                )
            }
            display.sha256?.let {
                Spacer(Modifier.height(ThemeMargins.SmallMargin))
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    readOnly = true,
                    singleLine = true,
                    label = "SHA256",
                    value = it,
                    onValueChange = {},
                    colors = OutlinedTextFieldDefaults.colors(
                        unfocusedContainerColor = Color.LightGray,
                        focusedContainerColor = Color.LightGray,
                    )
                )
            }

            Spacer(Modifier.height(ThemeMargins.LargeMargins))
            LoadingButton(
                label = "Save Changes",
                enabled = it.value != display,
                action = {
                    client.setAndroidConfiguration(
                        organisationResponse.organisation.id,
                        project.id,
                        display
                    )
                    it.value = display
                }
            )
        }
    }

}
