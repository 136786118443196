package com.appcreator.blueprint.components

import com.appcreator.blueprint.components.navigation.BottomNavigationComponent
import com.appcreator.blueprint.components.navigation.NavigationComponent
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.ComponentDefault
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.DisplayInline
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.core.ScreenPath
import com.appcreator.blueprint.core.StringDefault
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Screen")
@ComponentClass(
    group = "",
    title = "Screen",
    availableFrom = "0.0.1",
    accessible = false
)
data class Screen(

    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "ID",
        hidden = true,
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    val id: String,

    @InputProperty(
        title = "Path",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    val path: ScreenPath?,

    @InputProperty(
        title = "Title",
        availableFrom = "0.0.1"
    )
    val title: LocalizableString?,

    @InputProperty(
        title = "Screen Type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("Screen.ScreenType.Content")
    val screenType: ScreenType?,

    @InputProperty(
        section = "Navigation Bar",
        title = "Color",
        availableFrom = "0.0.1"
    )
    @DisplayIf("screenType.value == Screen.ScreenType.Content.name")
    val navigationColor: ColorReference?,

    @InputProperty(
        section = "Navigation Bar",
        title = "Style",
        availableFrom = "0.0.1"
    )
    @DisplayIf("screenType.value == Screen.ScreenType.Content.name")
    @GenericDefault("Screen.NavigationStyle.Large")
    val navigationStyle: NavigationStyle,

    @InputProperty(
        section = "Navigation Bar",
        title = "Custom Title",
        availableFrom = "0.0.1"
    )
    @DisplayIf("screenType.value == Screen.ScreenType.Content.name")
    val navigationCustomTitle: Component?,

    @InputProperty(
        section = "Navigation Bar",
        title = "Content Color",
        availableFrom = "0.0.1"
    )
    @DisplayIf("screenType.value == Screen.ScreenType.Content.name")
    @GenericDefault("Screen.NavigationContent.Automatic")
    val navigationContent: NavigationContent,

    @InputProperty(
        section = "Navigation Bar",
        title = "Items",
        availableFrom = "0.0.1"
    )
    @DisplayIf("screenType.value == Screen.ScreenType.Content.name")
    val navigationItems: Component?,

    @InputProperty(
        section = "Content",
        title = "Container",
        availableFrom = "0.0.1"
    )
    @DisplayIf("screenType.value == Screen.ScreenType.Content.name")
    @AlwaysContainer
    val content: Component?,

    @InputProperty(
        section = "Navigation",
        title = "Navigation",
        availableFrom = "0.0.1"
    )
    @DisplayIf("screenType.value == Screen.ScreenType.Navigation.name")
    @ComponentDefault(NavigationComponent::class)
    @DisplayInline
    val navigation: Component?,

    @InputProperty(
        section = "Other",
        title = "On Appear Action",
        availableFrom = "0.0.1"
    )
    val onLoadAction: Action?

): Component {

    enum class NavigationStyle {
        Large,
        Inline,
        Hidden
    }

    enum class NavigationContent {
        Light,
        Dark,
        Automatic
    }

    enum class ScreenType {
        Navigation,
        Content,
    }

}
