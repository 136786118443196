package com.appcreator.creatorapp.editor.centerdata

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Api
import androidx.compose.material.icons.filled.Dashboard
import androidx.compose.material3.Icon
import androidx.compose.material3.NavigationRailItem
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import app_creator.app.generated.resources.Res
import app_creator.app.generated.resources.logo_firebase_28dp
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.creatorapp.editor.centerdata.firestore.FirestoreDataScreen
import com.appcreator.creatorapp.editor.centerdata.global.GlobalDataEditor
import com.appcreator.creatorapp.editor.centerdata.rest.RestData
import com.appcreator.creatorapp.editor.panelsidebar.SaveBarItem
import org.jetbrains.compose.resources.painterResource

@Composable
fun DataCenterModal(
    anchor: @Composable (() -> Unit) -> Unit,
) {
    ModalScaffold(
        anchor = anchor,
        header = {
            Text("Data center")
        },
        body = {
            DataCenterScreen()
        }
    )
}

enum class DataOptions(
    val displayName: String,
    val icon: @Composable () -> Unit,
    val screen: @Composable () -> Unit
) {
    Global(
        displayName = "Global Data",
        icon = { Icon(Icons.Default.Dashboard, contentDescription = null) },
        screen = { GlobalDataEditor() }
    ),
    Rest(
        displayName = "API Data",
        icon = { Icon(Icons.Default.Api, contentDescription = null) },
        screen = { RestData() }
    ),
    Firestore(
        displayName = "Firestore",
        icon = {
            Image(
                modifier = Modifier.size(28.dp),
                painter = painterResource(Res.drawable.logo_firebase_28dp),
                contentDescription = null
            )
        },
        screen = { FirestoreDataScreen() }
    )
}

@Composable
fun DataCenterScreen() {

    var selected by remember { mutableStateOf(DataOptions.Rest) }
    Row(Modifier.fillMaxSize()) {
        Column(modifier = Modifier) {

            SaveBarItem(false)

            Spacer(Modifier.height(24.dp))

            DataOptions.entries.forEach { option ->
                NavigationRailItem(
                    selected = selected == option,
                    onClick = {
                        selected = option
                    },
                    icon = option.icon,
                    label = {
                        Text(option.displayName)
                    }
                )
            }
        }

        VerticalDivider()

        selected.screen()
    }
}
