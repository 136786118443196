package com.appcreator.compose.actions.data

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.data.SetValueAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.actions.Performer

class SetValuePerformer(private val action: SetValueAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        val inputStore = LocalInputEnvStore.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.key?.let {
                    inputStore.set(it.value, envStore.injectVariables(action.value))
                }
            }
        }
    }
}
