package com.appcreator.creatorapp.editor.local

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.staticCompositionLocalOf
import com.appcreator.blueprint.core.Component

val LocalComponentClipboard = staticCompositionLocalOf<ComponentClipboard> {
    error("No instance of ComponentClipboard provided")
}

class ComponentClipboard {
    var components = mutableStateListOf<Component>()
}