package com.appcreator.creatorapp.editor.buildercondition

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.spec.ConditionBuilder
import com.appcreator.blueprint.spec.inputs.ConditionInputSpec
import com.appcreator.styles.ThemeMargins

private class SelectedCondition(
    val builder: ConditionBuilder,
    val remove: () -> Unit
)

@Composable
fun ConditionScreen(
    conditionSpec: ConditionInputSpec,
    save: () -> Unit
) {

    var selected by remember { mutableStateOf<SelectedCondition?>(null) }

    Row(modifier = Modifier.fillMaxSize()) {

        selected?.let {
            Row(modifier = Modifier
                .width(300.dp)
                .fillMaxHeight()
                .padding(horizontal = ThemeMargins.SmallMargin)) {
                Column(
                    Modifier.padding(end = ThemeMargins.SmallMargin)
                ) {
                    ConditionBuilder(
                        condition = it.builder,
                        save = save,
                        remove = {
                            it.remove()
                            selected = null
                            save()
                        }
                    )
                }
                VerticalDivider()
            }
        }

        ConditionsGraph(conditionSpec, save) { builder, remove ->
            selected = SelectedCondition(builder, remove)
        }
    }
}
