package com.appcreator.blueprint.components.logic

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@SerialName("Conditional")
@Serializable
@ComponentClass(
    group = "Logic",
    title = "Conditional",
    availableFrom = "0.0.1"
)
data class ConditionalComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "Condition",
        availableFrom = "0.0.1"
    )
    val condition: Condition?,
    @InputProperty(
        title = "Content",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

    @InputProperty(
        section = "Preview",
        title = "Show",
        availableFrom = "0.0.1"
    )
    @GenericDefault("true")
    val show: Boolean?

): Component
