package com.appcreator.blueprint.loaderspec

import com.appcreator.blueprint.core.DataShape
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.blueprint.core.UrlSpec
import com.appcreator.blueprint.core.findParameters
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("com.appbuilder.blueprint.loaderspec.JsonLoaderSpec") // TODO rename this and update old blueprints
data class JsonLoaderSpec(
    override val id: String,
    val urlSpec: UrlSpec = UrlSpec(""),
    override val dataShape: DataShape?,
    override val parameterSettings: Map<String, LoaderSpec.ParameterSetting>?
): LoaderSpec {
    override val parameters: Set<String>
        get() {
            val params = urlSpec.body.findParameters() +
                    urlSpec.url.findParameters() +
                    urlSpec.headers.map {
                        it.key.findParameters() + it.value.findParameters()
                    }.flatten() +
                    urlSpec.queries.map {
                        it.key.findParameters() + it.value.findParameters()
                    }.flatten()
            return params.toSet()
        }

}
