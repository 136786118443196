package com.appcreator.compose.components.logic

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.logic.ConditionalComponent
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator

@Composable
fun ConditionalComposable(modifier: Modifier, component: ConditionalComponent) {
    val envStore = LocalEnvStore.current
    component.content?.let { content ->
        val show = if(LocalInPreview.current) {
            component.show == true
        } else {
            component.condition?.let { Container.evaluator(it)?.evaluate(envStore) } ?: true
        }
        if(show) {
            ComponentComposable(modifier, content)
        }
    }
}
