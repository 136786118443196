package com.appcreator.dto.configurations

import kotlinx.serialization.Serializable


@Serializable
data class IOSConfigurationResponse(
    val issuerId: String?,
    val keyId: String?,
    val p8File: Boolean,
    val teamId: String?,
    val bundleIdentifier: String?
)

