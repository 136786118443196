package com.appcreator.creatorapp.crm

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.height
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.app.file.FileInfo
import com.appcreator.app.file.openFilePicker
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.FileResponse
import com.appcreator.styles.ThemeMargins
import io.ktor.utils.io.ByteReadChannel
import kotlinx.coroutines.launch


@Composable
fun ImageUploaderModal(
    orgId: String,
    projectId: String,
    onAdd: (FileResponse) -> Unit
) {

    var file by remember { mutableStateOf<FileInfo?>(null) }
    var title by remember { mutableStateOf("") }

    Column {

        Text("Upload New Image")

        CreatorTextInput(
            label = "Title",
            value = title,
            onValueChange = {
                title = it
            }
        )

        Box(Modifier.height(ThemeMargins.MedMargins))


        TextButton(onClick = {
            openFilePicker(
                accept = ".png,.jpg,.jpeg,.svg"
            ) {
                file = it
            }
        }) {
            Text(file?.let { "Change File" } ?: "Select File")
        }

        file?.let {
            Box(Modifier.height(ThemeMargins.MedMargins))
            Text("Selected: ${it.name}")
            Box(Modifier.height(ThemeMargins.MedMargins))
        }

        val apiClient = LocalApiClient.current
        val scope = rememberCoroutineScope()
        var loading by remember { mutableStateOf(false) }
        file?.let { file ->
            TextButton(
                enabled = title.isNotEmpty() && !loading,
                onClick = {
                    loading = true
                    scope.launch {
                        try {

                            val channel = ByteReadChannel(file.data)

                            val response = apiClient.uploadFile(
                                orgId = orgId,
                                projectId = projectId,
                                name = title,
                                fileName = file.name,
                                contentType = file.mimeType ?: "",
                                size = file.size,
                                file = channel
                            )
                            onAdd(response)
                        } catch (ex: Exception) {
                            ex.printStackTrace()
                            // TODO
                        }
                        loading = false
                    }

                }) {
                Text("Upload")
            }
        }
    }
}
