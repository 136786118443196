package com.appcreator.creatorapp.editor.panelsidebar

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateDpAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Undo
import androidx.compose.material.icons.filled.DataObject
import androidx.compose.material.icons.filled.FormatPaint
import androidx.compose.material.icons.filled.Image
import androidx.compose.material.icons.filled.Save
import androidx.compose.material.icons.filled.TypeSpecimen
import androidx.compose.material3.Icon
import androidx.compose.material3.NavigationRail
import androidx.compose.material3.NavigationRailItem
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import com.appcreator.app.utils.onHover
import com.appcreator.creatorapp.editor.centercolor.ColorCenterModal
import com.appcreator.creatorapp.editor.centerdata.DataCenterModal
import com.appcreator.creatorapp.editor.centerimages.ImageCenterModal
import com.appcreator.creatorapp.editor.centertypography.TypographyCenterModal
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.editor.local.LocalScreenHistory
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch


@Composable
fun LeftSidebarPanel(
    orgId: String,
    projectId: String
) {

    var hovering by remember { mutableStateOf(false) }
    val width by animateDpAsState(if (hovering) 180.dp else 64.dp)
    Row {
        NavigationRail(
            Modifier.width(width)
                .onHover {
                    hovering = it
                }
        ) {

            Spacer(Modifier.height(56.dp))
            Spacer(Modifier.height(ThemeMargins.LargeMargins))

            SaveBarItem(hovering)
            UndoSection(hovering)

            Spacer(Modifier.height(ThemeMargins.LargeMargins))

            DataCenterModal {
                BarItem(
                    showLabel = hovering,
                    icon = Icons.Default.DataObject,
                    label = "Data Center",
                    onClick = it
                )
            }

            ColorCenterModal {
                BarItem(
                    showLabel = hovering,
                    icon = Icons.Default.FormatPaint,
                    label = "Colors",
                    onClick = it
                )
            }

            TypographyCenterModal {
                BarItem(
                    showLabel = hovering,
                    icon = Icons.Default.TypeSpecimen,
                    label = "Typography",
                    onClick = it
                )
            }

            ImageCenterModal(orgId, projectId) {
                BarItem(
                    showLabel = hovering,
                    icon = Icons.Default.Image,
                    label = "Images",
                    onClick = it
                )
            }

        }
        VerticalDivider()
    }
}

@Composable
fun SaveBarItem(
    showLabel: Boolean = true
) {
    val blueprintManager = LocalBlueprintManager.current
    val scope = rememberCoroutineScope()
    BarItem(
        showLabel = showLabel,
        icon = Icons.Default.Save,
        enabled = blueprintManager.inDate.not(),
        label = "Save"
    ) {
        scope.launch {
            try {
                blueprintManager.save()
            } catch (ex: Exception) {
                ex.printStackTrace()
            }
        }
    }
}

@Composable
fun BarItem(
    showLabel: Boolean,
    icon: ImageVector,
    label: String,
    enabled: Boolean = true,
    onClick: () -> Unit
) {
    NavigationRailItem(false,
        onClick = onClick,
        enabled = enabled,
        icon = {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                Icon(icon, contentDescription = null)
                AnimatedVisibility(visible = showLabel) {
                    Text(
                        modifier = Modifier.width(110.dp).padding(start = 12.dp),
                        text = label,
                        maxLines = 1
                    )
                }
            }
        }
    )
}

@Composable
private fun UndoSection(
    hovering: Boolean
) {
    val blueprintManager = LocalBlueprintManager.current
    val history = LocalScreenHistory.current
    BarItem(
        showLabel = hovering,
        enabled = history.history.size > 1,
        icon = Icons.AutoMirrored.Default.Undo,
        label = "Undo",
        onClick = {
            history.history.removeLast()
            blueprintManager.revertTo(history.history.last())
        }
    )
}

