package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Percent
import androidx.compose.material.icons.filled.PhotoSizeSelectSmall
import androidx.compose.material.icons.filled.Pix
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.properties.SizeValue
import com.appcreator.blueprint.spec.inputs.SizeValueInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.Dropdown
import com.appcreator.components.layouts.DropdownItem

@Composable
fun SizeInput(spec: SizeValueInputSpec, save: () -> Unit) {
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = spec.properties.title,
        value = spec.value?.value?.toString()?: "",
        onValueChange = {
            spec.value = it.toIntOrNull()?.let { value ->
                if (value == 0) {
                    null
                } else {
                    when (spec.value) {
                        is SizeValue.Px -> SizeValue.Px(value)
                        else -> SizeValue.Percent(value)
                    }
                }
            }
            save()
        },
        leadingIcon = {
            Icon(Icons.Default.PhotoSizeSelectSmall, contentDescription = null)
        },
        trailingIcon = {
            Dropdown(anchor = {
                IconButton(onClick = it) {
                    val icon = when(spec.value) {
                        is SizeValue.Px -> Icons.Default.Pix
                        else -> Icons.Default.Percent
                    }
                    Icon(icon, contentDescription = null)
                }
            }) {
                DropdownItem("Percentage") {
                    spec.value = SizeValue.Percent(spec.value?.value ?: 100)
                    save()
                    it()
                }
                DropdownItem("Exact Pixel") {
                    spec.value = SizeValue.Px(spec.value?.value ?: 100)
                    save()
                    it()
                }
            }
        }
    )
}