package com.appcreator.creatorapp.local

import androidx.compose.runtime.staticCompositionLocalOf
import com.russhwolf.settings.Settings

object SettingsKeys {
    const val ShowingNodes = "ShowingNodes"
}

val LocalSettings = staticCompositionLocalOf {
    Settings()
}
