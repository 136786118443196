package com.appcreator.creatorapp.api

import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.UrlSpec
import com.appcreator.compose.api.requestJson
import io.ktor.client.HttpClient

interface DataShapeClient {
    suspend fun loadDataShape(urlSpec: UrlSpec, defaults: Map<String, String>?): List<DataSpec>
}

class KtorDataShapeClient(
    private val client: HttpClient,
    private val dataShapeParser: DataShapeParser,
): DataShapeClient {

    override suspend fun loadDataShape(urlSpec: UrlSpec, defaults: Map<String, String>?): List<DataSpec> {
        val envStore = EnvStore.create(defaultLocale = "GB", env = emptyMap())
        val injectedSpec = envStore.injectUrlSpec(urlSpec, emptyMap(), defaults?: emptyMap())
//        val json = try {
        val json = client.requestJson(injectedSpec)
// Cors proxy work around
//        } catch (ex: Throwable) {
//            ex.printStackTrace()
//            val corsSpec = UrlSpec(
//                url = "http://localhost:8080",
//                queries = mapOf("url" to injectedSpec.url),
//                body = urlSpec.body,
//                bodyType = urlSpec.bodyType,
//                headers = urlSpec.headers
//            )
//            client.requestJson(corsSpec)
//        }
        return dataShapeParser.parse(json)
    }

}


