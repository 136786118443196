package com.appcreator.blueprint.components.pager

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.LocalizableString
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("TappedPage")
@ComponentClass(
    group = "Pager",
    title = "Tapped Page",
    availableFrom = "0.0.1",
    accessible = false
)
data class TabbedPageComponent(
    override val _nodeId: String?,
    override val _nodeLabel: String?,

    @InputProperty(
        title = "Title Style",
        availableFrom = "0.0.1"
    )
    @GenericDefault("TabbedPageComponent.TitleStyle.Text")
    val titleStyle: TitleStyle?,

    @InputProperty(
        title = "Title",
        availableFrom = "0.0.1"
    )
    @DisplayIf("titleStyle.value == TabbedPageComponent.TitleStyle.Text.name")
    val title: LocalizableString?,

    @InputProperty(
        title = "Title",
        availableFrom = "0.0.1"
    )
    @DisplayIf("titleStyle.value == TabbedPageComponent.TitleStyle.Custom.name")
    @AlwaysContainer
    val titleContent: Component?,

    @InputProperty(
        title = "Selected Title",
        availableFrom = "0.0.1"
    )
    @DisplayIf("titleStyle.value == TabbedPageComponent.TitleStyle.Custom.name")
    @AlwaysContainer
    val selectedTitleContent: Component?,

    @InputProperty(
        title = "Content",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?

): Component {

    enum class TitleStyle {
        Text,
        Custom
    }

}


