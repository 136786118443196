package com.appcreator.creatorapp.editor.centerdata.firestore.sections

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.api.MutableDataSource
import com.appcreator.styles.ThemeMargins


@Composable
fun FirestoreRequest(
    data: MutableDataSource,
    spec: FirebaseFirestoreLoaderSpec,
    save: () -> Unit
) {

    val items = remember { mutableStateListOf(*(spec.dataValues ?: emptyList()).toTypedArray()) }
    items.forEachIndexed { index, value ->
        Row(
            modifier = Modifier.widthIn(max = 750.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(ThemeMargins.MedMargins)
        ) {
            CreatorTextInput(
                modifier = Modifier.weight(1f),
                label = "Key",
                value = value.key,
                onValueChange = {
                    items[index] = value.copy(key = it)
                    data.loaderSpec = spec.copy(dataValues = items)
                    save()
                },
                trailingIcon = {
                    IconButton(onClick = {
                        items.remove(value)
                        data.loaderSpec = spec.copy(dataValues = items)
                        save()
                    }) {
                        Icon(Icons.Default.Delete, contentDescription = null)
                    }
                }
            )
            CreatorTextInput(
                modifier = Modifier.weight(1f),
                label = "Preview Value",
                value = value.value ?: "",
                onValueChange = {
                    items[index] = value.copy(value = it)
                    data.loaderSpec = spec.copy(dataValues = items)
                    save()
                }
            )
            DropDownInputGroup(
                modifier = Modifier.width(180.dp),
                title = "Type",
                dropDownTitle = value.valueType?.name ?: "",
                dropDownContent = { close ->
                    ValueDataSpec.Type.entries.forEach {
                        DropdownItem(title = it.name) {
                            items[index] = value.copy(valueType = it)
                            data.loaderSpec = spec.copy(dataValues = items)
                            save()
                            close()
                        }
                    }
                }
            )
        }
    }

    TextButton(onClick = {
        items.add(ValueDataSpec("", valueType = ValueDataSpec.Type.String, "", true))
        data.loaderSpec = spec.copy(dataValues = items)
        save()
    }) {
        Text("Add Value")
    }

}
