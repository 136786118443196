package com.appcreator.compose.di

import com.appcreator.blueprint.ScreenSpec
import com.appcreator.blueprint.actions.AlertAction
import com.appcreator.blueprint.actions.ConditionAction
import com.appcreator.blueprint.actions.SequenceAction
import com.appcreator.blueprint.actions.TriggerAction
import com.appcreator.blueprint.actions.alerts.SnackbarAlertAction
import com.appcreator.blueprint.actions.data.AddListItemAction
import com.appcreator.blueprint.actions.data.ApiRequestAction
import com.appcreator.blueprint.actions.data.DataConversionAction
import com.appcreator.blueprint.actions.data.RemoveListItemAction
import com.appcreator.blueprint.actions.data.SetTitleAction
import com.appcreator.blueprint.actions.data.SetValueAction
import com.appcreator.blueprint.actions.navigation.CloseScreenAction
import com.appcreator.blueprint.actions.navigation.OpenExternalAction
import com.appcreator.blueprint.actions.navigation.OpenScreenAction
import com.appcreator.blueprint.actions.navigation.SetNavigationDrawerAction
import com.appcreator.blueprint.actions.permission.PermissionRequestAction
import com.appcreator.blueprint.blueprintSerializer
import com.appcreator.blueprint.components.CustomClientComponent
import com.appcreator.blueprint.components.CustomComponent
import com.appcreator.blueprint.components.basic.ButtonComponent
import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.components.basic.ImageComponent
import com.appcreator.blueprint.components.forms.InputComponent
import com.appcreator.blueprint.components.basic.MaterialIconComponent
import com.appcreator.blueprint.components.basic.TextComponent
import com.appcreator.blueprint.components.basic.VideoComponent
import com.appcreator.blueprint.components.basic.WebViewComponent
import com.appcreator.blueprint.components.data.CountdownComponent
import com.appcreator.blueprint.components.data.DataConversionComponent
import com.appcreator.blueprint.components.data.DataRepeaterComponent
import com.appcreator.blueprint.components.data.DatePartsComponent
import com.appcreator.blueprint.components.data.LoadingComponent
import com.appcreator.blueprint.components.forms.LocalStateComponent
import com.appcreator.blueprint.components.data.PagedLoaderComponent
import com.appcreator.blueprint.components.logic.ABComponent
import com.appcreator.blueprint.components.logic.ConditionalComponent
import com.appcreator.blueprint.components.forms.DatePickerModalComponent
import com.appcreator.blueprint.components.modal.DialogComponent
import com.appcreator.blueprint.components.modal.SheetComponent
import com.appcreator.blueprint.components.navigation.NavigationComponent
import com.appcreator.blueprint.components.navigation.NavigationDrawerComponent
import com.appcreator.blueprint.components.pager.TabbedPagerComponent
import com.appcreator.blueprint.conditions.JavascriptCondition
import com.appcreator.blueprint.conditions.data.ContainsItemCondition
import com.appcreator.blueprint.conditions.data.DateCondition
import com.appcreator.blueprint.conditions.data.EqualsCondition
import com.appcreator.blueprint.conditions.data.NumberCompareCondition
import com.appcreator.blueprint.conditions.logic.AndCondition
import com.appcreator.blueprint.conditions.logic.NotCondition
import com.appcreator.blueprint.conditions.logic.OrCondition
import com.appcreator.blueprint.conditions.permission.CheckPermissionCondition
import com.appcreator.blueprint.conversions.BasicMathsConversion
import com.appcreator.blueprint.conversions.DateAdditionConversion
import com.appcreator.blueprint.conversions.DateFormatConversion
import com.appcreator.blueprint.conversions.JavascriptConversion
import com.appcreator.blueprint.conversions.ListCountConversion
import com.appcreator.blueprint.conversions.NowConversion
import com.appcreator.blueprint.conversions.TodayConversion
import com.appcreator.blueprint.conversions.UUIDConversion
import com.appcreator.blueprint.conversions.UppercaseConversion
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.loaderspec.JsonLoaderSpec
import com.appcreator.blueprint.loaderspec.ScreenLoaderSpec
import com.appcreator.compose.BlueprintProvider
import com.appcreator.compose.CustomClientComponentComponent
import com.appcreator.compose.CustomComponentComposable
import com.appcreator.compose.ScreenSpecComposable
import com.appcreator.compose.SharedContainer
import com.appcreator.compose.actions.AlertPerformer
import com.appcreator.compose.actions.ConditionPerformer
import com.appcreator.compose.actions.SequencePerformer
import com.appcreator.compose.actions.TriggerPerformer
import com.appcreator.compose.actions.alert.SnackbarAlertPerformer
import com.appcreator.compose.actions.data.AddListItemPerformer
import com.appcreator.compose.actions.data.ApiRequestPerformer
import com.appcreator.compose.actions.data.DataConversionPerformer
import com.appcreator.compose.actions.data.RemoveListItemPerformer
import com.appcreator.compose.actions.data.SetTitlePerformer
import com.appcreator.compose.actions.data.SetValuePerformer
import com.appcreator.compose.actions.navigation.CloseScreenPerformer
import com.appcreator.compose.actions.navigation.OpenExternalPerformer
import com.appcreator.compose.actions.navigation.OpenScreenPerformer
import com.appcreator.compose.actions.navigation.SetNavigationDrawerPerformer
import com.appcreator.compose.actions.permission.PermissionRequestPerformer
import com.appcreator.compose.components.basic.ButtonComposable
import com.appcreator.compose.components.basic.ContainerComposable
import com.appcreator.compose.components.basic.ImageComposable
import com.appcreator.compose.components.basic.InputComposable
import com.appcreator.compose.components.basic.MaterialIconComposable
import com.appcreator.compose.components.basic.TextComposable
import com.appcreator.compose.components.basic.VideoComposable
import com.appcreator.compose.components.basic.WebViewComposable
import com.appcreator.compose.components.data.CountdownComposable
import com.appcreator.compose.components.data.DataConversionComposable
import com.appcreator.compose.components.data.DataRepeaterComposable
import com.appcreator.compose.components.data.DatePartsComposable
import com.appcreator.compose.components.data.LoadingComposable
import com.appcreator.compose.components.data.LocalStateComposable
import com.appcreator.compose.components.data.PagedLoaderComposable
import com.appcreator.compose.components.logic.ABComposable
import com.appcreator.compose.components.logic.ConditionalComposable
import com.appcreator.compose.components.modal.DataPickerModalComposable
import com.appcreator.compose.components.modal.DialogComposable
import com.appcreator.compose.components.modal.SheetComposable
import com.appcreator.compose.components.navigation.NavigationComposable
import com.appcreator.compose.components.navigation.NavigationDrawerComposable
import com.appcreator.compose.components.pager.TabbedPagerComposable
import com.appcreator.compose.conditions.JavascriptEvaluator
import com.appcreator.compose.conditions.data.ContainsItemEvaluator
import com.appcreator.compose.conditions.data.DateEvaluator
import com.appcreator.compose.conditions.data.EqualsEvaluator
import com.appcreator.compose.conditions.data.NumberCompareEvaluator
import com.appcreator.compose.conditions.logic.AndEvaluator
import com.appcreator.compose.conditions.logic.NotEvaluator
import com.appcreator.compose.conditions.logic.OrEvaluator
import com.appcreator.compose.conditions.permission.CheckPermissionEvaluator
import com.appcreator.compose.conversions.BasicMathsConvertor
import com.appcreator.compose.conversions.DateAdditionConvertor
import com.appcreator.compose.conversions.DateFormatConvertor
import com.appcreator.compose.conversions.JavascriptConvertor
import com.appcreator.compose.conversions.ListCountConvertor
import com.appcreator.compose.conversions.NowConvertor
import com.appcreator.compose.conversions.TodayConvertor
import com.appcreator.compose.conversions.UUIDConvertor
import com.appcreator.compose.conversions.UppercaseConvertor
import com.appcreator.compose.extensions.JSEvaluationConfig
import com.appcreator.compose.loaders.JsonLoader
import com.appcreator.compose.loaders.ScreenFetcher
import com.appcreator.compose.loaders.ScreenLoader
import io.ktor.client.HttpClient
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.PolymorphicModuleBuilder
import kotlinx.serialization.modules.SerializersModule

object Setup {

    var developmentMode: Boolean = false
    lateinit var json: Json
    lateinit var httpClient: HttpClient
    lateinit var apiToken: String
    lateinit var blueprintProvider: BlueprintProvider

    fun initialize(
        screenFetcher: (Json) -> ScreenFetcher,
        blueprintProvider: (Json) -> BlueprintProvider,
        jsEvaluationConfig: JSEvaluationConfig,
        developmentMode: Boolean = false,
        apiToken: String = "",
        componentSerializers: PolymorphicModuleBuilder<Component>.() -> Unit = {},
        componentRegister: (Container) -> Unit = {}
    ) {

        this.developmentMode = developmentMode
        this.apiToken = apiToken

        json = Json {
            serializersModule = SerializersModule {
                blueprintSerializer(components = componentSerializers)()
            }
            ignoreUnknownKeys = true
            encodeDefaults = true
            explicitNulls = false
            isLenient = true
        }
        httpClient = HttpClient {
            install(ContentNegotiation) { json(json) }
            expectSuccess = true
        }

        this.blueprintProvider = blueprintProvider(json)

        val sharedContainer = SharedContainer(jsEvaluationConfig)
        Container.jsConfig = jsEvaluationConfig
        Container.register(sharedContainer, screenFetcher(json))
        componentRegister(Container)
    }

    fun onNewPushToken(token: String) {

    }


}

private fun Container.register(shared: SharedContainer, screenFetcher: ScreenFetcher) {

    // Components
    registerComponent(ScreenSpec::class) { modifier, component -> ScreenSpecComposable(modifier, component) }
    registerComponent(NavigationComponent::class) { modifier, component -> NavigationComposable(modifier, component) }

    registerComponent(ButtonComponent::class) { modifier, component -> ButtonComposable(modifier, component) }
    registerComponent(ImageComponent::class) { modifier, component -> ImageComposable(modifier, component) }
    registerComponent(ContainerComponent::class) { modifier, component -> ContainerComposable(modifier, component) }
    registerComponent(LoadingComponent::class) { modifier, component -> LoadingComposable(modifier, component) }
    registerComponent(TextComponent::class) { modifier, component -> TextComposable(modifier, component) }
    registerComponent(DataRepeaterComponent::class) { modifier, component -> DataRepeaterComposable(modifier, component) }
    registerComponent(MaterialIconComponent::class) { modifier, component -> MaterialIconComposable(modifier, component) }
    registerComponent(InputComponent::class) { modifier, component -> InputComposable(modifier, component) }
    registerComponent(ABComponent::class) { modifier, component -> ABComposable(modifier, component) }
    registerComponent(ConditionalComponent::class) { modifier, component -> ConditionalComposable(modifier, component) }
    registerComponent(NavigationDrawerComponent::class) { modifier, component -> NavigationDrawerComposable(modifier, component) }
    registerComponent(CountdownComponent::class) { modifier, component -> CountdownComposable(modifier, component) }
    registerComponent(PagedLoaderComponent::class) { modifier, component -> PagedLoaderComposable(modifier, component) }
    registerComponent(SheetComponent::class) { modifier, component -> SheetComposable(modifier, component) }
    registerComponent(DialogComponent::class) { modifier, component -> DialogComposable(modifier, component) }
    registerComponent(DatePickerModalComponent::class) { modifier, component -> DataPickerModalComposable(modifier, component) }
    registerComponent(LocalStateComponent::class) { modifier, component -> LocalStateComposable(modifier, component) }
    registerComponent(DataConversionComponent::class) { modifier, component -> DataConversionComposable(modifier, component) }
    registerComponent(DatePartsComponent::class) { modifier, component -> DatePartsComposable(modifier, component) }
    registerComponent(TabbedPagerComponent::class) { modifier, component -> TabbedPagerComposable(modifier, component) }
    registerComponent(VideoComponent::class) { modifier, component -> VideoComposable(modifier, component) }
    registerComponent(WebViewComponent::class) { modifier, component -> WebViewComposable(modifier, component) }

    registerComponent(CustomComponent::class) { modifier, component -> CustomComponentComposable(modifier, component) }
    registerComponent(CustomClientComponent::class) { modifier, component -> CustomClientComponentComponent(modifier, component) }

    // Loaders
    registerLoader(ScreenLoaderSpec::class) { ScreenLoader(screenFetcher, it) }
    registerLoader(JsonLoaderSpec::class) { JsonLoader(shared.jsonFetcher, it) }

    // Conditions
    registerCondition(EqualsCondition::class) { EqualsEvaluator(it) }
    registerCondition(AndCondition::class) { AndEvaluator(it) }
    registerCondition(OrCondition::class) { OrEvaluator(it) }
    registerCondition(NotCondition::class) { NotEvaluator(it) }
    registerCondition(JavascriptCondition::class) { JavascriptEvaluator(shared.jsEvaluationConfig, it) }
    registerCondition(DateCondition::class) { DateEvaluator(it) }
    registerCondition(ContainsItemCondition::class) { ContainsItemEvaluator(it) }
    registerCondition(NumberCompareCondition::class) { NumberCompareEvaluator(it) }
    registerCondition(CheckPermissionCondition::class) { CheckPermissionEvaluator(it) }

    // Actions
    registerAction(OpenScreenAction::class) { OpenScreenPerformer(it) }
    registerAction(CloseScreenAction::class) { CloseScreenPerformer(it) }
    registerAction(ConditionAction::class) { ConditionPerformer(it) }
    registerAction(SequenceAction::class) { SequencePerformer(it) }
    registerAction(AlertAction::class) { AlertPerformer(it) }
    registerAction(SetValueAction::class) { SetValuePerformer(it) }
    registerAction(AddListItemAction::class) { AddListItemPerformer(it) }
    registerAction(RemoveListItemAction::class) { RemoveListItemPerformer(it) }
    registerAction(TriggerAction::class) { TriggerPerformer(it) }
    registerAction(ApiRequestAction::class) { ApiRequestPerformer(it) }
    registerAction(DataConversionAction::class) { DataConversionPerformer(it) }
    registerAction(PermissionRequestAction::class) { PermissionRequestPerformer(it) }
    registerAction(OpenExternalAction::class) { OpenExternalPerformer(it) }
    registerAction(SetNavigationDrawerAction::class) { SetNavigationDrawerPerformer(it) }
    registerAction(SetTitleAction::class) { SetTitlePerformer(it) }
    registerAction(SnackbarAlertAction::class) { SnackbarAlertPerformer(it) }

    // Conversions
    registerConversion(BasicMathsConversion::class) { BasicMathsConvertor(it) }
    registerConversion(DateAdditionConversion::class) { DateAdditionConvertor(it) }
    registerConversion(DateFormatConversion::class) { DateFormatConvertor(it) }
    registerConversion(JavascriptConversion::class) { JavascriptConvertor(it) }
    registerConversion(ListCountConversion::class) { ListCountConvertor(it) }
    registerConversion(NowConversion::class) { NowConvertor(it) }
    registerConversion(TodayConversion::class) { TodayConvertor(it) }
    registerConversion(UppercaseConversion::class) { UppercaseConvertor(it) }
    registerConversion(UUIDConversion::class) { UUIDConvertor(it) }
}
