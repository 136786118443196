package com.appcreator.compose.components.basic

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.compositeOver
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.components.data.DataRepeaterComponent
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalHorizontalScrollingParent
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.LocalVerticalScrollingParent
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.data.dataRepeaterComposable
import com.appcreator.compose.components.data.rememberRepeaterItems
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer
import com.appcreator.compose.extensions.SizingView
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.toPadding
import com.appcreator.compose.extensions.toShape
import com.valentinilk.shimmer.shimmer
import kotlinx.coroutines.launch

@Composable
fun ContainerComposable(modifier: Modifier, component: ContainerComponent) {
    val theme = LocalTheme.current

    val padding = component.padding?.toPadding() ?: PaddingValues()
    val margin = component.margin?.toPadding() ?: PaddingValues()

    SizingView(
        component.width,
        component.height,
        component.minWidth,
        component.maxWidth,
        component.minHeight,
        component.maxHeight,
        padding, margin
    ) { mod, _, _ ->
        val updatedModifier = mod.then(modifier)
            .let { mod ->
                component.margin?.let { mod.padding(margin) } ?: mod
            }
            .let { mod ->
                if (component.shimmer == true) mod.shimmer()
                else mod
            }
            .let { mod ->
                component.borderRadius?.let { mod.clip(it.toShape()) } ?: mod
            }
            .let { mod ->
                when(component.backgroundType) {
                    ContainerComponent.Background.Color -> component.backgroundColor?.let { ref ->
                        theme?.color(ref)?.let { color ->
                            val alpha = component.opacity?.toFloatOrNull()?: 1f
                            val composeColor = color.composeColor()
                            if (component.gradient == true) {
                                val brush = remember {
                                    val black = Color.Black
                                    val white = Color.White
                                    val overlay = composeColor.copy(alpha = 0.9f)
                                    val start = overlay.compositeOver(white)
                                    val end = overlay.compositeOver(black)
                                    Brush.verticalGradient(listOf(start, end))
                                }
                                mod.background(brush = brush)
                            } else {
                                mod.background(composeColor.copy(alpha = alpha))
                            }
                        }
                    } ?: mod
                    ContainerComponent.Background.Image -> mod.backgroundImage(component.backgroundImage, component.opacity)
                    else -> mod
                }
            }.let { mod ->
                val borderRadius = component.borderRadius
                val borderThickness = component.borderThickness ?: 0
                val borderColor =
                    theme?.color(component.borderColor)?.composeColor() ?: Color.Unspecified
                mod.border(
                    borderThickness.dp,
                    borderColor,
                    borderRadius?.toShape() ?: RoundedCornerShape(0.dp)
                )
            }.let { mod ->
                component.action?.let {
                    val deferred = Container.performer(it)?.deferred()
                    val envStore = LocalEnvStore.current
                    val scope = rememberCoroutineScope()
                    mod.clickable {
                        scope.launch {
                            try {
                                deferred?.perform(envStore)
                            } catch (ex: Exception) {
                                ex.printStackTrace()
                                // TODO handle error
                            }
                        }
                    }
                } ?: mod
            }.let { mod ->
                component.viewOpacity?.toFloatOrNull()?.let {
                    mod.alpha(it)
                }?: mod
            }

        val paddingValues = component.padding?.toPadding() ?: PaddingValues(0.dp)
        when (component.axis) {
            ContainerComponent.Axis.X -> RowInternal(
                modifier = updatedModifier,
                paddingValues = paddingValues,
                scrollable = component.scrolling,
                keepOffScreenViews = component.keepOffScreenViewsAlive == true,
                horizontalArrangement = when (component.spacing) {
                    ContainerComponent.Spacing.ByValue -> Arrangement.spacedBy(
                        (component.spacingValue ?: 0).dp
                    )

                    ContainerComponent.Spacing.Between -> Arrangement.SpaceBetween
                    ContainerComponent.Spacing.Around -> Arrangement.SpaceAround
                    else -> when (component.horizontalAlignment) {
                        ContainerComponent.HorizontalAlignment.Center -> Arrangement.Center
                        ContainerComponent.HorizontalAlignment.End -> Arrangement.End
                        else -> Arrangement.Start
                    }
                },
                verticalAlignment = when (component.verticalAlignment) {
                    ContainerComponent.VerticalAlignment.Center -> Alignment.CenterVertically
                    ContainerComponent.VerticalAlignment.Bottom -> Alignment.Bottom
                    else -> Alignment.Top
                },
                content = component.content
            )

            ContainerComponent.Axis.Y -> ColumnInternal(
                modifier = updatedModifier,
                paddingValues = paddingValues,
                scrollable = component.scrolling,
                keepOffScreenViews = component.keepOffScreenViewsAlive == true,
                horizontalAlignment = when (component.horizontalAlignment) {
                    ContainerComponent.HorizontalAlignment.Center -> Alignment.CenterHorizontally
                    ContainerComponent.HorizontalAlignment.End -> Alignment.End
                    else -> Alignment.Start
                },
                verticalArrangement = when (component.spacing) {
                    ContainerComponent.Spacing.ByValue -> Arrangement.spacedBy(
                        (component.spacingValue ?: 0).dp
                    )

                    ContainerComponent.Spacing.Between -> Arrangement.SpaceBetween
                    ContainerComponent.Spacing.Around -> Arrangement.SpaceAround
                    else -> when (component.verticalAlignment) {
                        ContainerComponent.VerticalAlignment.Center -> Arrangement.Center
                        ContainerComponent.VerticalAlignment.Bottom -> Arrangement.Bottom
                        else -> Arrangement.Top
                    }
                },
                content = component.content
            )

            ContainerComponent.Axis.Z -> Box(
                modifier = updatedModifier.padding(paddingValues),
                contentAlignment = when (component.verticalAlignment) {
                    ContainerComponent.VerticalAlignment.Center -> when (component.horizontalAlignment) {
                        ContainerComponent.HorizontalAlignment.Center -> Alignment.Center
                        ContainerComponent.HorizontalAlignment.End -> Alignment.CenterEnd
                        else -> Alignment.CenterStart
                    }

                    ContainerComponent.VerticalAlignment.Bottom -> when (component.horizontalAlignment) {
                        ContainerComponent.HorizontalAlignment.Center -> Alignment.BottomCenter
                        ContainerComponent.HorizontalAlignment.End -> Alignment.BottomEnd
                        else -> Alignment.BottomEnd
                    }

                    else -> when (component.horizontalAlignment) {
                        ContainerComponent.HorizontalAlignment.Center -> Alignment.TopCenter
                        ContainerComponent.HorizontalAlignment.End -> Alignment.TopEnd
                        else -> Alignment.TopStart
                    }
                }
            ) {
                component.content.forEach {
                    ComponentComposable(Modifier, it)
                }
            }
        }
    }
}

@Composable
private fun RowInternal(
    modifier: Modifier,
    paddingValues: PaddingValues,
    scrollable: Boolean,
    keepOffScreenViews: Boolean,
    horizontalArrangement: Arrangement.Horizontal,
    verticalAlignment: Alignment.Vertical,
    content: List<Component>
) {
    val shouldScroll = scrollable && !LocalHorizontalScrollingParent.current
    if (shouldScroll && !keepOffScreenViews) {
        val envStore = LocalEnvStore.current
        val repeaters = remember { mutableStateMapOf<String, List<EnvStore>?>() }
        content.forEach {
            if (it is DataRepeaterComponent) {
                it._nodeId?.let { nodeId ->
                    repeaters[nodeId] = rememberRepeaterItems(envStore, it)
                }
            }
        }
        CompositionLocalProvider(LocalHorizontalScrollingParent provides true) {
            LazyRow(
                modifier = modifier,
                horizontalArrangement = horizontalArrangement,
                verticalAlignment = verticalAlignment,
                contentPadding = paddingValues
            ) {
                content.forEach {
                    if (it is DataRepeaterComponent) {
                        dataRepeaterComposable(it, repeaters[it._nodeId])
                    } else {
                        item {
                            ComponentComposable(Modifier, it)
                        }
                    }
                }
            }
        }
    } else if(shouldScroll) {
        CompositionLocalProvider(LocalHorizontalScrollingParent provides true) {
            Row(
                modifier = modifier
                    .horizontalScroll(rememberScrollState())
                    .padding(paddingValues),
                horizontalArrangement = horizontalArrangement,
                verticalAlignment = verticalAlignment
            ) {
                content.forEach {
                    ComponentComposable(Modifier, it)
                }
            }
        }
    } else {
        Row(
            modifier = modifier.padding(paddingValues),
            horizontalArrangement = horizontalArrangement,
            verticalAlignment = verticalAlignment
        ) {
            content.forEach {
                ComponentComposable(Modifier, it)
            }
        }
    }
}

@Composable
private fun ColumnInternal(
    modifier: Modifier,
    paddingValues: PaddingValues,
    scrollable: Boolean,
    keepOffScreenViews: Boolean,
    verticalArrangement: Arrangement.Vertical,
    horizontalAlignment: Alignment.Horizontal,
    content: List<Component>
) {
    val shouldScroll = scrollable && !LocalVerticalScrollingParent.current
    if (shouldScroll && !keepOffScreenViews) {
        val envStore = LocalEnvStore.current
        val repeaters = remember { mutableStateMapOf<String, List<EnvStore>?>() }
        content.forEach {
            if (it is DataRepeaterComponent) {
                it._nodeId?.let { nodeId ->
                    repeaters[nodeId] = rememberRepeaterItems(envStore, it)
                }
            }
        }

        CompositionLocalProvider(LocalVerticalScrollingParent provides true) {
            LazyColumn(
                modifier = modifier,
                verticalArrangement = verticalArrangement,
                horizontalAlignment = horizontalAlignment,
                contentPadding = paddingValues
            ) {
                content.forEach {
                    if (it is DataRepeaterComponent) {
                        dataRepeaterComposable(it, repeaters[it._nodeId])
                    } else {
                        item {
                            ComponentComposable(Modifier, it)
                        }
                    }
                }
            }
        }
    } else if (shouldScroll) {
        CompositionLocalProvider(LocalVerticalScrollingParent provides true) {
            Column(
                modifier = modifier
                    .verticalScroll(rememberScrollState())
                    .padding(paddingValues),
                verticalArrangement = verticalArrangement,
                horizontalAlignment = horizontalAlignment
            ) {
                content.forEach {
                    ComponentComposable(Modifier, it)
                }
            }
        }
    } else {
        Column(
            modifier = modifier.padding(paddingValues),
            verticalArrangement = verticalArrangement,
            horizontalAlignment = horizontalAlignment
        ) {
            content.forEach {
                ComponentComposable(Modifier, it)
            }
        }
    }
}