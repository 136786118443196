package com.appcreator.compose.conversions

import com.appcreator.blueprint.conversions.UUIDConversion
import com.appcreator.blueprint.core.EnvStore
import kotlinx.serialization.json.JsonElement
import kotlin.uuid.ExperimentalUuidApi
import kotlin.uuid.Uuid

data class UUIDConvertor(
    val conversion: UUIDConversion
): Convertor {
    @OptIn(ExperimentalUuidApi::class)
    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String? {
        return Uuid.random().toString()
    }

}
