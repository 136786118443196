package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.MutableState
import com.appcreator.blueprint.core.LoaderConfig
import com.appcreator.blueprint.spec.SingleInputSpec

class LoaderConfigInputSpec(
    properties: InputProperties,
    value: MutableState<LoaderConfig?>
): SingleInputSpec<LoaderConfig?>(properties, value)
