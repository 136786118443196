package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class Font(
    val id: String,
    val name: String,
    val fontName: String,
    val fontSize: Int,
    val fontWeight: Int,
    val userCreated: Boolean?
)

@Serializable
data class FontReference(
    val id: String?,
    val custom: Font?
)
