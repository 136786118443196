package com.appcreator.compose.actions.permission

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.permission.PermissionRequestAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.actions.Performer

@Composable
actual fun PermissionRequestPerformerDeferred(action: PermissionRequestAction): Performer.Deferred {
    return object : Performer.Deferred {
        override suspend fun perform(envStore: EnvStore) {
            println("On mobile would request permission for ${action.permission}")
        }
    }
}
