package com.appcreator.local

import androidx.compose.runtime.State
import androidx.compose.runtime.staticCompositionLocalOf

sealed class UserState {
    data object Unknown: UserState()
    open class Known: UserState()
    data object LoggedOut: Known()
    open class LoggedIn(
        val email: String?,
        val token: suspend () -> String
    ): Known()
    class Firebase(email: String?, token: suspend () -> String): LoggedIn(email, token)
}

val LocalUserState = staticCompositionLocalOf<State<UserState>> {
    error("No instance of FirebaseUser provided")
}
