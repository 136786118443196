package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Link
import androidx.compose.material.icons.filled.LinkOff
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.CornerValues
import com.appcreator.blueprint.core.properties.SideValues
import com.appcreator.blueprint.spec.inputs.CornerValuesInputSpec
import com.appcreator.blueprint.spec.inputs.SideValuesInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.styles.ThemeMargins


@Composable
fun SideValuesInput(spec: SideValuesInputSpec, save: () -> Unit) {
    val default = SideValues(true, null, null, null, null)
    val value = spec.value?: default

    val updateAll: (Int) -> Unit = {
        spec.value = SideValues(true, it, it, it, it)
    }

    Box(Modifier.fillMaxWidth()) {

        Text(text = spec.properties.title)

        Column(
            modifier = Modifier.align(Alignment.Center).padding(start = ThemeMargins.LargeMargins),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            CreatorTextInput(
                modifier = Modifier.width(80.dp),
                label = "Top",
                value = value.top?.toString() ?: "0",
                onValueChange = { update ->
                    update.toIntOrNull()?.let {
                        if(value.locked) {
                            updateAll(it)
                        } else {
                            spec.value = (spec.value ?: default).copy(top = it)
                        }
                        save()
                    }
                }
            )

            Row(verticalAlignment = Alignment.CenterVertically) {
                CreatorTextInput(
                    modifier = Modifier.width(80.dp),
                    label = "Start",
                    value = value.start?.toString() ?: "0",
                    onValueChange = { update ->
                        update.toIntOrNull()?.let {
                            if(value.locked) {
                                updateAll(it)
                            } else {
                                spec.value = (spec.value ?: default).copy(start = it)
                            }
                            save()
                        }
                    }
                )

                IconButton(onClick = {
                    spec.value = (spec.value?: default).copy(locked = !value.locked)
                }) {
                    Icon(
                        if(value.locked) Icons.Default.Link else Icons.Default.LinkOff,
                        contentDescription = null
                    )
                }

                CreatorTextInput(
                    modifier = Modifier.width(80.dp),
                    label = "End",
                    value = value.end?.toString() ?: "0",
                    onValueChange = { update ->
                        update.toIntOrNull()?.let {
                            if(value.locked) {
                                updateAll(it)
                            } else {
                                spec.value = (spec.value ?: default).copy(end = it)
                            }
                            save()
                        }
                    }
                )
            }
            CreatorTextInput(
                modifier = Modifier.width(80.dp),
                label = "Bottom",
                value = value.bottom?.toString() ?: "0",
                onValueChange = { update ->
                    update.toIntOrNull()?.let {
                        if(value.locked) {
                            updateAll(it)
                        } else {
                            spec.value = (spec.value ?: default).copy(bottom = it)
                        }
                        save()
                    }
                }
            )
        }
    }
}

@Composable
fun CornerValuesInput(spec: CornerValuesInputSpec, save: () -> Unit) {
    val default = CornerValues(true, null, null, null, null)
    val value = spec.value ?: default

    val updateAll: (Int) -> Unit = {
        spec.value = CornerValues(true, it, it, it, it)
    }


    Text(text = spec.properties.title)

    Box(Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {

        Column {

            Row {

                CreatorTextInput(
                    modifier = Modifier.width(80.dp),
                    label = "Top Left",
                    value = value.topStart?.toString() ?: "0",
                    onValueChange = { update ->
                        update.toIntOrNull()?.let {
                            if (value.locked) {
                                updateAll(it)
                            } else {
                                spec.value = (spec.value ?: default).copy(topStart = it)
                            }
                            save()
                        }
                    }
                )

                Spacer(Modifier.width(ThemeMargins.SmallMargin))

                CreatorTextInput(
                    modifier = Modifier.width(80.dp),
                    label = "Top Right",
                    value = value.topEnd?.toString() ?: "0",
                    onValueChange = { update ->
                        update.toIntOrNull()?.let {
                            if (value.locked) {
                                updateAll(it)
                            } else {
                                spec.value = (spec.value ?: default).copy(topEnd = it)
                            }
                            save()
                        }
                    }
                )
            }

            Spacer(Modifier.height(ThemeMargins.SmallMargin))

            Row {

                CreatorTextInput(
                    modifier = Modifier.width(80.dp),
                    label = "Bottom Left",
                    value = value.bottomStart?.toString() ?: "0",
                    onValueChange = { update ->
                        update.toIntOrNull()?.let {
                            if (value.locked) {
                                updateAll(it)
                            } else {
                                spec.value = (spec.value ?: default).copy(bottomStart = it)
                            }
                            save()
                        }
                    }
                )

                Spacer(Modifier.width(ThemeMargins.SmallMargin))

                CreatorTextInput(
                    modifier = Modifier.width(80.dp),
                    label = "Bottom Right",
                    value = value.bottomEnd?.toString() ?: "0",
                    onValueChange = { update ->
                        update.toIntOrNull()?.let {
                            if (value.locked) {
                                updateAll(it)
                            } else {
                                spec.value = (spec.value ?: default).copy(bottomEnd = it)
                            }
                            save()
                        }
                    }
                )
            }
        }

        IconButton(
            modifier = Modifier
                .padding(top = 6.dp)
                .align(alignment = Alignment.Center),
            onClick = {
                spec.value = (spec.value ?: default).copy(locked = !value.locked)
            }) {
            Icon(
                if (value.locked) Icons.Default.Link else Icons.Default.LinkOff,
                contentDescription = null
            )
        }
    }
}

