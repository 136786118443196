package com.appcreator.compose

import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.staticCompositionLocalOf
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.components.Screen
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.theme.Theme
import com.appcreator.compose.components.InputEnvStore
import com.appcreator.compose.di.Container
import com.appcreator.compose.interfaces.Analytics

val LocalEnvStore = staticCompositionLocalOf {
    EnvStore.create(defaultLocale = "GB")
}

val LocalNavigator = staticCompositionLocalOf<Navigator?> {
    null
}

val LocalBlueprint = staticCompositionLocalOf<Blueprint> {
    error("No instance of Blueprint provided")
}

val LocalSelected = staticCompositionLocalOf {
    false
}

val LocalModal = staticCompositionLocalOf {
    false
}

val LocalInputEnvStore = staticCompositionLocalOf<InputEnvStore> {
    error("No instance of InputEnvStore provided")
}

val LocalTheme = staticCompositionLocalOf<Theme?> {
    null
}

val LocalScreen = staticCompositionLocalOf<State<Screen>> {
    error("No instance of Screen provided")
}

val LocalVerticalScrollingParent = staticCompositionLocalOf {
    false
}

val LocalHorizontalScrollingParent = staticCompositionLocalOf {
    false
}

val LocalNavigationDrawer = staticCompositionLocalOf {
    false
}

val LocalAbsoluteSize = staticCompositionLocalOf<AbsoluteSize?> {
    null
}

val LocalTriggerBus = staticCompositionLocalOf {
    TriggerBus()
}

val LocalInPreview = staticCompositionLocalOf {
    false
}

val LocalAnalytics = staticCompositionLocalOf<Analytics> {
    Container.analytics
}


val LocalDrawOnRoot = staticCompositionLocalOf<MutableState<(@Composable BoxScope.() -> Unit)?>> {
    mutableStateOf(null)
}