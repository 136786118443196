package com.appcreator.compose

import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect

@Composable
fun Modifier.tracingPaperBackground(
    color: Color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.3f)
) = drawWithCache {
        onDrawBehind {
            val pathEffect = PathEffect.dashPathEffect(floatArrayOf(10f, 10f), 0f)

            val step = 50

            for (i in step.div(2)..size.width.toInt() step step) {
                drawLine(
                    color = color,
                    start = Offset(i.toFloat(), 0f),
                    end = Offset(i.toFloat(), size.height),
                    pathEffect = pathEffect
                )
            }

            for (i in step.div(2)..size.height.toInt() step step) {
                drawLine(
                    color = color,
                    start = Offset(0f, i.toFloat()),
                    end = Offset(size.width, i.toFloat()),
                    pathEffect = pathEffect
                )
            }
        }
    }

