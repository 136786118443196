package com.appcreator.creatorapp.editor.inputs

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.spec.inputs.LocalizableStringInputSpec
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput

@Composable
fun LocalizableString(spec: LocalizableStringInputSpec, save: () -> Unit) {
    val locale = "gb" // TODO this -->
    DataSourceTextInput(
        label = spec.properties.title,
        value = spec.value?.values?.get(locale) ?: "",
        allowDataItems = spec.properties.allowDataFields,
        update = {
            spec.value = LocalizableString(values = mapOf(locale to it))
            save()
        }
    )
}