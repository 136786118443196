package com.appcreator.creatorapp.editor.centerdata.rest

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.conversions.conversionOptions
import com.appcreator.blueprint.dataspecs.ConversionDataSpecBuilder
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.editor.centerdata.rest.functions.FunctionBuilder
import com.appcreator.creatorapp.editor.local.DataOption
import com.appcreator.creatorapp.editor.local.LocalDataOptions
import com.appcreator.styles.ThemeMargins

@Composable
fun DataItemFunction(
    options: List<DataOption>,
    dataItem: ConversionDataSpecBuilder,
    save: () -> Unit
) {
    Row(modifier = Modifier.fillMaxHeight()) {

        Column(modifier = Modifier.width(200.dp).verticalScroll(rememberScrollState())) {

            conversionOptions().forEach { (spec, provider) ->
                NavigationDrawerItem(
                    selected = dataItem.conversion.value?.spec?.title == spec.title,
                    onClick = {
                        dataItem.conversion.value = provider.block()
                    },
                    label = {
                        Text(spec.title)
                    },
                    colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified)
                )
            }
        }

        VerticalDivider()

        Column(modifier = Modifier.padding(horizontal = ThemeMargins.MedMargins)) {

            CreatorTextInput(
                label = "Output Path",
                value = dataItem.key.value ?: "",
                onValueChange = {
                    dataItem.key.value = it
                    save()
                }
            )

            Box(Modifier.height(ThemeMargins.SmallMargin))

            dataItem.conversion.value?.let {
                CompositionLocalProvider(LocalDataOptions provides options) {
                    FunctionBuilder(it, save)
                }
            }
        }
    }
}
