package com.appcreator.creatorapp.api

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.appcreator.dto.DataSource

class MutableDataSource(dataSource: DataSource) {

    val id = dataSource.id
    var title by mutableStateOf(dataSource.title)
    var loaderSpec by mutableStateOf(dataSource.loaderSpec)

    fun toDataSource(): DataSource = DataSource(id, title, loaderSpec)
}
//
//class MutableDataShape(
//    dataShape: DataShape?
//) {
//
//    val options = mutableStateListOf(
//        *dataShape?.options?.map {
//            when(it) {
//                is ValueDataSpec -> MutableDataItem.Flat(mutableStateOf(it.key), mutableStateOf(it.visible), it.value)
//                is ListDataSpec -> MutableDataItem.List(mutableStateOf(it.key), MutableDataShape(it.shape))
//                is ConversionDataSpec -> MutableDataItem.Conversion(mutableStateOf(it.key), mutableStateOf(it.conversion?.toBuilder()))
//            }
//        }?.toTypedArray() ?: emptyArray()
//    )
//
//    fun toDataShape(): DataShape = DataShape(options.map {
//        when(it) {
//            is MutableDataItem.Flat -> DataItem.Flat(it.path.value, it.visible.value, it.example)
//            is MutableDataItem.List -> DataItem.List(it.path.value, it.shape.toDataShape())
//            is MutableDataItem.Conversion -> DataItem.Conversion(it.path.value, it.conversion.value?.build())
//        }
//    })
//}
//
//sealed class MutableDataItem(open val path: MutableState<String> = mutableStateOf("")) {
//    class Flat(
//        override val path: MutableState<String>,
//        val visible: MutableState<Boolean> = mutableStateOf(true),
//        val example: String? = null
//    ): MutableDataItem(path)
//    class List(override val path: MutableState<String>, val shape: MutableDataShape): MutableDataItem(path)
//    class Conversion(override val path: MutableState<String>, val conversion: MutableState<DataConversionBuilder?>): MutableDataItem(path)
//
//}

