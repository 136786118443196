package com.appcreator.creatorapp

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.appcreator.dto.OrganisationsResponse


@Composable
fun RootScreen(
    organisations: OrganisationsResponse,
    navigate: (String) -> Unit
) {
    LaunchedEffect(Unit) {
        val org = organisations.organisations.first()
        val link = "${Navigation.Organisations.route}/${org.id}"
        navigate(link)
    }
}