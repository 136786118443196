package com.appcreator.theme

import androidx.compose.ui.graphics.Color


val primaryLight = Color(0xFF00205D)
val onPrimaryLight = Color(0xFFFFFFFF)
val primaryContainerLight = Color(0xFF1C4192)
val onPrimaryContainerLight = Color(0xFFD6DEFF)
val secondaryLight = Color(0xFF6C5D31)
val onSecondaryLight = Color(0xFFFFFFFF)
val secondaryContainerLight = Color(0xFFF8E3AC)
val onSecondaryContainerLight = Color(0xFF55481E)
val tertiaryLight = Color(0xFF7F5700)
val onTertiaryLight = Color(0xFFFFFFFF)
val tertiaryContainerLight = Color(0xFFF1A916)
val onTertiaryContainerLight = Color(0xFF3C2700)
val errorLight = Color(0xFFBA1A1A)
val onErrorLight = Color(0xFFFFFFFF)
val errorContainerLight = Color(0xFFFFDAD6)
val onErrorContainerLight = Color(0xFF410002)
val backgroundLight = Color(0xFFFAF8FF)
val onBackgroundLight = Color(0xFF1A1B21)
val surfaceLight = Color(0xFFFCF8F8)
val onSurfaceLight = Color(0xFF1C1B1C)
val surfaceVariantLight = Color(0xFFE0E2F0)
val onSurfaceVariantLight = Color(0xFF444651)
val outlineLight = Color(0xFF747683)
val outlineVariantLight = Color(0xFFC4C6D3)
val scrimLight = Color(0xFF000000)
val inverseSurfaceLight = Color(0xFF313030)
val inverseOnSurfaceLight = Color(0xFFF4F0EF)
val inversePrimaryLight = Color(0xFFB3C5FF)
val surfaceDimLight = Color(0xFFDDD9D9)
val surfaceBrightLight = Color(0xFFFCF8F8)
val surfaceContainerLowestLight = Color(0xFFFFFFFF)
val surfaceContainerLowLight = Color(0xFFF7F3F2)
val surfaceContainerLight = Color(0xFFF1EDED)
val surfaceContainerHighLight = Color(0xFFEBE7E7)
val surfaceContainerHighestLight = Color(0xFFE5E2E1)

val primaryLightMediumContrast = Color(0xFF00205D)
val onPrimaryLightMediumContrast = Color(0xFFFFFFFF)
val primaryContainerLightMediumContrast = Color(0xFF1C4192)
val onPrimaryContainerLightMediumContrast = Color(0xFFFFFFFF)
val secondaryLightMediumContrast = Color(0xFF4F4219)
val onSecondaryLightMediumContrast = Color(0xFFFFFFFF)
val secondaryContainerLightMediumContrast = Color(0xFF837345)
val onSecondaryContainerLightMediumContrast = Color(0xFFFFFFFF)
val tertiaryLightMediumContrast = Color(0xFF5B3D00)
val onTertiaryLightMediumContrast = Color(0xFFFFFFFF)
val tertiaryContainerLightMediumContrast = Color(0xFF9C6B00)
val onTertiaryContainerLightMediumContrast = Color(0xFFFFFFFF)
val errorLightMediumContrast = Color(0xFF8C0009)
val onErrorLightMediumContrast = Color(0xFFFFFFFF)
val errorContainerLightMediumContrast = Color(0xFFDA342E)
val onErrorContainerLightMediumContrast = Color(0xFFFFFFFF)
val backgroundLightMediumContrast = Color(0xFFFAF8FF)
val onBackgroundLightMediumContrast = Color(0xFF1A1B21)
val surfaceLightMediumContrast = Color(0xFFFCF8F8)
val onSurfaceLightMediumContrast = Color(0xFF1C1B1C)
val surfaceVariantLightMediumContrast = Color(0xFFE0E2F0)
val onSurfaceVariantLightMediumContrast = Color(0xFF40424D)
val outlineLightMediumContrast = Color(0xFF5C5F6A)
val outlineVariantLightMediumContrast = Color(0xFF787A86)
val scrimLightMediumContrast = Color(0xFF000000)
val inverseSurfaceLightMediumContrast = Color(0xFF313030)
val inverseOnSurfaceLightMediumContrast = Color(0xFFF4F0EF)
val inversePrimaryLightMediumContrast = Color(0xFFB3C5FF)
val surfaceDimLightMediumContrast = Color(0xFFDDD9D9)
val surfaceBrightLightMediumContrast = Color(0xFFFCF8F8)
val surfaceContainerLowestLightMediumContrast = Color(0xFFFFFFFF)
val surfaceContainerLowLightMediumContrast = Color(0xFFF7F3F2)
val surfaceContainerLightMediumContrast = Color(0xFFF1EDED)
val surfaceContainerHighLightMediumContrast = Color(0xFFEBE7E7)
val surfaceContainerHighestLightMediumContrast = Color(0xFFE5E2E1)

val primaryLightHighContrast = Color(0xFF001E57)
val onPrimaryLightHighContrast = Color(0xFFFFFFFF)
val primaryContainerLightHighContrast = Color(0xFF173E8F)
val onPrimaryContainerLightHighContrast = Color(0xFFFFFFFF)
val secondaryLightHighContrast = Color(0xFF2B2100)
val onSecondaryLightHighContrast = Color(0xFFFFFFFF)
val secondaryContainerLightHighContrast = Color(0xFF4F4219)
val onSecondaryContainerLightHighContrast = Color(0xFFFFFFFF)
val tertiaryLightHighContrast = Color(0xFF311F00)
val onTertiaryLightHighContrast = Color(0xFFFFFFFF)
val tertiaryContainerLightHighContrast = Color(0xFF5B3D00)
val onTertiaryContainerLightHighContrast = Color(0xFFFFFFFF)
val errorLightHighContrast = Color(0xFF4E0002)
val onErrorLightHighContrast = Color(0xFFFFFFFF)
val errorContainerLightHighContrast = Color(0xFF8C0009)
val onErrorContainerLightHighContrast = Color(0xFFFFFFFF)
val backgroundLightHighContrast = Color(0xFFFAF8FF)
val onBackgroundLightHighContrast = Color(0xFF1A1B21)
val surfaceLightHighContrast = Color(0xFFFCF8F8)
val onSurfaceLightHighContrast = Color(0xFF000000)
val surfaceVariantLightHighContrast = Color(0xFFE0E2F0)
val onSurfaceVariantLightHighContrast = Color(0xFF21242E)
val outlineLightHighContrast = Color(0xFF40424D)
val outlineVariantLightHighContrast = Color(0xFF40424D)
val scrimLightHighContrast = Color(0xFF000000)
val inverseSurfaceLightHighContrast = Color(0xFF313030)
val inverseOnSurfaceLightHighContrast = Color(0xFFFFFFFF)
val inversePrimaryLightHighContrast = Color(0xFFE8EBFF)
val surfaceDimLightHighContrast = Color(0xFFDDD9D9)
val surfaceBrightLightHighContrast = Color(0xFFFCF8F8)
val surfaceContainerLowestLightHighContrast = Color(0xFFFFFFFF)
val surfaceContainerLowLightHighContrast = Color(0xFFF7F3F2)
val surfaceContainerLightHighContrast = Color(0xFFF1EDED)
val surfaceContainerHighLightHighContrast = Color(0xFFEBE7E7)
val surfaceContainerHighestLightHighContrast = Color(0xFFE5E2E1)

val primaryDark = Color(0xFFB3C5FF)
val onPrimaryDark = Color(0xFF002B75)
val primaryContainerDark = Color(0xFF002971)
val onPrimaryContainerDark = Color(0xFFA3BAFF)
val secondaryDark = Color(0xFFFFFFFF)
val onSecondaryDark = Color(0xFF3B2F07)
val secondaryContainerDark = Color(0xFFE7D39D)
val onSecondaryContainerDark = Color(0xFF4B3E15)
val tertiaryDark = Color(0xFFFFC66A)
val onTertiaryDark = Color(0xFF432C00)
val tertiaryContainerDark = Color(0xFFDD9A00)
val onTertiaryContainerDark = Color(0xFF291900)
val errorDark = Color(0xFFFFB4AB)
val onErrorDark = Color(0xFF690005)
val errorContainerDark = Color(0xFF93000A)
val onErrorContainerDark = Color(0xFFFFDAD6)
val backgroundDark = Color(0xFF121318)
val onBackgroundDark = Color(0xFFE2E2E9)
val surfaceDark = Color(0xFF141313)
val onSurfaceDark = Color(0xFFE5E2E1)
val surfaceVariantDark = Color(0xFF444651)
val onSurfaceVariantDark = Color(0xFFC4C6D3)
val outlineDark = Color(0xFF8E909D)
val outlineVariantDark = Color(0xFF444651)
val scrimDark = Color(0xFF000000)
val inverseSurfaceDark = Color(0xFFE5E2E1)
val inverseOnSurfaceDark = Color(0xFF313030)
val inversePrimaryDark = Color(0xFF3A5AAD)
val surfaceDimDark = Color(0xFF141313)
val surfaceBrightDark = Color(0xFF3A3939)
val surfaceContainerLowestDark = Color(0xFF0E0E0E)
val surfaceContainerLowDark = Color(0xFF1C1B1C)
val surfaceContainerDark = Color(0xFF201F20)
val surfaceContainerHighDark = Color(0xFF2A2A2A)
val surfaceContainerHighestDark = Color(0xFF353435)

val primaryDarkMediumContrast = Color(0xFFB9C9FF)
val onPrimaryDarkMediumContrast = Color(0xFF00133E)
val primaryContainerDarkMediumContrast = Color(0xFF6F8EE3)
val onPrimaryContainerDarkMediumContrast = Color(0xFF000000)
val secondaryDarkMediumContrast = Color(0xFFFFFFFF)
val onSecondaryDarkMediumContrast = Color(0xFF3B2F07)
val secondaryContainerDarkMediumContrast = Color(0xFFE7D39D)
val onSecondaryContainerDarkMediumContrast = Color(0xFF281E00)
val tertiaryDarkMediumContrast = Color(0xFFFFC66A)
val onTertiaryDarkMediumContrast = Color(0xFF281800)
val tertiaryContainerDarkMediumContrast = Color(0xFFDD9A00)
val onTertiaryContainerDarkMediumContrast = Color(0xFF000000)
val errorDarkMediumContrast = Color(0xFFFFBAB1)
val onErrorDarkMediumContrast = Color(0xFF370001)
val errorContainerDarkMediumContrast = Color(0xFFFF5449)
val onErrorContainerDarkMediumContrast = Color(0xFF000000)
val backgroundDarkMediumContrast = Color(0xFF121318)
val onBackgroundDarkMediumContrast = Color(0xFFE2E2E9)
val surfaceDarkMediumContrast = Color(0xFF141313)
val onSurfaceDarkMediumContrast = Color(0xFFFEFAF9)
val surfaceVariantDarkMediumContrast = Color(0xFF444651)
val onSurfaceVariantDarkMediumContrast = Color(0xFFC8CAD7)
val outlineDarkMediumContrast = Color(0xFFA0A2AF)
val outlineVariantDarkMediumContrast = Color(0xFF80828F)
val scrimDarkMediumContrast = Color(0xFF000000)
val inverseSurfaceDarkMediumContrast = Color(0xFFE5E2E1)
val inverseOnSurfaceDarkMediumContrast = Color(0xFF2B2A2A)
val inversePrimaryDarkMediumContrast = Color(0xFF1F4394)
val surfaceDimDarkMediumContrast = Color(0xFF141313)
val surfaceBrightDarkMediumContrast = Color(0xFF3A3939)
val surfaceContainerLowestDarkMediumContrast = Color(0xFF0E0E0E)
val surfaceContainerLowDarkMediumContrast = Color(0xFF1C1B1C)
val surfaceContainerDarkMediumContrast = Color(0xFF201F20)
val surfaceContainerHighDarkMediumContrast = Color(0xFF2A2A2A)
val surfaceContainerHighestDarkMediumContrast = Color(0xFF353435)

val primaryDarkHighContrast = Color(0xFFFCFAFF)
val onPrimaryDarkHighContrast = Color(0xFF000000)
val primaryContainerDarkHighContrast = Color(0xFFB9C9FF)
val onPrimaryContainerDarkHighContrast = Color(0xFF000000)
val secondaryDarkHighContrast = Color(0xFFFFFFFF)
val onSecondaryDarkHighContrast = Color(0xFF000000)
val secondaryContainerDarkHighContrast = Color(0xFFE7D39D)
val onSecondaryContainerDarkHighContrast = Color(0xFF000000)
val tertiaryDarkHighContrast = Color(0xFFFFFAF7)
val onTertiaryDarkHighContrast = Color(0xFF000000)
val tertiaryContainerDarkHighContrast = Color(0xFFFFC054)
val onTertiaryContainerDarkHighContrast = Color(0xFF000000)
val errorDarkHighContrast = Color(0xFFFFF9F9)
val onErrorDarkHighContrast = Color(0xFF000000)
val errorContainerDarkHighContrast = Color(0xFFFFBAB1)
val onErrorContainerDarkHighContrast = Color(0xFF000000)
val backgroundDarkHighContrast = Color(0xFF121318)
val onBackgroundDarkHighContrast = Color(0xFFE2E2E9)
val surfaceDarkHighContrast = Color(0xFF141313)
val onSurfaceDarkHighContrast = Color(0xFFFFFFFF)
val surfaceVariantDarkHighContrast = Color(0xFF444651)
val onSurfaceVariantDarkHighContrast = Color(0xFFFCFAFF)
val outlineDarkHighContrast = Color(0xFFC8CAD7)
val outlineVariantDarkHighContrast = Color(0xFFC8CAD7)
val scrimDarkHighContrast = Color(0xFF000000)
val inverseSurfaceDarkHighContrast = Color(0xFFE5E2E1)
val inverseOnSurfaceDarkHighContrast = Color(0xFF000000)
val inversePrimaryDarkHighContrast = Color(0xFF002568)
val surfaceDimDarkHighContrast = Color(0xFF141313)
val surfaceBrightDarkHighContrast = Color(0xFF3A3939)
val surfaceContainerLowestDarkHighContrast = Color(0xFF0E0E0E)
val surfaceContainerLowDarkHighContrast = Color(0xFF1C1B1C)
val surfaceContainerDarkHighContrast = Color(0xFF201F20)
val surfaceContainerHighDarkHighContrast = Color(0xFF2A2A2A)
val surfaceContainerHighestDarkHighContrast = Color(0xFF353435)

val autumnJoyLight = Color(0xFFA73A00)
val onAutumnJoyLight = Color(0xFFFFFFFF)
val autumnJoyContainerLight = Color(0xFFFC7337)
val onAutumnJoyContainerLight = Color(0xFF1F0500)
val hydrangeaLight = Color(0xFF133DCF)
val onHydrangeaLight = Color(0xFFFFFFFF)
val hydrangeaContainerLight = Color(0xFF4767F5)
val onHydrangeaContainerLight = Color(0xFFFFFFFF)

val autumnJoyLightMediumContrast = Color(0xFF792800)
val onAutumnJoyLightMediumContrast = Color(0xFFFFFFFF)
val autumnJoyContainerLightMediumContrast = Color(0xFFC84D11)
val onAutumnJoyContainerLightMediumContrast = Color(0xFFFFFFFF)
val hydrangeaLightMediumContrast = Color(0xFF0030B8)
val onHydrangeaLightMediumContrast = Color(0xFFFFFFFF)
val hydrangeaContainerLightMediumContrast = Color(0xFF4767F5)
val onHydrangeaContainerLightMediumContrast = Color(0xFFFFFFFF)

val autumnJoyLightHighContrast = Color(0xFF421200)
val onAutumnJoyLightHighContrast = Color(0xFFFFFFFF)
val autumnJoyContainerLightHighContrast = Color(0xFF792800)
val onAutumnJoyContainerLightHighContrast = Color(0xFFFFFFFF)
val hydrangeaLightHighContrast = Color(0xFF001768)
val onHydrangeaLightHighContrast = Color(0xFFFFFFFF)
val hydrangeaContainerLightHighContrast = Color(0xFF0030B8)
val onHydrangeaContainerLightHighContrast = Color(0xFFFFFFFF)

val autumnJoyDark = Color(0xFFFFB599)
val onAutumnJoyDark = Color(0xFF5A1C00)
val autumnJoyContainerDark = Color(0xFFC84D11)
val onAutumnJoyContainerDark = Color(0xFFFFFFFF)
val hydrangeaDark = Color(0xFFB9C3FF)
val onHydrangeaDark = Color(0xFF00228B)
val hydrangeaContainerDark = Color(0xFF4868F5)
val onHydrangeaContainerDark = Color(0xFFFFFFFF)

val autumnJoyDarkMediumContrast = Color(0xFFFFBBA2)
val onAutumnJoyDarkMediumContrast = Color(0xFF2E0A00)
val autumnJoyContainerDarkMediumContrast = Color(0xFFED682D)
val onAutumnJoyContainerDarkMediumContrast = Color(0xFF000000)
val hydrangeaDarkMediumContrast = Color(0xFFBFC8FF)
val onHydrangeaDarkMediumContrast = Color(0xFF000E4B)
val hydrangeaContainerDarkMediumContrast = Color(0xFF6F88FF)
val onHydrangeaContainerDarkMediumContrast = Color(0xFF000000)

val autumnJoyDarkHighContrast = Color(0xFFFFF9F8)
val onAutumnJoyDarkHighContrast = Color(0xFF000000)
val autumnJoyContainerDarkHighContrast = Color(0xFFFFBBA2)
val onAutumnJoyContainerDarkHighContrast = Color(0xFF000000)
val hydrangeaDarkHighContrast = Color(0xFFFCFAFF)
val onHydrangeaDarkHighContrast = Color(0xFF000000)
val hydrangeaContainerDarkHighContrast = Color(0xFFBFC8FF)
val onHydrangeaContainerDarkHighContrast = Color(0xFF000000)

