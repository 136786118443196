package com.appcreator.blueprint.actions.permission

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("PermissionAction")
@ActionClass(
    group = "",
    title = "Request Permission",
    availableFrom = "0.0.1",
    accessible = true
)
class PermissionRequestAction(
    @InputProperty(
        title = "Type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("PermissionRequestAction.Permission.Notifications")
    val permission: Permission?
): Action {

    enum class Permission {
        Notifications,
        Location
    }
}