package com.appcreator.blueprint.firebase.auth

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseAuthSignUp")
@ActionClass(
    group = "Firebase Auth",
    title = "Sign Up",
    availableFrom = "0.0.1"
)
class FirebaseAuthSignUpAction(
    @InputProperty(
        title = "Email",
        availableFrom = "0.0.1"
    )
    val email: String?,
    @InputProperty(
        title = "Password",
        availableFrom = "0.0.1"
    )
    val password: String?
): Action
