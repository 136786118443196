package com.appcreator.compose.extensions

import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

fun parseToMilliseconds(dateString: String): Long? =
    try { Instant.parse(dateString).toEpochMilliseconds() } catch (ex: Exception) {
        ex.printStackTrace()
        null
    }

fun parseToDateString(milliseconds: Long): String? =
    try { Instant.fromEpochMilliseconds(milliseconds).toString() } catch (ex: Exception) {
        ex.printStackTrace()
        null
    }

fun createDateParts(dateString: String?): Map<String, String> {
    return try {
        dateString?.let {
            val localDate = Instant.parse(it).toLocalDateTime(TimeZone.currentSystemDefault())

            // TODO localize this
            val month =
                localDate.month.name.lowercase().replaceFirstChar { char -> char.titlecase() }
            val day =
                localDate.dayOfWeek.name.lowercase().replaceFirstChar { char -> char.titlecase() }
            mapOf(
                "date-parts.year" to localDate.year.toString(),
                "date-parts.month-number" to localDate.monthNumber.toString(),
                "date-parts.month-name" to month,
                "date-parts.month-name-short" to month.take(3),
                "date-parts.day-number" to localDate.dayOfMonth.toString(),
                "date-parts.day-name" to day,
                "date-parts.day-name-short" to day.take(3),
                "date-parts.hour-24" to localDate.hour.toString().padStart(2, '0'),
                "date-parts.minute" to localDate.minute.toString().padStart(2, '0'),
                "date-parts.second" to localDate.second.toString().padStart(2, '0'),
            )
        }?: emptyMap()
    } catch (ex: Exception) {
        emptyMap()
    }
}
