package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("TodayDataConversion")
@ConversionClass(
    title = "Today",
    availableFrom = "0.0.1"
)
class TodayConversion: Conversion