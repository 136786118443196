package com.appcreator.blueprint.spec

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateList
import com.appcreator.blueprint.spec.inputs.InputProperties

abstract class SingleInputSpec<T>(
    properties: InputProperties,
    value: MutableState<T>
): InputSpec(properties) {
    var value by value
}

abstract class ListInputSpec<T>(
    properties: InputProperties,
    val value: SnapshotStateList<T>
): InputSpec(properties)

abstract class InputSpec(
    val properties: InputProperties,
)