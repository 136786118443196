package com.appcreator.creatorapp.editor

import androidx.compose.runtime.mutableStateListOf
import com.appcreator.dto.UserComponent
import com.appcreator.shared.api.ApiClient

class CustomComponentManager(
    private val orgId: String,
    private val projectId: String,
    private val apiClient: ApiClient,
    _components: List<UserComponent>
) {

    val components = mutableStateListOf(*_components.toTypedArray())

    suspend fun createComponent(name: String): UserComponent {
        val component = apiClient.createUserComponents(orgId, projectId, name)
        components.add(component)
        return component
    }

}