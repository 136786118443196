package com.appcreator.local

import androidx.compose.runtime.staticCompositionLocalOf
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.format.MonthNames
import kotlinx.datetime.format.char
import kotlinx.datetime.toLocalDateTime

class DateTimeFormatter {

    private val formatter = LocalDateTime.Format {
        hour()
        char(':')
        minute()
        char(' ')
        dayOfMonth()
        char(' ')
        monthName(MonthNames.ENGLISH_ABBREVIATED)
    }

    fun format(instant: Instant): String = instant.toLocalDateTime(TimeZone.currentSystemDefault()).format(formatter)

}

val LocalDateTimeFormatter = staticCompositionLocalOf {
    DateTimeFormatter()
}