package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.spec.inputs.BooleanInputSpec
import com.appcreator.styles.ThemeMargins

@Composable
fun BooleanInput(spec: BooleanInputSpec, save: () -> Unit) {
    Box(modifier = Modifier.padding(vertical = ThemeMargins.SmallMargin)) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Checkbox(
                checked = spec.value ?: false,
                onCheckedChange = {
                    spec.value = it
                    save()
                }
            )
            Text(spec.properties.title)
        }
    }
}
